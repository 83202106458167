import { omitBy, isUndefined } from 'lodash'
import QueryString from 'query-string'

type QueryParams = {
  projectId: string
  roleNameNotIn?: string[]
}

export class GetProjectPositionListQuery {
  query = ({ projectId, roleNameNotIn }: QueryParams): string => {
    const query = omitBy({
      company_role__not_in: roleNameNotIn?.length ? roleNameNotIn.join(',') : undefined,
    }, isUndefined)

    const queryString = QueryString.stringify(query, { skipNull: true })

    

    return `projects/${projectId}/users/positions?${queryString}`
  }
}