import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetSystemUserByIdQuery } from './get/GetSystemUserByIdQuery'
import { GetSystemUserListQuery } from './get/GetSystemUserListQuery'
import { PutUserRoleByIdQuery } from './put/PutUserRoleByIdQuery'
import { PutSystemUserByIdQuery } from './put/PutSystemUserByIdQuery'
import { DeleteUserByIdQuery } from './DeleteUserByIdQuery'
import { PostUserWorkerByIdQuery } from './post/PostUserWorkerByIdQuery'
import { PostExcelWorkersQuery } from './post/PostExcelWorkersQuery'
import { baseQuery } from '../BaseQuery'

export const systemUserApi = createApi({
  reducerPath: 'systemUserApi',
  tagTypes: ['systemUser', 'systemUserById'],
  baseQuery: fetchBaseQuery(baseQuery),
  endpoints: (builder) => ({
    getList: builder.query({
      ...new GetSystemUserListQuery(),
      providesTags: ['systemUser']
    }),
    getById: builder.query({
      ...new GetSystemUserByIdQuery(),
      providesTags: ['systemUser', 'systemUserById']
    }),
    putUser: builder.mutation({
      ...new PutSystemUserByIdQuery(),
      invalidatesTags: ['systemUser']
    }),
    putRoleById: builder.mutation({
      ...new PutUserRoleByIdQuery(),
      invalidatesTags: ['systemUser']
    }),
    deleteById: builder.mutation({
      ...new DeleteUserByIdQuery(),
      invalidatesTags: ['systemUser']
    }),
    postUserWorker: builder.mutation({
      ...new PostUserWorkerByIdQuery(),
      invalidatesTags: ['systemUser']
    }),
    postExcelWorkers: builder.mutation({
      ...new PostExcelWorkersQuery(),
      invalidatesTags: ['systemUser']
    })
  })
})