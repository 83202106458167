import { propInit } from '../type'

export const chatChannelsInitSettings: propInit = {
  initApp: true,
  uniqueName: 'chat',
  path: '/$PROJECT$/chats?access_token=$TOKEN$',
  pathData:'data',
  dependencies: [
    {
      state: {
        actionTypeDependence: 'SET_ACTIVE_PROJECT',
        pathActionType: 'payload',
        pathStore: 'app.activeProjectId'
      },
      uniqueKey: '$PROJECT$'
    }
  ],
}
