import React from 'react'
import { SnackbarKey, SnackbarMessage, SnackbarProviderProps } from 'notistack'

//TODO-перенести
import CustomNotification from '../../../../(deprecated)/components/UI/CustomNotification/CustomNotification'

export const appSnackbarConfig: Omit<SnackbarProviderProps, 'children'> = {
  autoHideDuration: 3000,
  maxSnack: 3,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  } as const,
  content: (key: SnackbarKey, message: SnackbarMessage) => (
    <CustomNotification
      //@ts-ignore
      id={key}
      notification={message}
    />
  )
}