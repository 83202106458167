export const getFullName = ({ firstName = '', middleName = '', lastName = '' } = {}) =>
  [lastName, firstName, middleName].filter(Boolean).join(' ') ?? ''

export const getShortName = ({ firstName = '', middleName = '', lastName = '' } = {}) => {
  if (!lastName) return [firstName, middleName].filter(Boolean).join(' ') || ''
  if (!firstName) return lastName || ''

  const lastNameAndShortFirstName = `${[lastName, firstName[0]].join(' ')}.`
  return middleName
    ? `${lastNameAndShortFirstName} ${middleName[0]}.`
    : lastNameAndShortFirstName
}