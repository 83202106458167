import React from 'react'
import { useDispatch } from 'react-redux'
import { CardLink } from './ui/CardLink'
import { useRightSidebarLinks} from './hooks/useRightSidebarLinks'
import { useTSelector } from '../../../utils/hooks/reduxHooks'
import { sidebarActions } from '../../../redux/sidebar/sidebarSlice'

import AppNotificationList from '../../UI/AppNotificationList/AppNotificationList'
import * as Icon from '../../../../fsd/shared/ui-icons'
import cn from 'classnames'
import './RightSidebar.scss'


export const RightSidebar = () => {
  const dispatch = useDispatch()

  const isOpenedRightSidebar = useTSelector(state => state.sidebar.isOpenedRightSidebar)

  const {
    rightSidebarBottomLinks,
    rightSidebarTopLinks,
    isOpenAppNotification,
    setIsOpenAppNotification
  } = useRightSidebarLinks()

  const handleToggleSidebar = () => (
    dispatch(sidebarActions.setIsOpenRightSidebar(!isOpenedRightSidebar))
  )

  return (
    <div className={cn('right-sidebar-wrapper', { closed: !isOpenedRightSidebar })}>
      <div className='sidebar-open-btn' onClick={handleToggleSidebar}>
        <Icon.ChevronWithoutBorder />
      </div>

      <AppNotificationList
        visible={isOpenAppNotification}
        onClose={() => setIsOpenAppNotification(false)}
      />

      {rightSidebarTopLinks.map(({ children, ...props }) => (
        <CardLink {...props}>
          {children}
        </CardLink>
      ))}


      <div className='bottom-links'>
        {rightSidebarBottomLinks.map(({ children, ...props }) => (
          <CardLink {...props}>
            {children}
          </CardLink>
        ))}
      </div>
    </div>
  )
}

