import React from 'react'
import { IIcon } from './IIcon'

export const EmptyReportsTemplates: React.FC<IIcon> = ({
  width = '6.2',
  height = '5.9',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg width={`${width}rem`} className={className} height={`${height}rem`} {...innerProps} viewBox="0 0 62 59" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="4.3471" width="53.764" height="53.764" rx="26.882" fill="#F2F2F2"/>
    <path
      d="M35.9698 19.2356C36.5002 19.2356 37.0089 19.4463 37.384 19.8214L42.1787 24.6161C42.5538 24.9912 42.7645 25.4999 42.7645 26.0303V34.5291C42.7645 35.6337 41.869 36.5291 40.7645 36.5291H27.471C26.3664 36.5291 25.471 35.6337 25.471 34.5291V21.2356C25.471 20.1311 26.3664 19.2356 27.471 19.2356H35.9698ZM36.7982 16.7651H25.471C24.1122 16.7651 23.0005 17.8768 23.0005 19.2356V36.5291C23.0005 37.8879 24.1122 38.9996 25.471 38.9996H42.7645C44.1232 38.9996 45.235 37.8879 45.235 36.5291V25.2019C45.235 24.5472 44.9756 23.9172 44.5062 23.4602L38.5399 17.4939C38.0829 17.0245 37.4529 16.7651 36.7982 16.7651ZM27.9415 32.8234C27.9415 32.1412 28.4945 31.5881 29.1767 31.5881H39.0587C39.7409 31.5881 40.294 32.1412 40.294 32.8234C40.294 33.5056 39.7409 34.0586 39.0587 34.0586H29.1767C28.4945 34.0586 27.9415 33.5056 27.9415 32.8234ZM27.9415 27.8824C27.9415 27.2002 28.4945 26.6471 29.1767 26.6471H39.0587C39.7409 26.6471 40.294 27.2002 40.294 27.8824C40.294 28.5646 39.7409 29.1176 39.0587 29.1176H29.1767C28.4945 29.1176 27.9415 28.5646 27.9415 27.8824ZM27.9415 22.9414C27.9415 22.2592 28.4945 21.7061 29.1767 21.7061H35.353C36.0352 21.7061 36.5882 22.2592 36.5882 22.9414C36.5882 23.6236 36.0352 24.1766 35.353 24.1766H29.1767C28.4945 24.1766 27.9415 23.6236 27.9415 22.9414Z"
      fill={color}/>
    <rect x="7.23535" y="1" width="53.7647" height="53.7647" rx="26.8824" stroke="#5C5C5C" stroke-width="2"
          stroke-linecap="round" stroke-dasharray="10 15"/>
  </svg>
)