import { useChangeFaviconNotification } from '../../../(deprecated)/utils/hooks/useChangeFaviconNotification'
import { useAmplitudeInitialize } from '../../../(deprecated)/utils/hooks/useAmplitudeInitialize'
import { useManageUserDataToLS } from './useManageUserDataToLS'
import { useSetUserProjectToLS } from './useSetUserProjectToLS'
import { useTSelector } from '../../../(deprecated)/utils/hooks/reduxHooks'

export const useApp = () => {

  const userInfo = useTSelector(state => state.user.userInfo)

  useChangeFaviconNotification()
  useAmplitudeInitialize(userInfo.id)
  useSetUserProjectToLS()
  useManageUserDataToLS()
}