import * as actions from './actionCreators'
import { put, post, get, deleteRequest } from '../../utils/axios'

import camelcaseFields, { snakecaseFields } from '../../utils/formatFields'
import { getProjectsMy } from '../project/actions'
import { addedNewNotification } from '../notifications/actions'
import { amplitudeLogEvent, identify } from '../../amplitude'
import { AMPLITUDE_EVENTS } from '../../amplitude/events'
import { normalizeDataProfile } from '../../utils/normalizeDataProfile'
import { getRefresh, removeTenantInfo, setToken } from '../../utils/localStorageManagement'

export const putProfileInfo = payload => {
  return dispatch => {
    put('/profile', payload)
      .then(({ data }) => {
        const result = camelcaseFields(data)
        dispatch(actions.putProfileInfoSucceed({ ...result, ...result.profile }))
      })
  }
}

//подключение АПИ

export const getConfirmInvite = (sesame) => (
  dispatch => {
    dispatch(actions.setConfirmInviteLoading())
    get({ path: `/users/confirm_invite?sesame=${sesame}` })
      .then((data) => {

        dispatch(actions.getConfirmInviteSuccess({ ...data, ...data.profile }))
      })
      .catch(() => dispatch(actions.getConfirmInviteFailed()))
  }
)

export const postRegisterUser = (userRegisterPayload, sesame) => (
  async dispatch => {
    dispatch(actions.setLoadingRegisterUserStart())

    try {
      const userInfo = await put(`/users/register_user?sesame=${sesame}`, snakecaseFields(userRegisterPayload))
      userInfo.user = normalizeDataProfile(userInfo.user)
      dispatch(actions.postRegisterUserSuccess(userInfo))
      identify(userInfo.user.id)
      amplitudeLogEvent({ event: AMPLITUDE_EVENTS.SIGN_UP_SUCCESS, options: userInfo.user })
      dispatch(getProjectsMy(userInfo.user.company.id))
    } catch (error) {
      dispatch(actions.postRegisterUserFailed(error))
    }
  }
)

export const postAuth = ({ user, onSuccess, onFail }) => (
  async dispatch => {
    try {
      dispatch(actions.setLoadingAuthStart())
      const res = await post(`/users/auth`, user)

      res.user = normalizeDataProfile(res.user)
      dispatch(actions.postAuthSuccess(res))

      identify(res.user.id)
      amplitudeLogEvent({ event: AMPLITUDE_EVENTS.LOG_IN_SUCCESS, options: res.user })

      dispatch(getProjectsMy(res.user.company.id, false))
      onSuccess?.()
    } catch (error) {
      dispatch(actions.postAuthFailed(error?.detail))
      onFail?.(error)
    }
  }
)

export const postAuthTenant = ({ user, onSuccess, onFail }) => (
  async (dispatch) => {
    try {
      dispatch(actions.setIsLoadingTenants(true))
      const response = await post('/accounts/auth', user)
      dispatch(actions.setTenantsList(response))
      onSuccess?.()
    } catch (error) {
      dispatch(actions.postAuthFailed(error?.detail))
      removeTenantInfo()
      onFail?.(error)
    } finally {
      dispatch(actions.setIsLoadingTenants(false))
    }
  }
)

export const postAuthRefresh = (callback = null) => (
  async dispatch => {
    try {
      const refresh = getRefresh()
      dispatch(actions.setLoadingAuthRefresh())
      const responseToken = await post(`/users/auth/refresh`, { refresh })
      dispatch(actions.postAuthRefreshSuccess(responseToken))
      setToken(responseToken)
      callback && callback()
    } catch (e) {
      dispatch(addedNewNotification({ message: 'Не удалось обновить токен авторизации' }))
    }
  }
)

export const getUser = (callback = null) => (
  dispatch => {
    dispatch(actions.setLoadingGetUser())
    get({ path: '/users/me' })
      .then(data => {
        const normalizeData = normalizeDataProfile(data)
        dispatch(actions.getUserSuccess(normalizeData))
        dispatch(getProjectsMy(normalizeData.company.id, false))
        callback && callback()
      })
      .catch(() => dispatch(actions.getUserFailed()))
  }
)

export const putUser = (data, callback = () => { }) => (
  dispatch => {
    dispatch(actions.setLoadingPutUser())
    put('/users/me', { profile: { ...data, image: data.image?.id } })
      .then(data => {
        //normilizeData
        const normalizeData = normalizeDataProfile(data)
        dispatch(actions.putUserSuccess(normalizeData))
        dispatch(addedNewNotification({ message: 'Изменения сохранены' }))
        callback?.()
      })
      .catch(() => {
        dispatch(actions.putUserFailed())
        dispatch(addedNewNotification({ message: 'Не удалось сохранить изменения' }))
      })
  }
)

export const putSignature = (id, sign, callback, typeUser) => (
  dispatch => {
    dispatch(actions.setLoadingPostSignature)
    put(`/users/${id}/sign`, { sign })
      .then((data) => {
        dispatch(actions.postSignatureSuccess(data.sign))
        dispatch(addedNewNotification({ message: 'Подпись добавлена' }))
      })
      .catch(() => {
        dispatch(addedNewNotification({ message: 'Не удалось добавить подпись' }))
        dispatch(actions.postSignatureFailed())
      })
      .then(() => callback())
  }
)

export const putNotificationSettings = (payload) => (
  dispatch => {
    put('/users/me/setting_notifications', { ...payload })
      .catch(() => {
        dispatch(addedNewNotification({ message: 'Не удалось изменить настройки уведомлений' }))
      })
  }
)

export const deleteSignature = (id, callback) => (
  dispatch => {
    deleteRequest(`/users/${id}/sign`)
      .then(() => {
        dispatch(actions.postSignatureSuccess())
        dispatch(actions.deleteSignatureSuccess())
        dispatch(addedNewNotification({ message: 'Подпись успешно сброшена' }))
      })
      .catch(() => {
        dispatch(addedNewNotification({ message: 'Не удалось удалить подпись' }))
        dispatch(actions.postSignatureFailed())
      })
  }
)

export const postNewPassword = (password, callback) => (
  dispatch => {
    dispatch(actions.toggleLoadingPostNewPassword())
    post('/users/change_password', { password })
      .then(() => {
        dispatch(actions.toggleLoadingPostNewPassword())
        dispatch(actions.postNewPasswrodSucces())
        callback()
      })
      .catch((error) => {
        dispatch(actions.postNewPasswordFailed({ password: error.password }))
        dispatch(actions.toggleLoadingPostNewPassword())
      })
  }
)

export const getConfirmPassword = (sesame) => (
  dispatch => {
    dispatch(actions.setLoadingGetConfirmPasswordStart())
    get({ path: `/users/change_password`, params: { sesame } })
      .then(() => {
        dispatch(actions.setLoadingGetConfirmPasswordFinish())
        dispatch(addedNewNotification({ message: 'Пароль был успешно изменен' }))
      })
      .catch(() => {
        dispatch(actions.setLoadingGetConfirmPasswordFinish())
        dispatch(addedNewNotification({ message: 'Не удалось обновить пароль' }))
      })
  }
)

export const getUserOnboarding = () => (
  dispatch => {
    get({ path: '/users/me/onboarding' })
      .then(data => dispatch(actions.getUserOnboardingSuccess(data)))
      .catch(() => dispatch(addedNewNotification({ message: 'Информация по онбордингу не может быть получена' })))
  }
)

export const putUserOnboarding = (onboardingData) => (
  dispatch => {
    dispatch(actions.togglePutOnbordingLoading())
    const param = snakecaseFields(onboardingData)
    const resStr = Object
      .keys(param)
      .reduce((accum, key) => accum.concat(`&${key}=${param[key]}`), '')
    put(`/users/me/onboarding?${resStr}`)
      .then(data => dispatch(actions.putUserOnboardingSuccess(data)))
      .finally(() => dispatch(actions.togglePutOnbordingLoading()))
  }
)

export const postResetPassword = (email, isUrlWithoutTenant) => (
  dispatch => {
    const requestEndpoint = isUrlWithoutTenant
      ? '/accounts/reset_password'
      : '/users/reset_password'

    dispatch(actions.setLoadingResetPassword())
    post(requestEndpoint, { email })
      .then(() => dispatch(actions.postResetPasswordSuccess()))
      .catch(data => dispatch(actions.postResetPasswordFailed(data)))
  }
)

export const putResetPassword = ({ sesame, password }, isUrlWithoutTenant, callback) => (
  dispatch => {
    const requestEndpoint = isUrlWithoutTenant
      ? `/accounts/reset_password?sesame=${sesame}`
      : `/users/reset_password?sesame=${sesame}`

    dispatch(actions.setLoadingResetPassword())
    put(requestEndpoint, { password })
      .then(() => callback())
      .catch(data => dispatch(actions.putResetPasswordFailed(data)))
  }
)


export const getUserRoleInProject = ({ projectId }) => (
  dispatch => {
    dispatch(actions.setUserRoleLoading('pending'))
    get({ path: `/users/me/role_in_project/${projectId}` })
      .then((payload) => {
        dispatch(actions.getUserRoleInProjectSuccess(payload.userRole))
        dispatch(actions.setUserRoleLoading('succeeded'))
      })
      .catch(() => {
        dispatch(addedNewNotification({ message: 'Не удалось получить ролевую модель пользователя' }))
        dispatch(actions.setUserRoleLoading('failed'))
      })
  }
)
