import React, { useEffect, useRef } from 'react'
import { SnackbarProvider } from 'notistack'
import { appSnackbarConfig } from './snackbarConfig'

//TODO - перенести
import { useTSelector } from '../../../../(deprecated)/utils/hooks/reduxHooks'

export const AppSnackBarProvider:React.FC<PropsType> = ({
  children
}) => {

  const snackbarRef = useRef<SnackbarProvider>(null)
  const notification = useTSelector(state => state.notification)

  useEffect(() => {
    notification.message && snackbarRef.current?.enqueueSnackbar(notification)
  }, [notification])

  return (
    <SnackbarProvider
      ref={snackbarRef}
      {...appSnackbarConfig}
    >
      {children}
    </SnackbarProvider>
  )
}

type PropsType = {
  children: React.ReactNode
}