import React from 'react'
import { IIcon } from './IIcon'

export const Lock: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.875 6.875H15.125V4.8125C15.125 3.71848 14.6904 2.66927 13.9168 1.89568C13.1432 1.1221 12.094 0.6875 11 0.6875C9.90598 0.6875 8.85677 1.1221 8.08318 1.89568C7.3096 2.66927 6.875 3.71848 6.875 4.8125V6.875H4.125C3.76033 6.875 3.41059 7.01987 3.15273 7.27773C2.89487 7.53559 2.75 7.88533 2.75 8.25V17.875C2.75 18.2397 2.89487 18.5894 3.15273 18.8473C3.41059 19.1051 3.76033 19.25 4.125 19.25H17.875C18.2397 19.25 18.5894 19.1051 18.8473 18.8473C19.1051 18.5894 19.25 18.2397 19.25 17.875V8.25C19.25 7.88533 19.1051 7.53559 18.8473 7.27773C18.5894 7.01987 18.2397 6.875 17.875 6.875ZM11 14.0938C10.796 14.0938 10.5967 14.0333 10.4271 13.92C10.2575 13.8066 10.1253 13.6456 10.0472 13.4571C9.9692 13.2687 9.94877 13.0614 9.98856 12.8613C10.0284 12.6613 10.1266 12.4775 10.2708 12.3333C10.415 12.1891 10.5988 12.0909 10.7988 12.0511C10.9989 12.0113 11.2062 12.0317 11.3946 12.1097C11.5831 12.1878 11.7441 12.32 11.8575 12.4896C11.9708 12.6592 12.0312 12.8585 12.0312 13.0625C12.0312 13.336 11.9226 13.5983 11.7292 13.7917C11.5358 13.9851 11.2735 14.0938 11 14.0938ZM13.75 6.875H8.25V4.8125C8.25 4.08315 8.53973 3.38368 9.05546 2.86796C9.57118 2.35223 10.2707 2.0625 11 2.0625C11.7293 2.0625 12.4288 2.35223 12.9445 2.86796C13.4603 3.38368 13.75 4.08315 13.75 4.8125V6.875Z"
      fill={color}/>
  </svg>
)
