import { IDocAttachmet } from './../../models/IDocs'
import { ParamLocation } from '../../components/pages/Docs/types'
import { IDoc, IDocShort } from '../../models/IDocs'
import { IDynamicPageNumberPagination } from '../../models/IPagination'
import { RootState } from '../store'
import { createThunk } from '../../utils/hooks/reduxHooks'
import {
  afterAction,
  clearDoc,
  setDoc,
  toggleLoadingApproveDocument,
  toggleLoadingDoc,
  toggleLoadingDocsShort,
  toggleLoadingNotNegotiateDocument,
  toggleLoadingUpdateAccess,
  toggleLoadingWideUpdateAccessDocs,
  updateAttachments
} from './docsSlice'
import { withoutHost } from '../../utils/axios/withoutHost'
import { snakecaseFields } from '../../utils/formatFields'
import { addedNewNotification } from '../notifications/actions'
import { omit } from 'lodash'
import get from '../../utils/axios/get'
import put from '../../utils/axios/put'
import QueryString from 'query-string'

export interface IGetDocsPayload {
  box: ParamLocation['box']
  projectId: number | null,
  filters: {
    template_id__in?: string | null
    status?: string | null
    company_id__in?: string | number | (string | number)[] | null
    date_create_before?: string | null
    date_create_after?: string | null,
    creator_id__in?: string | number | (string | number)[] | null
    title?: string | null
  }
}
interface IGetDocsResponse extends IDynamicPageNumberPagination<IDocShort> {
  newDocumentsCount: number
}

export const getDocsShort = createThunk<IGetDocsResponse, Partial<IGetDocsPayload> | string>(
  'docs/get-docs',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingDocsShort('pending'))

      if (typeof payload == 'string') {
        // withoutHost нужен для того чтобы хост и версия api не дублировались в запросе
        const res = await get<IGetDocsResponse>({ path: withoutHost(payload) })
        dispatch(toggleLoadingDocsShort('succeeded'))
        return res
      }

      const path = payload.box == 'incoming'
        ? `/docflow/${payload.projectId}/incoming-documents`
        : '/docflow/documents'

      const res = await get<IGetDocsResponse>({
        path,
        params: {
          page: 1,
          per_page: 25,
          order_by: 'id',
          project: payload.projectId,
          ...payload.filters,
        },
        withCancellation: true,
        onCancellation: () => {
          dispatch(toggleLoadingDocsShort('pending'))
        }
      })

      dispatch(toggleLoadingDocsShort('succeeded'))
      return res

    } catch (error) {
      dispatch(toggleLoadingDocsShort('failed'))
      return rejectWithValue(error)
    }
  }
)

export const getDoc = createThunk<void, string>(
  'docs/get-doc',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingDoc('pending'))
      const res = await get<IDoc>({ path: `/docflow/documents/${id}` })
      dispatch(toggleLoadingDoc('succeeded'))
      dispatch(setDoc(res))
    } catch (error) {
      dispatch(toggleLoadingDoc('failed'))
      return rejectWithValue(error)
    }
  }
)

interface IPutDocTitlePayload {
  id: number
  title: string
  setError?: (error: string) => void
}

export const putDocTitle = createThunk<{ id: number, changes: { title: string } }, IPutDocTitlePayload>(
  'docs/put-doc-title',
  async ({ id, title, setError }, { dispatch, rejectWithValue }) => {
    try {
      const res = await put<IDoc>(`/docflow/documents/${id}`, { title: title })
      return { id: res.id, changes: { title: res.title } }
    }
    catch (error) {
      setError?.((error as { title: string }).title[0])
      return rejectWithValue(error)
    }
  }
)

// ACTIONS
interface IPutApproveDocumentPayload {
  documentId: number,
  userId: number
  actionInfo: {
    action?: number,
    tableAction?: number
    comment?: string
  },
  cb?: () => void
}
export const putApproveDocument = createThunk<IDoc, IPutApproveDocumentPayload>(
  'docs/approve-document',
  async ({ documentId, actionInfo, userId, cb }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingApproveDocument('pending'))

      const res = await put<IDoc>(`/docflow/${documentId}/approve_document`, actionInfo)

      dispatch(setDoc(res))
      dispatch(afterAction({ doc: res, userId }))
      dispatch(toggleLoadingApproveDocument('succeeded'))

      cb?.()
      return res
    } catch (error) {
      dispatch(addedNewNotification({ message: 'Не удалось подписать документ ' + error }))
      dispatch(toggleLoadingApproveDocument('failed'))

      return rejectWithValue(error)
    }
  }
)

interface IPutRejectDocument {
  documentId: number,
  rejectionReason: string
  userId: number
}
export const putRejectDocument = createThunk<IDoc, IPutRejectDocument>(
  'docs/reject-document',
  async ({ documentId, rejectionReason, userId }, { dispatch, rejectWithValue }) => {
    try {
      const res = await put<IDoc>(`/docflow/${documentId}/reject_document`, snakecaseFields({ rejectionReason }))

      dispatch(setDoc(res))
      dispatch(afterAction({ doc: res, userId }))

      return res
    } catch (error) {
      dispatch(addedNewNotification({ message: 'Не удалось отозвать документ ' + error }))

      return rejectWithValue(error)
    }
  }
)

interface IPutNotNegotiateDocument {
  documentId: number,
  userId: number,
  rejectInfo: {
    action?: number | undefined;
    tableAction?: number | undefined;
    rejectionReason: string;
  },
  cb?: () => void
}

export const putNotNegotiate = createThunk<IDoc, IPutNotNegotiateDocument>(
  'docs/not-negotiate-document',
  async ({ documentId, rejectInfo, userId, cb }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingNotNegotiateDocument('pending'))

      const res = await put<IDoc>(`/docflow/${documentId}/not_negotiate`, snakecaseFields({ ...rejectInfo }))

      dispatch(setDoc(res))
      dispatch(afterAction({ doc: res, userId }))
      dispatch(toggleLoadingNotNegotiateDocument('succeeded'))

      cb?.()

      return res
    } catch (error) {
      dispatch(addedNewNotification({ message: 'Не удалось отказаться от подписания документа ' + error }))
      dispatch(toggleLoadingNotNegotiateDocument('failed'))

      return rejectWithValue(error)
    }
  }
)

interface IPutUpdateAccessDocPayload {
  id: number,
  data: {
    editors: number[],
    watchers: number[]
  },
  cb?: () => void
}

export const putUpdateAccessDoc = createThunk<IDoc, IPutUpdateAccessDocPayload>(
  'doc/put-update-access',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingUpdateAccess('pending'))
      const res = await put<IDoc>(`/docflow/documents/${payload.id}/update_users_access`, { admitted_users: payload.data })
      dispatch(addedNewNotification({ message: 'Настройки доступа обновлены' }))
      dispatch(toggleLoadingUpdateAccess('succeeded'))
      payload.cb?.()
      return res
    } catch (error: any) {
      dispatch(toggleLoadingUpdateAccess('failed'))
      dispatch(addedNewNotification({
        message: 'Не удалось обновить настройки доступа. ' + error?.nonFieldErrors[0] ?? ''
      }))
      return rejectWithValue(error)
    }
  }
)

interface IPutWideUpdateAccessDocs {
  data: {
    project: number
    documents: number[],
    allDocuments: boolean,
    typeDocuments: "incoming" | "outgoing",
    assign: {
      editors: number[],
      watchers: number[]
    },
    delete: {
      editors: number[],
      watchers: number[]
    },
    filters: IGetDocsPayload['filters']
  }
  cbSuccess?: () => void
  cbError?: () => void
}

export const putWideUpdateAccessDocs = createThunk<IPutWideUpdateAccessDocs, IPutWideUpdateAccessDocs>(
  'doc/put-wide-access-docs',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    try {
      dispatch(toggleLoadingWideUpdateAccessDocs('pending'))
      const queryString = QueryString.stringify({ ...payload.data.filters }, { skipNull: true })
      await put(`/docflow/documents/update-users-access?${queryString}`, snakecaseFields(omit(payload.data, ['filters'])))
      dispatch(toggleLoadingWideUpdateAccessDocs('succeeded'))
      dispatch(addedNewNotification({ message: 'Настройки доступа успешно обновлены.' }))

      dispatch(getDocsShort({
        box: payload.data.typeDocuments == 'outgoing' ? 'sent' : payload.data.typeDocuments,
        projectId: payload.data.project,
        filters: payload.data.filters
      }))

      const state = getState() as RootState
      state.docs.doc && dispatch(getDoc(state.docs.doc.id.toString()))
      payload.cbSuccess?.()

      return payload
    } catch (error) {
      payload.cbError?.()
      dispatch(toggleLoadingWideUpdateAccessDocs('failed'))
      dispatch(addedNewNotification({ message: error }))
      return rejectWithValue(error)
    }
  }
)

interface IUpdateDocAttachments {
  attachmentId: number
  userDocuments: number[]
  callback?: () => void
}

// DOC ATTACHMENTS
export const updateDocAttachments = createThunk<void, IUpdateDocAttachments>(
  'docs/update-attachments',
  async ({ attachmentId, userDocuments, callback }, { dispatch }) => {
    try {
      const res = await put<IDocAttachmet>(`/docflow/documents/attachments/${attachmentId}`, snakecaseFields({ userDocuments }))

      dispatch(updateAttachments(res))
      dispatch(addedNewNotification({ message: 'Вложение успешно обновлено' }))

      callback?.()
    } catch (error) {
      dispatch(addedNewNotification({ message: 'Не удалось обновить вложение' }))
    }
  }
)
