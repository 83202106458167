import React from 'react'

import './EmptyPlaceholderInSquare.scss'

export const EmptyPlaceholderInSquare: React.FC<PropType> = React.memo(({
  text,
  children
}) => (
  <div className='empty-project-user-table-placeholder'>
    {text}
    {children}
  </div>
))

type PropType = {
  text: string,
  children?: React.ReactNode | React.ReactNode[] 
}
