import {
  GET_USER_LICENSES_SUCCESS,
  SET_LOADING_USER_LICENSES,
  GET_USER_LICENSES_FAILED,
  POST_USER_LICENSES_FAILED,
  POST_DOCUMENT_MARK_SUCCEED,
  TOGGLE_LOADING_USER_SAVED_DOC,
  GET_USER_SAVED_DOC_SUCCESS,
  GET_USER_SAVED_DOC_FAILED,
  TOGGLE_LOADING_USER_NEW_SAVED_DOC,
  PUT_USER_SAVED_DOC_UPDATE_TITLE,
  DELETE_USER_LICENSE_SUCCESS,
  SET_ACTIVE_USER_LICENSE,
  SET_IS_USER_LICENSE_LOADING
} from './constant'

const initialState = {
  isLoading: false,
  licensesList: null,
  activeUserLicense: null,
  isUserLicenseLoading: false,
  licensesCount: 0,
  savedDocumentsList: [],
  savedDocumentsMeta: {},
  isLoadingSavedDoc: false,
  isLoadingNewSavedDoc: false,
}

export const userDocumentsReducer = (state = initialState, { payload, type }) => {
  switch (type) {

    case SET_LOADING_USER_LICENSES:
      return { ...state, isLoading: true }

    case GET_USER_LICENSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        licensesList: payload.results,
        licensesCount: payload.count
      }
    case SET_ACTIVE_USER_LICENSE:
      return {
        ...state,
        activeUserLicense: payload
      }
    case SET_IS_USER_LICENSE_LOADING:
      return {
        ...state,
        isUserLicenseLoading: payload
      }

    case GET_USER_LICENSES_FAILED:
      return { ...state, isLoading: false }

    case POST_USER_LICENSES_FAILED:
      return state

    case POST_DOCUMENT_MARK_SUCCEED: {
      const newDocumentsList = [...state.documentList]
      const targetDocIndex = newDocumentsList.findIndex(doc => doc.id === payload.documentId)
      const newDoc = newDocumentsList[targetDocIndex]
      newDoc.marks = newDoc.marks?.length ? [...newDoc.marks, payload] : [payload]

      newDocumentsList[targetDocIndex] = newDoc
      return {
        ...state,
        documentList: newDocumentsList,
      }
    }

    case TOGGLE_LOADING_USER_SAVED_DOC:
      return { ...state, isLoadingSavedDoc: !state.isLoadingSavedDoc }

    case GET_USER_SAVED_DOC_SUCCESS:
      return {
        ...state,
        savedDocumentsList: payload.results,
        savedDocumentsMeta: { ...payload.meta, totalResults: payload.count }
      }

    case GET_USER_SAVED_DOC_FAILED:
      return { ...state, isLoadingSavedDoc: false }

    case TOGGLE_LOADING_USER_NEW_SAVED_DOC:
      return { ...state, isLoadingNewSavedDoc: !state.isLoadingNewSavedDoc }

    case PUT_USER_SAVED_DOC_UPDATE_TITLE:
      return {
        ...state,
        savedDocumentsList: state.savedDocumentsList
          .map(doc => {
            if (doc.id === payload.id) {
              doc.title = payload.title
              return doc
            }
            return doc
          })
      }

    case DELETE_USER_LICENSE_SUCCESS:
      return {
        ...state,
        licensesList: state.licensesList.map(license => (
          license.id === payload
            ? { type: license.type }
            : license
        ))
      }

    default:
      return state
  }
}