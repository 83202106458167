import camelcaseKeys from 'camelcase-keys';
import { ILocation } from '../../../../(deprecated)/models/ILocation';
import { BuilderProjectLocationApi } from './types';


interface ILocationTreeResponse {
  treeBranchNodes: ILocation[]
  leafNeighbours: ILocation[]
}

interface ILocationQueryParam {
  projectId: number
  leafId: number
}

export const createGetLocationsTreeQuery = (builder: BuilderProjectLocationApi) => (
  builder.query<ILocationTreeResponse, ILocationQueryParam>({
    query: (param) => (
      `projects/${param.projectId}/locations_tree_branch/${param.leafId}?with_neighbours=true`
    ),
    transformResponse: (response: any) => camelcaseKeys(response) as ILocationTreeResponse
  })
)