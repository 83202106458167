import React, { useEffect, useState } from 'react'

import { SetState } from '../../types/commonTypes'

import Button from '../Button/Button'

export const ButtonWithInterval: React.FC<PropsType> = React.memo(({ title, interval, onClick }) => {
  const [time, setTime] = useState(interval);

  const formattedTime = `00:${time < 10 ? '0' : ''}${time}`

  // TODO пока решил оставить так, т к в завтрашний день не все могут смотреть.
  // Вернее смотреть могут не только лишь все, мало кто может это делать
  const buttonTitle = `${title} ${!!time ? `через ${formattedTime}` : ''}`

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [time]);

  return (
    <Button
      title={buttonTitle}
      onClick={() => onClick(setTime)}
      disabled={!!time}
      {...!time && { buttonType: 'blue-ligth' }}
    />
  )
})

type PropsType = {
  title: string,
  interval: number,
  onClick: (setTime: SetState<number>) => void
}