import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IDynamicPageNumberPaginationMeta } from '../../models/IPagination'
import { loadingObj, LoadingType } from '../utilityObject'
import {
  IModel,
  IModelAdditionalProperty,
  IModelAdditionalPropertyInfo,
  IModelEntity,
  IModelProperty,
  IModelPropertyListValue,
  IModelTemplateProperties,
  IModelTemplatePropertiesInfo
} from '../../models/IModel'
import {
  getEntityData,
  getModelAdditionalProperties,
  getModelAdditionalPropertiesInfo,
  getModelPropertiesList,
  getModelPropertyColValues,
  getModels,
  getModelsProperties,
  getModelTemplateProperties,
  getModelTemplatesPropertyInfo,
} from './thunk'
import lodash from 'lodash'

export const modelsAdapter = createEntityAdapter<IModel>()
export const modelPropertiesAdapter = createEntityAdapter<IModelProperty>()
export const modelEntitiesAdapter = createEntityAdapter<IModelEntity>()
export const modelAdditionalPropertiesInfoAdapter = createEntityAdapter<IModelAdditionalPropertyInfo>()
export const modelAdditionalPropertiesAdapter = createEntityAdapter<IModelAdditionalProperty>()
export const modelTemplatesPropertyInfoAdapter = createEntityAdapter<IModelTemplatePropertiesInfo>()
export const modelPropertyListValuesAdapter = createEntityAdapter<IModelPropertyListValue>()

const initialState = {
  models: modelsAdapter.getInitialState({
    ...loadingObj
  }),
  modelProperties: modelPropertiesAdapter.getInitialState({
    ...loadingObj
  }),
  modelEntities: modelEntitiesAdapter.getInitialState({
    meta: null as IDynamicPageNumberPaginationMeta | null,
    ...loadingObj
  }),
  modelAdditionalPropertiesInfo: modelAdditionalPropertiesInfoAdapter.getInitialState({
    ...loadingObj
  }),
  modelAdditionalProperties: modelAdditionalPropertiesAdapter.getInitialState({
    additionalModelPropertiesInfoName: '',
    ...loadingObj
  }),
  modelTemplatesPropertyInfo: modelTemplatesPropertyInfoAdapter.getInitialState({
    meta: null as IDynamicPageNumberPaginationMeta | null,
    ...loadingObj
  }),
  modelTemplateProperties: null as IModelTemplateProperties | null,
  modelPropertyColValues: null as string[] | null,
  modelPropertyColValuesLoading: 'idle' as LoadingType,

  modelPropertyListValues: modelPropertyListValuesAdapter.getInitialState({
    meta: null as IDynamicPageNumberPaginationMeta | null,
    ...loadingObj
  }),

  modelsReportDownloadXlsLoading: 'idle' as LoadingType
}

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    toggleLoadingModelProperties(state, action: PayloadAction<LoadingType>) {
      state.modelProperties.loading = action.payload
    },
    toggleLoadingModelTemplatesPropertiesInfo(state, action: PayloadAction<LoadingType>) {
      state.modelTemplatesPropertyInfo.loading = action.payload
    },
    toggleLoadingEntityData(state, action: PayloadAction<LoadingType>) {
      state.modelEntities.loading = action.payload
    },
    toggleLoadingAdditionalPropertiesInfo(state, action: PayloadAction<LoadingType>) {
      state.modelAdditionalPropertiesInfo.loading = action.payload
    },
    toggleLoadingAdditionalProperties(state, action: PayloadAction<{ loading: LoadingType, name: string }>) {
      state.modelAdditionalProperties.additionalModelPropertiesInfoName = action.payload.name
      state.modelAdditionalProperties.loading = action.payload.loading
    },
    clearModelAdditionalProperties(state) {
      modelAdditionalPropertiesAdapter.removeAll(state.modelAdditionalProperties)
      state.modelAdditionalProperties.additionalModelPropertiesInfoName = ''
      state.modelAdditionalProperties.loading = 'idle'
    },
    toggleLoadingModelPropertiesColValues(state, action: PayloadAction<LoadingType>) {
      state.modelPropertyColValuesLoading = action.payload
    },
    toggleLoadingModelPropertiesListValues(state, action: PayloadAction<LoadingType>) {
      state.modelPropertyListValues.loading = action.payload
    },
    toggleLoadingModeDownloadReportXls(state, action: PayloadAction<LoadingType>) {
      state.modelsReportDownloadXlsLoading = action.payload
    },
    clearModelPropertyListValues(state) {
      modelPropertyListValuesAdapter.removeAll(state.modelPropertyListValues)
      state.modelPropertyListValues.meta = null
      state.modelPropertyListValues.loading = 'idle'
    },
    clearModelPropertyColValues(state) {
      state.modelPropertyColValues = null
    },
    reset() {
      return initialState
    }
  },
  extraReducers: (builder) => {
    // Model
    builder.addCase(getModels.fulfilled, (state, { payload }) => {
      const uniqueModel: IModel[] = payload.map((model) => ({
        ...model,
        id: model.name,
      }))
      modelsAdapter.setAll(state.models, uniqueModel)
    })

    // Model Properties
    builder.addCase(getModelsProperties.fulfilled, (state, { payload }) => {
      const uniqueModelProperties: IModelProperty[] = payload.map((modelProperties) => ({
        ...modelProperties,
        id: modelProperties.name,
      }))
      modelPropertiesAdapter.setAll(state.modelProperties, uniqueModelProperties)
    })

    // Model Entity
    builder.addCase(getEntityData.fulfilled, (state, { payload }) => {
      modelEntitiesAdapter.setAll(state.modelEntities, payload.results)

      if (payload.meta.totalPages > 0)
        state.modelEntities.meta = payload.meta
      else
        state.modelEntities.meta = null
    })

    // Additional model properties
    builder.addCase(getModelAdditionalPropertiesInfo.fulfilled, (state, { payload }) => {
      const uniqueAdditionalModelPropertiesInfo: IModelAdditionalPropertyInfo[] = payload.map((additionalModelPropertyInfo) => ({
        ...additionalModelPropertyInfo,
        id: additionalModelPropertyInfo.name,
      }))
      modelAdditionalPropertiesInfoAdapter.setAll(state.modelAdditionalPropertiesInfo, uniqueAdditionalModelPropertiesInfo)
      state.modelAdditionalProperties.additionalModelPropertiesInfoName = ''
    })

    builder.addCase(getModelAdditionalProperties.fulfilled, (state, { payload }) => {
      const uniqueData = payload.data.map(({ count, fields, templateName }) => ({
        id: lodash.uniqueId(),
        count,
        templateName,
        fields: fields.map((modelProperties) => ({
          ...modelProperties,
          id: modelProperties.name
        }))
      }))
      modelAdditionalPropertiesAdapter.setAll(state.modelAdditionalProperties, uniqueData)
      state.modelAdditionalProperties.additionalModelPropertiesInfoName = payload.additionalModelPropetiesInfoName
    })

    // Templates
    builder.addCase(getModelTemplatesPropertyInfo.fulfilled, (state, { payload }) => {
      if (payload.meta.page > 1)
        modelTemplatesPropertyInfoAdapter.addMany(state.modelTemplatesPropertyInfo, payload.results)
      else
        modelTemplatesPropertyInfoAdapter.setAll(state.modelTemplatesPropertyInfo, payload.results)
      state.modelTemplatesPropertyInfo.meta = payload.meta
    })

    builder.addCase(getModelTemplateProperties.fulfilled, (state, { payload }) => {
      state.modelTemplateProperties = {
        ...payload,
        filterFields: payload.filterFields.map(modelProperty => ({
          ...modelProperty,
          id: modelProperty.name
        }))
      }
    })

    // Model Property Col Values
    builder.addCase(getModelPropertyColValues.fulfilled, (state, { payload }) => {
      state.modelPropertyColValues = payload
    })

    builder.addCase(getModelPropertiesList.fulfilled, (state, { payload }) => {
      const convertedResults = payload.results.map(item => ({
        ...item,
        id: lodash.uniqueId()
      }))

      if (payload.meta.page > 1)
        modelPropertyListValuesAdapter.addMany(state.modelPropertyListValues, convertedResults)
      else
        modelPropertyListValuesAdapter.setAll(state.modelPropertyListValues, convertedResults)
      state.modelPropertyListValues.meta = payload.meta
    })
  }
})
export const {
  toggleLoadingModelTemplatesPropertiesInfo,
  clearModelPropertyColValues,
  toggleLoadingModelProperties,
  toggleLoadingEntityData,
  toggleLoadingAdditionalPropertiesInfo,
  toggleLoadingAdditionalProperties,
  clearModelAdditionalProperties,
  toggleLoadingModelPropertiesColValues,
  toggleLoadingModelPropertiesListValues,
  toggleLoadingModeDownloadReportXls,
  clearModelPropertyListValues,
  reset
} = modelSlice.actions
export default modelSlice.reducer