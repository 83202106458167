import * as _ from 'lodash'
import { 
  SET_DOCUMENTS_LOADING_START,
  GET_DOCUMENTS_LIST_FAILED,
  GET_DOCUMENTS_LIST_SUCCESS,
  SET_LOADING_POST_NEW_DOCUMENT,
  POST_NEW_DOCUMENT_SUCCESS,
  POST_NEW_DOCUMENT_FAILED,
  PUT_DOCUMENT_STATUS_SUCCEED,
  GET_DOCUMENT_STATUSES_SUCCEED,
  PUT_FLOW_DOCUMENT_SUCCESS,
} from './constants'

const initialState = {
  list: [],
  isLoading: false,
  isAddedDocument: false,
  failedDocuments: [],
  isLoadingActiveDocument: false,
  activeDocument: {},
  isLoadingDocumentApprove: false,
  isLoadingDocumentReject: false,
  isLoadingAdmissionOrder: false,
}

export const documentsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DOCUMENTS_LOADING_START:
      return { ...state, isLoading: true }

    case GET_DOCUMENTS_LIST_SUCCESS: {
      return { 
        ...state, 
        list: payload,
        isLoading: false,
        isAddedDocument: false
      }
    }

    case GET_DOCUMENTS_LIST_FAILED:
      return { ...state, list: [], isLoading: false }

    case SET_LOADING_POST_NEW_DOCUMENT:
      return { ...state, isAddedDocument: true, statusAddedDocument: '' }

    case POST_NEW_DOCUMENT_SUCCESS:
      return { 
        ...state, 
        list: [ ...state.list, payload ], 
        isAddedDocument: false, 
      }

    case POST_NEW_DOCUMENT_FAILED:
      return {
        ...state,
        isAddedDocument: false,
      }

    case PUT_DOCUMENT_STATUS_SUCCEED: {
      const { documentId, newStatus } = payload
      const newList = [...state.list]
      const targetIndex = newList.findIndex(document => document.id === documentId)
      newList[targetIndex] = { ...newList[targetIndex], ...newStatus }
      return { ...state, list: [...newList]  }
    }

    case GET_DOCUMENT_STATUSES_SUCCEED:
      return { ...state, statuses: payload }

    case PUT_FLOW_DOCUMENT_SUCCESS:
      // const newFlowDocumentList = [...state.list]
      // const changingIndex = newFlowDocumentList.findIndex(({ id }) => id === payload.id)
      // newFlowDocumentList[changingIndex] = payload
      return { ...state }

    default:
      return state
  } 
}