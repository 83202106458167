import { ADDED_NEW_NOTIFICATION_SUCCESS, SET_CLEAR_NOTIFICATION } from './constants'

const initialState = {
  title: '',
  message: '',
  buttonList: []
}

export const notificationReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case ADDED_NEW_NOTIFICATION_SUCCESS:
      return payload
    default:
      return state
  }
}