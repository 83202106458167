import React from 'react'
import { FileChip } from '../FileChip/FileChip'
import { StatusFileUpload } from '../../types/types'

import * as Icons from '../../../../shared/ui-icons'

import './FileChipWithRemove.scss'

export const FileChipWithRemove = <T extends BaseFile,>({
  file,
  className,
  onRemove
}: PropType<T>) => (
  <FileChip
    className={className}
    title={file.title}
    statusFileUpload={file.statusFileUpload}
    control={
      <Icons.Close
        width='2.4'
        height='2.4'
        color='#5C5C5C'
        className='remove-file-icon'
        onClick={(e) => onRemove(file.id)}
      />
    }
  />
)

type BaseFile = { id?: number | string, title: string, statusFileUpload: StatusFileUpload }

type PropType<T extends BaseFile> = {
  file: T,
  className?: string,
  onRemove: (id: BaseFile['id']) => void
}
