import React from 'react'
import { useRootAppLoading } from './hooks/useRootAppLoading'
import Loader from '../../../../(deprecated)/components/UI/Loader/Loader'
export const AppLoading:React.FC<PropsType> = ({
  children
}) => {

  const { isRootAppLoading } = useRootAppLoading()

  return (
    isRootAppLoading
      ? <Loader />
      : <>{children}</>
  )
}

type PropsType = {
  children: React.ReactNode | React.ReactNode[]
}