import React from 'react'
import { getDocumentFormat, isImage } from '../../../../../(deprecated)/utils/getDocumentFormat'
import { ImagePreview } from './ImagePreview/ImagePreview'

import PreviewFilePdf from '../../../../../(deprecated)/components/UI/PreviewFile/PreviewFilePdf'
import filePreview from '../../../../shared/assets/preview-file.png'

export const FilePreview = React.memo<PropsType>(({
  title,
  url,
  customClassName
}) => {

  const isAttachmentImage = isImage(title)
  const isPdf = getDocumentFormat(title) === '.pdf'

  if (url && isPdf)
    return <PreviewFilePdf urlFile={url} isMiniPreview />

  if (isAttachmentImage)
    return <ImagePreview url={url} customClassName={customClassName} />

  return (
    <div className={customClassName}>
      <img src={filePreview} />
    </div>
  )
})

type PropsType = {
  title: string
  url: string
  customClassName?: string
}