import React from 'react'
import { IIcon } from './IIcon'

export const Filter: React.FC<IIcon> = ({
  width = '1.8',
  height = '1.8',
  color = 'black',
  opacity='0.64',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 18 18" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 7.24264L12.4393 8.3033L9.25736 5.12132L6.07538 8.3033L5.01472 7.24264L9.25736 3L13.5 7.24264Z"
          fill={color} fillOpacity={opacity}/>
    <path
      d="M13.5 10.8325L12.4393 9.77181L9.25736 12.9538L6.07538 9.77181L5.01472 10.8325L9.25736 15.0751L13.5 10.8325Z"
      fill={color} fillOpacity={opacity}/>
  </svg>
)