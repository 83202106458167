import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CatalogTabsType } from '../../../entities/Catalog/types/tabsType'
import { SortOrderType } from '../../../features/Catalog/CatalogFIlter/ui/SortBtn/SortBtn'
import { CatalogEntityType } from '../../../entities/Catalog/types/entitiesType'
import { IDynamicPageNumberPaginationMeta } from '../../../../(deprecated)/models/IPagination'

type InitialState = {
  filters: {
    activeTab: CatalogTabsType
    sortOrder: SortOrderType
    searchValue: string
  }
  meta: IDynamicPageNumberPaginationMeta | null
  entities: Map<string, CatalogEntityType[]> | null
  isNotFound: boolean
}

const initialState: InitialState = {
  filters: {
    activeTab: 'companies',
    sortOrder: 'direct',
    searchValue: ''
  },
  meta: null,
  entities: null,
  isNotFound: false
}

const catalogSlice = createSlice({
  name: 'catalog-slice',
  initialState,
  reducers: {

    toggleCatalogOrder(state) {
      state.filters.sortOrder = state.filters.sortOrder === 'direct' ? 'reverse' : 'direct'
      state.meta = null
      state.entities = null
    },

    switchCatalogTab(state, { payload }: PayloadAction<CatalogTabsType>) {
      if (state.filters.activeTab === payload)
        return

      state.filters.activeTab = payload
      state.filters.sortOrder = 'direct'
      state.filters.searchValue = ''
      state.meta = null
      state.entities = null
      state.isNotFound = false
    },

    setCatalogSearchValue(state, { payload }: PayloadAction<string>) {
      state.filters.searchValue = payload
      state.meta = null
      state.entities = null
      state.isNotFound = false
    },

    setCatalogEntites(state, { payload }: PayloadAction<Map<string, CatalogEntityType[]>>) {
      if (!state.entities) {
        state.entities = payload
        return
      }

      payload.forEach((value, key) => {
        state.entities!.has(key)
          ? state.entities!.set(key, [...state.entities?.get(key) as [], ...value])
          : state.entities!.set(key, value)
      })
    },

    setCatalogEntitesIsNotFound(state, { payload }: PayloadAction<boolean>) {
      state.isNotFound = payload
    },

    setCatalogMeta(state, { payload }: PayloadAction<IDynamicPageNumberPaginationMeta>) {
      state.meta = payload

      if (!payload.totalResults && state.filters.searchValue) {
        state.isNotFound = true
        return
      }

      state.isNotFound = false
    },

    resetCatalogData(state) {
      state.filters.activeTab = 'companies'
      state.filters.searchValue = ''
      state.filters.sortOrder = 'direct'

      state.meta = null
      state.entities = null
      state.isNotFound = false
    },
  }
})

export const {
  toggleCatalogOrder,
  switchCatalogTab,
  setCatalogSearchValue,
  setCatalogEntites,
  setCatalogMeta,
  setCatalogEntitesIsNotFound,
  resetCatalogData,
} = catalogSlice.actions

export default catalogSlice.reducer