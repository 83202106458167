import { ICompany } from '../../models/ICompany'
import { CompanyAdapter } from './CompanyAdapter'

export class GetCompanyByIdQuery {
  query = ({ id }: { id: number }) => {
    return `companies/${id}`
  }

  transformResponse = (response: ICompany) => {
    return new CompanyAdapter(response).getFields()
  }
}