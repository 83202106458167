import React from 'react'
import { IIcon } from './IIcon'

export const Briefcase:React.FC<IIcon> = ({
  width = '18.7',
  height = '17.7',
  color = '#5C5C5C',
  opacity='0.48',
  ...innerProps
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} viewBox="0 0 187 177" fill="none" xmlns="http://www.w3.org/2000/svg" {...innerProps}>
    <rect x="0.165039" y="11.6372" width="164.998" height="164.998" rx="82.4989" fill="#E8F1FB"/>
    <g clipPath="url(#clip0)">
      <path
        d="M117.498 64.9106V65.9106H118.498H133.662C137.279 65.9106 140.244 68.8747 140.244 72.4924V83.8651C140.244 87.4827 137.279 90.4468 133.662 90.4468H111.916V87.6559C111.916 85.0187 109.763 82.8651 107.126 82.8651H99.5438C96.9065 82.8651 94.7529 85.0187 94.7529 87.6559V90.4468H73.0076C69.352 90.4468 66.4258 87.5207 66.4258 83.8651V72.4924C66.4258 68.8747 69.3899 65.9106 73.0076 65.9106H88.1711H89.1711V64.9106C89.1711 57.085 95.5091 50.7471 103.335 50.7471C111.16 50.7471 117.498 57.085 117.498 64.9106ZM96.7529 65.9106H110.916H111.916V64.9106C111.916 60.1884 108.057 56.3288 103.335 56.3288C98.6124 56.3288 94.7529 60.1884 94.7529 64.9106H95.715V65.9106H95.7529H96.7529ZM107.126 100.029C109.42 100.029 111.349 98.3984 111.811 96.2377H136.453V110.401C136.453 114.019 133.489 116.983 129.871 116.983H76.8364C73.2187 116.983 70.2546 114.019 70.2546 110.401V96.2377H94.8583C95.3208 98.3984 97.2492 100.029 99.5438 100.029H107.126Z"
        stroke="#1A65DA" strokeWidth="2"/>
    </g>
    <rect x="20.835" y="1.36523" width="165" height="165" rx="82.5" stroke="#1A65DA" strokeWidth="2"
          strokeLinecap="round" strokeDasharray="10 15"/>
    <defs>
      <clipPath id="clip0">
        <rect width="90.9813" height="90.9813" fill="white" transform="translate(57.8442 38.3745)"/>
      </clipPath>
    </defs>
  </svg>
)