import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoadingType, loadingObj } from '../utilityObject'
import { ITemplate } from '../../models/ITemplate'
import { ICategoriesStructure, ICategory } from '../../models/ICategory'
import {
  createTemplatesCategory,
  deleteTemplate,
  getCategoriesList,
  getTemplate,
  getTemplatesCategories,
  postCopyTemplate
} from './thunk'

export const categoriesListAdapter = createEntityAdapter<ICategory>()

const initialState = {
  templatesCategoriesList: {
    data: {} as ICategoriesStructure,
    ...loadingObj
  },
  categoriesList: categoriesListAdapter.getInitialState({
    ...loadingObj
  }),
  activeDocTemplate: {
    activeTemplate: null as null | ITemplate,
    ...loadingObj
  }
}

const docsTemplatesSlice = createSlice({
  name: 'docsTemplates',
  initialState,
  reducers: {
    setTemplatesCategoriesLoading(state, action: PayloadAction<LoadingType>) {
      state.templatesCategoriesList.loading = action.payload
    },
    setDocTemplateIsLoading(state, action: PayloadAction<LoadingType>) {
      state.activeDocTemplate.loading = action.payload
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getTemplatesCategories.fulfilled, (state, { payload }) => {
      state.templatesCategoriesList.data = payload
    })

    builder.addCase(getCategoriesList.fulfilled, (state, { payload }) => {
      categoriesListAdapter.setAll(state.categoriesList, payload)
    })

    builder.addCase(createTemplatesCategory.fulfilled, (state, { payload }) => {
      categoriesListAdapter.addOne(state.categoriesList, payload)
      state.templatesCategoriesList.data[payload.title] = []
    })

    builder.addCase(getTemplate.fulfilled, (state, { payload }) => {
      state.activeDocTemplate.activeTemplate = payload
    })

    builder.addCase(deleteTemplate.fulfilled, (state, { payload }) => {
      // Изменить респонс бекенда, чтобы не было такой херни
      state.templatesCategoriesList.data = Object.keys(state.templatesCategoriesList.data).reduce((accum, key) => ({
        ...accum,
        [key]: state.templatesCategoriesList.data[key].filter(({ id }) => id !== payload.templateId)
      }), {} as ICategoriesStructure)
      state.activeDocTemplate.activeTemplate = null
    })

    builder.addCase(postCopyTemplate.fulfilled, (state, { payload }) => {
      if (payload.category) {
        const keyCategory = state.categoriesList.entities[payload.category]!.title
        state.templatesCategoriesList.data[keyCategory].push(payload)
        return
      }
      if (state.templatesCategoriesList.data['templates_without_category']) {
        state.templatesCategoriesList.data['templates_without_category'].push(payload)
        return
      }
      state.templatesCategoriesList.data['templates_without_category'] = [payload]
    })
  }
})

export const {
  setTemplatesCategoriesLoading,
  setDocTemplateIsLoading,
} = docsTemplatesSlice.actions

export default docsTemplatesSlice.reducer
