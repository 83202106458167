import React from 'react'
import { StatusFileUpload } from '../../types/types'
import { FILE_LOADING_ICON } from '../../constant/constant'

import cn from 'classnames'
import * as Icons from '../../../../shared/ui-icons'

import './FileChip.scss'

export const FileChip: React.FC<PropsType> = ({
  title,
  className,
  icon,
  control,
  statusFileUpload,
}) => (
  <div className={cn('file-chip', className)}>

    <div className='section'>
      <div className='icon-wrapper'>
        {icon ?? (
          <Icons.Document
            className='file-chip-icon'
            height='2.4'
            width='2.4'
            color='#949494'
          />
        )}
      </div>
      <p className='title'>{title}</p>
    </div>

    <div className='section'>
      {statusFileUpload && FILE_LOADING_ICON[statusFileUpload]}
      <div className='control-wrapper'>
        {control}
      </div>
    </div>

  </div>
)


type PropsType = {
  title: string
  className?: string
  icon?: React.ReactNode
  statusFileUpload?: StatusFileUpload
  control?: JSX.Element
}