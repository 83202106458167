import React, { useState } from 'react'

// todo поправить уход 2 запросов
// без прокидывания name ломается
const withAutoSaveData = (Component) => {
  const AutoSaveContainer = (props) => {

    const {
      isAutoSave,
      onSaveData,
      onChange,
      ...passThroughProps
    } = props

    const [timeOutId, setTimeOutId] = useState()
    const onChangeForTimeOut = (event) => {
      // Так сделано потому что некоторые компоненты возвращают синтетик событие,
      // а некоторые массив с именем и значением
      const currentValue =
        event[0].constructor.name === 'SyntheticEvent'
          ? event[0].target.value
          : event[1]
      if (currentValue !== props.value) {
        clearTimeout(timeOutId)
        setTimeOutId(setTimeout(() => { props.onSaveData(props.name, currentValue) }, 700))
      }
    }

    const onChangeMerge = (...props) => {
      onChangeForTimeOut(props)
      onChange(...props)
    }

    if (isAutoSave) {
      return (
        <Component
          {...passThroughProps}
          onChange={onChangeMerge}
        />
      )
    } else return <Component {...passThroughProps} onChange={onChange} />
  }

  return AutoSaveContainer
}

export default withAutoSaveData