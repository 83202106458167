import { BuilderRemarksApi } from '../types/types'
import { RemarksListResponseType, RemarkType } from '../../../../entities/Remarks/types/types'
import { MetaAdapter } from '../../../../../(deprecated)/api/MetaAdapter'
import { addedNewNotification } from '../../../../../(deprecated)/redux/notifications/actions'
import { format } from 'date-fns'

import QueryString from 'query-string'

export const getAllRemarks = (builder: BuilderRemarksApi) => (
  builder.query({
    query: ({
      page,
      perPage
    } : {
      page: number
      perPage: number
      cbSuccess?: (listOptions: RemarkType[]) => void
    }) => {
      const queryString = QueryString.stringify({
        page: page - 1,
        size: perPage,
      }, { skipNull: true })

      return `issue?${queryString}`
    },

    transformResponse: (response: RemarksListResponseType) => {
      const listOptions = response.content.map(item => ({
        ...item,
        dateCreate: format(new Date(item.dateCreate), 'dd.MM.yyyy'),
        dateToFix: format(new Date(item.dateToFix), 'dd.MM.yyyy'),
      }))

      const meta = new MetaAdapter({
        page: response.number + 1,
        perPage: response.size,
        totalPages: response.totalPages,
        totalResults: response.totalElements
      }).getFields()

      return {
        meta,
        listOptions
      }
    },

    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        const { data: { listOptions } } = await queryFulfilled
        arg?.cbSuccess?.(listOptions)
      } catch (e) {
        dispatch(addedNewNotification({ message: 'Не удалось загрузить замечания' }))
      }
    }
  })
)