import React from 'react'
import { IIcon } from './IIcon'

export const PuzzlePiece:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5419 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V15.9703C20.9994 15.8473 20.9685 15.7264 20.9101 15.6181C20.8517 15.5099 20.7676 15.4176 20.6651 15.3496C20.5627 15.2815 20.4451 15.2397 20.3227 15.2277C20.2002 15.2158 20.0768 15.2342 19.9631 15.2812C19.6973 15.391 19.4126 15.4474 19.125 15.4472C17.8847 15.4472 16.875 14.4066 16.875 13.1287C16.875 11.8509 17.8847 10.8103 19.125 10.8103C19.4126 10.8101 19.6973 10.8665 19.9631 10.9762C20.0774 11.0235 20.2015 11.0419 20.3245 11.0296C20.4475 11.0173 20.5656 10.9748 20.6682 10.9058C20.7709 10.8369 20.8549 10.7437 20.9127 10.6344C20.9706 10.5252 21.0006 10.4033 21 10.2797V6.75C21 6.35218 20.842 5.97064 20.5607 5.68934C20.2794 5.40804 19.8978 5.25 19.5 5.25H16.1044C16.1183 5.12549 16.1252 5.00029 16.125 4.875C16.125 3.97989 15.7694 3.12145 15.1365 2.48851C14.5036 1.85558 13.6451 1.5 12.75 1.5C11.8549 1.5 10.9965 1.85558 10.3635 2.48851C9.73058 3.12145 9.375 3.97989 9.375 4.875C9.37478 5.00029 9.38167 5.12549 9.39563 5.25H6C5.60218 5.25 5.22064 5.40804 4.93934 5.68934C4.65804 5.97064 4.5 6.35218 4.5 6.75V9.77063C4.37549 9.75667 4.25029 9.74978 4.125 9.75C3.22989 9.75 2.37145 10.1056 1.73851 10.7385C1.10558 11.3715 0.75 12.2299 0.75 13.125C0.75 14.0201 1.10558 14.8785 1.73851 15.5115C2.37145 16.1444 3.22989 16.5 4.125 16.5C4.25029 16.5002 4.37549 16.4933 4.5 16.4794V19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H9.95813" fill="#5C5C5C"/>
  </svg>
)



