export const normalizeDataProfile = (data) => (
  data 
  ? ({
    ...Object.fromEntries(
      Object.entries(data)
        .filter(([key, value]) => key !== 'profile')
    ),
    ...data.profile
  }) 
  : data
)