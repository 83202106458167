import React from 'react'
import { IIcon } from './IIcon'

export const Printer:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5 9V16.5C22.5 16.6989 22.421 16.8897 22.2803 17.0303C22.1397 17.171 21.9489 17.25 21.75 17.25H18.75V20.25C18.75 20.4489 18.671 20.6397 18.5303 20.7803C18.3897 20.921 18.1989 21 18 21H6C5.80109 21 5.61032 20.921 5.46967 20.7803C5.32902 20.6397 5.25 20.4489 5.25 20.25V17.25H2.25C2.05109 17.25 1.86032 17.171 1.71967 17.0303C1.57902 16.8897 1.5 16.6989 1.5 16.5V9C1.5 7.75969 2.565 6.75 3.87469 6.75H5.25V3.75C5.25 3.55109 5.32902 3.36032 5.46967 3.21967C5.61032 3.07902 5.80109 3 6 3H18C18.1989 3 18.3897 3.07902 18.5303 3.21967C18.671 3.36032 18.75 3.55109 18.75 3.75V6.75H20.1253C21.435 6.75 22.5 7.75969 22.5 9ZM6.75 6.75H17.25V4.5H6.75V6.75ZM17.25 15H6.75V19.5H17.25V15ZM18.75 10.875C18.75 10.6525 18.684 10.435 18.5604 10.25C18.4368 10.065 18.2611 9.92078 18.0555 9.83564C17.85 9.75049 17.6238 9.72821 17.4055 9.77162C17.1873 9.81502 16.9868 9.92217 16.8295 10.0795C16.6722 10.2368 16.565 10.4373 16.5216 10.6555C16.4782 10.8738 16.5005 11.1 16.5856 11.3055C16.6708 11.5111 16.815 11.6868 17 11.8104C17.185 11.934 17.4025 12 17.625 12C17.9234 12 18.2095 11.8815 18.4205 11.6705C18.6315 11.4595 18.75 11.1734 18.75 10.875Z"
      fill={color}/>
  </svg>
)


