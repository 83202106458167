import React from 'react'
import cn from 'classnames'
import './SpinnerLoader.scss'

type PropsType = {
  className?: string
}

export const SpinnerLoader: React.FC<PropsType> = React.memo(({
  className
}) => (
  <div className={cn('loader spinner-loader', className)}>
    <div className='spinner' />
  </div>
))
