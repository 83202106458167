export class SendAnswerForInvitationInProjectQuery {
  query = ({
    projectId,
    decision,
    comment,
    onSuccessCb
  } : {
    projectId: number
    decision: 'approve' | 'reject'
    comment: string | null
    onSuccessCb?: () => void
  }) => {
    return {
      url: `projects/${projectId}/invite_approval_by_company`,
      method: 'PUT',
      body: { decision, comment }
    }
  }
}