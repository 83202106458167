import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { getToken } from '../../../(deprecated)/utils/localStorageManagement'
import { getUser } from '../../../(deprecated)/redux/user/actions'
import { useTDispatch } from '../../../(deprecated)/utils/hooks/reduxHooks'

export const useManageUserDataToLS = () => {
  const dispatch = useTDispatch()
  const location = useLocation()
  const { current: pathName } = useRef(location.pathname)

  const changeUserInfo = (e: StorageEvent) => {
    if (e.key === 'simple-construction-token' && e.newValue !== e.oldValue && !!e.oldValue) {
      dispatch(getUser())
    }
  }

  useEffect(() => {

    if(!['/sign-up', '/auth'].includes(pathName)) {
      getToken() && dispatch(getUser())
    }

    window.addEventListener('storage', changeUserInfo)
    return () => window.removeEventListener('storage', changeUserInfo)
  }, [])
}