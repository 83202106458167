import { IProjectRoleRTK } from "../../../../(deprecated)/models/IProjectRole"
import { ProjectRoleAdapter } from "./ProjectRoleAdapter"

export class GetAllProjectRoles {

  query = (param: { project: number }) => `/role_access?project=${param.project}`

  transformResponse = (response: unknown[]) => {
    return response
      .map(res => new ProjectRoleAdapter(res).getFields())
      .sort((a, b) => a.dndPosition - b.dndPosition)
  }

  providesTags = (result: IProjectRoleRTK[] | undefined) =>
    result ? [
      ...result.map(({ id }) => ({ type: 'ProjectRole', id } as const)),
      { type: 'ProjectRole', id: 'LIST' } as const,
    ] : [
      { type: 'ProjectRole', id: 'LIST' } as const
    ]
}