import React from 'react'
import { Link } from 'react-router-dom'
import * as Icon from '../../../../../fsd/shared/ui-icons'

export const LeftSidebarHeader = () => (
  <div className='sidebar_top__main'>
    <Link className='sidebar-logo' to='/'>
      <Icon.SimpleSolutionLogo/>
    </Link>
  </div>
)


