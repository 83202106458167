import React from 'react'
import { IIcon } from './IIcon'

export const ChatTeardropTextBigPlaceholder: React.FC<IIcon> = ({
  width = '17.7',
  height = '17.7',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 177 177"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="17" width="160" height="160" rx="80" fill="#E8F1FB"/>
    <rect x="16" y="1" width="160" height="160" rx="80" stroke="#1A65DA" stroke-width="2" stroke-linecap="round"
          stroke-dasharray="10 15"/>
    <path
      d="M107.875 76C107.875 76.4973 107.677 76.9742 107.326 77.3258C106.974 77.6775 106.497 77.875 106 77.875H86C85.5027 77.875 85.0258 77.6775 84.6742 77.3258C84.3225 76.9742 84.125 76.4973 84.125 76C84.125 75.5027 84.3225 75.0258 84.6742 74.6742C85.0258 74.3225 85.5027 74.125 86 74.125H106C106.497 74.125 106.974 74.3225 107.326 74.6742C107.677 75.0258 107.875 75.5027 107.875 76ZM106 84.125H86C85.5027 84.125 85.0258 84.3225 84.6742 84.6742C84.3225 85.0258 84.125 85.5027 84.125 86C84.125 86.4973 84.3225 86.9742 84.6742 87.3258C85.0258 87.6775 85.5027 87.875 86 87.875H106C106.497 87.875 106.974 87.6775 107.326 87.3258C107.677 86.9742 107.875 86.4973 107.875 86C107.875 85.5027 107.677 85.0258 107.326 84.6742C106.974 84.3225 106.497 84.125 106 84.125ZM127.875 79.75C127.866 87.8695 124.636 95.6538 118.895 101.395C113.154 107.136 105.369 110.366 97.25 110.375H70.8969C69.7642 110.374 68.6781 109.924 67.8771 109.123C67.0762 108.322 66.6258 107.236 66.625 106.103V79.75C66.625 71.6277 69.8516 63.8382 75.5949 58.0949C81.3382 52.3516 89.1277 49.125 97.25 49.125C105.372 49.125 113.162 52.3516 118.905 58.0949C124.648 63.8382 127.875 71.6277 127.875 79.75ZM124.125 79.75C124.125 72.6223 121.294 65.7865 116.253 60.7465C111.213 55.7065 104.378 52.875 97.25 52.875C90.1223 52.875 83.2865 55.7065 78.2465 60.7465C73.2065 65.7865 70.375 72.6223 70.375 79.75V106.103C70.375 106.242 70.43 106.374 70.5279 106.472C70.6257 106.57 70.7585 106.625 70.8969 106.625H97.25C104.375 106.617 111.206 103.783 116.244 98.7444C121.283 93.7061 124.117 86.8752 124.125 79.75Z"
      fill="#1A65DA"/>
  </svg>
)