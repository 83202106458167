import store from '../../redux/store'
import { addedNewNotification } from '../../redux/notifications/actions'
import { clearLocalStorage } from '../localStorageManagement'

const ME_ENDPOINT = 'me'

export const errorProccessing = (error) => {
  if (error.message == 'canceled')
    return

  const urlFragmentsList = error.config.url.split('/')
  const lastUrlPart = urlFragmentsList[urlFragmentsList.length - 1]

  const toAuthRedirectCondition = error?.response?.status === 401 ||
    (error?.response?.status === 404 && lastUrlPart === ME_ENDPOINT)

  if (toAuthRedirectCondition) {
    clearLocalStorage()
    window.location.href = `${window.location.origin}/auth`
  }
  if (error?.response?.status === 500) {
    store.dispatch(addedNewNotification({
      message: `Internal Server Error ${error.response.request.responseURL}`
    }))
  }
  if (error?.response?.data?.message) {
    store.dispatch(addedNewNotification({
      message: error?.response?.data?.message
    }))
    return error.response.data
  } else return error?.response?.data
}
