import camelcaseFields, { snakecaseFields } from '../../utils/formatFields'

export class InviteCompanyQuery {
  query = ({
    companyName,
    companyInn,
    adminEmail,
    project
  }: IInviteCompanyPayload) => ({
    url: 'companies/invite_company',
    method: 'POST',
    body: snakecaseFields({ companyName, companyInn, adminEmail, project })
  })

  transformResponse = (response: IInviteCompanyResponse) => camelcaseFields(response)
  transformErrorResponse = (response: any) => camelcaseFields(response)
}

export interface IInviteCompanyPayload {
  companyName: string
  companyInn: string
  adminEmail: string
  project: number
}

export interface IInviteCompanyResponse {
  adminEmail: string
  companyInn: string
  companyName: string
  inviter: number
  project: number
}