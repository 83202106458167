import React from 'react'
import { IIcon } from './IIcon'

export const Document:React.FC<IIcon> = ({
  onClick = () => {},
  width = '2.4',
  height = '2.4',
  className = '',
  opacity = '1',
  color = '#5C5C5C',
  ...props
})    => (
  <svg {...props} onClick={onClick} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.0306 7.71938L14.7806 2.46938C14.7109 2.39975 14.6282 2.34454 14.5371 2.3069C14.4461 2.26926 14.3485 2.24992 14.25 2.25H5.25C4.85218 2.25 4.47064 2.40804 4.18934 2.68934C3.90804 2.97064 3.75 3.35218 3.75 3.75V20.25C3.75 20.6478 3.90804 21.0294 4.18934 21.3107C4.47064 21.592 4.85218 21.75 5.25 21.75H18.75C19.1478 21.75 19.5294 21.592 19.8107 21.3107C20.092 21.0294 20.25 20.6478 20.25 20.25V8.25C20.2501 8.15148 20.2307 8.05391 20.1931 7.96286C20.1555 7.87182 20.1003 7.78908 20.0306 7.71938ZM14.25 8.25V4.125L18.375 8.25H14.25Z" fill={color} fillOpacity={opacity}/>
  </svg>
)