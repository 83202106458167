import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { createPostFileMutation } from './createPostFileMutation'
import { createDeleteFileMutation } from './createDeleteFileMutation'

//TODo перенести
import { baseQuery } from '../../../../(deprecated)/api/BaseQuery'

export const fileApi = createApi({
  reducerPath: 'fileApi',
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ['file-api'],
  endpoints: (builder) => ({
    postFile: createPostFileMutation(builder),
    deleteFile: createDeleteFileMutation(builder)
  })
})

export const {
  useDeleteFileMutation,
  usePostFileMutation,
} = fileApi
