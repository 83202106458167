import React from 'react'
import { IIcon } from './IIcon'

export const Tray:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM19.5 19.5H4.5V15.75H7.18969L9 17.5613C9.13895 17.7008 9.30421 17.8115 9.48622 17.8868C9.66822 17.9621 9.86335 18.0006 10.0603 18H13.9397C14.1367 18.0006 14.332 17.9621 14.514 17.8866C14.696 17.8111 14.8612 17.7002 15 17.5603L16.8103 15.75H19.5V19.5Z" fill={color}/>
  </svg>
)
