import React, { useMemo } from 'react'
import { useTSelector } from '../../../../utils/hooks/reduxHooks'
import { channelType } from '../../../pages/Chat/type'

import * as Icon from '../../../../../fsd/shared/ui-icons'

export const ChatSidebarLink = () => {

  const openChannels = useTSelector((state: any) => state.chat.openChannels) as channelType[]
  const chatsNewMessages = useMemo(() => openChannels.reduce((accum, channel) => accum + channel.unreadMessagesCount, 0), [openChannels])

  return (
    <div style={{ position: 'relative' }}>
      <Icon.Chat />
      {
        !!chatsNewMessages && (
          <div className='sidebar_top__notification-count'>
            {chatsNewMessages}
          </div>
        )
      }
    </div>
  )
}