import React from 'react'
import { IIcon } from "./IIcon";

export const PencilSimpleLine: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  ...props
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} {...props} viewBox="0 0 24 24" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.3113 6.87844L17.1216 2.68969C16.9823 2.55037 16.8169 2.43985 16.6349 2.36444C16.4529 2.28904 16.2578 2.25023 16.0608 2.25023C15.8638 2.25023 15.6687 2.28904 15.4867 2.36444C15.3047 2.43985 15.1393 2.55037 15 2.68969L3.4397 14.25C3.2998 14.3888 3.18889 14.554 3.11341 14.736C3.03792 14.918 2.99938 15.1133 3.00001 15.3103V19.5C3.00001 19.8978 3.15804 20.2794 3.43935 20.5607C3.72065 20.842 4.10218 21 4.50001 21H20.25C20.4489 21 20.6397 20.921 20.7803 20.7803C20.921 20.6397 21 20.4489 21 20.25C21 20.0511 20.921 19.8603 20.7803 19.7197C20.6397 19.579 20.4489 19.5 20.25 19.5H10.8113L21.3113 9C21.4506 8.86071 21.5611 8.69534 21.6365 8.51332C21.7119 8.33131 21.7507 8.13623 21.7507 7.93922C21.7507 7.74221 21.7119 7.54713 21.6365 7.36512C21.5611 7.18311 21.4506 7.01773 21.3113 6.87844ZM18 10.1897L13.8113 6L16.0613 3.75L20.25 7.93969L18 10.1897Z"
      fill={color}/>
  </svg>
)