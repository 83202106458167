import React from 'react'
import { IIcon } from './IIcon'

export const Chats:React.FC<IIcon> = ({
  width = '2.3',
  height = '2.2',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 23 22"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.7383 8.25C20.7383 7.88533 20.5934 7.53559 20.3356 7.27773C20.0777 7.01987 19.728 6.875 19.3633 6.875H16.6133V4.125C16.6133 3.76033 16.4684 3.41059 16.2106 3.15273C15.9527 2.89487 15.603 2.75 15.2383 2.75H4.23828C3.87361 2.75 3.52387 2.89487 3.26601 3.15273C3.00815 3.41059 2.86328 3.76033 2.86328 4.125V15.125C2.86368 15.2543 2.90057 15.3809 2.96969 15.4903C3.03881 15.5996 3.13738 15.6872 3.25405 15.743C3.37073 15.7988 3.50078 15.8206 3.62928 15.8059C3.75778 15.7911 3.8795 15.7404 3.98047 15.6595L6.98828 13.2344V15.8125C6.98828 16.1772 7.13315 16.5269 7.39101 16.7848C7.64887 17.0426 7.99861 17.1875 8.36328 17.1875H16.4062L19.6211 19.7845C19.7427 19.8829 19.8943 19.9369 20.0508 19.9375C20.2331 19.9375 20.408 19.8651 20.5369 19.7361C20.6658 19.6072 20.7383 19.4323 20.7383 19.25V8.25ZM17.0816 15.9655C16.96 15.8671 16.8084 15.8131 16.652 15.8125H8.36328V13.0625H15.2383C15.603 13.0625 15.9527 12.9176 16.2106 12.6598C16.4684 12.4019 16.6133 12.0522 16.6133 11.6875V8.25H19.3633V17.8105L17.0816 15.9655Z"
      fill={color}/>
  </svg>
)