import React from 'react'
import { IIcon } from './IIcon'

export const UsersWithPlus:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 12.75C24 12.9489 23.921 13.1397 23.7804 13.2803C23.6397 13.421 23.4489 13.5 23.25 13.5H21.75V15C21.75 15.1989 21.671 15.3897 21.5304 15.5303C21.3897 15.671 21.1989 15.75 21 15.75C20.8011 15.75 20.6104 15.671 20.4697 15.5303C20.329 15.3897 20.25 15.1989 20.25 15V13.5H18.75C18.5511 13.5 18.3604 13.421 18.2197 13.2803C18.079 13.1397 18 12.9489 18 12.75C18 12.5511 18.079 12.3603 18.2197 12.2197C18.3604 12.079 18.5511 12 18.75 12H20.25V10.5C20.25 10.3011 20.329 10.1103 20.4697 9.96967C20.6104 9.82901 20.8011 9.75 21 9.75C21.1989 9.75 21.3897 9.82901 21.5304 9.96967C21.671 10.1103 21.75 10.3011 21.75 10.5V12H23.25C23.4489 12 23.6397 12.079 23.7804 12.2197C23.921 12.3603 24 12.5511 24 12.75ZM13.5 14.7825C14.6757 14.0502 15.581 12.9549 16.0788 11.6624C16.5766 10.3698 16.6399 8.95028 16.259 7.61855C15.8782 6.28681 15.074 5.11534 13.9682 4.28135C12.8623 3.44736 11.5148 2.99625 10.1297 2.99625C8.74461 2.99625 7.39718 3.44736 6.29128 4.28135C5.18539 5.11534 4.3812 6.28681 4.00039 7.61855C3.61958 8.95028 3.68286 10.3698 4.18066 11.6624C4.67846 12.9549 5.58369 14.0502 6.75941 14.7825C4.82347 15.4162 3.08159 16.6003 1.68097 18.2672C1.58927 18.3762 1.53051 18.509 1.51155 18.6502C1.4926 18.7914 1.51423 18.935 1.57393 19.0643C1.63362 19.1937 1.72891 19.3033 1.84864 19.3805C1.96837 19.4576 2.1076 19.4991 2.25003 19.5H18C18.1429 19.5001 18.2829 19.4595 18.4035 19.3827C18.524 19.306 18.6202 19.1964 18.6806 19.0669C18.7409 18.9373 18.7631 18.7933 18.7443 18.6516C18.7256 18.5099 18.6667 18.3765 18.5747 18.2672C17.1732 16.6003 15.4313 15.4162 13.5 14.7825Z"
      fill={color}/>
  </svg>
)