export const createFaviconNotification = (count) => {
  const canvas = document.createElement('canvas')
  canvas.height = 30
  canvas.width = 30 
  const ctx = canvas.getContext('2d')
  ctx.beginPath()
  ctx.fillStyle = "#1A65DA"
  ctx.arc(15, 15, 15, 0, 2 * Math.PI, true)
  ctx.fill()
  ctx.textAlign = 'center'
  ctx.font = '16px sans-serif'
  ctx.fillStyle = '#fff'
  ctx.fillText(count, 15, 21)
  return canvas.toDataURL()
}