import { SystemUserAdapter, ISystemUserModel } from '../SystemUserAdapter'
import { MetaAdapter } from '../../MetaAdapter'
import QueryString from 'query-string'
import camelcaseFields from '../../../utils/formatFields'

type PaginationQuery = {
  page: number
  perPage: number
}

type QueryParams = PaginationQuery & {
  profilePhone: string
  email: boolean
  companyId: string
  companyRole: ISystemUserModel['companyRole']
  search?: string,
  project?: number,
  excludeProjectUsers?: boolean
}

export class GetSystemUserListQuery {
  query = ({
    profilePhone,
    email,
    companyId,
    companyRole,
    page,
    perPage,
    search,
    project,
    excludeProjectUsers
  }: Partial<QueryParams>): string => {
    const queryString = QueryString.stringify({
      search,
      profile__phone: profilePhone,
      email,
      company_id__in: companyId,
      company_role: companyRole,
      exclude_project_users: excludeProjectUsers,
      page,
      per_page: perPage,
      project,
    }, { skipNull: true })

    return `users?${queryString}`
  }

  transformResponse = (response: { results: unknown[], meta: Record<string, unknown> }) => {
    try {
      const { meta, results } = response

      const listOptions = results.map((user: unknown) =>
        new SystemUserAdapter(user).getFields()
      )

      return {
        meta: new MetaAdapter(meta).getFields(),
        listOptions
      }
    } catch (error) {
      return {
        meta: new MetaAdapter({}).getFields(),
        listOptions: []
      }
    }

  }
}