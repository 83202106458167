import React from 'react'
import { IIcon } from './IIcon'

export const PersonWithKey: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_14234_101279)">
      <path
        d="M8.5 13C10.433 13 12 11.433 12 9.5C12 7.567 10.433 6 8.5 6C6.567 6 5 7.567 5 9.5C5 11.433 6.567 13 8.5 13Z"
        fill={color}/>
      <path d="M8.5 15C5.99687 15 1 16.34 1 19V21H16V19C16 16.34 11.0031 15 8.5 15Z" fill={color}/>
      <g clip-path="url(#clip1_14234_101279)">
        <path
          d="M16.4922 9.41536C15.2783 9.18793 14.0002 9.73172 13.3477 10.8619C12.5202 12.2952 13.0125 14.1325 14.4457 14.96C15.879 15.7875 17.7163 15.2952 18.5438 13.8619C19.1963 12.7317 19.0282 11.3529 18.2243 10.4154L19.3118 8.53176L21.0438 9.53176L22.0438 7.79971L20.3118 6.79971L20.8118 5.93368L19.0797 4.93368L16.4922 9.41536ZM16.8118 12.8619C16.5368 13.3382 15.922 13.5029 15.4457 13.2279C14.9694 12.9529 14.8047 12.3382 15.0797 11.8619C15.3547 11.3856 15.9694 11.2209 16.4457 11.4959C16.922 11.7709 17.0868 12.3856 16.8118 12.8619Z"
          fill={color}/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_14234_101279">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
      <clipPath id="clip1_14234_101279">
        <rect width="12" height="12" fill="white" transform="translate(9 12.3926) rotate(-60)"/>
      </clipPath>
    </defs>
  </svg>
)