import { BuilderFileApi, } from './types';

//TODO перенести
import { IFile } from '../../../../(deprecated)/models/IDocument';

interface IDeleteFileMutationParam {
  id: number
}

export const createDeleteFileMutation = (builder: BuilderFileApi) => (
  builder.mutation<IFile, IDeleteFileMutationParam>({
    query: ({ id }) => ({
      url: `/files/${id}`,
      method: 'DELETE'
    })
  })
)