import 'core-js'
import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './fsd/app'

import { BrowserTracing } from '@sentry/tracing'

import './(deprecated)/styles/main-styles.scss'

process.env.NODE_ENV !== 'development' && process.env.REACT_APP_IS_REVIEW !== 'True' && Sentry.init({
  dsn: "https://20c11f0ba13f49bbad3e457e9bea28d3@o370212.ingest.sentry.io/6181988",
  environment: process.env.REACT_APP_ENV_SENTRY || 'NOT_FOUND_ENV',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

ReactDOM
  .createRoot(document.getElementById('root') as HTMLDivElement)
  .render(<App />)

