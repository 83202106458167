import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type ProjectRoleUsersFilters = {
  fullName: string
  companyIds: number[]
  position: string[]
  roles: { id: number, name: string }[]
}

type InitialState = {
  checkedUsersTable: {
    users: {
      userId: number,
      companyId: number,
    }[]
    userIds: Record<number, true>
  }
  filters: ProjectRoleUsersFilters
}

const InitialFiltersState: InitialState['filters'] = {
  fullName: '',
  companyIds: [],
  position: [],
  roles: []
}

const projectRole = createSlice({
  name: 'project-role',
  initialState: {
    checkedUsersTable: {
      users: [],
      userIds: {}
    },
    filters: InitialFiltersState
  } as InitialState,

  reducers: {
    onCheckUserId(
      state,
      { payload }: PayloadAction<{ user: { userId: number, companyId: number } }>
    ) {
      state.checkedUsersTable.users.push(payload.user)
      state.checkedUsersTable.userIds[payload.user.userId] = true
    },

    onCheckUsers(state, { payload }: PayloadAction<InitialState['checkedUsersTable']['users']>) {

      const checkedUsersTable = state.checkedUsersTable

      checkedUsersTable.users = [...checkedUsersTable.users, ...payload]
      checkedUsersTable.users.forEach(({ userId }) => {
        state.checkedUsersTable.userIds[userId] = true
      })
    },

    onRemoveUsers(state, { payload }: PayloadAction<{ users: { id: number }[] }>) {

      const checkedUsersTable = state.checkedUsersTable

      const usersForRemoveMapObj = new Set()
      payload.users.forEach(({ id }) => usersForRemoveMapObj.add(id))

      checkedUsersTable.users = checkedUsersTable.users.filter(({ userId }) => (
        !usersForRemoveMapObj.has(userId)
      ));

      checkedUsersTable.userIds = checkedUsersTable.users.reduce((acc, { userId }) => {
        acc[userId] = true
        return acc
      }, {} as Record<number, true>)
    },

    onRemoveAllUsers(state) {
      state.checkedUsersTable.users = []
      state.checkedUsersTable.userIds = {}
    },

    onChangeFilter(state, { payload }: PayloadAction<{
      filterKey: keyof ProjectRoleUsersFilters,
      value: ProjectRoleUsersFilters[keyof ProjectRoleUsersFilters]
    }>) {
      const { filterKey, value } = payload

      // @ts-ignore
      state.filters[filterKey] = value
    },

    clearFilters(state) {
      state.filters = InitialFiltersState
    }
  }
})

export const {
  onCheckUserId,
  onCheckUsers,
  onRemoveAllUsers,
  onRemoveUsers,
  onChangeFilter,
  clearFilters
} = projectRole.actions

export default projectRole.reducer