import React from 'react'
import { IIcon } from './IIcon'

export const NewNotification: React.FC<IIcon> = ({
  width = '2',
  height = '2',
  color = 'black',
  opacity = '0.64',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 20 20" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.31671 3.40016L5.12504 2.2085C3.12504 3.7335 1.80838 6.0835 1.69171 8.75016H3.35838C3.48338 6.54183 4.61671 4.6085 6.31671 3.40016ZM16.6417 8.75016H18.3084C18.1834 6.0835 16.8667 3.7335 14.875 2.2085L13.6917 3.40016C15.375 4.6085 16.5167 6.54183 16.6417 8.75016ZM15 9.16683C15 6.6085 13.6334 4.46683 11.25 3.90016V3.3335C11.25 2.64183 10.6917 2.0835 10 2.0835C9.30838 2.0835 8.75005 2.64183 8.75005 3.3335V3.90016C6.35838 4.46683 5.00004 6.60016 5.00004 9.16683V13.3335L3.33338 15.0002V15.8335H16.6667V15.0002L15 13.3335V9.16683ZM10 18.3335C10.1167 18.3335 10.225 18.3252 10.3334 18.3002C10.875 18.1835 11.3167 17.8168 11.5334 17.3168C11.6167 17.1168 11.6584 16.9002 11.6584 16.6668H8.32505C8.33338 17.5835 9.07505 18.3335 10 18.3335Z"
      fill={color} fillOpacity={opacity}/>
  </svg>
)
