 /* eslint-disable */
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { createFaviconNotification } from '../createFaviconNotification'

let interval = null
let faviconNode = null
let startFavicon = null

export const useChangeFaviconNotification = () => {

  const newCount = useSelector(state => state.appNotification.unreadNotificationsCount)

  useEffect(() => {
    faviconNode = document.querySelector('#favicon')
    startFavicon = faviconNode.href
  }, [])

  useEffect(() => {
    if (favicon && newCount) {
      const faviconNotification = createFaviconNotification(newCount)
      interval = setInterval(() => {
        favicon.href = favicon.href === startFavicon ? faviconNotification : startFavicon
      }, 2000)
    } else {
      clearInterval(interval)
      if (favicon) favicon.href = startFavicon
    }
    return () => clearInterval(interval)
  }, [newCount, favicon])
}