
import { createThunk } from '../../utils/hooks/reduxHooks'
import { addedNewNotification } from '../notifications/actions'
import { snakecaseFields } from '../../utils/formatFields'
import {
    toggleLoadingActiveMeet,
    toggleLoadingEngageStatus,
    toggleLoadingFormMeet,
    toggleLoadingMeetList,
    toggleLoadingMeetMonthData,
    toggleLoadingMeetTypes,
    toggleLoadingSubscribers
} from './meetSlice'

import { ICreateMeet } from '../../components/pages/MeetCalendar/types'
import { IDynamicPageNumberPagination } from '../../models/IPagination'
import {
    IFullMeet,
    IMeetList,
    IMeetType,
    IShortMeet,
    ISubscriber,
    StatusParticipant
} from '../../models/IMeet'

import get from '../../utils/axios/get'
import post from '../../utils/axios/post'
import put from '../../utils/axios/put'
import deleteRequest from '../../utils/axios/delete'
import moment from 'moment'

//SUBSCRIBERS
export const getSubscribers = createThunk<ISubscriber[]>(
  'meet/get-sybscribers',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingSubscribers('pending'))
      const res = await get<ISubscriber[]>({ path: '/calendar/follows' })
      dispatch(toggleLoadingSubscribers('succeeded'))
      return res
    } catch (error) {
      dispatch(addedNewNotification({ message: 'Не удалось загрузить календари сотрудников' }))
      dispatch(toggleLoadingSubscribers('failed'))
      return rejectWithValue(error)
    }

  }
)

type PutSubscriberPayload = {
    id: number,
    isActive: boolean
    callback?: () => void
}
export const putSubscriber = createThunk<ISubscriber, PutSubscriberPayload>(
    'meet/put-subscriber',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            dispatch(toggleLoadingSubscribers('pending'))
            const res = await put<ISubscriber>(`/calendar/follows/${payload.id}`, { isActive: payload.isActive })
            dispatch(toggleLoadingSubscribers('succeeded'))
            payload.callback?.()
            return res
        } catch (error) {
            dispatch(addedNewNotification({ message: 'Не удалось изменить статус' }))
            dispatch(toggleLoadingSubscribers('failed'))
            return rejectWithValue(error)
        }
    }
)

export const postSubscriber = createThunk<ISubscriber, number>(
  'meet/post-subscriber',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingSubscribers('pending'))
      const res = post<ISubscriber>('/calendar/follows', { is_active: false, following: id })
      dispatch(toggleLoadingSubscribers('succeeded'))
      return res
    } catch (error) {
      dispatch(addedNewNotification({ message: 'Не удалось добавить контакт' }))
      dispatch(toggleLoadingSubscribers('failed'))
      return rejectWithValue(error)
    }
  }
)

type DeleteSubscriberPayload = {
    id: number
    callback?: () => void
}
export const deleteSubscriber = createThunk<number, DeleteSubscriberPayload>(
    'meet/delete-subscriber',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            dispatch(toggleLoadingSubscribers('pending'))
            await deleteRequest(`/calendar/follows/${payload.id}`)
            payload.callback?.()
            return payload.id
        } catch (error) {
            dispatch(addedNewNotification({ message: 'Не удалось удалить контакт' }))
            dispatch(toggleLoadingSubscribers('failed'))
            return rejectWithValue(error)
        }
    }
)

// MEET TYPES 
export const getMeetTypes = createThunk<IMeetType[], void>(
  'meet/get-meet-types',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingMeetTypes('pending'))
      const res = await get<IMeetType[]>({ path: '/calendar/types' })
      dispatch(toggleLoadingMeetTypes('succeeded'))
      return res
    } catch (error) {
      dispatch(toggleLoadingMeetTypes('failed'))
      return rejectWithValue(error)
    }
  }
)

export const postMeetTypes = createThunk<IMeetType, Omit<IMeetType, 'id'>>(
  'meet/post-meet-types',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingMeetTypes('pending'))
      const res = await post<IMeetType>('/calendar/types', snakecaseFields(payload))
      dispatch(toggleLoadingMeetTypes('succeeded'))
      return res
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type putMeetTypes = {
  data: IMeetType
  callback?: Function
}

export const putMeetTypes = createThunk<IMeetType, putMeetTypes>(
  'meet/put-meet-types',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingMeetTypes('pending'))
      const res = await put<IMeetType>(`/calendar/types/${payload.data.id}`, snakecaseFields(payload.data))
      dispatch(toggleLoadingMeetTypes('succeeded'))
      payload.callback?.()
      return res
    }
    catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteMeetTypes = createThunk<number, number>(
  'meet/delete-meet-types',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const res = await deleteRequest(`/calendar/types/${id}`)
      return id
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

//MEET LIST
interface IMeetListResponse extends IDynamicPageNumberPagination<IMeetList> {
  newEventsCount: number
}
export const getMeetList = createThunk<IMeetListResponse, { page: number, perPage: number, search?: string }>(
  'meet/get-meet-list',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingMeetList('pending'))
      const res = await get<IMeetListResponse>({ path: '/calendar/events', params: snakecaseFields(params) })
      dispatch(toggleLoadingMeetList('succeeded'))
      return res
    } catch (error) {
      dispatch(addedNewNotification({ message: 'Не удалось получить события' }))
      dispatch(toggleLoadingMeetList('failed'))
      return rejectWithValue(error)
    }
  }
)

// MEET MONTH
interface IGetMeetMonthResponse {
  events: {
    [k: string]: IShortMeet[]
  }
  regularTemplates: [],
}
export const getMeetMonth = createThunk<IGetMeetMonthResponse, string>(
  'meet/get(post)-meet-month',
  async (date, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingMeetMonthData('pending'))
      type Response = { events: { [k: string]: IShortMeet[] }, regularTemplates: [] }
      //  Должен быть гет! но гении бекендеры сделали пост)))))))
      const res = await post<Response>('/calendar/events/month_events', snakecaseFields({
        dtStart: date,
        dtEnd: moment(date, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD')
      }), 'json', true)
      dispatch(toggleLoadingMeetMonthData('succeeded'))
      return res
    } catch (error: any) {
      if (error.message !== 'canceled') {
        dispatch(addedNewNotification({ message: 'Не удалось получить события месяца' }))
        dispatch(toggleLoadingMeetMonthData('failed'))
      }
      return rejectWithValue(error)
    }
  }
)

export const getMeet = createThunk<IFullMeet, number>(
  'meet/get-meet',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingActiveMeet('pending'))
      const res = await get<IFullMeet>({ path: `/calendar/events/${id}` })
      dispatch(toggleLoadingActiveMeet('succeeded'))
      return res
    } catch (error) {
      dispatch(toggleLoadingActiveMeet('failed'))
      return rejectWithValue(error)
    }
  }
)

export const deleteMeet = createThunk<number, number>(
  'meet/delete-meet',
  async (id, { rejectWithValue }) => {
    try {
      await deleteRequest(`/calendar/events/${id}`)
      return id
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type PostMeetPayload = {
  data: ICreateMeet,
  callback: Function
}
export const postMeet = createThunk<IFullMeet, PostMeetPayload>(
  'meet/post-create',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingFormMeet('pending'))
      const res = await post<IFullMeet>('/calendar/events', snakecaseFields(payload.data))
      dispatch(toggleLoadingFormMeet('succeeded'))
      payload.callback()
      return res
    } catch (error) {
      dispatch(toggleLoadingFormMeet('failed'))
      dispatch(addedNewNotification({ message: 'Не удалось создать событие' }))
      return rejectWithValue(error)
    }
  }
)

type PutMeetPayload = {
  id: number,
  data: ICreateMeet,
  callback: Function
}
export const putMeet = createThunk<IFullMeet, PutMeetPayload>(
  'meet/put-edit',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingFormMeet('idle'))
      const res = await put<IFullMeet>(`/calendar/events/${payload.id}`, snakecaseFields(payload.data))
      dispatch(toggleLoadingFormMeet('succeeded'))
      payload.callback()
      return res
    } catch (error) {
      dispatch(toggleLoadingFormMeet('failed'))
      dispatch(addedNewNotification({ message: 'Не удалось обновить событие' }))
      return rejectWithValue(error)
    }
  }
)

type PutEngageStatusPayload = {
  id: number,
  status: StatusParticipant,
  meId: number,
  comment?: string,
  callback?: Function
}
type PutEngageStatusResponse = {
  data: IFullMeet,
  meId: number,
}
export const putEngageStatusActiveMeet = createThunk<PutEngageStatusResponse, PutEngageStatusPayload>(
  'meet/put-engage-status',
  async ({ id, meId, status, comment, callback }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingEngageStatus('pending'))
      const res = await put<IFullMeet>(`/calendar/events/${id}/engage_status`, { status, comment })
      dispatch(toggleLoadingEngageStatus('succeeded'))
      callback?.()
      return {
        data: res,
        meId
      }
    } catch (error) {
      dispatch(toggleLoadingEngageStatus('failed'))
      return rejectWithValue(error)
    }
  }
)

type PostUsersBusynessPayload = {
  users: number[],
  dtStart: string,
  dtEnd: string
}
export type PostUsersBusynessResponse = { [k: number]: number[] }
export const postUsersBusyness = createThunk<PostUsersBusynessResponse, PostUsersBusynessPayload>(
  'meet/post-user-busyness',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await post<PostUsersBusynessResponse>('/calendar/events/user_busyness', snakecaseFields(payload))
      return res
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)