import axios from 'axios'
import camelcaseFields from '../formatFields'
import HOST from './host'

import { getHeaders } from './getHeaders.js'
import { errorProccessing } from './errorProccessing.js'

const getDocument = async ({ path }) => {
  const headers = await getHeaders()
  const REQUEST_URL = HOST.getInstance().getRequestUrl()

  return axios({
    headers,
    method: 'get',
    url: `${REQUEST_URL}${path}`,
    responseType: 'blob'
  })
    .then(response => response.data)
    .catch(error => new Promise((_, rej) => {
      if (error?.response?.data instanceof Blob) {
        const blobError = error?.response?.data
        const reader = new FileReader()

        reader.onload = () => {
          const errorJSON = JSON.parse(reader.result)
          rej(camelcaseFields(errorJSON))
        }

        reader.readAsText(blobError)
        return
      }
      return rej(camelcaseFields(errorProccessing(error)))
    }))
}

export default getDocument

export const getDocumentOnPost = async (path, data) => {
  const headers = await getHeaders()
  const REQUEST_URL = HOST.getInstance().getRequestUrl()

  return axios({
    headers,
    method: 'post',
    url: `${REQUEST_URL}${path}`,
    data: data,
    responseType: 'blob'
  })
    .then(response => response.data)
    .catch(error => new Promise((_, rej) => rej(camelcaseFields(errorProccessing(error)))))
}
