import camelcaseFields, { snakecaseFields } from '../../../../(deprecated)/utils/formatFields'

export class PostCreateProjectRole {

  query = (data: { project: number, name: string, cb?: () => void }) => ({
    url: `/role_access`,
    method: 'POST',
    body: snakecaseFields(data),
  })

  transformErrorResponse = (error: { data: { nonFieldErrors: string[] }, status: number}) => camelcaseFields(error)

  invalidatesTags = [{ type: 'ProjectRole', id: 'LIST' }] as const
}