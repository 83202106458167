import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { routersWithoutMainLayout } from './constants/routesWithoutMainLayout'
import { MainLayout } from './ui/MainLayout'

export const AppLayout:React.FC<PropsType> = ({ children }) => {
  const location = useLocation()

  const withoutMainLayout = useMemo(() => (
    routersWithoutMainLayout.includes(location.pathname)
  ), [location.pathname])

  if (withoutMainLayout)
    return <div style={{ height: '100%' }}>{children}</div>

  return (
    <MainLayout>
      {children}
    </MainLayout>
  )
}

type PropsType = {
  children: React.ReactNode | React.ReactNode[]
}