import React, { useState } from 'react'
import { PrefixIcon } from './ui/PrefixIcon/PrefixIcon'
import { ProjectButtonBody } from './ui/ProjectButtonBody/ProjectButtonBody'
import { SuffixControls } from './ui/SuffixControls/SuffixControls'

import cn from 'classnames'
import * as Icon from '../../../../../../fsd/shared/ui-icons'


export const ProjectButton: React.FC<PropsType> = ({
  isOpen,
  disabledSidebar,
  isOpenProjectPopUp,
  setIsOpenProjectsPopUp,
  invitingProjectsCount
}) => {

  const [isHover, setIsHover] = useState(false)

  const setHover = (e: React.MouseEvent<HTMLDivElement>) => {
    const iconElement = e.target as HTMLDivElement | SVGSVGElement
    if (iconElement.id === 'left-sidebar-project-suffix-icon' || iconElement.parentElement?.id === 'left-sidebar-project-suffix-icon') {
      isHover && setIsHover(false)
      return
    }
    !isHover && setIsHover(true)
  }


  return (
    <div
      className={cn('project-button', {
        close: !isOpen,
        hover: isHover,
        active: isOpenProjectPopUp
      })}
      onClick={() => setIsOpenProjectsPopUp(!isOpenProjectPopUp)}
      onMouseOver={setHover}
      onMouseLeave={() => setIsHover(false)}
    >
      <PrefixIcon
        isActive={isOpenProjectPopUp}
        isOpenLeftSidebar={isOpen}
        disabledSidebar={disabledSidebar}
      />
      <ProjectButtonBody disabledSidebar={disabledSidebar} />
      <SuffixControls
        isOpen={isOpen}
        isOpenProjectPopUp={isOpenProjectPopUp}
        disabledSidebar={disabledSidebar}
        invitingProjectsCount={invitingProjectsCount}
      />

      <Icon.CircleChevron
        className={cn('folded-icon', {
          'folded-icon_hidden': isOpen,
          'folded-icon_open': isOpenProjectPopUp
        })}
        width='1.8'
        height='1.8'
      />

    </div>
  )
}

type PropsType = {
  isOpen: boolean
  disabledSidebar: boolean
  isOpenProjectPopUp: boolean
  setIsOpenProjectsPopUp: React.Dispatch<React.SetStateAction<boolean>>
  invitingProjectsCount: number | undefined
}