import { SET_LOADING_ADDED_FILE, ADDED_FILE_SUCCESS, ADDED_FILE_FAILED, SET_CLEARED_FILE } from "./constants"

const initialState = {
  isLoading: false,
  filesInfo: [],
  type: ''
}

export const fileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    
    case SET_LOADING_ADDED_FILE:
      return { ...state, isLoading: true}

    case ADDED_FILE_SUCCESS:
      return { 
        ...state,
        isLoading: false,
        filesInfo: payload.filesInfo,
        type: payload.type
      }

    case ADDED_FILE_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    case SET_CLEARED_FILE:
      return {
        ...state,
        filesInfo: [],
        type: ''
      }

    default:
      return state
  }
}