import React from 'react'
import cn from 'classnames'
import * as Icon from '../../../../shared/ui-icons'

import './FileUploadCard.scss'

export const FileUploadCard: React.FC<PropType> = React.memo(({
  title,
  className,
  onClick,
  icon = <Icon.DownloadOther />
}) => (
  <div className={cn('file-upload-card', className)} onClick={onClick}>
    <div className='btn'>
      {icon}
      <p>{title}</p>
    </div>
  </div>
))

type PropType = {
  title: string,
  className?: string,
  icon?: React.ReactNode
  onClick: () => void,
}