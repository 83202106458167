import { IProjectRole } from "../../../../(deprecated)/models/IProjectRole";
import { snakecaseFields } from "../../../../(deprecated)/utils/formatFields";

export class PutEditProjectRole {

  query = (data: { id: string | number, cb?: () => void } & Partial<IProjectRole>) => ({
    url: `/role_access/${data.id}`,
    method: 'PUT',
    body: snakecaseFields(data),
  })

  invalidatesTags = (res: IProjectRole | undefined, err: any, data: { id: string | number } & Partial<IProjectRole>) => (
    [{ type: 'ProjectRole', id: data.id }] as const
  )
}