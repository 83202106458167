import { PayloadAction, createSlice } from "@reduxjs/toolkit";


const checkboxForDocsShort = createSlice({
  name: 'checkbox-docsShort',
  initialState: {
    ids: [] as number[],
    isAllCheck: false as boolean
  },
  reducers: {
    onCheckDocId(state, { payload }: PayloadAction<{ id: number, isCheck: boolean }>) {
      state.isAllCheck = false
      if (!payload.isCheck) {
        state.ids = state.ids.filter((id) => id !== payload.id)
        return
      }
      state.ids.push(payload.id)
    },
    onCheckAllDoc(state, { payload }: PayloadAction<boolean>) {
      state.isAllCheck = payload
      state.ids = []
    }
  }
})

export const {
  onCheckDocId,
  onCheckAllDoc
} = checkboxForDocsShort.actions

export default checkboxForDocsShort.reducer