import camelcaseFields from '../../utils/formatFields'

export class GetInvitationsInProjectsListQuery {
  query = () => ({
    url: 'projects/invites'
  })

  transformResponse = (response: IProjectInviteResponse) => camelcaseFields(response)
}

export interface IProjectInvite {
  projectId: number
  projectName: string
}
export interface IProjectInviteResponse {
  invitingProjects: IProjectInvite[]
  invitingProjectsCount: number
}