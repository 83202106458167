export const SET_LOADING_GET_CHECKPOINTS = 'SET_LOADING_GET_CHECKPOINTS'
export const GET_CHECKPOINTS_SUCCESS = 'GET_CHECKPOINTS_SUCCESS'

export const SET_LOADING_POST_CHECKPOINT = 'SET_LOADING_POST_CHECKPOINT'

export const GET_CHECKPOINTS_VEHICLES_LOG = 'GET_CHECKPOINTS_VEHICLES_LOG'
export const GET_CHECKPOINTS_EMPLOYEE_LOG = 'GET_CHECKPOINTS_EMPLOYEE_LOG'
export const TOGGLE_LOADING_GET_CHECKPOINTS_VEHICLES_LOG = 'TOGGLE_LOADING_GET_CHECKPOINTS_VEHICLES_LOG'
export const TOGGLE_LOADING_GET_CHECKPOINTS_EMPLOYEE_LOG = 'TOGGLE_LOADING_GET_CHECKPOINTS_EMPLOYEE_LOG'
export const CLEAR_ALL_CHECKPOINTS_LOGS = 'CLEAR_ALL_CHECKPOINTS_LOGS'

export const PUT_CHECKPOINT_POSITION_SUCCESS = 'PUT_CHECKPOINT_POSITION_SUCCESS'

export const GET_CHECKPOINT_SECURITY_SUCCESS = 'GET_CHECKPOINT_SECURITY_SUCCESS'
export const POST_CHECKPOINT_SECURITY_SUCCESS = 'POST_CHECKPOINT_SECURITY_SUCCESS'
export const DELETE_CHECKPOINT_SECURITY_SUCCESS = 'DELETE_CHECKPOINT_SECURITY_SUCCESS'