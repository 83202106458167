import React from 'react'
import { IIcon } from './IIcon'

export const BigUserInTwoCircle: React.FC<IIcon> = ({ }) => (
  <svg width="187" height="177" viewBox="0 0 187 177" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.165039" y="11.2725" width="164.998" height="164.998" rx="82.4989" fill="#E8F1FB" />
    <path d="M138.683 114.065C132.812 103.914 123.401 96.9842 112.497 94.4858C117.678 92.3631 121.962 88.5071 124.615 83.5768C127.269 78.6464 128.128 72.9476 127.047 67.4539C125.965 61.9603 123.009 57.0127 118.684 53.4565C114.36 49.9003 108.934 47.9561 103.335 47.9561C97.7358 47.9561 92.3104 49.9003 87.9857 53.4565C83.6609 57.0127 80.7051 61.9603 79.6234 67.4539C78.5416 72.9476 79.401 78.6464 82.0547 83.5768C84.7084 88.5071 88.9917 92.3631 94.1729 94.4858C83.2836 96.9736 73.8585 103.914 67.9873 114.065C67.8174 114.389 67.7795 114.767 67.8817 115.12C67.9838 115.472 68.2181 115.771 68.5355 115.954C68.853 116.138 69.229 116.191 69.5852 116.104C69.9413 116.017 70.25 115.796 70.4467 115.486C77.3911 103.47 89.6949 96.2948 103.335 96.2948C116.975 96.2948 129.279 103.47 136.223 115.486C136.348 115.702 136.527 115.881 136.743 116.006C136.959 116.131 137.204 116.197 137.453 116.197C137.703 116.198 137.948 116.131 138.164 116.005C138.49 115.816 138.727 115.506 138.825 115.142C138.922 114.778 138.871 114.391 138.683 114.065ZM82.0112 72.1278C82.0112 67.9104 83.2619 63.7877 85.6049 60.281C87.948 56.7743 91.2783 54.0412 95.1747 52.4273C99.0711 50.8133 103.359 50.391 107.495 51.2138C111.631 52.0366 115.431 54.0675 118.413 57.0497C121.395 60.0318 123.426 63.8314 124.249 67.9678C125.072 72.1042 124.649 76.3917 123.036 80.2881C121.422 84.1845 118.688 87.5148 115.182 89.8579C111.675 92.201 107.552 93.4516 103.335 93.4516C97.6816 93.445 92.2616 91.1963 88.2641 87.1987C84.2665 83.2012 82.0178 77.7812 82.0112 72.1278Z" fill="#1A65DA" />
    <rect x="20.835" y="1" width="165" height="165" rx="82.5" stroke="#1A65DA" stroke-width="2" stroke-linecap="round" stroke-dasharray="10 15" />
  </svg>
)
