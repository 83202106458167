import React from 'react'
import cn from 'classnames'

import './FileUploadErrorInfo.scss'

export const FileUploadErrorInfo: React.FC<PropType> = React.memo(({
  isVisible,
  color,
  text,
  className
}) => (
  <div className={cn('file-upload-error-info', { visible: isVisible }, color, className)}>
    <p>{text}</p>
  </div>
))

type PropType = {
  isVisible: boolean,
  color: 'red' | 'yellow',
  text: string
  className?: string
}