import React, { useMemo } from 'react'

import { ACCESS_SUBJECT_KEY } from '../../constant/accessSubject'
import { IProjectRole } from '../../../../../(deprecated)/models/IProjectRole'
import { checkLockRoleAccessSubject } from '../../utils/checkLockRoleAccessSubject'

import * as Icons from '../../../../shared/ui-icons'
import cn from 'classnames'

import './RoleChipMatrix.scss'

export const RoleChipMatrix: React.FC<PropType> = React.memo(({
  data,
  accessSubjectKey,
  className,
  onClick
}) => {

  const value = data.accessSubjects[accessSubjectKey]
  const isLock = useMemo(() => checkLockRoleAccessSubject(data, accessSubjectKey), [data])


  const icon = useMemo(() => {

    if (isLock)
      return (
        <div className='icon-with-lock'>
          {value ? <Icons.CheckCircle /> : <Icons.XCircle />}
          <Icons.LockWithBorder />
        </div>
      )

    return value ? <Icons.CheckCircle /> : <Icons.XCircle />

  }, [value, isLock])

  return (
    <div
      className={cn(
        'role-chip-matrix',
        className,
        { [`${value}-lock`]: isLock }
      )}
      onClick={onClick}
    >
      {icon}
    </div>
  )
})

type PropType = {
  data: IProjectRole
  accessSubjectKey: ACCESS_SUBJECT_KEY
  className?: string,
  onClick?: () => void
}
