import * as TYPES from './types'

const initialState = {
  isLicenseTemplatesLoading: false,
  licensesTemplates: [],
  licensesTemplatesCount: 0,

  licensesTypes: [],
  allTypesCount: 0,
  isLicensesTypesLoading: false,

  activeLicense: null,
  isLoadingLicenseTemplate: false,
  isLoadingCreateLicenseTemplate: false,
  isLoadingCreateLicense: false,
  isLoadingDeletionTemplate: false
}

export const licensesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    //----Шаблоны удостоверений----
    case TYPES.GET_LICENSES_TEMPLATES_LIST:
      return {
        ...state,
        licensesTemplates: [...state.licensesTemplates, ...payload.results],
        licensesTemplatesCount: payload.count
      }
    case TYPES.CLEAR_LICENSES_TEMPLATES_LIST:
      return {
        ...state,
        licensesTemplates: [],
        licensesTemplatesCount: 0
      }
    case TYPES.SET_IS_LOADING_LICENSES:
      return {
        ...state,
        isLicenseTemplatesLoading: payload
      }
    //----Типы шаблонов удостоверений----
    case TYPES.GET_LICENSES_TYPES:
      return {
        ...state,
        licensesTypes: [...state.licensesTypes, ...payload.results],
        allTypesCount: payload.count
      }
    case TYPES.SET_IS_LOADING_LICENSES_TYPES:
      return {
        ...state,
        isLicensesTypesLoading: payload
      }
    case TYPES.CLEAR_LICENSES_TYPES:
      return {
        ...state,
        licensesTypes: [],
        allTypesCount: 0
      }
    //----CRUD на шаблон удостоверения----
    case TYPES.SET_IS_LOADING_LICENSE_TEMPLATE:
      return {
        ...state,
        isLoadingLicenseTemplate: payload
      }
    case TYPES.GET_LICENSE_TEMPLATE:
      return {
        ...state,
        activeLicense: payload
      }

    case TYPES.SET_LOADING_CREATE_LICENSE_TEMPLATE:
      return {
        ...state,
        isLoadingCreateLicenseTemplate: payload
      }

    case TYPES.DELETE_LICENSE_TEMPLATE_LOADING:
      return {
        ...state,
        isLoadingDeletionTemplate: payload
      }

    case TYPES.SET_IS_LOADING_CREATE_LICENSE:
      return {
        ...state,
        isLoadingCreateLicense: payload
      }
    default:
      return state
  }
}