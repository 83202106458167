import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../../ui-component'
import { useMobileMode } from './hooks/useMobileMode'

import * as Icons from '../../ui-icons'
import './MobileAppsDownloadBanner.scss'
import Modal from "../../../../(deprecated)/components/UI/Modal/Modal";

export const MobileAppsDownloadBanner = () => {

  const heightScreen = useSelector((state: any) => state.app.mobileInfo.height)
  const [isFirstScreen, setIsFirstScreen] = useState(true)

  const {
    isViewBanner,
    setIsViewBanner,
    typeOS,
    isMobile
  } = useMobileMode()

  const changeViewBanner = () => (
    setIsViewBanner(window.screen.orientation.angle == 0 && window.screen.orientation.type == 'portrait-primary')
  )

  const downloadApp = () => {
    let link = typeOS == 1
      ? 'https://play.google.com/store/apps/details?id=com.simplesolutiondocuments'
      : 'https://apps.apple.com/ru/app/simple-solution-documents/id1582614997'
    window.open(link, '_blank')
  }

  useEffect(() => {
    if (!isFirstScreen) {
      changeViewBanner()
      window.addEventListener('orientationchange', changeViewBanner)
    }
  }, [isFirstScreen])

  useEffect(() => {
    // Жесткий костыль(
    if (isViewBanner && isMobile) {
      const html = document.querySelector('html')
      if (window.matchMedia('(max-width:1024px) and (min-width:954px) and (orientation: portrait)').matches && html) {
        html.style.fontSize = '130%'
      }
      if (window.matchMedia('(max-width:953px) and (min-width:767px) and (orientation: portrait)').matches && html) {
        html.style.fontSize = '100%'
      }
      if (window.matchMedia('(max-width:1366px) and (min-width:954px) and (orientation: landscape)').matches && html) {
        html.style.fontSize = '85%'
      }
      if (window.matchMedia('(max-width:953px) and (min-width:767px) and (orientation: landscape)').matches && html) {
        html.style.fontSize = '75%'
      }
      if (window.matchMedia('(max-width:767px) and (min-width:425px) and (orientation: portrait)').matches && html) {
        html.style.fontSize = '75%'
      }
      if (window.matchMedia('(max-width:424px) and (min-width:375px) and (orientation: portrait)').matches && html) {
        html.style.fontSize = '65%'
      }
    }
  }, [isViewBanner, isMobile])

  return (
    <Modal
      start={isViewBanner && isMobile}
      title=''
      className='banner-download-modal'
      style={{ height: heightScreen / 0.8 - 32 }}
    >
      <div className='banner-download' >
        {isFirstScreen ? (
          <>
            <div className='banner-download__top-block'>
              <Icons.BackgroundBanner className='banner-download__top-block_background-svg' />
              <div className='banner-download__top-block__main'>
                <div className='banner-download__top-block__main_svg-close'>
                  <Icons.Close
                    className='btn-close'
                    onClick={() => setIsFirstScreen(false)}
                  />
                </div>
                <div className='banner-download__top-block__main_svg-wrapper'>
                  <Icons.FaviconSL />
                </div>
                <div className='banner-download__top-block__main_text-wrapper'>
                  <h1>В приложении удобнее!</h1>
                  <p>Скачайте приложение SLDocuments в</p>
                  <p> App Store или Google Play</p>
                </div>
                <div className='banner-download__top-block__main_icons-wrapper'>
                  <Icons.AppStore />
                </div>
              </div>
            </div>
            <div className='banner-download__bottom-block'>
              <Button
                title='Скачать'
                onClick={downloadApp} />
            </div>
          </>
        ) : (
          <div className='banner-download__turn-over'>
            <div className='banner-download__turn-over__top-block'>
              <Icons.AttentionOrientation />
              <h2>Переверните устройство</h2>
              <p>для удобства использования</p>
            </div>
            <div className='banner-download__turn-over__middle-block'></div>
          </div>
        )}
      </div>
    </Modal>
  )
}