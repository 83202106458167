import { WS_CONNECT_SUCCESS, WS_DISCONNECT_SUCCESS, WS_INCOMING_MESSAGE } from "../../utils/websockets/actionTypes/actionTypes"

// CONNECT
export const WS_NOTIFICATIONS_CONNECT_SUCCESS = WS_CONNECT_SUCCESS.concat('_NOTIFICATIONS')
export const WS_NOTIFICATIONS_DISCONNECT_SUCCESS = WS_DISCONNECT_SUCCESS.concat('_NOTIFICATIONS')

// INCOMMING_MESSAGE
export const WS_INCOMING_MESSAGE_ALL_NOTIFICATIONS = WS_INCOMING_MESSAGE.concat('_NOTIFICATIONS_ALL_NOTIFICATIONS')
export const WS_INCOMING_MESSAGE_RECEIVE_INCOMING_NOTIFICATION = WS_INCOMING_MESSAGE.concat('_NOTIFICATIONS_RECEIVE_INCOMING_NOTIFICATION')

// COMMON
export const SET_TOGGLE_LOADING_NOTIFICATIONS = 'SET_TOGGLE_LOADING_NOTIFICATIONS'
export const CLEAR_APP_NOTIFICATIONS = 'CLEAR_APP_NOTIFICATIONS'
export const READ_ALL_APP_NOTIFICATIONS = 'READ_ALL_APP_NOTIFICATIONS'
export const CLEAR_ALL_APP_NOTIFICATIONS_FOR_SHOW = 'CLEAR_ALL_APP_NOTIFICATIONS_FOR_SHOW'