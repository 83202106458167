import React from 'react'
import { IIcon } from './IIcon'

export const SupportPersonHead: React.FC<IIcon> = ({
  width = '2',
  height = '1.8',
  color = '#5C5C5C',
  ...innerProps
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} viewBox="0 0 20 18" fill="none"
       xmlns="http://www.w3.org/2000/svg" {...innerProps}>
    <path
      d="M19 9.22C19 3.73 14.74 0 10 0C5.31 0 1 3.65 1 9.28C0.4 9.62 0 10.26 0 11V13C0 14.1 0.9 15 2 15H3V8.9C3 5.03 6.13 1.9 10 1.9C13.87 1.9 17 5.03 17 8.9V16H9V18H17C18.1 18 19 17.1 19 16V14.78C19.59 14.47 20 13.86 20 13.14V10.84C20 10.14 19.59 9.53 19 9.22Z"
      fill={color}/>
    <path
      d="M7 11C7.55228 11 8 10.5523 8 10C8 9.44772 7.55228 9 7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11Z"
      fill={color}/>
    <path
      d="M13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11Z"
      fill={color}/>
    <path
      d="M16 8.03C15.52 5.18 13.04 3 10.05 3C7.01997 3 3.75997 5.51 4.01997 9.45C6.48997 8.44 8.34997 6.24 8.87997 3.56C10.19 6.19 12.88 8 16 8.03Z"
      fill={color}/>
  </svg>
)
