import React from 'react'
import { IIcon } from './IIcon'

export const BriefcaseMini:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 25 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.75 10.5C14.75 10.6989 14.671 10.8897 14.5303 11.0303C14.3897 11.171 14.1989 11.25 14 11.25H11C10.8011 11.25 10.6103 11.171 10.4697 11.0303C10.329 10.8897 10.25 10.6989 10.25 10.5C10.25 10.3011 10.329 10.1103 10.4697 9.96967C10.6103 9.82902 10.8011 9.75 11 9.75H14C14.1989 9.75 14.3897 9.82902 14.5303 9.96967C14.671 10.1103 14.75 10.3011 14.75 10.5ZM22.25 6.75V18.75C22.25 19.1478 22.092 19.5294 21.8107 19.8107C21.5294 20.092 21.1478 20.25 20.75 20.25H4.25C3.85218 20.25 3.47064 20.092 3.18934 19.8107C2.90804 19.5294 2.75 19.1478 2.75 18.75V6.75C2.75 6.35218 2.90804 5.97064 3.18934 5.68934C3.47064 5.40804 3.85218 5.25 4.25 5.25H8V4.5C8 3.90326 8.23705 3.33097 8.65901 2.90901C9.08097 2.48705 9.65326 2.25 10.25 2.25H14.75C15.3467 2.25 15.919 2.48705 16.341 2.90901C16.7629 3.33097 17 3.90326 17 4.5V5.25H20.75C21.1478 5.25 21.5294 5.40804 21.8107 5.68934C22.092 5.97064 22.25 6.35218 22.25 6.75ZM9.5 5.25H15.5V4.5C15.5 4.30109 15.421 4.11032 15.2803 3.96967C15.1397 3.82902 14.9489 3.75 14.75 3.75H10.25C10.0511 3.75 9.86032 3.82902 9.71967 3.96967C9.57902 4.11032 9.5 4.30109 9.5 4.5V5.25ZM20.75 10.6509V6.75H4.25V10.6509C6.78146 12.0289 9.61783 12.7505 12.5 12.75C15.3822 12.7505 18.2185 12.0289 20.75 10.6509Z"
      fill={color}/>
  </svg>
)