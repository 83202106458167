import React from 'react'
import { IIcon } from './IIcon'

export const ArrowClockwise:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.4995 5.24989V9.74989C22.4995 9.9488 22.4205 10.1396 22.2799 10.2802C22.1392 10.4209 21.9484 10.4999 21.7495 10.4999H17.2495C17.1011 10.5 16.956 10.4561 16.8325 10.3737C16.7091 10.2913 16.6129 10.1741 16.5561 10.037C16.4992 9.89987 16.4844 9.74897 16.5134 9.60341C16.5424 9.45785 16.6139 9.32416 16.7189 9.21926L18.3126 7.62551L17.3236 6.72083L17.3001 6.69833C16.2577 5.65629 14.9313 4.9445 13.4867 4.6519C12.0421 4.3593 10.5433 4.49884 9.17754 5.05311C7.81178 5.60737 6.63961 6.55178 5.80746 7.76835C4.97531 8.98493 4.52008 10.4197 4.49864 11.8935C4.4772 13.3673 4.89049 14.8148 5.68689 16.055C6.48329 17.2953 7.62749 18.2734 8.97655 18.8672C10.3256 19.4609 11.8197 19.644 13.2722 19.3936C14.7248 19.1431 16.0713 18.4702 17.1436 17.459C17.2152 17.3912 17.2994 17.3383 17.3915 17.3031C17.4835 17.268 17.5816 17.2513 17.6801 17.254C17.7786 17.2568 17.8756 17.2789 17.9655 17.3191C18.0555 17.3593 18.1366 17.4169 18.2044 17.4885C18.2721 17.5601 18.325 17.6443 18.3602 17.7364C18.3953 17.8284 18.412 17.9265 18.4093 18.025C18.4065 18.1235 18.3844 18.2205 18.3442 18.3104C18.304 18.4004 18.2464 18.4816 18.1748 18.5493C16.5072 20.1284 14.2962 21.0058 11.9995 20.9999H11.8758C10.4018 20.9797 8.9553 20.5978 7.66349 19.8876C6.37167 19.1775 5.27406 18.1609 4.46714 16.9273C3.66021 15.6936 3.16866 14.2806 3.03571 12.8125C2.90275 11.3443 3.13245 9.86604 3.70462 8.50747C4.27679 7.14891 5.17391 5.95169 6.31713 5.02104C7.46036 4.09039 8.81468 3.4548 10.2611 3.17013C11.7075 2.88547 13.2016 2.96044 14.6122 3.38847C16.0229 3.8165 17.3068 4.58447 18.3511 5.62489L19.3758 6.56239L21.2189 4.71551C21.324 4.61028 21.458 4.53867 21.604 4.5098C21.7499 4.48093 21.9011 4.4961 22.0384 4.55338C22.1756 4.61066 22.2928 4.70746 22.3749 4.83149C22.457 4.95551 22.5004 5.10115 22.4995 5.24989Z"
      fill={color}/>
  </svg>
)