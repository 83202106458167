import React from 'react'
import { IIcon } from './IIcon'

export const Book:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.25 3V18C20.25 18.1989 20.171 18.3897 20.0303 18.5303C19.8897 18.671 19.6989 18.75 19.5 18.75H6.75C6.35218 18.75 5.97064 18.908 5.68934 19.1893C5.40804 19.4706 5.25 19.8522 5.25 20.25H18C18.1989 20.25 18.3897 20.329 18.5303 20.4697C18.671 20.6103 18.75 20.8011 18.75 21C18.75 21.1989 18.671 21.3897 18.5303 21.5303C18.3897 21.671 18.1989 21.75 18 21.75H4.5C4.30109 21.75 4.11032 21.671 3.96967 21.5303C3.82902 21.3897 3.75 21.1989 3.75 21V5.25C3.75 4.45435 4.06607 3.69129 4.62868 3.12868C5.19129 2.56607 5.95435 2.25 6.75 2.25H19.5C19.6989 2.25 19.8897 2.32902 20.0303 2.46967C20.171 2.61032 20.25 2.80109 20.25 3Z" fill={color} />
  </svg>
)



