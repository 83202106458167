import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTSelector } from '../../../../../../../utils/hooks/reduxHooks'
import { IProjectRole } from '../../../../../../../models/IProjectRole'
import { ACCESS_SUBJECT_BY_MODULE } from '../../../../../../../../fsd/entities/ProjectRole'

import cn from 'classnames'
import * as Icon from '../../../../../../../../fsd/shared/ui-icons'
export const SuffixControls: React.FC<PropsType> = ({
  invitingProjectsCount,
  isOpen,
  isOpenProjectPopUp,
  disabledSidebar
}) => {
  const history = useHistory()
  const myRole = useTSelector(state => state.user.userRoleInProject) as IProjectRole | null

  const isVisibleSettings = useMemo(() => (
    ACCESS_SUBJECT_BY_MODULE.project.accessSubjectsKeys.find((key) => (
      myRole?.accessSubjects[key]
    ))
  ), [myRole])

  return (
    <>
      <div
        className={cn('projects-invitations', {
          blinker: invitingProjectsCount,
          close: !isOpen
        })}
      >
        {invitingProjectsCount}
      </div>

      <Icon.ChevronWithoutBorder
        className={cn('chevron', {
          close: !isOpen,
          active: isOpenProjectPopUp,
          hidden: invitingProjectsCount
        })}
      />

      {!disabledSidebar && (
        isVisibleSettings
          ? (
            <Icon.Gear
              className={cn('suffix_icon', { close: !isOpen })}
              color='#949494'
              id='left-sidebar-project-suffix-icon'
              onClick={(e: React.MouseEvent<SVGSVGElement>) => {
                e.stopPropagation()
                history.push('/')
              }}
            />)
          : (
            <Icon.Eye
              isOpenEye
              id='left-sidebar-project-suffix-icon'
              className={cn('suffix_icon', { close: !isOpen })}
              onClick={(e: React.MouseEvent<SVGSVGElement>) => {
                e.stopPropagation()
                history.push('/')
              }}
            />
          )
      )}
    </>
  )
}

type PropsType = {
  invitingProjectsCount: number | undefined
  isOpen: boolean
  isOpenProjectPopUp: boolean
  disabledSidebar: boolean
}