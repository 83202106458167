import sanitizeHtml from 'sanitize-html'

export const sanitizeHtmlChat = (text: string) => sanitizeHtml(text, {
  allowedTags: ['a'],
  allowedAttributes: {
    a: ['href','target']
  },
  allowedClasses:{
    'a':['tag']
  }
})