import React from 'react'

export const SupportInfo = React.memo(() => (
  <>
    <p className='support-title'>Техподдержка</p>
    <a
      className='support-link'
      href="mailto:sl-help@liis.su"
      target='_blank'
    >
      sl-help@liis.su
    </a>
  </>
))