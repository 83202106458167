import React from 'react'
import Button from '../Button/Button'

import './ModalSaveButtons.scss'

export const ModalSaveButtons: React.FC<PropType> = React.memo(({
  onClose,
  onSave
}) => (
  <div className='footer-btn-row'>
    <Button
      buttonType='blue-ligth'
      title='Отменить'
      onClick={onClose}
    />
    <Button
      title='Сохранить'
      onClick={onSave}
    />
  </div>
))

type PropType = {
  onClose: () => void
  onSave: () => void
}