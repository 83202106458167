import React, { useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

const PreviewFilePdf = ({
  urlFile,
  isMiniPreview = false
}) => {
  const [renderPages, setRenderPage] = useState()

  const afterLoad = ({ numPages }) => {
    let prepareRenderPages = []
    for (let index = 1; index <= numPages; index++)
      prepareRenderPages.push(<Page pageNumber={index} key={index} scale={1.27} />)
    setRenderPage([...prepareRenderPages])
  }

  return (
    <div className={isMiniPreview ? 'preview-pdf-file' : 'pdf-viewer'}>
      <Document
        file={urlFile}
        onLoadSuccess={afterLoad}
      >
        {isMiniPreview
          ? <Page pageNumber={1} scale={0.2} />
          : renderPages}
      </Document>
    </div>
  )
}

export default PreviewFilePdf