import camelcaseFields from '../utils/formatFields'
import * as yup from 'yup'

export type MetaData = {
  page: number
  perPage: number
  totalPages: number
  totalResults: number
}

export class MetaAdapter {
  private model: MetaData = {
    page: 0,
    perPage: 0,
    totalPages: 0,
    totalResults: 0
  }

  readonly validateSchema = yup.object().shape({
    page: yup.number().required(),
    perPage: yup.number().required(),
    totalPages: yup.number().required(),
    totalResults: yup.number().required()
  })

  constructor(meta: any) {
    try {
      const camelMeata = camelcaseFields(meta)
      const validMeta: any = this.validateSchema.cast(camelMeata)

      this.model.page = validMeta.page
      this.model.perPage = validMeta.perPage
      this.model.totalPages = validMeta.totalPages
      this.model.totalResults = validMeta.totalResults
    } catch (e) {
      console.error(e)
    }
  }

  getFields(): MetaData {
    return this.model
  }
}