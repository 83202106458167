import { ProjectAdapter } from './ProjectAdapter'

export class GetProjectByIdQuery {
  query = ({ id }: { id: number }) => {
    return `projects/${id}`
  }

  transformResponse = (response: any) => {
    return new ProjectAdapter(response).getFields()
  }
}