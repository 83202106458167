import React from 'react'
import { IIcon } from './IIcon'

export const ArrowBack: React.FC<IIcon> = ({
  width = '24',
  height = '24',
  color = 'black',
  opacity = '0.8',
  ...innerProps
}) => (
  <svg {...innerProps} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M20 12.6058L7.47696 12.6058L13 17.7554L11.3503 19.2758L3 11.5205L11.1154 4L12.7651 5.52047L7.47696 10.4351L20 10.4351V12.6058Z"
          fill={color} fillOpacity={opacity}/>
  </svg>
)
