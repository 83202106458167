import React, { useMemo } from 'react'
import { useFunctionsAccess } from '../hook/useFuctionsAccess'
import { ALL_FUNCTIONS_FOR_ACCESS } from '../type/typeFunctions'

import cn from 'classnames'

export const FunctionAccess: React.FC<PropType> = ({
  functionsAccess,
  className,
  children,
  noAccessComponent
}) => {

  const approveFunctions = useFunctionsAccess()
  const hasAccess = useMemo(() => (
    approveFunctions.find(f => functionsAccess.includes(f))
  ), [approveFunctions, functionsAccess])

  return (
    <div className={cn('function-access', className)}>
      {hasAccess && children}
      {!hasAccess && noAccessComponent}
    </div>
  )
}

type PropType = {
  functionsAccess: ALL_FUNCTIONS_FOR_ACCESS[]
  className?: string
  children?: React.ReactNode | React.ReactNode[]
  noAccessComponent?: React.ReactNode | React.ReactNode[]
}
