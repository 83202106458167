import React from 'react'
import { IIcon } from './IIcon'

export const Checks: React.FC<IIcon> = React.memo(({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  opacity = '1',
  className,
  ...innerProps
}) => (
    <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 4.5H3C2.60218 4.5 2.22064 4.65804 1.93934 4.93934C1.65804 5.22064 1.5 5.60218 1.5 6V18C1.5 18.3978 1.65804 18.7794 1.93934 19.0607C2.22064 19.342 2.60218 19.5 3 19.5H21C21.3978 19.5 21.7794 19.342 22.0607 19.0607C22.342 18.7794 22.5 18.3978 22.5 18V6C22.5 5.60218 22.342 5.22064 22.0607 4.93934C21.7794 4.65804 21.3978 4.5 21 4.5ZM7.5 15C7.40148 15.0001 7.30391 14.9807 7.21286 14.9431C7.12182 14.9055 7.03908 14.8503 6.96937 14.7806L4.71938 12.5306C4.57864 12.3899 4.49958 12.199 4.49958 12C4.49958 11.801 4.57864 11.6101 4.71938 11.4694C4.86011 11.3286 5.05098 11.2496 5.25 11.2496C5.44902 11.2496 5.63989 11.3286 5.78062 11.4694L7.5 13.1897L12.2194 8.46937C12.2891 8.39969 12.3718 8.34442 12.4628 8.3067C12.5539 8.26899 12.6515 8.24958 12.75 8.24958C12.8485 8.24958 12.9461 8.26899 13.0372 8.3067C13.1282 8.34442 13.2109 8.39969 13.2806 8.46937C13.3503 8.53906 13.4056 8.62178 13.4433 8.71283C13.481 8.80387 13.5004 8.90145 13.5004 9C13.5004 9.09855 13.481 9.19613 13.4433 9.28717C13.4056 9.37822 13.3503 9.46094 13.2806 9.53063L8.03063 14.7806C7.96092 14.8503 7.87818 14.9055 7.78714 14.9431C7.69609 14.9807 7.59852 15.0001 7.5 15ZM20.0306 9.53063L14.7806 14.7806C14.711 14.8504 14.6283 14.9057 14.5372 14.9434C14.4462 14.9812 14.3486 15.0006 14.25 15.0006C14.1514 15.0006 14.0538 14.9812 13.9628 14.9434C13.8717 14.9057 13.789 14.8504 13.7194 14.7806L12.2194 13.2806C12.0786 13.1399 11.9996 12.949 11.9996 12.75C11.9996 12.551 12.0786 12.3601 12.2194 12.2194C12.3601 12.0786 12.551 11.9996 12.75 11.9996C12.949 11.9996 13.1399 12.0786 13.2806 12.2194L14.25 13.1897L18.9694 8.46937C19.0391 8.39969 19.1218 8.34442 19.2128 8.3067C19.3039 8.26899 19.4015 8.24958 19.5 8.24958C19.5985 8.24958 19.6961 8.26899 19.7872 8.3067C19.8782 8.34442 19.9609 8.39969 20.0306 8.46937C20.1003 8.53906 20.1556 8.62178 20.1933 8.71283C20.231 8.80387 20.2504 8.90145 20.2504 9C20.2504 9.09855 20.231 9.19613 20.1933 9.28717C20.1556 9.37822 20.1003 9.46094 20.0306 9.53063Z"
        fill={color} fillOpacity={opacity}/>
    </svg>
  )
)