import React, { useState } from 'react'
import { useTDispatch, useTSelector } from '../../../../../utils/hooks/reduxHooks'
import { declOfNum } from '../../../../../utils/declOfNum'
import { settingActiveProject } from '../../../../../redux/project/actions'
import { setProjectExtended } from '../../../../../utils/localStorageManagement'
import { ModalProjectInvitation } from '../Modals/ModalProjectInvitation/ModalProjectInvitation'
import { IProject } from '../../../../../models/IProject'
import { IProjectInvite } from '../../../../../api/projectApi/GetInvitationsInProjectsListQuery'
import { FunctionAccess } from '../../../../../../fsd/features/FunctionAccess'
import { CreateProjectButton } from '../../../../../../fsd/features/Project/CreateProjectButton/CreateProjectButton'

import cn from 'classnames'

import './ProjectsListPopUp.scss'

export const ProjectsListPopUp: React.FC<PropsType> = ({
  isOpenPopUp,
  isOpenLeftSidebar,
  getInvitationsProject,
  invitingProjectsCount,
  invitingProjects,
  onChooseProject
}) => {
  const dispatch = useTDispatch()

  const [showModal, setShowModal] = useState(false)
  const [inviteProjectModalData, setInviteProjectModalData] = useState({ projectId: NaN, projectName: '' })

  const projectList = useTSelector((state: any) => state.project.projectList) as IProject[]
  const activeProjectId = useTSelector(state => state.app.activeProjectId) as number
  const userId = useTSelector(state => state.user.userInfo.id)

  const handleChooseProject = (projectId: number) => {
    dispatch(settingActiveProject(projectId))
    setProjectExtended({ userId, projectId })
    onChooseProject()
  }

  const handleClickOnInviteProject = (projectId: number, projectName: string) => {
    setShowModal(true)
    setInviteProjectModalData({ projectId, projectName })
  }

  return (
    <div
      className={cn('left-sidebar-projects-popup', {
        hidden: !isOpenPopUp,
        close: !isOpenLeftSidebar
      })}
      onClick={(e) => e.stopPropagation()}
    >
      <div className='left-sidebar-projects-popup-list'>
        {!!invitingProjectsCount && (
          <div className='projects-invites-counts'>
            {
              `
                ${invitingProjectsCount}
                ${declOfNum(invitingProjectsCount, ['новый', 'новых', 'новых'])}
                ${declOfNum(invitingProjectsCount, ['проект', 'проекта', 'проектов'])}
              `
            }
          </div>
        )}
        {invitingProjects?.map(({ projectId, projectName }) => (
          <div
            className='project-invite'
            key={projectId}
            onClick={() => handleClickOnInviteProject(projectId, projectName)}
          >
            {projectName}
          </div>
        ))}


        {projectList?.map(project => (
          <div
            key={project.id}
            className={cn('left-sidebar-projects-popup-list__item', {
              active: activeProjectId === project.id
            })}
            onClick={() => handleChooseProject(Number(project.id))}
          >
            <p className='project-title'>{project.name}</p>
          </div>
        ))}
      </div>

      <FunctionAccess functionsAccess={['create-project']}>
        <CreateProjectButton />
      </FunctionAccess>

      <ModalProjectInvitation
        open={showModal}
        data={inviteProjectModalData}
        getInvitationsProject={getInvitationsProject}
        setInviteProjectModalData={setInviteProjectModalData}
        onCancel={() => setShowModal(false)}
      />
    </div>
  )
}

type PropsType = {
  isOpenPopUp: boolean
  isOpenLeftSidebar: boolean
  getInvitationsProject: (arg?: any) => any
  invitingProjectsCount: number | undefined
  invitingProjects: IProjectInvite[] | undefined
  onChooseProject: () => void
}