import React from 'react'

import cn from 'classnames'

import './ProjectParticipantsInfo.scss'

export const ProjectParticipantsInfo: React.FC<PropType> = React.memo(({
  allCount,
  countInMyCompany,
  className
}) => (
  <div className={cn('settings-project-role__participants', className)}>
    <p className='title'>Участники проекта</p>
    <p className='all-count'>Всего <span>{allCount}</span> </p>
    <p className='count-my-project'>Моя компания <span>{countInMyCompany}</span></p>
  </div>
))

type PropType = {
  allCount?: number,
  countInMyCompany?: number,
  className?: string
}
