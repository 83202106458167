import React from 'react'
import { IIcon } from './IIcon'

export const User:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.6497 20.625C21.5839 20.739 21.4892 20.8337 21.3751 20.8996C21.2611 20.9654 21.1317 21 21 21H3.00002C2.86844 20.9999 2.7392 20.9651 2.62529 20.8992C2.51139 20.8334 2.41682 20.7387 2.35108 20.6247C2.28535 20.5107 2.25076 20.3814 2.25079 20.2498C2.25083 20.1182 2.28548 19.989 2.35127 19.875C3.77909 17.4066 5.9794 15.6366 8.54721 14.7975C7.27706 14.0414 6.29022 12.8892 5.73826 11.5179C5.1863 10.1467 5.09973 8.63213 5.49184 7.2069C5.88396 5.78167 6.73308 4.52456 7.9088 3.62862C9.08452 2.73268 10.5218 2.24745 12 2.24745C13.4782 2.24745 14.9155 2.73268 16.0913 3.62862C17.267 4.52456 18.1161 5.78167 18.5082 7.2069C18.9003 8.63213 18.8137 10.1467 18.2618 11.5179C17.7098 12.8892 16.723 14.0414 15.4528 14.7975C18.0207 15.6366 20.221 17.4066 21.6488 19.875C21.7147 19.9889 21.7496 20.1182 21.7497 20.2499C21.7499 20.3815 21.7154 20.5109 21.6497 20.625Z"
      fill={color}/>
  </svg>
)