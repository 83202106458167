import React, { useState, useRef } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-textarea-autosize'
import withAutoSaveData from '../HOC/withAutoSaveData'
import './Textarea.scss'

const Textarea = ({
  value = '',
  name,
  onChange,
  placeholder,
  className,
  maxlength = 1000,
  onFocus = () => { },
  onBlur = () => { },
  touched,
  error,
  suffix,
  autoCompleteList,
  isViewMaxLength = true,
  modifyRenderNodes, //доп ноды, для измененного рендера
  onEnter,
  disabled,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false)
  const textareaElement = useRef()
  const lasKeyDownRef = useRef({})

  const handleFocus = (e) => {
    setIsFocus(true)
    onFocus(name)
  }

  const handleChoose = (item) => {
    onChange({ target: { name, value: item } }, lasKeyDownRef.ref)
    setIsFocus(false)
  }

  return (
    <div className={cn('custom-textarea', className, {
      'custom-textarea_error': error && touched && (error[name] || typeof error == 'string'),
      'custom-textarea_focus': isFocus,
      'custom-textarea_disabled': disabled,
      'move-placeholder': isFocus || Boolean(value)
    })}
      id={name}
      onClick={() => textareaElement.current.focus()}
    >
      <div className='custom-textarea__modify'>
        {modifyRenderNodes && modifyRenderNodes}
        <TextareaAutosize
          tabIndex={0}
          ref={textareaElement}
          value={value}
          name={name}
          onChange={(e) => onChange(e, lasKeyDownRef.ref)}
          maxLength={maxlength}
          onFocus={handleFocus}
          disabled={disabled}
          style={{ height: '15rem' }}
          onBlur={() => {
            setIsFocus(false)
            onBlur(name, true)
          }}
          onKeyDown={(e) => {
            lasKeyDownRef.ref = e
            e.keyCode === 9 && setIsFocus(false)
            e.key === 'Enter' && onEnter?.(name, value)
          }}
          {...props}
        />
      </div>
      {suffix}
      {isViewMaxLength &&
        <div className='custom-textarea__count-symbol'>
          {`${value ? value.length : 0}/${maxlength}`}
        </div>
      }
      {error && error[name] && touched && (
        <div className='input__error'>{error[name]}</div>
      )}
      {typeof error == 'string' && touched && (
        <div className='input__error'>{error}</div>
      )}
      {autoCompleteList && isFocus && !(autoCompleteList.length === 1 && autoCompleteList[0] === value) && (
        <div className='custom-textarea__dropdown-list'>
          {autoCompleteList.map((item, index) => (
            <span
              onClick={() => handleChoose(item)}
              key={`event-${index}`}>
              {item}
            </span>
          ))}
        </div>
      )}
      {placeholder &&
        <label
          className='text-area-label'
          onClick={() => { setIsFocus(true) }}
        >
          {placeholder}
        </label>
      }
    </div>
  )
}

Textarea.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  maxlength: PropTypes.number,
  disabled: PropTypes.bool,
}

export default withAutoSaveData(Textarea)