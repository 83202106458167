import camelcaseFields from '../../utils/formatFields'

export type IProjecModel = {
  address: string
  name: string
  description: string
  email: string
  id: number
  imageId: number
  imageUrl: string
  totalUsersCount: number
  usersFromMyCompanyCount: number
}

export class ProjectAdapter {

  model: IProjecModel = {
    address: '',
    description: '',
    name: '',
    email: '',
    id: NaN,
    imageId: NaN,
    imageUrl: '',
    totalUsersCount: NaN,
    usersFromMyCompanyCount: NaN
  }

  constructor(projectResponse: any) {
    try {

      const readableProject = camelcaseFields(projectResponse)
      this.model.address = readableProject.address
      this.model.description = readableProject.description
      this.model.email = readableProject.email
      this.model.id = readableProject.id
      this.model.name = readableProject.name

      this.model.totalUsersCount = readableProject.totalUsersCount
      this.model.usersFromMyCompanyCount = readableProject.usersFromMyCompanyCount

      this.model.imageId = readableProject.image.id
      this.model.imageUrl = readableProject.image.url
    } catch (e) {
      console.error(e)
    }
  }

  getFields(): IProjecModel {
    return this.model
  }

}