import { ProjectUserAdapter } from './ProjectUserAdapter'
import { IChatUser } from '../../models/IUser'

export class GetProjectUserByIdQuery {
  query = ({
    projectId,
    id
  }: {
    projectId?: number
    id: number
  }) => {
    return `projects/${projectId}/find_users?id=${id}`
  }

  transformResponse = (response: IChatUser[]) => {
    return new ProjectUserAdapter(response[0]).getFields()
  }
}