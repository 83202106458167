import camelcaseFields from '../../utils/formatFields'

export type ISystemUserModel = {
  id: number
  email: string
  firstName: string
  middleName: string
  lastName: string
  position: string
  imageUrl: string
  imageId: number | null
  companyTitle: string
  companyType: string
  companyId: string
  companyAddress: string
  birthDate: string
  companyRole: 'user' | 'admin' | 'worker'
  signUrl?: string | null
  signId?: number | null
  isEditable?: boolean
  isDeleted: boolean

  blockEmailNotification: boolean,
  blockSnackbar: boolean
}

export class SystemUserAdapter {
  private model: ISystemUserModel = {
    id: NaN,
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    position: '',
    imageUrl: '',
    imageId: null,
    companyType: '',
    birthDate: '',
    companyRole: 'worker',
    signUrl: null,
    signId: null,
    companyTitle: '',
    companyId: '',
    companyAddress: '',
    isEditable: false,
    isDeleted: false,

    blockEmailNotification: false,
    blockSnackbar: false
  }

  constructor(user: unknown) {
    try {
      const camelCaseUser = camelcaseFields(user)

      this.model.id = camelCaseUser.id
      this.model.email = camelCaseUser.email
      this.model.firstName = camelCaseUser.profile.firstName
      this.model.middleName = camelCaseUser.profile.middleName
      this.model.lastName = camelCaseUser.profile.lastName
      this.model.position = camelCaseUser.profile.position
      this.model.imageUrl = camelCaseUser.profile.image?.url
      this.model.imageId = camelCaseUser.profile.image?.id
      this.model.birthDate = camelCaseUser.profile.birthDate
      this.model.companyRole = camelCaseUser.profile.companyRole
      this.model.signId = camelCaseUser.profile.sign?.id
      this.model.signUrl = camelCaseUser.profile.sign?.url
      this.model.companyTitle = camelCaseUser.profile.company.title
      this.model.companyType = camelCaseUser.profile.company.type
      this.model.companyId = camelCaseUser.profile.company.id
      this.model.companyAddress = camelCaseUser.profile.company.address
      this.model.isEditable = camelCaseUser.profile.isEditable
      this.model.isDeleted = camelCaseUser.isDeleted
      this.model.blockEmailNotification = camelCaseUser.profile.settingNotifications.blockEmailNotification
      this.model.blockSnackbar = camelCaseUser.profile.settingNotifications.blockSnackbar
    } catch (e) {
      console.error(e)
    }
  }

  getFields(): ISystemUserModel {
    return this.model
  }
}