import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CardLinkWrapper } from '../../LeftSidebar/LeftSidebarNavLinks/CardLink/CardLinkWrapper/CardLinkWrapper'
import { CardLinkTooltip } from '../../LeftSidebar/LeftSidebarNavLinks/CardLink/CardLinkTooltip/CardLinkTooltip'
import cn from 'classnames'

export const CardLink:React.FC<PropsType> = ({
  id,
  to,
  onClick,
  condition,
  className,
  tooltipTitle,
  children
}) => {

  const history = useHistory()
  const [isShowNotification, setShowNotification] = useState(false)

  const isActiveCardLink = to && history.location.pathname.includes(to)

  return (
    <>
      <CardLinkWrapper
        condition={condition}
        id={id}
        to={to}
        className={cn(className, { active: isActiveCardLink })}
        onClick={onClick}
        onMouseEnter={() => setShowNotification(true)}
        onMouseLeave={() => setShowNotification(false)}
      >
        {children}
      </CardLinkWrapper>

      <CardLinkTooltip
        className='tool-tip-of-right-sidebar'
        cardLinkId={id}
        cardLinkTitle={tooltipTitle}
        isDisabledTooltip={!isShowNotification}
      />
    </>
  )
}

type PropsType = {
  id: string
  to: string
  onClick?: () => void
  tooltipTitle: string
  className: string
  condition: boolean
  children: React.ReactNode
}