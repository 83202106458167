import React from 'react'
import cn from 'classnames'

import './NoticePanel.scss'

export const NoticePanel: React.FC<PropsType> = ({
  children,
  className
}) => (
  <div className={cn('notice-panel', className)}>
    {children}
  </div>
)

type PropsType = {
  children: JSX.Element | JSX.Element[]
  className?: string
}