export const SET_LOADING_GET_LOCATIONS = 'SET_LOADING_GET_LOCATIONS'
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_FAILED = 'GET_LOCATIONS_FAILED'

export const SET_LOADING_POST_NEW_LOCATION = 'SET_LOADING_POST_NEW_LOCATION'
export const POST_NEW_LOCATION_SUCCESS = 'POST_NEW_LOCATION_SUCCESS'
export const POST_NEW_LOCATION_FAILED = 'POST_NEW_LOCATION_FAILED'

export const SET_LOADING_PUT_LOCATION = 'SET_LOADING_PUT_LOCATION'
export const PUT_LOCATION_SUCCESS = 'PUT_LOCATION_SUCCESS'
export const PUT_LOCATION_FAILED = 'PUT_LOCATION_FAILED'

export const SET_LOADING_DELETE_LOCATION = 'SET_LOADING_DELETE_LOCATION'
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS'
export const DELETE_LOCATION_FAILED = 'DELETE_LOCATION_FAILED'

// удалят поле в редаксе, после анмаунта компонеты 
// работает только в новой компоненте
export const REMOVE_UNIQUE_STATE_PROPERTY = 'REMOVE_UNIQUE_STATE_PROPERTY' 