import { useMemo } from 'react'
import { IProjectRole } from '../../../../(deprecated)/models/IProjectRole'
import { IOldUser } from '../../../../(deprecated)/models/IUser'
import { useTSelector } from '../../../../(deprecated)/utils/hooks/reduxHooks'
import { ALL_FUNCTIONS_FOR_ACCESS } from '../type/typeFunctions'
import { APPROVE_FUNCTIONS } from '../constant/approveFunctions'
import { ACCESS_SUBJECT_KEY } from '../../../entities/ProjectRole'
import { isEmpty } from 'lodash'

export const useFunctionsAccess = () => {

  const myRole = useTSelector(state => state.user.userRoleInProject) as IProjectRole | null
  const userInfo = useTSelector((state: any) => state.user.userInfo) as IOldUser

  const getFunctionsFromRoleInCompany = (userInfo: IOldUser | undefined) => (
    userInfo?.companyRole ? APPROVE_FUNCTIONS.other[userInfo.companyRole] : []
  )

  const getFunctionsFromRoleModel = (myRole: IProjectRole | null) => {
    if (!myRole || isEmpty(userInfo))
      return []

    const accessSubjectKeys = Object.keys(myRole.accessSubjects) as ACCESS_SUBJECT_KEY[]

    const functionsFromRoleModel = accessSubjectKeys
      .filter((key) => myRole.accessSubjects[key])
      .reduce((acc, key) => ([
        ...acc,
        ...APPROVE_FUNCTIONS[key][userInfo.companyRole]
      ]), [] as ALL_FUNCTIONS_FOR_ACCESS[])

    return functionsFromRoleModel
  }


  const approveFunctions = useMemo(() => {
    const functionsFromRoleModel = getFunctionsFromRoleModel(myRole)
    const functionsFromRoleInCompany = getFunctionsFromRoleInCompany(userInfo)

    return functionsFromRoleModel.concat(functionsFromRoleInCompany)
  }, [myRole, userInfo])

  return approveFunctions
}