import React from 'react'
import { IIcon } from './IIcon'

export const BigFileIcon: React.FC<IIcon> = ({
  width = '42',
  height = '42',
  color = '#F9F9FB',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 420 420" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M105 35C85.75 35 70.175 50.75 70.175 70L70 350C70 369.25 85.575 385 104.825 385H315C334.25 385 350 369.25 350 350V140L245 35H105ZM227.5 157.5V61.25L323.75 157.5H227.5Z"
      fill={color}/>
  </svg>
)