export const SET_LOADING_USER_LICENSES = 'SET_LOADING_USER_LICENSES'
export const SET_ACTIVE_USER_LICENSE = 'SET_ACTIVE_USER_LICENSE'
export const SET_IS_USER_LICENSE_LOADING = 'SET_IS_USER_LICENSE_LOADING'
export const GET_USER_LICENSES_SUCCESS = 'GET_USER_LICENSES_SUCCESS'
export const GET_USER_LICENSES_FAILED = 'GET_USER_LICENSES_FAILED'

export const POST_USER_LICENSES_SUCCESS = 'POST_USER_LICENSES_SUCCESS'
export const POST_USER_LICENSES_FAILED = 'POST_USER_LICENSES_FAILED'

export const POST_DOCUMENT_MARK_SUCCEED = 'POST_DOCUMENT_MARK_SUCCEED'

export const TOGGLE_LOADING_USER_SAVED_DOC = 'TOGGLE_LOADING_USER_SAVED_DOC'
export const GET_USER_SAVED_DOC_SUCCESS = 'GET_USER_SAVED_DOC_SUCCESS'
export const GET_USER_SAVED_DOC_FAILED = 'GET_USER_SAVED_DOC_FAILED'

export const TOGGLE_LOADING_USER_NEW_SAVED_DOC = 'TOGGLE_LOADING_USER_NEW_SAVED_DOC'
export const PUT_USER_SAVED_DOC_UPDATE_TITLE = 'PUT_USER_SAVED_DOC_UPDATE_TITLE'

export const DELETE_USER_LICENSE_SUCCESS = 'DELETE_USER_LICENSE_SUCCESS'