import React from 'react'
import { IIcon } from './IIcon'

export const Reply:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#1A65DA',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 9V5L3 12L10 19V14.9C15 14.9 18.5 16.5 21 20C20 15 17 10 10 9Z" stroke={color} strokeWidth="2"/>
  </svg>
)