import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '../../../../fsd/shared/ui-component'
import { addedImage } from '../../../redux/images/action'

import defaultImage from '../../../../fsd/shared/assets/default-avatar.png'
import * as Icon from '../../../../fsd/shared/ui-icons'
import cn from 'classnames'

import './Avatar.scss'

const IMAGE_TYPE = 'avatar'

export type AvatarPropTypesComponent = {
  className?: string,
  photo?: string,
  size?: AvatarSize,
  defaultAvatar?: any,
  onClick?: () => void,
  onUpload?: (...prop: any) => void
}
export type AvatarSize = 'xs' | 's' | 'sm' | 'm' | 'l' | 'xl'

const Avatar: React.FC<AvatarPropTypesComponent> = ({
  className = '',
  photo = '',
  size = 'm',
  onUpload = null,
  onClick,
  defaultAvatar = defaultImage
}) => {
  const uploadBtn = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const { imagesInfo, type } = useSelector((state: any) => state.images)
  const [isStartLoading, setIsStartLoading] = useState(false)

  const onChangeImage = ({ target: { files } }: { target: { files: any } }) => {
    setIsStartLoading(true)
    dispatch(addedImage(files, IMAGE_TYPE))
  }

  const handleUpload = () => {
    setIsStartLoading(false)
    onUpload && onUpload(imagesInfo[0])
  }
  useEffect(() => {
    if (type === IMAGE_TYPE && onUpload && isStartLoading) handleUpload()
  }, [imagesInfo])

  return (
    <div
      className={cn('avatar', `avatar_size-${size}`, className)}
      style={{ backgroundImage: `url(${photo || defaultAvatar})` }}
      onClick={onClick}
    >
      {onUpload && (
        <>
          <input
            accept='.svg, .png, .jpg, .jpeg'
            type='file'
            onChange={onChangeImage}
            ref={uploadBtn}
          />
          <Button
            title=''
            customClassName='avatar__upload-btn'
            icon={<Icon.UploadPhoto />}
            onClick={() => uploadBtn.current && uploadBtn.current.click()}
          />
        </>
      )}
    </div>
  )
}

export default Avatar
