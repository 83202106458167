import React, { useMemo } from 'react'
import { useTSelector } from '../../../../../../../utils/hooks/reduxHooks'
import { IProject } from '../../../../../../../models/IProject'

import cn from 'classnames'

export const ProjectButtonBody:React.FC<PropsType> = ({
  disabledSidebar
}) => {

  const projectList = useTSelector((state: any) => state.project.projectList) as IProject[]
  const activeProjectId = useTSelector(state => state.app.activeProjectId) as number

  const currentProjectInfo: IProject | null = useMemo(() => {
    return projectList.find(({ id }) => id === activeProjectId) || null
  }, [projectList, activeProjectId])

  return (
    <div className='project-button__main'>
      <div
        className={cn('project-button__main_info', {
          'new-project': disabledSidebar
        })}
      >
        {!disabledSidebar && <span className='title'>Проект</span>}
        <p
          className={cn('name', {
            'new-project': disabledSidebar
          })}
        >
          {disabledSidebar
            ? 'Новый проект'
            : currentProjectInfo?.name || 'Проектов нет'
          }
        </p>
      </div>
    </div>
  )
}

type PropsType = {
  disabledSidebar: boolean
}