import React, { useCallback, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import './SimpleInput.scss'

export const isNamedSetValue = (setValue: (...args: any) => void): setValue is NamedSetValue => setValue.length === 2

export const SimpleInput = React.memo(<SetFunction extends SimpleSetValue>
  ({
    value,
    setValue,
    children,
    placeholder,
    name,
    className,
    stylePattern = [],
    icons,
    inputMask,
    error,
    onBlur,
    touched,
    disabled,
    testId,
    maxLength,
    wrapperClassName
  }: SimpleInputProps<SetFunction>) => {

  const inputRef = useRef<HTMLInputElement>(null)
  const svgLeftRef = useRef<HTMLDivElement>(null)
  const placeholderRef = useRef<HTMLParagraphElement>(null)

  const [isFocus, setIsFocus] = useState(false)

  const setValueInner = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    const valueInput = inputMask instanceof Function
      ? inputMask(value)
      : value

    if (isNamedSetValue(setValue)) {
      setValue(name ?? '', valueInput, e)
      return
    }
    setValue(valueInput, e)
  }, [setValue, name])


  useEffect(() => {
    if (placeholderRef.current && svgLeftRef) {
      placeholderRef.current.style.left = `${(16 + ((svgLeftRef.current?.clientWidth ?? -12) + 12) * 1.25) / 10}rem`
    }
  }, [placeholderRef, svgLeftRef])

  return (
    <div className={cn('s-i-wrapper', wrapperClassName)}>
      <div
        className={cn('simple-input', className, ...stylePattern, {
          focus: isFocus,
          'not-empty': value?.length,
          'svg-left': icons?.left,
          'svg-right': icons?.right,
          'disabled': disabled,
        })}
        onClick={() => inputRef.current?.focus()}
      >
        {icons?.left && (
          <div
            className='simple-input_svg simple-input_svg-left'
            ref={svgLeftRef}
          >
            {icons?.left}
          </div>
        )}
        <input
          data-testid={testId}
          // для того, что бы работала табуляция через Enter
          tabIndex={0}
          ref={inputRef}
          value={value}
          maxLength={maxLength}
          disabled={disabled}
          onChange={setValueInner}
          onFocus={() => setIsFocus(true)}
          onBlur={() => {
            setIsFocus(false)
            onBlur?.()
          }}
        />
        {typeof placeholder == 'string' && (
          <p
            className='simple-input_placeholder'
            ref={placeholderRef}
          >{placeholder}</p>
        )}
        {icons?.right && (<div className='simple-input_svg simple-input_svg-right'>{icons?.right}</div>)}
      </div>
      {children?.({
        inputRef,
        isFocus
      })}
      {
        error && typeof error === 'string' && touched && (
          <div className='simple-input_error'>
            {error}
          </div>
        )
      }
    </div>
  )
}
)

type NamedSetValue = (name: string, value: string, e: React.ChangeEvent<HTMLInputElement>) => void
type SetValue = (value: string, e: React.ChangeEvent<HTMLInputElement>) => void
export type SimpleSetValue = NamedSetValue | SetValue

export type SimpleInputProps<SetFunction extends NamedSetValue | SetValue> = {
  value: string,
  setValue: SetFunction,
  onBlur?: () => void | SetFunction,
  onFocus?: SetFunction,
  error?: string,
  touched?: boolean,
  disabled?: boolean,
  inputMask?: (value: string) => string,
  children?: (props: {
    inputRef: React.RefObject<HTMLInputElement>,
    isFocus: boolean
  }) => React.ReactElement,
  name?: string,
  placeholder?: React.ReactElement | string
  className?: string,
  wrapperClassName?: string
  stylePattern?: Array<
    'border' | 'blue-border-focus' |
    'gray-background' |
    'top-placeholder'
  >
  icons?: {
    left?: React.ReactElement,
    right?: React.ReactElement
  }
  testId?: string
  maxLength?: number
}