import React from 'react'
import { IIcon } from './IIcon'

export const PencilLine: React.FC<IIcon> = React.memo(({
  width = '2.4',
  height = '2.4',
  color = 'black',
  opacity = '0.64',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.3113 6.87845L17.1216 2.68971C16.9823 2.55038 16.8169 2.43986 16.6349 2.36446C16.4529 2.28905 16.2578 2.25024 16.0608 2.25024C15.8638 2.25024 15.6687 2.28905 15.4867 2.36446C15.3047 2.43986 15.1393 2.55038 15 2.68971L3.4397 14.25C3.2998 14.3888 3.18889 14.554 3.11341 14.736C3.03792 14.9181 2.99938 15.1133 3.00001 15.3103V19.5C3.00001 19.8978 3.15804 20.2794 3.43935 20.5607C3.72065 20.842 4.10218 21 4.50001 21H20.25C20.4489 21 20.6397 20.921 20.7803 20.7803C20.921 20.6397 21 20.4489 21 20.25C21 20.0511 20.921 19.8603 20.7803 19.7197C20.6397 19.579 20.4489 19.5 20.25 19.5H10.8113L21.3113 9.00002C21.4506 8.86072 21.5611 8.69535 21.6365 8.51334C21.7119 8.33133 21.7507 8.13625 21.7507 7.93924C21.7507 7.74222 21.7119 7.54714 21.6365 7.36513C21.5611 7.18312 21.4506 7.01775 21.3113 6.87845ZM7.43626 17.625L15.9056 9.15564L17.4703 10.7194L9.00001 19.1897L7.43626 17.625ZM14.8425 8.09439L6.37501 16.5647L4.81032 15L13.2806 6.53064L14.8425 8.09439ZM4.50001 16.8103L7.1897 19.5H4.50001V16.8103Z"
      fill={color} fillOpacity={opacity}/>
  </svg>
))