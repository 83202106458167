import { IDoc, IDocAttachmet, IDocShort } from "../../models/IDocs"
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { LoadingType } from "../utilityObject"
import { getDocsShort, putDocTitle, putUpdateAccessDoc } from "./thunk"
import { IDynamicPageNumberPagination } from "../../models/IPagination"
import { actualStageIsHaveMe } from "../../components/pages/Docs/utils/actualStageIsHaveMe"
import { DocChecklistsMetaData } from "../../../fsd/entities/Checklists/types/types";

export const docsShortAdapter = createEntityAdapter<IDocShort>()

export interface IDocsShortMeta extends Omit<IDynamicPageNumberPagination<IDocShort>, 'results' | 'meta'> {
  newDocumentsCount: number
}

const docsSlice = createSlice({
  name: 'docs',
  initialState: {
    docsShort: docsShortAdapter.getInitialState({
      meta: null as IDocsShortMeta | null,
    }),
    doc: null as IDoc | null,
    loading: {
      docsShort: 'idle' as LoadingType,
      doc: 'idle' as LoadingType,
      putUpdateAccessDoc: 'idle' as LoadingType,
      putWideUpdateAccessDocs: 'idle' as LoadingType,
      putApproveDocument: 'idle' as LoadingType,
      putNotNegotiate: 'idle' as LoadingType
    },
    docChecklistsData: null as null | Record<string, DocChecklistsMetaData>
  },
  reducers: {
    // SHORT DOCS

    afterAction(state, { payload }: PayloadAction<{ doc: IDoc, userId: number }>) {
      if (!actualStageIsHaveMe(payload.doc, payload.userId)) {
        state.docsShort.meta!.newDocumentsCount = state.docsShort.meta!.newDocumentsCount - 1
        docsShortAdapter.updateOne(state.docsShort, { id: payload.doc.id, changes: { new: false } })
      }
      docsShortAdapter.updateOne(state.docsShort, { id: payload.doc.id, changes: { status: payload.doc.status } })
    },
    clearDocsShort(state) {
      docsShortAdapter.removeAll(state.docsShort)
      state.loading.docsShort = 'idle'
    },
    // DOC
    clearDoc(state) {
      state.doc = null
      state.loading.doc = 'idle'
      state.docChecklistsData = null
    },
    setDoc(state, { payload }: PayloadAction<IDoc>) {
      state.doc = payload
    },
    removeDocShort(state, { payload }: PayloadAction<number>) {
      docsShortAdapter.removeOne(state.docsShort, payload)
      state.loading.doc = 'idle'
    },
    // LOADING
    toggleLoadingDocsShort(state, action: PayloadAction<LoadingType>) {
      state.loading.docsShort = action.payload
    },
    toggleLoadingDoc(state, action: PayloadAction<LoadingType>) {
      state.loading.doc = action.payload
    },
    toggleLoadingUpdateAccess(state, action: PayloadAction<LoadingType>) {
      state.loading.putUpdateAccessDoc = action.payload
    },
    toggleLoadingWideUpdateAccessDocs(state, action: PayloadAction<LoadingType>) {
      state.loading.putWideUpdateAccessDocs = action.payload
    },
    toggleLoadingApproveDocument(state, action: PayloadAction<LoadingType>) {
      state.loading.putApproveDocument = action.payload
    },
    toggleLoadingNotNegotiateDocument(state, action: PayloadAction<LoadingType>) {
      state.loading.putNotNegotiate = action.payload
    },

    // DOC ATTACHMENTS
    updateAttachments(state, { payload }: PayloadAction<IDocAttachmet>) {
      if (state.doc)
        state.doc.attachments = state.doc.attachments.map(attachment => (
          attachment.id === payload.id ? payload : attachment
        ))
    },

    // DOC CHECKLISTS DATA

    setDocChecklistsData(state, { payload }: PayloadAction<Record<string, DocChecklistsMetaData>>) {
      state.docChecklistsData = !state.docChecklistsData
        ? payload
        : { ...state.docChecklistsData, ...payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDocsShort.fulfilled, (state, { payload }) => {
      docsShortAdapter.addMany(state.docsShort, payload.results)
      state.docsShort.meta = {
        count: payload.count,
        newDocumentsCount: payload.newDocumentsCount,
        next: payload.next,
        previous: payload.previous,
      }
    })

    builder.addCase(putDocTitle.fulfilled, (state, { payload }) => {
      if (state.doc?.title)
        state.doc.title = payload.changes.title

      docsShortAdapter.updateOne(state.docsShort, payload)
      const changedDoc = state.docsShort.entities[payload.id]
      if (changedDoc?.title)
        changedDoc.title = payload.changes.title
    })

    builder.addCase(putUpdateAccessDoc.fulfilled, (state, { payload }) => {
      state.doc!.admittedUsers = payload.admittedUsers
    })
  }
})
export const {
  afterAction,
  clearDocsShort,
  setDoc,
  clearDoc,
  toggleLoadingDocsShort,
  toggleLoadingDoc,
  toggleLoadingUpdateAccess,
  toggleLoadingWideUpdateAccessDocs,
  toggleLoadingApproveDocument,
  toggleLoadingNotNegotiateDocument,
  updateAttachments,
  removeDocShort,
  setDocChecklistsData
} = docsSlice.actions
export default docsSlice.reducer