import React from 'react'
import { IIcon } from './IIcon'

export const Flag:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  opacity='1',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.25875 3.93763C3.17806 4.00756 3.11324 4.09392 3.06862 4.19093C3.024 4.28794 3.0006 4.39335 3 4.50013V20.2501C3 20.449 3.07902 20.6398 3.21967 20.7805C3.36032 20.9211 3.55109 21.0001 3.75 21.0001C3.94891 21.0001 4.13968 20.9211 4.28033 20.7805C4.42098 20.6398 4.5 20.449 4.5 20.2501V16.1036C7.01156 14.1198 9.17531 15.1895 11.6672 16.4233C13.2047 17.1836 14.8603 18.0029 16.6359 18.0029C17.9419 18.0029 19.3116 17.5576 20.7441 16.3154C20.8247 16.2455 20.8896 16.1591 20.9342 16.0621C20.9788 15.9651 21.0022 15.8597 21.0028 15.7529V4.50013C21.0025 4.35617 20.9607 4.21535 20.8825 4.09449C20.8043 3.97362 20.693 3.87782 20.5618 3.81851C20.4306 3.7592 20.2852 3.73889 20.1428 3.76001C20.0004 3.78114 19.8671 3.8428 19.7587 3.93763C17.1337 6.20919 14.91 5.10857 12.3328 3.83263C9.66281 2.50888 6.63562 1.01169 3.25875 3.93763ZM19.5 15.3986C16.9884 17.3823 14.8247 16.3117 12.3328 15.0789C9.98906 13.9211 7.38187 12.6292 4.5 14.2914V4.85544C7.01156 2.87169 9.17531 3.94138 11.6672 5.17419C14.0109 6.332 16.6191 7.62388 19.5 5.96169V15.3986Z"
      fill={color}/>
  </svg>
)