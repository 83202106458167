import React, { useState } from 'react'
import { useTDispatch, useTSelector } from '../../../../utils/hooks/reduxHooks'
import { Notifications } from '../ui/Notifications'
import { ChatSidebarLink } from '../ui/ChatSidebarLink'
import { amplitudeLogEvent } from '../../../../amplitude'
import { AMPLITUDE_EVENTS } from '../../../../amplitude/events'
import { markAsReadNotifications, readAllAppNotifications } from '../../../../redux/appNotification/actionCreator'

import Avatar from '../../../UI/Avatar/Avatar'
import * as Icon from '../../../../../fsd/shared/ui-icons'

export const useRightSidebarLinks = () => {
  const dispatch = useTDispatch()
  const [isOpenAppNotification, setIsOpenAppNotification] = useState(false)

  const userInfo = useTSelector(state => state.user.userInfo)

  const rightSidebarTopLinks = [
    {
      id: 'right-sidebar-notification',
      to: '',
      tooltipTitle: 'Оповещения',
      className: 'right-sidebar-card-link',
      condition: true,
      children: <Notifications isOpenAppNotification={isOpenAppNotification} />,
      onClick: () => {
        amplitudeLogEvent({
          event: isOpenAppNotification
            ? AMPLITUDE_EVENTS.RIGHT_SIDEBAR_CLOSE_NOTIFICATIONS_BTN_CLICK
            : AMPLITUDE_EVENTS.RIGHT_SIDEBAR_OPEN_NOTIFICATIONS_BTN_CLICK,
          options: userInfo
        })
        if (isOpenAppNotification) {
          dispatch(markAsReadNotifications())
          dispatch(readAllAppNotifications())
        }
        setIsOpenAppNotification(state => !state)
      }
    },
    {
      id: 'right-sidebar-profile',
      to: '/profile/edit',
      tooltipTitle: 'Мой профиль',
      className: 'right-sidebar-card-link',
      condition: false,
      children: <Avatar photo={userInfo?.image?.url} size='s' className='avatar' />,
      onClick: () => amplitudeLogEvent({
        event: AMPLITUDE_EVENTS.RIGHT_SIDEBAR_USER_PROFILE_BTN_CLICK,
        options: userInfo
      })
    },
    {
      id: 'right-sidebar-company',
      to: `/company/${userInfo?.company?.id}`,
      tooltipTitle: 'Моя компания',
      className: 'right-sidebar-card-link',
      condition: false,
      children: <Icon.BriefcaseMini />,
      onClick: () => amplitudeLogEvent({
        event: AMPLITUDE_EVENTS.RIGHT_SIDEBAR_USER_COMPANY_PROFILE_BTN_CLICK,
        options: userInfo
      })
    }
  ]

  const rightSidebarBottomLinks = [
    {
      id: 'right-sidebar-calendar',
      to: '/meetings',
      tooltipTitle: 'Календарь',
      className: 'right-sidebar-card-link',
      condition: false,
      children: <Icon.Calendar />,
      onClick: () => amplitudeLogEvent({
        event: AMPLITUDE_EVENTS.RIGHT_SIDEBAR_CALENDAR_BTN_CLICK,
        options: userInfo
      })

    },
    {
      id: 'right-sidebar-chat',
      to: '/chat',
      tooltipTitle: 'Чат',
      className: 'right-sidebar-card-link',
      condition: false,
      children: <ChatSidebarLink />,
      onClick: () => amplitudeLogEvent({
        event: AMPLITUDE_EVENTS.RIGHT_SIDEBAR_CHAT_BTN_CLICK,
        options: userInfo
      })

    }
  ] as const


  return {
    rightSidebarTopLinks,
    rightSidebarBottomLinks,
    isOpenAppNotification,
    setIsOpenAppNotification
  }
}