import React from 'react'
import { IIcon } from './IIcon'

export const DocumentEmptyPlaceholder: React.FC<IIcon> = ({
  width = '18.7',
  height = '17.7',
  color = 'blue',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 187 177" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.165039" y="11.6372" width="164.998" height="164.998" rx="82.4989" fill={color == 'blue' ? "#E8F1FB" : "#F2F2F2"} />
    <g clipPath="url(#clip0_2634_53550)">
      <path fillRule="evenodd" clipRule="evenodd" d="M75.0458 53.5381L75.0079 114.192C75.0079 114.192 75.0079 114.192 75.0079 114.192C75.0082 117.27 77.4996 119.774 80.5517 119.774H126.08C129.146 119.774 131.662 117.258 131.662 114.192V71.8481C131.662 70.3653 131.075 68.9528 130.03 67.9359L130.021 67.9266L111.692 49.5977L111.683 49.5882C110.666 48.5437 109.253 47.9563 107.77 47.9563H80.5896C77.5373 47.9563 75.0458 50.4596 75.0458 53.5381ZM73.0458 53.5381L73.0079 114.192C73.0079 118.362 76.3818 121.774 80.5517 121.774H126.08C130.25 121.774 133.662 118.362 133.662 114.192V71.8481C133.662 69.8389 132.866 67.9055 131.425 66.5029L113.115 48.1929C111.713 46.7524 109.779 45.9563 107.77 45.9563H80.5896C76.4197 45.9563 73.0458 49.3681 73.0458 53.5381ZM105.126 68.7016V56.4711C105.126 52.9074 109.434 51.1228 111.954 53.6426L125.976 67.6641C128.496 70.184 126.711 74.4925 123.147 74.4925H110.917C107.727 74.4925 105.126 71.8912 105.126 68.7016ZM107.126 56.4711V68.7016C107.126 70.7866 108.832 72.4925 110.917 72.4925H123.147C124.929 72.4925 125.821 70.3382 124.562 69.0783L110.54 55.0568C109.28 53.7969 107.126 54.6892 107.126 56.4711Z" fill={color == 'blue' ? "#1A65DA" : "#949494"} />
    </g>
    <rect x="20.835" y="1.36523" width="165" height="165" rx="82.5" stroke={color == 'blue' ? "#1A65DA" : "#949494"} strokeWidth="2" strokeLinecap="round" strokeDasharray="10 15" />
    <defs>
      <clipPath id="clip0_2634_53550">
        <rect width="90.9813" height="90.9813" fill="white" transform="translate(57.8443 38.3745)" />
      </clipPath>
    </defs>
  </svg>
)