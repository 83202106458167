export class AddCompaniesToProjectQuery {
  query = ({
    projectId,
    data
  } : {
    projectId: number,
    data: Array<{ company: number }>
  }) => ({
    url: `projects/${projectId}/companies`,
    method: 'POST',
    body: data
  })
}