import React, { useEffect } from 'react'
import { createHelpdeskChatScript } from './lib/createHelpdeskChatScript'
import { useTSelector } from '../../../(deprecated)/utils/hooks/reduxHooks'
import { getHelpDeskChatUserInfo } from '../../../(deprecated)/utils/localStorageManagement'

import './HelpDeskChatSupport.scss'

export const HelpDeskChatSupport:React.FC<PropsType> = React.memo(({
  position,
  containerClassname = '',
  buttonClassname = ''
}) => {

  const userEmail = useTSelector(state => state.user.userInfo.email)
  const userName = useTSelector(state => state.user.userInfo.firstName)

  useEffect(() => {
    const helpDeskChatInfo = localStorage.getItem(`hde-user-${userEmail}`)
    if (helpDeskChatInfo) {
      localStorage.setItem(`HDE_VISITOR_DATA[${new URL(window.location.href)?.hostname}]`,helpDeskChatInfo )
    }


    return () => {
        const hdeChatUserData = localStorage.getItem(`HDE_VISITOR_DATA[${new URL(window.location.href)?.hostname}]`)

        if (hdeChatUserData) {
          localStorage.setItem(`hde-user-${userEmail}`, hdeChatUserData)
        }
    }
  }, [])

  useEffect(() => {
    let script: HTMLScriptElement;

    if (userEmail && userName) {
      script = createHelpdeskChatScript()
      script.dataset.position = position
      script.dataset.userEmail = userEmail
      script.dataset.userName = userName
    }

    return () => {
      if (script)
        script.remove()
    }
  }, [userEmail, userName])

  return (
      <div
        id='help-desk'
        className={containerClassname}
      >
         <div
           id='help-desk__button'
           className={buttonClassname}
         >
         </div>
      </div>
  )
})

type PropsType = {
  position: 'bottom-right' | 'bottom-left'
  containerClassname?: string
  buttonClassname?: string
}