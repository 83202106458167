import {
  WS_CONNECT_SUCCESS,
  WS_DISCONNECT_SUCCESS,
  WS_INCOMING_MESSAGE
} from '../../utils/websockets/actionTypes/actionTypes'

export const GET_SHORT_TEMPLATED_DOCUMENTS_SUCCESS = 'GET_SHORT_TEMPLATED_DOCUMENTS_SUCCESS'
export const TOGGLE_LOADING_SHORT_TEMPLATED_DOCUMENTS = 'TOGGLE_LOADING_SHORT_TEMPLATED_DOCUMENTS'
export const SET_DATA_IN_SHORT_TEMPLATE_DOCUMENT_AFTER_ACTION = 'SET_DATA_IN_SHORT_TEMPLATE_DOCUMENT_AFTER_ACTION'
export const CLEAR_SHORT_TEMPLATED_DOCUMENT = 'CLEAR_SHORT_TEMPLATED_DOCUMENT'

export const UPDATE_ATTACHMENTS_USER_DOCUMENTS = 'UPDATE_ATTACHMENTS_USER_DOCUMENTS'

export const GET_TEMPLATE_TYPE_SUCCESS = 'GET_TEMPLATE_TYPE_SUCCESS'
export const TOGGLE_LOADING_TEMPLATE_TYPE = 'TOGGLE_LOADING_TEMPLATE_TYPE'

export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS'
export const TOGGLE_LOADING_GET_TEMPLATE = 'TOGGLE_LOADING_GET_TEMPLATE'
export const CLEAR_TEMPLATE = 'CLEAR_TEMPLATE'

export const GET_TEMPLATE_DOCUMENT_SUCCESS = 'GET_TEMPLATE_DOCUMENT_SUCCESS'
export const TOGGLE_LOADING_TEMPLATE_DOCUMENT = 'TOGGLE_LOADING_TEMPLATE_DOCUMENT'
export const CLEAR_TEMPLATE_DOCUMENT = 'CLEAR_TEMPLATE_DOCUMENT'

export const TOGGLE_LOADING_TEMPLATE_DOCUMENT_ACTION_APPROVE = 'TOGGLE_LOADING_TEMPLATE_DOCUMENT_ACTION_APPROVE'
export const TOGGLE_LOADING_TEMPLATE_DOCUMENT_ACTION_REJECT = 'TOGGLE_LOADING_TEMPLATE_DOCUMENT_ACTION_REJECT'
export const TOGGLE_LOADING_TEMPLATE_DOCUMENT_ACTION_NOT_NEGOTIATE = 'TOGGLE_LOADING_TEMPLATE_DOCUMENT_ACTION_NOT_NEGOTIATE'

export const TOGGLE_LOADING_CREATION_DOCUMENT = 'TOGGLE_LOADING_CREATION_DOCUMENT'

// EXCEL TABLE
export const TOGGLE_LOADING_TABLE_EXCEL_FILE = 'TOGGLE_LOADING_TABLE_EXCEL_FILE'
export const UPDATE_TABLE_EXCEL_INFO = 'UPDATE_TABLE_EXCEL_INFO'
export const CLEAR_TABLE_EXCEL_INFO = 'CLEAR_TABLE_EXCEL_INFO'

export const SET_DOCUMENT_PREVIEW = 'SET_DOCUMENT_PREVIEW'
export const CLEAR_DOCUMENT_PREVIEW = 'CLEAR_DOCUMENT_PREVIEW'
export const SET_PREVIEW_DOC_LOADING = 'SET_PREVIEW_DOC_LOADING'

export const TOGGLE_LOADING_DOWNLOAD_PDF = 'TOGGLE_LOADING_DOWNLOAD_PDF'

// HISTORY
export const GET_DOCUMENT_HISTORY_SUCCESS = 'GET_DOCUMENT_HISTORY_SUCCESS'
export const TOGGLE_LOADING_DOCUMENT_HISTORY = 'TOGGLE_LOADING_DOCUMENT_HISTORY'
export const CLEAR_DOCUMENT_HISTORY = 'CLEAR_DOCUMENT_HISTORY'

// CONSTURCTOR
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS'
export const TOGGLE_LOADING_TEMPLATES = 'TOOGLE_LOADING_TEMPLATES'

export const POST_TEMPLATES_SUCCESS = 'POST_TEMPLATES_SUCCESS'
export const TOGGLE_LOADING_POST_TEMPLATES = 'TOOGLE_LOADING_POST_TEMPLATES'

export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS'

export const PUT_UPDATE_TEMPLATES_SUCCESS = 'PUT_UPDATE_TEMPLATES_SUCCESS'
export const TOGGLE_LOADING_PUT_UPDATE_TEMPLATES = 'TOGGLE_LOADING_PUT_UPDATE_TEMPLATES'

// Actions
export const CLEAR_SHORT_TEMPLATED_DOCUMENT_AFTER_CHANGE_STATUS = 'CLEAR_SHORT_TEMPLATED_DOCUMENT_AFTER_CHANGE_STATUS'
export const TOGGLE_LOADING_CHECK_DOCUMENT_TITLE = 'TOGGLE_LOADING_CHECK_DOCUMENT_TITLE'

//---COMMENTS---
export const WS_DOCUMENT_DISCUSSION_CONNECT_SUCCESS = WS_CONNECT_SUCCESS.concat('_DOCUMENTS_COMMENTS')
export const WS_DOCUMENT_DISCUSSION_DISCONNECT_SUCCESS = WS_DISCONNECT_SUCCESS.concat('_DOCUMENTS_COMMENTS')

export const WS_DOCUMENT_DISCUSSION_CONNECT = WS_INCOMING_MESSAGE.concat('_DOCUMENTS_COMMENTS_CONNECT')
export const WS_DOCUMENT_DOC_COMMENT_UPDATE = WS_INCOMING_MESSAGE.concat('_DOCUMENTS_COMMENTS_UPDATE_MESSAGE')

export const INCOMING_DOC_DISCUSSION_COMMENTS =  WS_INCOMING_MESSAGE.concat('_DOCUMENTS_COMMENTS_LIST_MESSAGES')
export const INCOMING_DISCUSSION_COMMENTS_SEND_COMMENT =  WS_INCOMING_MESSAGE.concat('_DOCUMENTS_COMMENTS_SEND_DISCUSSION_MESSAGE')
export const DELETE_DISCUSSION_COMMENT =  WS_INCOMING_MESSAGE.concat('_DOCUMENTS_COMMENTS_DELETE_MESSAGE')

export const SET_DOC_COMMENTS_LOADING = 'SET_DOC_COMMENTS_LOADING'
export const CLEAR_DOCUMENT_COMMENT_LIST = 'CLEAR_DOCUMENT_COMMENT_LIST'

export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_CATEGORIES_LOADING = 'SET_CATEGORIES_LOADING'
export const CLEAR_CATEGORIES = 'CLEAR_CATEGORIES'

