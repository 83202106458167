import { ILicenseTemplate } from './../../models/ILicenseTemplate'
import { IWorkUser } from './../../models/IUser'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IDynamicPageNumberPagination } from '../../models/IPagination'


type LoadingType = 'idle' | 'pending' | 'succeeded' | 'failed'
interface IStateType {
  info: IWorkUser | null,
  licensesList: ILicenseTemplate[] | null,
  licensesCount: number | null,
  isNotFoundUser: boolean,
  loadings: {
    isLoadingGetProfile: LoadingType,
    isLoadingPostProfile: LoadingType,
  }
}

const workerSlice = createSlice({
  name: 'worker',
  initialState: {
    info: null,
    licensesList: null,
    licensesCount: null,
    isNotFoundUser: false,
    loadings: {
      isLoadingGetProfile: 'idle' as LoadingType,
      isLoadingPostProfile: 'idle' as LoadingType,
    }
  } as IStateType,
  reducers: {
    setWorkerProfile(state, { payload }: PayloadAction<IWorkUser>) {
      state.info = payload
    },

    setWorkerLicenses(state, { payload }: PayloadAction<IDynamicPageNumberPagination<ILicenseTemplate>>) {
      state.licensesList = payload.results
      state.licensesCount = payload.count
    },

    updateWorkerSign(state, { payload }: PayloadAction<IWorkUser>) {
      if (state.info)
        state.info.profile.sign = payload.profile.sign
    },

    toggleLoadingGetProfile(state, { payload }: PayloadAction<LoadingType>) {
      state.loadings.isLoadingGetProfile = payload
    },

    toggleLoadingPostProfile(state, { payload }: PayloadAction<LoadingType>) {
      state.loadings.isLoadingPostProfile = payload
    },

    deleteWorkerLicensesSuccess(state, { payload }: PayloadAction<number>) {
      if (state.licensesList)
        state.licensesList = state.licensesList?.filter(license => license.id !== payload)
    }
  },
})

export const {
  setWorkerProfile,
  deleteWorkerLicensesSuccess,
  updateWorkerSign,
  setWorkerLicenses,
  toggleLoadingGetProfile,
  toggleLoadingPostProfile
} = workerSlice.actions
export default workerSlice.reducer