import React, { useMemo } from 'react'
import { useFunctionsAccess } from '../../../../../../fsd/features/FunctionAccess'
import { useTSelector } from '../../../../../utils/hooks/reduxHooks'
import { IProject } from '../../../../../models/IProject'
import { DataLink, dataLinks } from '../constants'

export const useLinks = () => {

  const approveFunctions = useFunctionsAccess()

  const projectList = useTSelector((state: any) => state.project.projectList) as IProject[]

  const currentLinks = useMemo(() => (
    dataLinks.reduce((acc, linkObj) => {

      if (linkObj.approveFunctions.length) {
        const isApprove = linkObj.approveFunctions
          .find((functionAccess) => (
            approveFunctions.includes(functionAccess)
          ))
        return isApprove ? [...acc, linkObj] : acc
      }

      if (linkObj.inTabs?.length) {
        const filteredTabs = linkObj.inTabs.filter((tab) => {
          if (tab.approveFunctions.length) {
            return tab.approveFunctions
              .find((functionAccess) => (
                approveFunctions.includes(functionAccess)
              ))
          }
          return true
        }) ?? []

        if (filteredTabs.length)
          return [...acc, {...linkObj, inTabs: filteredTabs}]

        return acc
      }

      return [...acc, linkObj]
    }, [] as DataLink[])
  ), [projectList, approveFunctions])


  return currentLinks
}