import React from 'react'
import { IIcon } from './IIcon'

export const EmptyActiveTemplate: React.FC<IIcon> = ({
  width = '3.2',
  height = '3.2',
  color = '#C4C4C4',
  ...innerProps
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 32 32" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#E2E2E2"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M4.52637 27.4194C4.93007 22.9958 9.91268 19.5 16 19.5C21.8645 19.5 26.7038 22.7447 27.411 26.9377C24.4917 30.0533 20.3398 32 15.7334 32C11.3691 32 7.41297 30.2527 4.52637 27.4194ZM16 18.5C19.5898 18.5 22.5 15.5899 22.5 12C22.5 8.41015 19.5898 5.5 16 5.5C12.4101 5.5 9.49996 8.41015 9.49996 12C9.49996 15.5899 12.4101 18.5 16 18.5Z"
          fill="#949494"/>
  </svg>
)

