export const SET_LOADING_FLOW_STAGE_TITLE = 'SET_LOADING_FLOW_STAGE_TITLE'
export const GET_FLOW_STAGE_TITLE_SUCCESS = 'GET_FLOW_STAGE_TITLE_SUCCESS'
export const GET_FLOW_STAGE_TITLE_FAILED = 'GET_FLOW_STAGE_TITLE_FAILED'

export const SET_LOADING_FLOW_ACTION_START = 'SET_LOADING_FLOW_ACTION_START'
export const GET_FLOW_ACTION_SUCCESS = 'GET_FLOW_ACTION_SUCCESS'
export const GET_FLOW_ACTION_FAILED = 'GET_FLOW_ACTION_FAILED'

export const SET_LOADING_FLOW_TEMPLATES = 'SET_LOADING_FLOW_TEMPLATES'
export const GET_FLOW_TEMPLATES_SUCCESS = 'GET_FLOW_TEMPLATES_SUCCESS'
export const GET_FLOW_TEMPLATES_FAILED = 'GET_FLOW_TEMPLATES_FAILED'

export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT'

export const SET_LOADING_GET_DOCUMENT_FORM = 'SET_LOADING_GET_DOCUMENT_FORM'
export const GET_DOCUMENT_FORM_SUCCESS = 'GET_DOCUMENT_FORM_SUCCESS'
export const GET_DOCUMENT_FORM_FAILED = 'GET_DOCUMENT_FORM_FAILED'

export const SET_LOADING_GET_MEMBERS_ROLE = 'SET_LOADING_GET_MEMBERS_ROLE'
export const GET_MEMBERS_ROLE_SUCCESS = 'GET_MEMBERS_ROLE_SUCCESS'
export const GET_MEMBERS_ROLE_FAILED = 'GET_MEMBERS_ROLE_FAILED'

export const SET_LOADING_GET_TEMPLATE_EXCEL = 'SET_LOADING_GET_TEMPLATE_EXCEL'
export const GET_TEMPLATE_EXCEL_SUCCESS = 'GET_TEMPLATE_EXCEL_SUCCESS'
export const GET_TEMPLATE_EXCEL_FAILED = 'GET_TEMPLATE_EXCEL_FAILED'

export const SET_MOBILE_INFO = 'SET_MOBILE_INFO'

export const RESET_STORE = 'RESET_STORE'