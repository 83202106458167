import { IDoc, IDocAction, IDocTableAction } from "../../../../models/IDocs"
import { getAllAction } from "./getAllActions"

export const actualStageIsHaveMe = (doc: IDoc, meId: number) => {

  const allActions = getAllAction(doc)

  const actualStageId = doc.actualStage

  const actualStage = doc.actions.find((action) => action.id === actualStageId)

  if (!actualStage)
    return false

  const actualStageActions: (IDocTableAction | IDocAction | null)[] = actualStage.activeTableActions
    ? actualStage.activeTableActions
    : [actualStage]

  return !!allActions.find(action => {
    if (action.responsibleUser?.id == meId && !doc.isNotNegotiated && !doc.isRejected && !action.isComplete) {
      return doc.freeActionsOrder
        ? true
        : Boolean(actualStageActions.find((actualStageAction) => actualStageAction?.id == action.id))
    }
    return false
  })
}