import { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { getToken } from '../../../../../(deprecated)/utils/localStorageManagement'
import { useTSelector } from '../../../../../(deprecated)/utils/hooks/reduxHooks'
import { useManageUrlParamsTokens } from '../../../../shared/hook/useManageUrlParamsTokens'
import { IProject } from '../../../../../(deprecated)/models/IProject'

export const useRootAppLoading = () => {
  const location = useLocation()
  const { current: pathName } = useRef(location.pathname)
  const { access, refresh } = useManageUrlParamsTokens()

  const userInfo = useTSelector(state => state.user.userInfo)
  const myRole = useTSelector(state => state.user.userRoleInProject)
  const projectList = useTSelector((state:any) => state.project.projectList) as IProject[]
  const finishGetProject = useTSelector((state: any) => state.project.finishGetProject)
  const isEmptyProject = !projectList.length && finishGetProject

  const token = !['/sign-up', '/auth'].includes(pathName) && getToken()

  const isLoadingRoles = token && (isEmpty(userInfo) || (myRole === null && !isEmptyProject))
  const isLoadingUserData = !token && access && refresh && isEmpty(userInfo)

  return {
    isRootAppLoading: isLoadingUserData || isLoadingRoles
  }
}