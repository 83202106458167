import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

/**
 * Данный компонент создает портал, когда стадия монтирования завершена
 * Это поволяет накладывать анимацию на компонент-потомок
 */

export const PortalWrapper:React.FC<PropsType> = React.memo(({
  children,
  parentNodeSelector
}) => {
  const [isDomReady, setIsDomReady] = useState(false)

  useEffect(() => {
    setIsDomReady(true)
  }, [])

  const parentNode = document.querySelector(parentNodeSelector)

  return (
    <>
      {isDomReady && parentNode && createPortal(children, parentNode)}
    </>
  )
})

type PropsType = {
  children: React.ReactNode
  parentNodeSelector: string
}