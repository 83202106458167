import { ISystemUserModel } from "../../../../(deprecated)/api/systemUserApi/SystemUserAdapter";
import { ACCESS_SUBJECT_KEY } from "../../../entities/ProjectRole";
import { ALL_FUNCTIONS_FOR_ACCESS } from "../type/typeFunctions";

export const APPROVE_FUNCTIONS: { [k in ACCESS_SUBJECT_KEY | 'other']: {
  [k in ISystemUserModel['companyRole']]: ALL_FUNCTIONS_FOR_ACCESS[]
} } = {
  createDocuments: {
    admin: ['create-doc'],
    user: ['create-doc'],
    worker: []
  },
  templatesManagement: {
    admin: [
      'create-category', 'edit-category', 'remove-category',
      'create-template', 'edit-template', 'remove-template'
    ],
    user: [
      'create-category', 'edit-category', 'remove-category',
      'create-template', 'edit-template', 'remove-template'
    ],
    worker: []
  },
  readCheckpointLogs: {
    admin: ['read-checkpoint-logs'],
    user: ['read-checkpoint-logs'],
    worker: []
  },
  readCheckpointDocuments: {
    admin: ['read-checkpoint-docs', 'read-checkpoint-logs'],
    user: ['read-checkpoint-docs', 'read-checkpoint-logs'],
    worker: []
  },
  createCheckpointDocuments: {
    admin: ['read-checkpoint-logs', 'read-checkpoint-docs', 'create-checkpoin-doc', 'edit-checkpoin-doc'],
    user: ['read-checkpoint-logs', 'read-checkpoint-docs', 'create-checkpoin-doc', 'edit-checkpoin-doc'],
    worker: []
  },
  createOrUpdateProject: {
    admin: ['edit-project-header', 'remove-project'],
    user: [],
    worker: []
  },
  companiesManagement: {
    admin: ['add-company-in-project', 'remove-company-in-project'],
    user: ['add-company-in-project', 'remove-company-in-project'],
    worker: []
  },
  rolesManagement: {
    admin: ['create-role', 'edit-role', 'remove-role', 'role-tab'],
    user: ['create-role', 'edit-role', 'remove-role', 'role-tab'],
    worker: []
  },
  userRolesManagement: {
    admin: ['change-role-user-in-project'],
    user: ['change-role-user-in-project'],
    worker: []
  },
  locationsManagement: {
    admin: ['create-location', 'edit-location', 'remove-location'],
    user: ['create-location', 'edit-location', 'remove-location'],
    worker: []
  },
  licensesManagement: {
    admin: ['create-license', 'edit-license', 'remove-license'],
    user: ['create-license', 'edit-license', 'remove-license'],
    worker: []
  },
  readLicenses: {
    admin: ['read-license'],
    user: ['read-license'],
    worker: []
  },
  restrictedAccessToSystem: {
    admin: [],
    user: [],
    worker: []
  },
  managementProjectFiles: {
    admin: ['management-project-files'],
    user: ['management-project-files'],
    worker: []
  },
  readProjectFiles: {
    admin: ['read-project-files'],
    user: ['read-project-files'],
    worker: []
  },
  other: {
    admin: [
      'add-users-in-project',
      'remove-user-from-project',
      'create-project',
      'view-reports'
    ],
    user: [],
    worker: []
  }
}