import React from 'react'
import { IIcon } from './IIcon'

export const ArrowBackFat:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.9996 12.0001C20.9996 12.199 20.9206 12.3898 20.7799 12.5304C20.6393 12.6711 20.4485 12.7501 20.2496 12.7501H11.2496V18.7501C11.2497 18.8985 11.2058 19.0436 11.1234 19.1671C11.041 19.2905 10.9238 19.3868 10.7867 19.4436C10.6496 19.5004 10.4987 19.5152 10.3531 19.4863C10.2076 19.4573 10.0739 19.3857 9.96899 19.2807L3.21899 12.5307C3.14926 12.4611 3.09394 12.3784 3.05619 12.2873C3.01845 12.1963 2.99902 12.0987 2.99902 12.0001C2.99902 11.9015 3.01845 11.8039 3.05619 11.7129C3.09394 11.6218 3.14926 11.5391 3.21899 11.4695L9.96899 4.71948C10.0739 4.61447 10.2076 4.54294 10.3531 4.51396C10.4987 4.48497 10.6496 4.49982 10.7867 4.55664C10.9238 4.61345 11.041 4.70967 11.1234 4.83312C11.2058 4.95656 11.2497 5.10168 11.2496 5.2501V11.2501H20.2496C20.4485 11.2501 20.6393 11.3291 20.7799 11.4698C20.9206 11.6104 20.9996 11.8012 20.9996 12.0001Z"
      fill={color}/>
  </svg>
)