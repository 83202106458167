import * as types from '../actionTypes/actionTypes'
import { propInit } from '../type'

export const websocketInit = (settingConnection: propInit[]) => ({
  type: types.WS_INIT,
  payload: settingConnection
})

// todo сделать тип для nameConnect
export const connectWebsocket = (nameConnect: string) => ({
  type: types.WS_SET_CONNECT,
  nameConnect
})
export const stopConnectWebsocket = (nameConnect: string) => ({
  type: types.WS_SET_DISCONNECT,
  nameConnect
})