import React from 'react'
import cn from 'classnames'

import defaultImage from '../../assets/default-avatar.png'

import './Avatar.scss'

export type AvatarSize = 'xs' | 's' | 'sm' | 'm' | 'l' | 'xl'

export const Avatar: React.FC<PropType> = ({
  className,
  src,
  size = 'm',
  onClick,
  defaultAvatar = defaultImage
}) => (
  <div
    className={cn('avatar', `avatar_size-${size}`, className)}
    style={{ backgroundImage: `url(${src || defaultAvatar})` }}
    onClick={onClick}
  />
)

type PropType = {
  className?: string,
  src?: string,
  size?: AvatarSize,
  defaultAvatar?: any,
  onClick?: () => void,
}