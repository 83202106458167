import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios'
import camelcaseFields from '../formatFields'
import HOST from './host'

import { getHeaders } from './getHeaders'
import { errorProccessing } from './errorProccessing.js'

const deleteRequest = async <R>(path: string, data?: any) => (
  new Promise<AxiosResponse<R>>(async (res, rej) => {

    const headers = await getHeaders() as AxiosRequestHeaders
    const REQUEST_URL = HOST.getInstance().getRequestUrl()
    
    try {
      const response = await axios.delete<R>(
        `${REQUEST_URL}${path}`,
        {
          headers,
          data
        }
      )
      res(response)
    } catch (error) {
      rej(camelcaseFields(errorProccessing(error)))
    }
  })
)

export default deleteRequest
