import React, { ChangeEventHandler } from 'react'
import cn from 'classnames'
import './Checkbox.scss'

export const Checkbox: React.FC<PropType> = ({
  type,
  customClassName,
  customLabelCheckbox = '',
  name,
  onCheck,
  hideTitle,
  title,
  checked = true
}) => (
  <div className={cn('checkbox', customClassName, { 'checkbox_ckecked': checked })}>
    <input
      className='checkbox__field'
      type='checkbox'
      id={name}
      checked={checked}
      onChange={onCheck}
    />
    <label
      onClick={(e) => {
        e.preventDefault()
        //@ts-ignore
        onCheck?.(e)
      }}
      className={cn(['checkbox__label', type, customLabelCheckbox, { checkbox__label_disabled: hideTitle }])}
      htmlFor={name}
    >
      {!hideTitle && <div>{title}</div>}
    </label>
  </div>
)


type PropType = {
  type?: 'minus' | 'check'
  customClassName?: string,
  customLabelCheckbox?: string,
  name: string,
  onCheck?: ChangeEventHandler<HTMLInputElement>,
  hideTitle?: boolean,
  title?: string,
  checked?: boolean
}