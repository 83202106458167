import React from 'react'
import { IIcon } from './IIcon'

export const BigFile:React.FC<IIcon> = ({
  width = '10.4',
  height = '10.4',
  color = '#949494',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 104 104"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="File">
      <path id="Vector"
            d="M85.6497 34.6003L62.8997 11.8503C62.7487 11.6995 62.5694 11.5798 62.3721 11.4983C62.1749 11.4167 61.9635 11.3748 61.75 11.375H22.75C21.4571 11.375 20.2171 11.8886 19.3029 12.8029C18.3886 13.7171 17.875 14.9571 17.875 16.25V87.75C17.875 89.0429 18.3886 90.2829 19.3029 91.1971C20.2171 92.1114 21.4571 92.625 22.75 92.625H81.25C82.5429 92.625 83.7829 92.1114 84.6971 91.1971C85.6114 90.2829 86.125 89.0429 86.125 87.75V35.75C86.1252 35.5365 86.0833 35.3251 86.0017 35.1279C85.9202 34.9306 85.8005 34.7513 85.6497 34.6003ZM63.375 16.9203L80.5756 34.125H63.375V16.9203ZM81.25 89.375H22.75C22.319 89.375 21.9057 89.2038 21.601 88.899C21.2962 88.5943 21.125 88.181 21.125 87.75V16.25C21.125 15.819 21.2962 15.4057 21.601 15.101C21.9057 14.7962 22.319 14.625 22.75 14.625H60.125V35.75C60.125 36.181 60.2962 36.5943 60.601 36.899C60.9057 37.2038 61.319 37.375 61.75 37.375H82.875V87.75C82.875 88.181 82.7038 88.5943 82.399 88.899C82.0943 89.2038 81.681 89.375 81.25 89.375Z"
            fill={color}/>
    </g>
  </svg>
)
