import { BuilderProjectLocationApi } from './types';

//TODO пернести
import { ILocation } from '../../../../(deprecated)/models/ILocation';
import camelcaseKeys from 'camelcase-keys';

interface ILocationQueryParam {
  projectId: number,
  locationId?: number
}

export const createGetLocationsQuery = (builder: BuilderProjectLocationApi) => (
  builder.query<ILocation[], ILocationQueryParam>({
    query: ({ projectId, locationId }) => (
      // Это один роут, потому что мы получаем массив чилдов какой-то локации
      // Если не передаем значение, то получаем массив числдов null локации
      `projects/${projectId}/locations${locationId ? `/${locationId}` : ''}`
    ),
    providesTags: ['location-list'],
    transformResponse: (response: any) => response?.map(camelcaseKeys) as ILocation[]
  })
)