import React from 'react'
import { SpinnerLoader } from '../SpinnerLoader/SpinnerLoader'
import { Modal } from '../Modal/Modal'
import { ModalProps } from 'antd'

import Button from '../Button/Button'
import * as Icons from '../../ui-icons'
import cn from 'classnames'

import './ModalFlashMessage.scss'

const STATUS_VISUAL = {
  success: <Icons.CheckCircle />,
  fail: <Icons.XCircle color='#D65555' />,
  loading: <SpinnerLoader />
} as Record<PossibleStatus, JSX.Element>

export const ModalFlashMessage: React.FC<IPropsType> = ({
  isOpen,
  status,
  title,
  onClose,
  className,
  children,
  customImage,
  ...rest
}) => (
  <Modal
    open={isOpen}
    onCancel={onClose}
    className={cn('modal-flesh-message', status, className)}
    width='40rem'
    {...rest}
  >
    <div className='image-wrapper'>
      {customImage || STATUS_VISUAL[status]}
    </div>
    <p className='title'>
      {title}
    </p>
    <div className='content-wrapper'>
      {children}
    </div>
    {
      status !== 'loading' && (
        <Button onClick={onClose} title='Закрыть' buttonType='blue-ligth' />
      )
    }
  </Modal>
)

type PossibleStatus = 'success' | 'fail' | 'loading'

interface IPropsType extends ModalProps {
  isOpen: boolean
  status: PossibleStatus
  title: string
  onClose: () => void

  customImage?: JSX.Element
}