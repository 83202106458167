import React from 'react'
import { IIcon } from './IIcon'

export const BigChat: React.FC<IIcon> = ({
  width = '10.4',
  height = '10.4',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M86.6667 8.66675H17.3333C12.5667 8.66675 8.71001 12.5667 8.71001 17.3334L8.67204 85.669C8.67006 89.2337 12.9799 91.0202 15.5005 88.4996L24.8284 79.1717C25.5786 78.4215 26.596 78.0001 27.6569 78.0001H86.6667C91.4333 78.0001 95.3333 74.1001 95.3333 69.3334V17.3334C95.3333 12.5667 91.4333 8.66675 86.6667 8.66675ZM26 43.0001C26 40.7909 27.7909 39.0001 30 39.0001H74C76.2091 39.0001 78 40.7909 78 43.0001V43.6668C78 45.8759 76.2091 47.6667 74 47.6667H30C27.7909 47.6667 26 45.8759 26 43.6667V43.0001ZM60.6667 56.6668C60.6667 58.8759 58.8758 60.6667 56.6667 60.6667H30C27.7909 60.6667 26 58.8759 26 56.6667V56.0001C26 53.7909 27.7909 52.0001 30 52.0001H56.6667C58.8758 52.0001 60.6667 53.7909 60.6667 56.0001V56.6668ZM78 30.6668C78 32.8759 76.2091 34.6667 74 34.6667H30C27.7909 34.6667 26 32.8759 26 30.6667V30.0001C26 27.7909 27.7909 26.0001 30 26.0001H74C76.2091 26.0001 78 27.7909 78 30.0001V30.6668Z" stroke="black" strokeOpacity="0.24" strokeWidth="2" />
  </svg>
)