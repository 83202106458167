import React from 'react'
import { IIcon } from './IIcon'

export const ClearFilters: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#949494',
  ...innerProps
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 24 24" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M18.976 16.5617L21.3426 14.1951C21.7331 13.8045 22.3663 13.8045 22.7568 14.1951C23.1474 14.5856 23.1474 15.2188 22.7568 15.6093L20.3902 17.976L22.7568 20.3426C23.1474 20.7331 23.1474 21.3663 22.7568 21.7568C22.3663 22.1474 21.7331 22.1474 21.3426 21.7568L18.976 19.3902L16.6093 21.7568C16.2188 22.1474 15.5856 22.1474 15.1951 21.7568C14.8045 21.3663 14.8045 20.7331 15.1951 20.3426L17.5617 17.976L15.1951 15.6093C14.8045 15.2188 14.8045 14.5856 15.1951 14.1951C15.5856 13.8045 16.2188 13.8045 16.6093 14.1951L18.976 16.5617Z"
          fill={color}/>
    <path
      d="M4.24999 5.61C6.26999 8.2 9.99999 13 9.99999 13V19C9.99999 19.55 10.45 20 11 20H13C13.55 20 14 19.55 14 19V13C14 13 17.72 8.2 19.74 5.61C20.25 4.95 19.78 4 18.95 4H5.03999C4.20999 4 3.73999 4.95 4.24999 5.61Z"
      fill={color}/>
  </svg>
)