import React from 'react'
import { IIcon } from './IIcon'

export const ImageNew: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = "#5C5C5C",
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path d="M21 21V3H3V21H21ZM8.5 13.5L11 16.51L14.5 12L19 18H5L8.5 13.5Z" fill={color} />
    <clipPath id="clip0_2745_3827" >
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </svg>
)
