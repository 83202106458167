import React from 'react'
import { IIcon } from './IIcon'

export const Asterisk:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#000000',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.4647 17.0784C20.3887 17.2052 20.2885 17.3158 20.1698 17.4038C20.051 17.4919 19.9161 17.5557 19.7727 17.5915C19.6293 17.6274 19.4802 17.6347 19.334 17.6129C19.1878 17.5911 19.0474 17.5408 18.9206 17.4647L13.125 13.9866V20.25C13.125 20.5484 13.0065 20.8345 12.7955 21.0455C12.5845 21.2565 12.2984 21.375 12 21.375C11.7016 21.375 11.4155 21.2565 11.2045 21.0455C10.9935 20.8345 10.875 20.5484 10.875 20.25V13.9866L5.07938 17.4647C4.95269 17.5407 4.81228 17.591 4.66614 17.6128C4.52001 17.6345 4.37102 17.6273 4.22768 17.5915C4.08435 17.5557 3.94947 17.492 3.83076 17.404C3.71204 17.3161 3.61181 17.2056 3.53579 17.0789C3.38225 16.8231 3.33664 16.5167 3.40899 16.2272C3.48134 15.9377 3.66572 15.6888 3.92157 15.5353L9.81375 12L3.92157 8.46469C3.66572 8.31115 3.48134 8.06227 3.40899 7.77279C3.33664 7.48331 3.38225 7.17694 3.53579 6.92109C3.68932 6.66524 3.9382 6.48086 4.22768 6.40851C4.51716 6.33617 4.82353 6.38178 5.07938 6.53531L10.875 10.0134V3.75C10.875 3.45163 10.9935 3.16548 11.2045 2.9545C11.4155 2.74353 11.7016 2.625 12 2.625C12.2984 2.625 12.5845 2.74353 12.7955 2.9545C13.0065 3.16548 13.125 3.45163 13.125 3.75V10.0134L18.9206 6.53531C19.0473 6.45929 19.1877 6.40896 19.3339 6.38721C19.48 6.36545 19.629 6.37269 19.7723 6.40851C19.9157 6.44434 20.0505 6.50804 20.1693 6.59599C20.288 6.68394 20.3882 6.79441 20.4642 6.92109C20.5402 7.04778 20.5906 7.1882 20.6123 7.33433C20.6341 7.48046 20.6268 7.62945 20.591 7.77279C20.5552 7.91612 20.4915 8.051 20.4035 8.16972C20.3156 8.28843 20.2051 8.38866 20.0784 8.46469L14.1863 12L20.0784 15.5353C20.3343 15.6888 20.5187 15.9375 20.5911 16.2269C20.6635 16.5163 20.6181 16.8226 20.4647 17.0784Z"
      fill={color}/>
  </svg>
)