import React from 'react'
import { getFullName } from '../../../User/utils/getName'
import { Avatar } from '../../../../shared/ui-component'

import { IProjectUserModel } from '../../../../../(deprecated)/api/projectUserApi/ProjectUserAdapter'

import './TableUserRow.scss'

export const TableUserRow: React.FC<PropType> = React.memo(({
  user,
  onClick,
  children
}) => (
  <div
    className='table-row-user'
    onClick={onClick}
  >
    <Avatar src={user.imageUrl} size='sm' />
    <p className='table-row-user_name'>{getFullName(user)}</p>
    <p className='table-row-user_company'>{`${user.companyType} ${user.companyTitle}`}</p>
    <p className='table-row-user_position'>{user.position}</p>
    {children}
    <div className='table-row-user_line' />
  </div>
))

type PropType = {
  user: IProjectUserModel
  children?: React.ReactNode
  onClick: (e?: React.MouseEvent<HTMLDivElement>) => void
}