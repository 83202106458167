import React from 'react'
import { IIcon } from './IIcon'

export const TreeStructure: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  className = '',
  color = '#5C5C5C',
  ...innerProps
}) => (
  <svg className={className} width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 25 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.75 9V7.5H14C13.6022 7.5 13.2206 7.65804 12.9393 7.93934C12.658 8.22064 12.5 8.60218 12.5 9V15C12.5 15.3978 12.658 15.7794 12.9393 16.0607C13.2206 16.342 13.6022 16.5 14 16.5H14.75V15C14.75 14.6022 14.908 14.2206 15.1893 13.9393C15.4706 13.658 15.8522 13.5 16.25 13.5H20.75C21.1478 13.5 21.5294 13.658 21.8107 13.9393C22.092 14.2206 22.25 14.6022 22.25 15V19.5C22.25 19.8978 22.092 20.2794 21.8107 20.5607C21.5294 20.842 21.1478 21 20.75 21H16.25C15.8522 21 15.4706 20.842 15.1893 20.5607C14.908 20.2794 14.75 19.8978 14.75 19.5V18H14C13.2044 18 12.4413 17.6839 11.8787 17.1213C11.3161 16.5587 11 15.7956 11 15V12.75H8V13.5C8 13.8978 7.84196 14.2794 7.56066 14.5607C7.27936 14.842 6.89782 15 6.5 15H3.5C3.10218 15 2.72064 14.842 2.43934 14.5607C2.15804 14.2794 2 13.8978 2 13.5V10.5C2 10.1022 2.15804 9.72064 2.43934 9.43934C2.72064 9.15804 3.10218 9 3.5 9H6.5C6.89782 9 7.27936 9.15804 7.56066 9.43934C7.84196 9.72064 8 10.1022 8 10.5V11.25H11V9C11 8.20435 11.3161 7.44129 11.8787 6.87868C12.4413 6.31607 13.2044 6 14 6H14.75V4.5C14.75 4.10218 14.908 3.72064 15.1893 3.43934C15.4706 3.15804 15.8522 3 16.25 3H20.75C21.1478 3 21.5294 3.15804 21.8107 3.43934C22.092 3.72064 22.25 4.10218 22.25 4.5V9C22.25 9.39782 22.092 9.77936 21.8107 10.0607C21.5294 10.342 21.1478 10.5 20.75 10.5H16.25C15.8522 10.5 15.4706 10.342 15.1893 10.0607C14.908 9.77936 14.75 9.39782 14.75 9Z"
      fill={color}/>
  </svg>
)
