import { useMemo } from 'react'
import { setToken } from '../../../(deprecated)/utils/localStorageManagement'

export const useManageUrlParamsTokens = () => {
  const { access, refresh } = useMemo(() => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    return {
      access: params.get('access'),
      refresh: params.get('refresh')
    }
  }, [window.location.search])

  access && refresh && setToken({ access, refresh })

  return {
    access,
    refresh
  }
}