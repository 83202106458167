import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, HoverContainer, Modal } from '../../../../../../../fsd/shared/ui-component'
import { Close } from '../../../../../../../fsd/shared/ui-icons'
import { ModalRejectProjectInvitation } from '../ModalRejectProjectInvitation/ModalRejectProjectInvitation'
import { projectApi } from '../../../../../../api/projectApi/projectApi'
import { useTDispatch, useTSelector } from '../../../../../../utils/hooks/reduxHooks'
import { settingActiveProject } from '../../../../../../redux/project/actions'
import { setProjectExtended } from '../../../../../../utils/localStorageManagement'
import { getProjectsMy } from '../../../../../../redux/project/actions'
import './ModalProjectInvitation.scss'

export const ModalProjectInvitation: React.FC<PropsType> = ({
  data,
  setInviteProjectModalData,
  getInvitationsProject,
  onCancel,
  open
}) => {
  const dispatch = useTDispatch()
  const history = useHistory()
  const [isOpenRejectInvitationModal, setOpenRejectInvitationModal] = useState(false)

  const userId = useTSelector(state => state.user.userInfo.id)

  const [answerForInvitationInProject, { isLoading }] = projectApi.useSendAnswerForInvitationInProjectMutation()

  const handleChooseProject = (projectId: number) => {
    dispatch(settingActiveProject(projectId))
    setProjectExtended({ userId, projectId })
  }

  const handleRequest = ({
    decision,
    comment
  }: {
    decision: 'approve' | 'reject',
    comment: string | null
  }) => {
    const onSuccessCb = () => {
      if (decision === 'approve') {
        handleChooseProject(1)
        history.push('/')
      }
      onCancel()
      dispatch(getProjectsMy())
    }

    answerForInvitationInProject({ comment, decision, projectId: data.projectId, onSuccessCb })
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
    >
      <div>
        <div className='project-invitation-modal-header'>
          <h2>Вашу компанию приглашают в проект</h2>
          <HoverContainer
            className='icon_close'
            onClick={() => setInviteProjectModalData({ isOpen: false, projectId: NaN, projectName: '' })}
          >
            <Close />
          </HoverContainer>
        </div>

        <p className='invitation-message'>
          Компанию, в которой вы администратор, приглашают в проект
          <span className='invitation-project-name'> "{data.projectName}"</span>
        </p>

        <div className='project-invitation-modal-btns-control'>
          <Button
            isLoading={isLoading}
            title='Отказаться'
            buttonType='blue-ligth'
            onClick={() => setOpenRejectInvitationModal(true)}
          />
          <Button
            isLoading={isLoading}
            title='Принять приглашение'
            onClick={() => handleRequest({ decision: 'approve', comment: null })}
          />
        </div>
      </div>

      <ModalRejectProjectInvitation
        isOpen={isOpenRejectInvitationModal}
        onSend={handleRequest}
        onClose={() => setOpenRejectInvitationModal(false)}
      />
    </Modal>
  )
}

type PropsType = {
  open: boolean
  data: { projectId: number, projectName: string }
  getInvitationsProject: (arg?: any) => any
  setInviteProjectModalData: any
  onCancel: () => void
}