import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { createGetLocationsQuery } from './createGetLocationsQuery'
import { createGetLocationsTreeQuery } from './createGetLocationsTreeQuery'
import { baseQuery } from '../../../../(deprecated)/api/BaseQuery'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// TODO перенести

export const projectLocationsApi = createApi({
  reducerPath: 'projectLocationApi',
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ['location-list'],
  endpoints: (builder) => ({
    getLocations: createGetLocationsQuery(builder),
    getLocationsTree: createGetLocationsTreeQuery(builder)
  })
})