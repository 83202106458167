import axios, { AxiosRequestHeaders } from 'axios'
import camelcaseFields from '../formatFields'
import HOST from './host'

import { getHeaders } from './getHeaders.js'
import { errorProccessing } from './errorProccessing.js'
import { abortController } from './abortController'

let currentTokenCancel: any = null

type Get = {
  path: string,
  params?: any,
  isCamelCase?: boolean,
  isCancel?: boolean, //Устаревшее поле
  withCancellation?: boolean
  onCancellation?: () => void
}

const get = async <R>({ path, params, isCamelCase = true, isCancel = false, withCancellation = false, onCancellation }: Get) => (
  new Promise<R>(async (res, rej) => {

    const headers = await getHeaders() as AxiosRequestHeaders
    const REQUEST_URL = HOST.getInstance().getRequestUrl()

    if (isCancel) {
      currentTokenCancel && currentTokenCancel.cancel()
      currentTokenCancel = axios.CancelToken.source()
    }

    if (withCancellation) {
      abortController.abort(`get/${path}`)
      onCancellation?.()
    }

    try {
      const response = await axios.get<R>(
        `${REQUEST_URL}${path}`, {
        headers,
        params,
        cancelToken: isCancel && currentTokenCancel?.token,
        signal: abortController.getSignal(`get/${path}`)
      })
      res(isCamelCase ? camelcaseFields(response.data) : response.data)
    } catch (error) {
      rej(camelcaseFields(errorProccessing(error)))
    }
  })
)

export default get
