import React from 'react'
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_TYPE } from '../../../../amplitude/events'
import { ALL_FUNCTIONS_FOR_ACCESS } from '../../../../../fsd/features/FunctionAccess/type/typeFunctions'
import * as Icon from '../../../../../fsd/shared/ui-icons'

export type DataLink = {
  title: string,
  icon: React.ReactElement
  pathUrl?: string
  withCount?: boolean
  id: string
  amplitudeEvent: AMPLITUDE_EVENTS_TYPE
  approveFunctions: ALL_FUNCTIONS_FOR_ACCESS[]
  inTabs?: DataLink[]
}

export const dataLinks: DataLink[] = [
  {
    title: 'Документы',
    icon: <Icon.Document />,
    id: 'documents',
    amplitudeEvent: AMPLITUDE_EVENTS.LEFT_SIDEBAR_DOCUMENTS_BUTTON_CLICK,
    approveFunctions: [],
    inTabs: [
      {
        title: 'Входящие',
        icon: <Icon.Tray />,
        pathUrl: '/documents/incoming',
        id: 'documents-inbox',
        amplitudeEvent: AMPLITUDE_EVENTS.LEFT_SIDEBAR_DOCUMENTS_INCOMING_CLICK,
        approveFunctions: [],
      },
      {
        title: 'Отправленные',
        icon: <Icon.PaperPlane />,
        pathUrl: '/documents/sent',
        id: 'documents-send',
        amplitudeEvent: AMPLITUDE_EVENTS.LEFT_SIDEBAR_DOCUMENTS_SENDED_CLICK,
        approveFunctions: []
      },
      {
        title: 'Конструктор',
        icon: <Icon.PuzzlePiece />,
        pathUrl: '/documents/templates',
        id: 'documents-templates',
        amplitudeEvent: AMPLITUDE_EVENTS.LEFT_SIDEBAR_DOCUMENTS_CONSTRUCTOR_CLICK,
        approveFunctions: [
          'create-category', 'edit-category', 'remove-category',
          'create-template', 'edit-template', 'remove-template'
        ]
      },
      {
        title: 'Подать документ',
        icon: <Icon.FilePlus />,
        pathUrl: '/documents/sending',
        id: 'documents-sending',
        amplitudeEvent: AMPLITUDE_EVENTS.LEFT_SIDEBAR_DOCUMENTS_SEND_DOCUMENT_BTN_CLICK,
        approveFunctions: ['create-doc']
      },
    ]
  },
  {
    title: 'КПП',
    icon: <Icon.Gate />,
    id: 'nav-bar-link_kpp-btn',
    amplitudeEvent: AMPLITUDE_EVENTS.LEFT_SIDEBAR_CHECKPOINT_BTN_CLICK,
    approveFunctions: [],
    inTabs: [
      {
        title: 'Журнал',
        icon: <Icon.Book />,
        pathUrl: '/checkpoint/history',
        id: 'nav-bar-link-kpp-history-btn',
        amplitudeEvent: AMPLITUDE_EVENTS.LEFT_SIDEBAR_CHECKPOINT_ACCESS_CLICK,
        approveFunctions: [
          'read-checkpoint-logs'
        ],
      },
      {
        title: 'Пропуска',
        icon: <Icon.IdentificationCard />,
        pathUrl: '/checkpoint/access',
        id: 'nav-bar-link-kpp-access-btn',
        amplitudeEvent: AMPLITUDE_EVENTS.LEFT_SIDEBAR_CHECKPOINT_HISTORY_CLICK,
        approveFunctions: [
          'read-checkpoint-docs'
        ],
      }
    ]
  },
  {
    title: 'Справочник',
    icon: <Icon.Info />,
    pathUrl: '/catalog',
    id: 'catalog',
    amplitudeEvent: AMPLITUDE_EVENTS.LEFT_SIDEBAR_CATALOG_CLICK,
    approveFunctions: []
  },
  {
    title: 'Отчеты',
    icon: <Icon.ListBullets />,
    id: 'reportings',
    pathUrl: '/reportings',
    amplitudeEvent: AMPLITUDE_EVENTS.LEFT_SIDEBAR_REPORTS_CLICK,
    approveFunctions: ['view-reports']
  },
  {
    title: 'Чек-листы',
    icon: <Icon.Checks />,
    id: 'checklists',
    pathUrl: '/checklists',
    amplitudeEvent: AMPLITUDE_EVENTS.LEFT_SIDEBAR_CHECKLISTS_CLICK,
    approveFunctions: []
  },
  {
    title: 'Замечания',
    icon: <Icon.ChatTeardropText />,
    id: 'remarks',
    pathUrl: '/remarks',
    amplitudeEvent: AMPLITUDE_EVENTS.LEFT_SIDEBAR_REMARKS_CLICK,
    approveFunctions: []
  }
]