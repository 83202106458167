import React from 'react'
import { IIcon } from './IIcon'

export const DownloadSimple:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.71937 11.0306C7.61437 10.9257 7.54284 10.792 7.51385 10.6465C7.48487 10.5009 7.49972 10.35 7.55653 10.2129C7.61335 10.0758 7.70957 9.95861 7.83301 9.87621C7.95646 9.79381 8.10158 9.74988 8.25 9.75H11.25V3.75C11.25 3.55109 11.329 3.36032 11.4697 3.21967C11.6103 3.07902 11.8011 3 12 3C12.1989 3 12.3897 3.07902 12.5303 3.21967C12.671 3.36032 12.75 3.55109 12.75 3.75V9.75H15.75C15.8984 9.74988 16.0435 9.79381 16.167 9.87621C16.2904 9.95861 16.3867 10.0758 16.4435 10.2129C16.5003 10.35 16.5151 10.5009 16.4861 10.6465C16.4572 10.792 16.3856 10.9257 16.2806 11.0306L12.5306 14.7806C12.461 14.8504 12.3783 14.9057 12.2872 14.9434C12.1962 14.9812 12.0986 15.0006 12 15.0006C11.9014 15.0006 11.8038 14.9812 11.7128 14.9434C11.6217 14.9057 11.539 14.8504 11.4694 14.7806L7.71937 11.0306ZM20.25 13.5C20.0511 13.5 19.8603 13.579 19.7197 13.7197C19.579 13.8603 19.5 14.0511 19.5 14.25V19.5H4.5V14.25C4.5 14.0511 4.42098 13.8603 4.28033 13.7197C4.13968 13.579 3.94891 13.5 3.75 13.5C3.55109 13.5 3.36032 13.579 3.21967 13.7197C3.07902 13.8603 3 14.0511 3 14.25V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V14.25C21 14.0511 20.921 13.8603 20.7803 13.7197C20.6397 13.579 20.4489 13.5 20.25 13.5Z"
      fill={color}/>
  </svg>
)