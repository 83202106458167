import { snakecaseFields } from '../../../../../(deprecated)/utils/formatFields'
import { MetaAdapter, MetaData } from '../../../../../(deprecated)/api/MetaAdapter'
import { IProjectAttachment } from '../../../../../(deprecated)/models/IProjectAttachment'
import { IDynamicPageNumberPaginationMeta } from '../../../../../(deprecated)/models/IPagination'
import { BuilderProjectAttachmentsApi } from '../types'
import { addedNewNotification } from '../../../../../(deprecated)/redux/notifications/actions'

import QueryString from 'query-string'

type ResultType = {
  meta: MetaData,
  projectAttachments: IProjectAttachment[]
  next: string
  previous: string
}

type QueryArgsType = {
  page: number
  perPage: number
  project: number
  search: string
}

export const getProjectAttachmentsQuery = (builder: BuilderProjectAttachmentsApi) => (
  builder.query<ResultType, QueryArgsType>({
    query: ({ page, perPage, project, search }) => {
      const queryString = QueryString.stringify(snakecaseFields({
        page,
        perPage,
        project,
        search
      }), { skipNull: true })

      return `project_files?${queryString}`
    },
    providesTags: ['project-attachments'],
    transformResponse: (response: {
      results: IProjectAttachment[],
      meta: IDynamicPageNumberPaginationMeta,
      next: string,
      previous: string
    } ) => {
      const { meta, results, next, previous } = response

      return { meta: new MetaAdapter(meta).getFields(), projectAttachments: results, next, previous }
    },
    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        await queryFulfilled
      } catch (e) {
        dispatch(addedNewNotification({ message: 'Не удалось загрузить документы проекта.' }))
      }
    }
  })
)