import { snakecaseFields } from "../../utils/formatFields"

type AddAndRemoveUsersInProjectQuery ={
  id:number,
  data: {
    addingMembers?:number[]
    removingMembers?:number[]
  }
}

export class PutAddAndRemoveUsersInProject {

  query = ({ data, id }: AddAndRemoveUsersInProjectQuery) => {
    return {
      url: `/projects/${id}/users`,
      method: 'PUT',
      body: snakecaseFields(data)
    }
  }
}