import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { 
  getStateLeftSidebar, 
  getStateRightSidebar,
  removeStateLeftSidebar,
  removeStateRightSidebar,
  setStateLeftSidebar,
  setStateRightSidebar
} from '../../utils/localStorageManagement'

const initialState = {
  isOpenedLeftSidebar: !getStateLeftSidebar(),
  isOpenedRightSidebar: !getStateRightSidebar(),
}

const sidebarSlice = createSlice({
  name: 'sidebarSlice',
  initialState: initialState,
  reducers: {
    setIsOpenLeftSidebar: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenedLeftSidebar = payload

      state.isOpenedLeftSidebar
        ? removeStateLeftSidebar()
        : setStateLeftSidebar(state.isOpenedLeftSidebar)
    },

    setIsOpenRightSidebar: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenedRightSidebar = payload

      state.isOpenedRightSidebar
        ? removeStateRightSidebar()
        : setStateRightSidebar(state.isOpenedRightSidebar)
    }

  }
})

export const sidebarActions = sidebarSlice.actions

export default sidebarSlice.reducer