import React from 'react'
import { IIcon } from './IIcon'

export const Cube: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} viewBox="0 0 24 24" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.97 6.20158L12.72 1.68752C12.4996 1.56572 12.2518 1.50183 12 1.50183C11.7482 1.50183 11.5004 1.56572 11.28 1.68752L3.03 6.20345C2.7944 6.33237 2.59772 6.52217 2.46052 6.75304C2.32331 6.98391 2.25061 7.24739 2.25 7.51595V16.4822C2.25061 16.7508 2.32331 17.0142 2.46052 17.2451C2.59772 17.476 2.7944 17.6658 3.03 17.7947L11.28 22.3106C11.5004 22.4324 11.7482 22.4963 12 22.4963C12.2518 22.4963 12.4996 22.4324 12.72 22.3106L20.97 17.7947C21.2056 17.6658 21.4023 17.476 21.5395 17.2451C21.6767 17.0142 21.7494 16.7508 21.75 16.4822V7.51689C21.7499 7.24785 21.6774 6.98379 21.5402 6.75238C21.403 6.52096 21.206 6.33072 20.97 6.20158ZM12 11.25L4.46719 7.12502L12 3.00002L19.5328 7.12502L12 11.25ZM12.75 20.5913V12.5466L20.25 8.4422V16.4822L12.75 20.5913Z"
      fill={color}/>
  </svg>
)