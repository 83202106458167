import React from 'react'
import { HoverContainer } from '../../../../../../fsd/shared/ui-component'
import { BlockWrapper } from '../../../../UI/wrappers/BlockWrapper/BlockWrapper'
import { memoTemplateField } from '../memoTemplateField'
import Textarea from '../../../../UI/Textarea/Textarea'
import cn from 'classnames'
import * as Icon from '../../../../../../fsd/shared/ui-icons'
import './TemplateTextField.scss'

export type CommonFieldProps = {
  title: string
  name: string
  value: unknown
  placeholder?: string
  error?: unknown

  required?: boolean
  isAutoNowAdd?: boolean
  isDisabled?: boolean
  touched?: boolean
  cssSelector?: string

  setFieldTouched: (name: string, isTouched: boolean) => void
  setFieldValue: (name: string, value: unknown) => void
}

const TemplateTextField = ({
  value,
  touched,
  error,
  name,
  title,
  isDisabled,
  required,
  placeholder = 'Введите текст',
  setFieldValue,
  setFieldTouched,
  ...props
}: CommonFieldProps) => (
  <BlockWrapper
    title={title || name || ''}
    className={cn('template-field', { 'document-field-name': !!title, 'required-field': required })}
  >
    <Textarea
      customClassName={cn('template-input', { 'template-input_header': !!title, 'field_error': touched && error })}
      name={name}
      value={String(value || '')}
      error={String(error || '')}
      touched={touched}
      suffix={fieldSuffix({
        hasValue: Boolean(value),
        isDisabled: Boolean(isDisabled),
        onClick: () => setFieldValue(name, '')
      })}
      disabled={isDisabled}
      placeholder={placeholder}
      onBlur={() => setFieldTouched(name, true)}
      onChange={(e: { target: { value: string } }, keyEvent: { key: string, shiftKey: boolean }) => {
        if (!keyEvent)
          return
        if (keyEvent.key === 'Enter' && !keyEvent.shiftKey)
          return
        setFieldValue(name, e.target.value)
      }}
      {...props}
    />

  </BlockWrapper>
)

type FieldSuffixProps = {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isDisabled: boolean
  hasValue: boolean
}

export const fieldSuffix = ({
  onClick,
  isDisabled,
  hasValue
}: FieldSuffixProps) => {

  if (isDisabled) return <></>

  if (hasValue) {
    return (
      <HoverContainer onClick={onClick} >
        <Icon.Close />
      </HoverContainer>
    )
  }
}

export default memoTemplateField<CommonFieldProps>(TemplateTextField);

