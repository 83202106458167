import { IChatUser } from '../../models/IUser'
import { ProjectUserAdapter } from './ProjectUserAdapter'
import { IDynamicPageNumberPaginationMeta } from '../../models/IPagination'
import { omitBy, isUndefined } from 'lodash'
import QueryString from 'query-string'
import camelcaseFields from '../../utils/formatFields'

type QueryParams = {
  page: number
  perPage: number
  projectId: number | null | undefined
  searchText: string
  companyIds: number[]
  position: string[]
  roleIdIn: number[]
  withRoleName: boolean
  roleNameIn: string[]
  roleNameNotIn: string[]
  orderBy: string
}

export class GetProjectUserListQuery {
  query = ({
    page,
    perPage,
    projectId,
    searchText,
    withRoleName,
    companyIds,
    roleIdIn,
    position,
    roleNameIn,
    roleNameNotIn,
    orderBy
  }: Partial<QueryParams>): string => {
    const query = omitBy({
      full_name: searchText || undefined,
      company_id__in: companyIds?.length ? companyIds.join(',') : undefined,
      page,
      per_page: perPage,
      with_role_name: withRoleName,
      role_id__in: roleIdIn,
      role_name__in: roleNameIn?.length ? roleNameIn.join(',') : undefined,
      role_name__not_in: roleNameNotIn?.length ? roleNameNotIn.join(',') : undefined,
      position__in: position?.length ? position.join(',') : undefined,
      ordering: orderBy ? orderBy : undefined,
    }, isUndefined)

    const queryString = QueryString.stringify(query, { skipNull: true })

    return `projects/${projectId}/find_users?${queryString}`
  }

  transformResponse = (response: { results: IChatUser[], meta: Record<string, unknown> }) => {
    const { meta, results } = response

    const listOptions = results.map((user: IChatUser) =>
      new ProjectUserAdapter(user).getFields()
    )

    return { meta: camelcaseFields(meta) as IDynamicPageNumberPaginationMeta, listOptions }
  }

  // TODO  переделать в нормальные теги
  providesTags = (): ['user-project'] => ['user-project']
}