import React from 'react'
import imagePreview from '../../../../../shared/assets/prewiev-image.png'

export const ImagePreview:React.FC<PropsType> = ({
  url,
  customClassName
}) => (
  <div className={customClassName}>
    <img
      src={url ?? imagePreview}
      className='preview-image'
    />
  </div>
)

type PropsType = {
  url: string
  customClassName?: string
}