import { PayloadAction, createSlice } from "@reduxjs/toolkit";


const backlightForDocsShort = createSlice({
  name: 'backlight-docsShort',
  initialState: {
    ids: [] as number[],
  },
  reducers: {
    setBacklightForDocShort(state, { payload }: PayloadAction<number[]>) {
      state.ids = state.ids.concat(payload)
    },
    removeBacklightForDocShort(state, { payload }: PayloadAction<number[]>) {
      const set = new Set()

      payload.forEach((id) => set.add(id))

      state.ids = state.ids.filter((id) => !set.has(id))
    }
  }
})

export const {
  setBacklightForDocShort,
  removeBacklightForDocShort
} = backlightForDocsShort.actions

export default backlightForDocsShort.reducer