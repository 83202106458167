import { BuilderFileApi, } from './types';

//TODO перенести
import { IFile } from '../../../../(deprecated)/models/IDocument';

interface IPostFileMutationParam {
  file: File,
  cb?: (isError: boolean, file?: IFile) => void
}

export const createPostFileMutation = (builder: BuilderFileApi) => (
  builder.mutation<IFile, IPostFileMutationParam>({
    query: ({ file, cb }) => {
      const data = new FormData()
      data.append('file', file)
      return {
        url: '/files',
        method: 'POST',
        body: data,
      }
    },
    async onQueryStarted({ cb }, api) {
      try {
        const { data } = await api.queryFulfilled
        cb?.(false, data)
      } catch (error) {
        cb?.(true)
      }
    },
  })
)