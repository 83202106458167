import React from 'react'
import { IIcon } from './IIcon'

export const CaretDown:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
   className = '',
   ...innerProps
}) => {
  return (
    <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24"
         fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.031 9.53063L12.531 17.0306C12.4614 17.1004 12.3787 17.1557 12.2876 17.1934C12.1966 17.2312 12.099 17.2506 12.0004 17.2506C11.9019 17.2506 11.8043 17.2312 11.7132 17.1934C11.6222 17.1557 11.5394 17.1004 11.4698 17.0306L3.96979 9.53063C3.82906 9.3899 3.75 9.19902 3.75 9C3.75 8.80098 3.82906 8.61011 3.96979 8.46938C4.11052 8.32865 4.30139 8.24958 4.50042 8.24958C4.69944 8.24958 4.89031 8.32865 5.03104 8.46938L12.0004 15.4397L18.9698 8.46938C19.0395 8.39969 19.1222 8.34442 19.2132 8.30671C19.3043 8.26899 19.4019 8.24958 19.5004 8.24958C19.599 8.24958 19.6965 8.26899 19.7876 8.30671C19.8786 8.34442 19.9614 8.39969 20.031 8.46938C20.1007 8.53906 20.156 8.62178 20.1937 8.71283C20.2314 8.80387 20.2508 8.90146 20.2508 9C20.2508 9.09855 20.2314 9.19613 20.1937 9.28717C20.156 9.37822 20.1007 9.46094 20.031 9.53063Z"
        fill={color}/>
    </svg>
  )
}