export const createHelpdeskChatScript = () => {
  const script = document.createElement('script')

  script.src = '//cdn5.helpdeskeddy.com//js/modules/chat_widget/hde.js'
  script.dataset.lang = 'ru'
  script.id = 'hde-chat-widget'
  script.dataset.host = 'liis.helpdeskeddy.com'
  script.dataset.customContainer = 'help-desk'
  script.dataset.customButton = 'help-desk__button'
  script.defer = true;

  document.body.appendChild(script)

  return script
}