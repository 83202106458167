import React from 'react'
import { IIcon } from './IIcon'

export const ThreeVerticalDots:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#000000',
  opacity = '0.8',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 32 32"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 16C19.5 16.6922 19.2947 17.3689 18.9101 17.9445C18.5256 18.5201 17.9789 18.9687 17.3394 19.2336C16.6999 19.4985 15.9961 19.5678 15.3172 19.4328C14.6383 19.2977 14.0146 18.9644 13.5251 18.4749C13.0356 17.9854 12.7023 17.3618 12.5673 16.6828C12.4322 16.0039 12.5015 15.3002 12.7664 14.6606C13.0313 14.0211 13.4799 13.4744 14.0555 13.0899C14.6311 12.7053 15.3078 12.5 16 12.5C16.9283 12.5 17.8185 12.8688 18.4749 13.5251C19.1313 14.1815 19.5 15.0717 19.5 16ZM16 9.5C16.6922 9.5 17.3689 9.29473 17.9445 8.91015C18.5201 8.52556 18.9687 7.97894 19.2336 7.33939C19.4985 6.69985 19.5678 5.99612 19.4328 5.31719C19.2977 4.63825 18.9644 4.01461 18.4749 3.52513C17.9854 3.03564 17.3618 2.7023 16.6828 2.56725C16.0039 2.4322 15.3002 2.50152 14.6606 2.76642C14.0211 3.03133 13.4744 3.47993 13.0899 4.05551C12.7053 4.63108 12.5 5.30777 12.5 6C12.5 6.92826 12.8688 7.8185 13.5251 8.47487C14.1815 9.13125 15.0717 9.5 16 9.5ZM16 22.5C15.3078 22.5 14.6311 22.7053 14.0555 23.0899C13.4799 23.4744 13.0313 24.0211 12.7664 24.6606C12.5015 25.3002 12.4322 26.0039 12.5673 26.6828C12.7023 27.3618 13.0356 27.9854 13.5251 28.4749C14.0146 28.9644 14.6383 29.2977 15.3172 29.4328C15.9961 29.5678 16.6999 29.4985 17.3394 29.2336C17.9789 28.9687 18.5256 28.5201 18.9101 27.9445C19.2947 27.3689 19.5 26.6922 19.5 26C19.5 25.0717 19.1313 24.1815 18.4749 23.5251C17.8185 22.8687 16.9283 22.5 16 22.5Z"
      fill={color} fillOpacity={opacity}/>
  </svg>
)