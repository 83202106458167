import { Store } from 'redux'
import { propInit } from '../type'

import * as typesRedux from '../actionTypes/actionTypes'
import InstanceConnection from './InstanseConnection'


const ALL_DEPENDENCIES: string[] = []
const stateConnection: InstanceConnection[] = []
const initSuccess = false

export const websocketMiddleware = (store: Store) => (next: any) => (action: any) => {
  
  switch (action.type) {

    case typesRedux.WS_INIT: {
      if (!initSuccess) {
        action.payload.forEach((propInit: propInit, index: number) => {
          let stateConnectionNames = stateConnection.map(instanceItem => instanceItem.nameConnection)
          if (!stateConnectionNames.includes(propInit.uniqueName)) {
            stateConnection.push(new InstanceConnection(propInit))
            stateConnection[index].getDependeciesActionType().forEach((actionTypeDependence) => (
              !ALL_DEPENDENCIES.includes(actionTypeDependence) && ALL_DEPENDENCIES.push(actionTypeDependence)
            ))
            propInit.initApp && stateConnection[index].createConnection(store)
          }
        })
      }
      break
    }

    case typesRedux.WS_SEND_MESSAGE: {
      const instanceConnection = stateConnection.find((instanceConnection) =>
        instanceConnection.nameConnection == action.nameConnection
      )
      instanceConnection !== undefined
        ? instanceConnection.sendMessage(action.payload)
        : store.dispatch({
          type: typesRedux.WS_ERROR_SEND_MESSAGE.concat(action.nameConnection.toUpperCase())
        })
      break
    }

    case typesRedux.WS_SET_CONNECT: {
      const instanceConnection = stateConnection.find(instanceConnection => (
        instanceConnection.nameConnection == action.nameConnect
      ))
      console.log('!!! stateConnection', instanceConnection?.stateConnection);

      if (instanceConnection?.stateConnection !== 1)
        instanceConnection?.createConnection(store)
      break
    }
    case typesRedux.WS_SET_DISCONNECT: {
      const instanceConnection = stateConnection.find(instanceConnection => (
        instanceConnection.nameConnection == action.nameConnect
      ))
      instanceConnection?.closeConnection(true)
      break
    }

    case 'RESET_STORE':
      stateConnection.forEach((instanceConnection) => (
        instanceConnection.closeConnection()
      ))
      break

    default:
      stateConnection.forEach((instanceConnection) => {
        if (instanceConnection.getDependeciesActionType().includes(action.type))
          instanceConnection.updateConnection(store, action)
      })
      break
  }

  return next(action)
}