type PropTypeFileValidator = {
  file: File
  rules: FileValidationRulesType
}

export type FileValidationRulesType = {
  maxSize?: number // Указывать в Мб
  maxFileNameLength?: number
}

type ValidatedFileType = {
  file: File
  isValid: boolean
  errorText: string
  isFileNameLengthValid: boolean
  isFileSizeValid : boolean
}

export const fileValidator = ({
  file,
  rules
}: PropTypeFileValidator): ValidatedFileType => {

  const validatedFile = {
    file,
    isValid: true,
    errorText: '',
    errorType: null,
    isFileNameLengthValid: true,
    isFileSizeValid: true
  }

  if (rules.maxFileNameLength && file.name.length > rules.maxFileNameLength) {
    validatedFile.isValid = false
    validatedFile.isFileNameLengthValid = false
    validatedFile.errorText = `Максимальное кол-во символов в названии файла ${rules.maxFileNameLength}`
  }

  if (rules.maxSize && file.size > rules.maxSize * 1000000) {
    validatedFile.isValid = false
    validatedFile.isFileSizeValid = false
    validatedFile.errorText = `Размер каждого файла не должен превышать ${rules.maxSize} Мбайт.`
  }

  return validatedFile
}

export type FilesValidationRulesType = FileValidationRulesType & {
  maxCount?: number
}

export const createFilesValidator = (rules: FilesValidationRulesType) => {
  
  let countValidatedFiles = 0
  
  return (file: File) => {

    const validatedFile = fileValidator({ file, rules })
    
    countValidatedFiles++

    if(rules.maxCount && countValidatedFiles > rules.maxCount){
      validatedFile.isValid = false
      validatedFile.errorText = `Максимальное количество файлов для загрузки ${rules.maxCount}.`
    }

    return validatedFile
  }
}