import React, { RefObject, useEffect, useRef, useState } from 'react'

export const useIntersectionObserver = (options: IntersectionObserverInit)
  : [RefObject<HTMLDivElement>, boolean] => { //Неверно выводит тип, пришлось вручную прописать
  const observeRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)

  const callback: IntersectionObserverCallback = (entries) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options)
    if (observeRef.current) observer.observe(observeRef.current)

    return () => {
      if (observeRef.current) observer.unobserve(observeRef.current)
    }
  }, [observeRef, options])

  return [observeRef, isVisible]
}