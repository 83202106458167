import { propInit } from '../type'

export const DOCS_COMMENTS_SETTINGS_UNIQUE_NAME = 'documents_comments'

export const documentsCommentsSettings: propInit = {
  initApp: true,
  uniqueName: DOCS_COMMENTS_SETTINGS_UNIQUE_NAME,
  path: '/document/$DOCUMENT_ID$/discussion?access_token=$TOKEN$',
  dependencies: [
    {
      state: {
        actionTypeDependence: 'docs/setDocs',
        pathActionType: 'payload.id',
        pathStore: 'docs.doc.id'
      },
      uniqueKey: '$DOCUMENT_ID$'
    },
  ]
}