import React from 'react'
import { IIcon } from './IIcon'

export const AddList: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM13 8V3.5L17.5 8H13Z"
      fill={color}/>
    <path d="M15 17H18V15H15V12H13V15H10V17H13V20H15V17Z" fill="white"/>
  </svg>
)