export const TOGGLE_LOADING_GET_ACCESS_TICKETS_CAR = 'TOGGLE_LOADING_GET_ACCESS_TICKETS_CAR'
export const TOGGLE_LOADING_GET_ACCESS_TICKETS_EMPLOYEE = 'TOGGLE_LOADING_GET_ACCESS_TICKETS_EMPLOYEE'
export const GET_ACCESS_TICKETS_CAR_SUCCESS = 'GET_ACCESS_TICKETS_CAR_SUCCESS'
export const GET_ACCESS_TICKETS_EMPLOYEE_SUCCESS = 'GET_ACCESS_TICKETS_EMPLOYEE_SUCCESS'
export const CLEAR_ALL_ACCESS_TICKETS = 'CLEAR_ALL_ACCESS_TICKETS'


export const TOGGLE_LOADING_POST_CREATE_ACCESS_TICKETS_CAR = 'TOGGLE_LOADING_POST_CREATE_ACCESS_TICKETS_CAR'
export const TOGGLE_LOADING_POST_CREATE_ACCESS_TICKETS_EMPLOYEE = 'TOGGLE_LOADING_POST_CREATE_ACCESS_TICKETS_EMPLOYEE'
export const POST_CREATE_ACCESS_TICKETS_CAR_SUCCESS = 'POST_CREATE_ACCESS_TICKETS_CAR_SUCCESS'
export const POST_CREATE_ACCESS_TICKETS_EMPLOYEE_SUCCESS = 'POST_CREATE_ACCESS_TICKETS_EMPLOYEE_SUCCESS'
export const TICKET_UPDATED_SUCCESSFULLY = 'TICKET_UPDATED_SUCCESSFULLY'

export const ADD_NEW_ACCESS_TICKET_CAR = 'ADD_NEW_ACCESS_TICKET_CAR'
export const ADD_NEW_ACCESS_TICKET_EMPLOYEE = 'ADD_NEW_ACCESS_TICKET_EMPLOYEE'