import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTSelector } from '../../../../../utils/hooks/reduxHooks'
import { amplitudeLogEvent } from '../../../../../amplitude'
import { AMPLITUDE_EVENTS_TYPE } from '../../../../../amplitude/events'
import { CardLinkTooltip } from './CardLinkTooltip/CardLinkTooltip'

import cn from 'classnames'

export const SubCardLink = ({
  title,
  pathUrl,
  amplitudeEvent,
  id,
  currentPathUrl,
  isOpenSidebar, 
  setShowHeadLinkNotification,
  icon,
}: SubCardLinkProps) => {
  const [showNotification, setShowNotification] = useState(false)

  const userInfo = useTSelector(state => state.user.userInfo)

  const handleMouseEnter: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    setShowHeadLinkNotification(false)
    setShowNotification(true)
  }

  const handleMouseLeave: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    // @ts-ignore
    const container = e.target.closest('.tabs_wrapper') as HTMLDivElement | null
    if (container) {
      container.style.overflow = 'hidden'
    }
    setShowNotification(false)
  }

  return (
    <>
      <NavLink
        key={title}
        to={pathUrl}
        className='wrapper__card-link wrapper__card-link-tab'
        id={id}
        onClick={() => {
          amplitudeLogEvent({
            event: amplitudeEvent,
            options: userInfo
          })
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={cn('card-link__icon', {
            'active-icon': pathUrl === currentPathUrl
          })}
        >
          {icon}
        </div>
        <li
          className={cn('card-link', {
            'card-link_active': pathUrl === currentPathUrl,
            'card-link_reduce': !isOpenSidebar
          })}
        >
          <span>{title}</span>
        </li>
      </NavLink>

      <CardLinkTooltip
        cardLinkId={id}
        cardLinkTitle={title}
        isDisabledTooltip={!showNotification || isOpenSidebar}
      />
    </>
  )
}

type SubCardLinkProps = {
  title: string
  pathUrl: string
  id: string
  amplitudeEvent: AMPLITUDE_EVENTS_TYPE
  currentPathUrl: string
  isOpenSidebar: boolean
  icon: JSX.Element
  setShowHeadLinkNotification: React.Dispatch<React.SetStateAction<boolean>>
}