import * as TYPE from './actionTypes'
import {
  getStateLeftSidebar,
  setStateLeftSidebar,
  removeStateLeftSidebar,
  getStateRightSidebar,
  setStateRightSidebar,
  removeStateRightSidebar
} from '../../utils/localStorageManagement'

const initialState = {
  documentTypes: [],
  isLoadingDocumentTypes: false,
  flowStageTitles: [],
  isLoadingFlowStageTitles: false,
  flowActions: [],
  isLoadingFlowActions: false,
  flowTemplates: [],
  isLoadingFlowTemplates: false,
  activeProjectId: null,
  isLoadingDocumentForm: false,
  documentForm: [],
  isLoadingMembersRole: false,
  membersRoles: {},
  isLoadingTemplateExcel: false,
  templateExcel: '',
  mobileInfo: {
    isMobile: false,
    typeOS: null, // 1-Android 2-iOs
    width: null, // всегда значения при portret-primary
    height: null // всегда значения при portret-primary
  }
}

function appReducer(state = initialState, { type, payload }) {
  switch (type) {
    case TYPE.SET_LOADING_FLOW_STAGE_TITLE:
      return { ...state, isLoadingFlowStageTitles: true }

    case TYPE.GET_FLOW_STAGE_TITLE_SUCCESS:
      return { ...state, isLoadingFlowStageTitles: false, flowStageTitles: payload }

    case TYPE.GET_FLOW_STAGE_TITLE_FAILED:
      return { ...state, isLoadingFlowStageTitles: false }

    case TYPE.SET_LOADING_FLOW_ACTION_START:
      return { ...state, isLoadingFlowActions: true }

    case TYPE.GET_FLOW_ACTION_SUCCESS:
      return {
        ...state,
        isLoadingFlowActions: false,
        flowActions: payload,
      }

    case TYPE.GET_FLOW_ACTION_FAILED:
      return { ...state, isLoadingFlowActions: false }

    case TYPE.SET_ACTIVE_PROJECT:
      return {
        ...state, activeProjectId: payload
      }

    case TYPE.SET_LOADING_GET_DOCUMENT_FORM:
      return {
        ...state, isLoadingDocumentForm: true
      }

    case TYPE.GET_DOCUMENT_FORM_SUCCESS:
      return {
        ...state,
        isLoadingDocumentForm: false,
        documentForm: payload
      }

    case TYPE.GET_DOCUMENT_FORM_FAILED:
      return {
        ...state,
        isLoadingDocumentForm: false,
      }

    case TYPE.SET_LOADING_GET_MEMBERS_ROLE:
      return {
        ...state,
        isLoadingMembersRole: true
      }

    case TYPE.GET_MEMBERS_ROLE_SUCCESS:
      return {
        ...state,
        membersRoles: payload,
        isLoadingMembersRole: false
      }

    case TYPE.GET_MEMBERS_ROLE_FAILED:
      return {
        ...state,
        isLoadingMembersRole: false
      }

    case TYPE.SET_LOADING_GET_TEMPLATE_EXCEL:
      return {
        ...state,
        isLoadingTemplateExcel: true
      }

    case TYPE.GET_TEMPLATE_EXCEL_SUCCESS:
      return {
        ...state,
        templateExcel: payload,
        isLoadingTemplateExcel: false
      }

    case TYPE.GET_TEMPLATE_EXCEL_FAILED:
      return {
        ...state,
        isLoadingTemplateExcel: false
      }

    case TYPE.SET_MOBILE_INFO:
      return {
        ...state,
        mobileInfo: payload
      }

    default: return state
  }
}

export default appReducer
