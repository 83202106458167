import { FunctionComponent, memo} from 'react';

function compareWithoutFunctions(prevProps: Record<string, unknown>, nextProps: Record<string, unknown>) {
  const prevValies = Object.values(prevProps)
  const nextValues = Object.values(nextProps)

  const surfaseCompare = prevValies.every((value, valueIdx) => {
    if (value instanceof Function) return true
    return value === nextValues[valueIdx]
  })

  return surfaseCompare
}

export const memoTemplateField = <Props extends Record<string, unknown>>(Component: FunctionComponent<Props>) => memo(Component, compareWithoutFunctions)
