import { SET_LOADING_ADDED_IMAGES, ADDED_IMAGES_SUCCESS, ADDED_IMAGES_FAILED, CLEAR_IMAGES } from "./constant"

const initialState = {
  isLoading: false,
  imagesInfo: [],
  type: ''
}

export const imageReducer = (state = initialState, {payload, type}) => {
  switch (type) {

    case SET_LOADING_ADDED_IMAGES:
      return { ...state, isLoading: true }

    case ADDED_IMAGES_SUCCESS:
      return { 
        ...state, 
        isLoading: false, 
        imagesInfo: payload.images,
        type: payload.type
      }

    case ADDED_IMAGES_FAILED:
      return { ...state, isLoading: false }

    case CLEAR_IMAGES:
      return { 
        ...state, 
        imagesInfo: [],
        type: ''
      }
      
    default:
      return state
  }
}
