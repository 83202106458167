import React from 'react'
import { IIcon } from './IIcon'

export const Funnel:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#949494',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.3581 6.25875L21.3506 6.26719L15.001 13.0472V18.2494C15.0013 18.4968 14.9405 18.7405 14.8239 18.9587C14.7072 19.1769 14.5384 19.3628 14.3325 19.5L11.3325 21.5006C11.1064 21.6512 10.8437 21.7376 10.5724 21.7505C10.3011 21.7635 10.0313 21.7025 9.79192 21.5741C9.55255 21.4457 9.35253 21.2547 9.21322 21.0215C9.07392 20.7883 9.00055 20.5216 9.00095 20.25V13.0472L2.65126 6.26719L2.64376 6.25875C2.44853 6.04389 2.31984 5.77699 2.2733 5.49043C2.22677 5.20387 2.26439 4.90996 2.38161 4.64436C2.49883 4.37876 2.6906 4.15288 2.93366 3.99413C3.17673 3.83538 3.46064 3.75057 3.75095 3.75H20.251C20.5415 3.75003 20.8258 3.83444 21.0693 3.99298C21.3128 4.15152 21.505 4.37737 21.6225 4.64308C21.7401 4.90878 21.778 5.20292 21.7315 5.48973C21.6851 5.77655 21.5563 6.04371 21.361 6.25875H21.3581Z"
      fill={color}/>
  </svg>
)