import React from 'react'
import { IIcon } from './IIcon'

export const Check:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.7959 7.54597L9.7959 19.546C9.69138 19.6509 9.56719 19.7341 9.43044 19.7909C9.2937 19.8476 9.14709 19.8769 8.99902 19.8769C8.85096 19.8769 8.70435 19.8476 8.5676 19.7909C8.43085 19.7341 8.30666 19.6509 8.20215 19.546L2.95215 14.296C2.8475 14.1913 2.76449 14.0671 2.70785 13.9304C2.65122 13.7936 2.62207 13.6471 2.62207 13.4991C2.62207 13.3511 2.65122 13.2046 2.70785 13.0678C2.76449 12.9311 2.8475 12.8069 2.95215 12.7022C3.05679 12.5976 3.18103 12.5146 3.31776 12.4579C3.45448 12.4013 3.60103 12.3721 3.74902 12.3721C3.89702 12.3721 4.04356 12.4013 4.18029 12.4579C4.31702 12.5146 4.44125 12.5976 4.5459 12.7022L8.99996 17.1563L20.204 5.9541C20.4154 5.74276 20.702 5.62402 21.0009 5.62402C21.2998 5.62402 21.5864 5.74276 21.7978 5.9541C22.0091 6.16544 22.1278 6.45209 22.1278 6.75098C22.1278 7.04986 22.0091 7.33651 21.7978 7.54785L21.7959 7.54597Z"
      fill={color}/>
  </svg>
)