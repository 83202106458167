import snakecaseKeys from 'snakecase-keys'
export class PutSystemUserByIdQuery {
  query = ({ id, data }: PutSystemUserPayload) => {
    //@ts-ignore
    const preparedProfile = { ...snakecaseKeys(data), image: data.image?.id }
    return {
      url: `users/${id}`,
      method: 'PUT',
      body: { profile: { ...preparedProfile } }
    }
  }
}

export type PutSystemUserPayload = {
  id: number | string
  data: Record<string, unknown>
}