import React from 'react'
import { IIcon } from './IIcon'

export const UserCircle:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 25 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.625 11.25C16.625 12.0658 16.3831 12.8634 15.9298 13.5417C15.4766 14.2201 14.8323 14.7488 14.0786 15.061C13.3248 15.3732 12.4954 15.4549 11.6953 15.2957C10.8951 15.1366 10.1601 14.7437 9.58319 14.1668C9.0063 13.5899 8.61343 12.8549 8.45427 12.0547C8.2951 11.2546 8.37679 10.4252 8.689 9.67143C9.00121 8.91769 9.52992 8.27345 10.2083 7.82019C10.8866 7.36693 11.6842 7.125 12.5 7.125C13.5936 7.12624 14.6421 7.56124 15.4154 8.33455C16.1888 9.10787 16.6238 10.1564 16.625 11.25ZM22.25 12C22.25 13.9284 21.6782 15.8134 20.6068 17.4168C19.5355 19.0202 18.0127 20.2699 16.2312 21.0078C14.4496 21.7458 12.4892 21.9389 10.5979 21.5627C8.70656 21.1865 6.96928 20.2579 5.60571 18.8943C4.24215 17.5307 3.31355 15.7934 2.93735 13.9021C2.56114 12.0108 2.75422 10.0504 3.49218 8.26884C4.23013 6.48726 5.47982 4.96451 7.08319 3.89317C8.68657 2.82183 10.5716 2.25 12.5 2.25C15.085 2.25273 17.5634 3.28084 19.3913 5.10872C21.2192 6.93661 22.2473 9.41498 22.25 12ZM20.75 12C20.7488 10.8896 20.5237 9.79077 20.0881 8.76934C19.6525 7.7479 19.0154 6.82481 18.2148 6.05525C17.4143 5.2857 16.4668 4.68549 15.429 4.29053C14.3911 3.89556 13.2843 3.71395 12.1747 3.75656C7.75907 3.92719 4.23782 7.605 4.25 12.0234C4.25424 14.0349 4.99609 15.9749 6.335 17.4759C6.88028 16.6851 7.57292 16.0068 8.375 15.4781C8.44339 15.433 8.52469 15.4114 8.60646 15.4169C8.68824 15.4223 8.76599 15.4543 8.82782 15.5081C9.84705 16.3897 11.1496 16.8749 12.4972 16.8749C13.8448 16.8749 15.1473 16.3897 16.1666 15.5081C16.2284 15.4543 16.3061 15.4223 16.3879 15.4169C16.4697 15.4114 16.551 15.433 16.6194 15.4781C17.4225 16.0065 18.1161 16.6848 18.6622 17.4759C20.0077 15.9694 20.751 14.0199 20.75 12Z"
      fill={color}/>
  </svg>
)