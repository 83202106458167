import { ProjectUserAdapter } from './ProjectUserAdapter'
import { IChatUser } from '../../models/IUser'

import QueryString from 'query-string'
import snakecaseKeys from 'snakecase-keys'

type GetProjectUserByIdQueryParam = {
  projectId: number
  ids: number[]
}
export class GetProjectUsersByIdsQuery {
  query = ({ projectId, ids }: GetProjectUserByIdQueryParam) => {
    const param = QueryString.stringify(
      snakecaseKeys({ projectId, id__in: ids }, { exclude: ['id__in'] }),
      { arrayFormat: 'comma' }
    )

    return `projects/${projectId}/find_users?${param}`
  }

  transformResponse = (response: IChatUser[]) => {
    return response.map(user => new ProjectUserAdapter(user).getFields())
  }
}