export const SET_DOCUMENTS_LOADING_START = 'SET_DOCUMENTS_LOADING_START'
export const GET_DOCUMENTS_LIST_SUCCESS = 'GET_DOCUMENTS_LIST_SUCCESS'
export const GET_DOCUMENTS_LIST_FAILED = 'GET_DOCUMENTS_LIST_FAILED'

export const SET_LOADING_POST_NEW_DOCUMENT = 'SET_LOADING_POST_NEW_DOCUMENT'
export const POST_NEW_DOCUMENT_SUCCESS = 'POST_NEW_DOCUMENT_SUCCESS'
export const POST_NEW_DOCUMENT_FAILED = 'POST_NEW_DOCUMENT_FAILED'

export const PUT_DOCUMENT_STATUS_SUCCEED = 'PUT_DOCUMENT_STATUS_SUCCEED'

export const GET_DOCUMENT_STATUSES_SUCCEED = 'GET_DOCUMENT_STATUSES_SUCCEED'

export const PUT_FLOW_DOCUMENT_SUCCESS = 'PUT_FLOW_DOCUMENT_SUCCESS'