import React from 'react'
import { IIcon } from './IIcon'

export const ChevronPagination: React.FC<IIcon> = ({
  width = '3.4',
  height = '3.4',
  color = '#5C5C5C',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z" fill={color}/>
  </svg>
)