import { get, put, post, postImage, deleteRequest } from '../../utils/axios'
import {
  getProjectSuccess,
  getProjectFailed,
  setLoadingProjectStart,
  putProjectInfoSuccess,
  postProjectEmloyeesSuccess,
  setProjectImagesLoading,
  getProjectImagesSuccess,
  getProjectImagesFailed,
  postProjectImagesFailed,
  deleteProjectImageSuccess,
  getProjectCustomerImage,
  getProjectDocumentsSuccess,
  getProjectDocumentsFailed,
  setProjectDocumentsLoading,
  setLoadingPostProject,
  postProjectSuccess,
  postProjectFailed,
  setLoadingProjectMembers,
  getProjectMembersSuccess,
  getProjectMembersFailed,
  putProjectMembersSuccess,
  putProjectMembersFailed,
  deleteProjectMemberSuccess,
  deleteProjectDocumentSuccess,
  setLoadingGetProjectSigners,
  getProjectSignersSuccess,
  getProjectSignersFailed,
  setLoadingGetProjectWorker,
  getProjectWorkerSuccess,
  getProjectWorkerFailed,
  setLoadingGetProjectExecutor,
  getProjectExecutorSuccess,
  getProjectExecutorFailed,
  getBusyWorkersSuccess,
  postCompanyToProjectSuccess,
  deleteCompanyFromProjectSuccess,
  putCompanyProjectSuccess,
  getProjectCompanySignersSuccess,
  putProjectCompanySignersSuccess,
  setMetricsProject,
  toggleLoadingMetric,
  getOrderDistributorUsersSuccess,
  setAddedCompanies,
} from './actionsCreater'
import { addedNewNotification } from '../notifications/actions'
import { setActiveProject } from '../app/actionCreators'
import { getMoveMembersFromProjectList } from './actionsCreater'
import { getProjectId, setProjectExtended } from '../../utils/localStorageManagement'
import { normalizeDataProfile } from '../../utils/normalizeDataProfile'
import { snakecaseFields } from '../../utils/formatFields'
import { getAllProjectUsers } from '../userProject/actions'
import { getUserRoleInProject } from '../user/actions'


export const settingActiveProject = (projectId) => (
  dispatch => {
    dispatch(getUserRoleInProject({ projectId }))
    dispatch(setActiveProject(projectId))
    dispatch(getMoveMembersFromProjectList(projectId))
  }
)

export const getProjectsMy = (haveActiveProject = false) => (
  dispatch => {
    dispatch(setLoadingProjectStart())
    get({ path: '/projects' })
      .then(res => {
        dispatch(getProjectSuccess(res))
        res.length && !haveActiveProject && (
          dispatch(settingActiveProject(
            res.find(({ id }) => id === Number(getProjectId()))
              ? Number(getProjectId())
              : res[0].id
          ))
        )
      })
      .catch(() => dispatch(getProjectFailed()))
  }
)

export const getProjects = () => (
  dispatch => {
    get({ path: '/projects' })
      .then(res => dispatch(getProjectSuccess(res)))
      .catch(() => {
        dispatch(getProjectFailed())
        dispatch(addedNewNotification({ message: 'Не удалось загрузить проекты' }))
      })
  }
)

export const getProjectImages = (id) => (
  dispatch => {
    dispatch(setProjectImagesLoading())
    get({ path: `/projects/${id}/images`, isCancel: true })
      .then(data => dispatch(getProjectImagesSuccess(data)))
      .catch(() => dispatch(getProjectImagesFailed()))
  }
)

export const getProjectDocuments = (projectId) => (
  dispatch => {
    dispatch(setProjectDocumentsLoading())
    get({ path: `/resource_documents/projects/${projectId}` })
      .then(data => dispatch(getProjectDocumentsSuccess(data)))
      .catch(() => dispatch(getProjectDocumentsFailed()))
  }
)

export const putProjectInfo = (id, data, callback = () => { }) => (
  dispatch => {
    put(`/projects/${id}`, data)
      .then((data) => {
        dispatch(putProjectInfoSuccess(data))
        callback(true)
      })
      .catch(() => {
        dispatch(addedNewNotification({ message: 'Изменения не могут быть сохранены. Попробуйте позже' }))
        callback(false)
      })
  }
)

export const postProjectImages = (files, id) => (
  dispatch => {
    Promise.allSettled(Object.keys(files).map(key => postImage(files[key])))
      .then(data => post(`/projects/${id}/images`, {
        images: data.map(({ value }) => value.id)
      }))
      .then((data) => {
        dispatch(getProjectImagesSuccess(data.images))
      })
      .catch(() => dispatch(postProjectImagesFailed()))
  }
)

export const deleteProjectDocument = (id) => (
  dispatch => {
    deleteRequest(`/resource_documents/${id}`)
      .then(() => {
        dispatch(deleteProjectDocumentSuccess(id))
        dispatch(addedNewNotification({ message: 'Документ успешно удален' }))
      })
      .catch(() => dispatch(addedNewNotification({ message: 'Документ не может быть удален' })))
  }
)

export const getProjectMembers = (id) => (
  dispatch => {
    dispatch(setLoadingProjectMembers())
    get({ path: `/projects/${id}/companies` })
      .then(data => dispatch(getProjectMembersSuccess(data)))
      .catch(() => {
        dispatch(getProjectMembersFailed())
        dispatch(addedNewNotification({ message: 'Не удалось загрузить участников проекта' }))
      })
  }
)

export const putProjectMembers = (
  id,
  membersListId,
  textNotification = {
    success: 'Участники добавлены успешно',
    error: 'Не удалось добавить участников проекта'
  }
) => (
  dispatch => {
    dispatch(setLoadingProjectMembers())
    put(`/projects/${id}/members`, snakecaseFields({ members: membersListId }))
      .then((data) => {
        dispatch(putProjectMembersSuccess(data))
        dispatch(addedNewNotification({ message: textNotification.success }))
        // dispatch(getProjectMembers(id))
      })
      .catch(() => {
        dispatch(putProjectMembersFailed())
        dispatch(addedNewNotification({ message: textNotification.error }))
      })
  }
)

export const deleteProjectMember = (id, memberId) => (
  dispatch => {
    deleteRequest(`/projects/${id}/companies/${memberId}`)
      .then(() => {
        dispatch(deleteProjectMemberSuccess(memberId))
        dispatch(addedNewNotification({ message: 'Участник удален успешно' }))
      })
      .catch(() => addedNewNotification({ message: 'Не удалось удалить участника' }))
  }
)

export const postProjectEmployee = (data) => (
  dispatch => {
    post('/project', data)
      .then(({ data }) => {
        dispatch(postProjectEmloyeesSuccess(data))
      })
  }
)

export const postProjectCompany = (data, callback) => (
  dispatch => {
    post('/project', data)
      .then(({ data }) => dispatch(postProjectEmloyeesSuccess(data)))
      .then(callback())
  }
)

export const deleteProjectImage = (projectId, imageId) => (
  dispatch => {
    deleteRequest(`/projects/${projectId}/images/${imageId}`)
      .then(() => {
        dispatch(deleteProjectImageSuccess(imageId))
        dispatch(addedNewNotification({
          message: 'Изображение успешно удалено'
        }))
      })
      .catch(() => dispatch(addedNewNotification({
        message: 'Изображение не может быть удалено. Повторите позже.'
      })))
  }
)

export const getCustomerImage = (id) => (
  dispatch => {
    get({ path: `/images/${id}` })
      .then(data => dispatch(getProjectCustomerImage(data)))
  }
)

export const postNewProject = (dataProject, callback) => (
  dispatch => {
    dispatch(setLoadingPostProject())
    post('/projects', dataProject)
      .then(data => {
        dispatch(postProjectSuccess(data))
        dispatch(settingActiveProject(data.id))
        setProjectExtended({ userId: data.projectManager.id, projectId: data.id })
        callback()
      })
      .catch((data) => {
        dispatch(postProjectFailed(data))
        dispatch(addedNewNotification({ message: 'Проект не может быть создан' }))
      })
  }
)

export const getProjectSigners = (companies) => (
  dispatch => {
    const GET_PROJECT_SIGNERS_PARAMS = {
      email: true,
      first_name: true,
      company: companies.join(',')
    }
    dispatch(setLoadingGetProjectSigners())
    get({ path: '/users', params: GET_PROJECT_SIGNERS_PARAMS })
      .then((data) => dispatch(
        getProjectSignersSuccess(
          data.map(user => normalizeDataProfile(user))
        )
      ))
      .catch(() => dispatch(getProjectSignersFailed()))
  }
)

export const getProjectWorker = (companies) => (
  dispatch => {
    const GET_PROJECT_WORKER_PARAMS = {
      'filter{email.isnull}': true,
      'filter{profile|first_name.isnull}': false,
      'filter{profile|company.id.in}': companies
    }
    dispatch(setLoadingGetProjectWorker())
    get({ path: '/users', params: GET_PROJECT_WORKER_PARAMS })
      .then(data => {
        //normilizeData
        const normalizeData = data.users.map(user => normalizeDataProfile(user))
        dispatch(getProjectWorkerSuccess(normalizeData))
      })
      .catch(() => dispatch(getProjectWorkerFailed()))
  }
)

export const getProjectExecutor = (projectId) => (
  dispatch => {
    const GET_PROJECT_EXECUTOR_PARAMS = {
      'filter{role}': 'Исполнитель работ'
    }
    dispatch(setLoadingGetProjectExecutor())
    get({ path: `/projects/${projectId}/members`, params: GET_PROJECT_EXECUTOR_PARAMS })
      .then(data => dispatch(getProjectExecutorSuccess(
        data.memberships.map(({ role, user }) => ({ ...user, role }))
      )))
      .catch(() => dispatch(getProjectExecutorFailed()))
  }
)

export const getBusyWorkers = (projectId, range_time, workersId, jobTypeId) => (
  dispatch => {
    workersId.forEach(id => {
      get({ path: `/users/${id}/check_users_busyness/${projectId}`, params: range_time })
        .then(data => {
          if (!!data.length) {
            const admOrderWithBusyWorker = data.find(
              admOrder => admOrder.jobType == jobTypeId &&
                !['Отозван', 'Не согласован', 'Закрыт'].includes(admOrder.status)
            )
            admOrderWithBusyWorker && dispatch(getBusyWorkersSuccess({ id, title: `"${admOrderWithBusyWorker.title}"` }))
          }
        })
        .catch((e) => {
          dispatch(addedNewNotification(
            { message: 'Не могу проверить сотрудника на задействованность в других наряд допусках' }
          ))
        })
    })
  }
)

export const postCompanyToProject = (projectId, company, client, callback) => (
  dispatch => {
    post(`/projects/${projectId}/companies`,
      { project_companies: [{ company, client, project: projectId }] }
    )
      .then(data => {
        dispatch(postCompanyToProjectSuccess(data.projectCompanies[0]))
        dispatch(getAllProjectUsers(projectId))
        callback?.(data.projectCompanies[0])
      })
      .catch(() => dispatch(addedNewNotification({ message: 'Компания не может быть добавлена к проекту' })))
  }
)

export const deleteCompanyFromProject = (projectId, projectCompany, callback) => (
  dispatch => {
    deleteRequest(`/projects/${projectCompany}/project_company`)
      .then(() => {
        dispatch(deleteCompanyFromProjectSuccess({ projectId, projectCompany }))
        dispatch(getAllProjectUsers(projectId))
      })
      .catch(() => dispatch(addedNewNotification({ message: 'Компания не может быть удалена' })))
  }
)

export const putCompanyProject = (project, projectCompany, company, client) => (
  dispatch => {
    put(`/projects/${projectCompany}/project_company`, { project, company, client })
      .then(data => dispatch(putCompanyProjectSuccess(data)))
      .catch(() => dispatch(addedNewNotification({ message: 'Компания не может быть перенесена' })))
  }
)

export const getProjectCompanySigners = (projectId, companyId) => (
  dispatch => {
    get({ path: `/projects/${projectId}/companies/${companyId}` })
      .then(data => {
        dispatch(getProjectCompanySignersSuccess({
          orderDistributor: data.orderDistributor?.id || null,
          signers: data.signers
        }))
      })
      .catch(() => dispatch(addedNewNotification({ message: 'Ответственные за согласование мероприятий не могут быть загружены' })))
  }
)

export const putProjectCompanySigners = (projectId, companyId, params, callback) => (
  dispatch => {
    put(`/projects/${projectId}/companies/${companyId}`, params)
      .then((data) => {
        dispatch(putProjectCompanySignersSuccess({
          orderDistributor: data.orderDistributor?.id || null,
          signers: data.signers
        }))
        callback(true)
      })
      .catch(() => {
        dispatch(addedNewNotification({ message: 'Список ответственных за согласование не может быть изменен' }))
        callback(false)
      })
  }
)

export const getMetricsProject = (projectId) => (
  dispatch => {
    dispatch(toggleLoadingMetric())
    get({ path: `/projects/${projectId}/statistics` })
      .then((data) => {
        dispatch(setMetricsProject(data.data))
      })
      .catch(() => {
        dispatch(addedNewNotification({ message: 'Не удалось загрузить статистику' }))
      })
      .finally(() => {
        dispatch(toggleLoadingMetric())
      })
  }
)

export const getOrderDistributorUsers = (projectId) => (
  dispatch => {
    get({ path: `/projects/${projectId}/find_users` })
      .then((data) => {
        dispatch(getOrderDistributorUsersSuccess(data))
      })
      .catch(() => {
        dispatch(addedNewNotification({ message: 'Не удалось загрузить список ответственных за выдачу нарядов-допусков' }))
      })
  }
)

export const getAddedCompanies = (projectId) => (
  async dispatch => {
    try {
      const addedCompanies = await get({ path: `/projects/${projectId}/companies` })
      dispatch(setAddedCompanies(addedCompanies))
    } catch {
      dispatch(addedNewNotification({ message: 'Не удалось загрузить участников проекта' }))
    }
  }
)
