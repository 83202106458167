import React from 'react'
import { IIcon } from './IIcon'

export const BigStar: React.FC<IIcon> = ({
  width = '3',
  height = '3',
  color = '#1A65DA',
  opacity = '0.5',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 30 30" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 21.5875L22.725 26.25L20.675 17.4625L27.5 11.55L18.5125 10.7875L15 2.5L11.4875 10.7875L2.5 11.55L9.325 17.4625L7.275 26.25L15 21.5875Z"
      fill={color} fillOpacity={opacity}/>
  </svg>
)