import { WS_CONNECT_SUCCESS, WS_DISCONNECT_SUCCESS, WS_INCOMING_MESSAGE } from "../../utils/websockets/actionTypes/actionTypes";

// CONNECT
export const WS_CHAT_CHANNELS_CONNECT_SUCCESS = WS_CONNECT_SUCCESS.concat('_CHAT')
export const WS_CHAT_CHANNELS_DISCONNECT_SUCCESS = WS_DISCONNECT_SUCCESS.concat('_CHAT')

//INCOMMING MESSAGES 
export const INCOMING_MESSAGE_WS_CHAT_CHANNELS_CONNECT = WS_INCOMING_MESSAGE.concat('_CHAT_CONNECT')
export const INCOMING_MESSAGE_WS_CHAT_CHANNELS_CREATE_CHAT = WS_INCOMING_MESSAGE.concat('_CHAT_CREATE_CHAT')
export const INCOMING_MESSAGE_WS_CHAT_CHANNELS_SEND_CHAT_MESSAGE = WS_INCOMING_MESSAGE.concat('_CHAT_SEND_CHAT_MESSAGE')
export const INCOMING_MESSAGE_WS_CHAT_CHANNELS_UPDATE_CHAT_MESSAGES_COUNT = WS_INCOMING_MESSAGE.concat('_CHAT_UPDATE_CHAT_MESSAGES_COUNT')
export const INICOMING_MESSAGE_WS_CHAT_CHANNELS_PIN_MESSAGE = WS_INCOMING_MESSAGE.concat('_CHAT_PIN_MESSAGE')

// CONNECT
export const WS_CHAT_MESSAGES_CREATE_CONNECT_SUCCESS = WS_CONNECT_SUCCESS.concat('_CHAT_MESSAGES')
export const WS_CHAT_MESSAGES_DISCONNECT_SUCCESS = WS_DISCONNECT_SUCCESS.concat('_CHAT_MESSAGES')

// INCOMMING MESSAGES
export const INCOMING_MESSAGE_CHAT_MESSAGES_CONNECT = WS_INCOMING_MESSAGE.concat('_CHAT_MESSAGES_CONNECT')
export const INCOMING_MESSAGE_CHAT_MESSAGES_LIST_MESSAGES = WS_INCOMING_MESSAGE.concat('_CHAT_MESSAGES_LIST_MESSAGES')
export const INCOMING_MESSAGE_CHAT_MESSAGES_SEND_CHAT_MESSAGE = WS_INCOMING_MESSAGE.concat('_CHAT_MESSAGES_SEND_CHAT_MESSAGE')
export const INCOMING_MESSAGE_CHAT_MESSAGES_SEARCH_MESSAGES_BY_TEXT = WS_INCOMING_MESSAGE.concat('_CHAT_MESSAGES_SEARCH_MESSAGES_BY_TEXT')
export const INCOMING_MESSAGE_CHAT_MESSAGES_PIN_MESSAGE = WS_INCOMING_MESSAGE.concat('_CHAT_MESSAGES_PIN_MESSAGE')
export const INCOMING_MESSAGE_CHAT_MESSAGES_ADD_TO_CHAT_ROOM = WS_INCOMING_MESSAGE.concat('_CHAT_MESSAGES_ADD_TO_CHAT_ROOM')

// OTHER
export const SET_ACTIVE_CHAT_ID = 'SET_ACTIVE_CHAT_ID'
export const CLEAR_MESSAGES = 'CHAT_CLEAR_MESSAGES'
export const CLEAR_OPEN_CHANNELS = 'CLEAR_OPEN_CHANNELS'
export const SET_IS_LOADING_MESSAGES_PAGE = 'SET_IS_LOADING_MESSAGES_PAGE'
export const SET_IS_LOADING_OPEN_CHANNELS = 'SET_IS_LOADING_OPEN_CHANNELS'
export const GET_FIND_USERS_CHAT = 'GET_FIND_USERS_CHAT'
export const FIND_USERS_CHAT_SUCCESS = 'FIND_USERS_CHAT_SUCCESS'
export const FIND_USER_CHAT_ERROR = 'FIND_USER_CHAT_ERROR'
export const TOGGLE_LOADING_FIND_USERS_CHAT = 'TOGGLE_LOADING_FIND_USERS_CHAT'
export const CLEAR_FIND_USERS_CHAT = 'CLEAR_FIND_USERS_CHAT'

