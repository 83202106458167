export const getArrayWithUniqueObj = <T extends { id: number }>(messages: Array<T>) => {
    const uniqueIds = new Set()
    let uniqueObjectsArray: Array<T> = []
    Object.values(messages).forEach(value => {
      if (!uniqueIds.has(value.id)) {
        uniqueIds.add(value.id)
        uniqueObjectsArray.push(value)
      }
    })
    return uniqueObjectsArray
}
