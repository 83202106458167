import {
  CLEAR_ALL_ACCESS_TICKETS,
  GET_ACCESS_TICKETS_CAR_SUCCESS,
  GET_ACCESS_TICKETS_EMPLOYEE_SUCCESS,
  POST_CREATE_ACCESS_TICKETS_CAR_SUCCESS,
  POST_CREATE_ACCESS_TICKETS_EMPLOYEE_SUCCESS,
  TOGGLE_LOADING_GET_ACCESS_TICKETS_CAR,
  TOGGLE_LOADING_GET_ACCESS_TICKETS_EMPLOYEE,
  TOGGLE_LOADING_POST_CREATE_ACCESS_TICKETS_CAR,
  TOGGLE_LOADING_POST_CREATE_ACCESS_TICKETS_EMPLOYEE,
  ADD_NEW_ACCESS_TICKET_CAR,
  ADD_NEW_ACCESS_TICKET_EMPLOYEE,
  TICKET_UPDATED_SUCCESSFULLY
} from "./constant"

const initialState = {
  accessTicketsCar: [],
  countAccessTicketsCar: null,
  accessTicketsEmployee: [],
  countAccessTicketsEmployee: null,
  isLoadingTicketsAccessCar: false,
  isLoadingTicketsAccessEmployee: false,
  isLoadingCreateAccessTicketCar: false,
  isLoadingCreateAccessTicketEmployee: false,
}

export const kppReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case GET_ACCESS_TICKETS_CAR_SUCCESS:
      return {
        ...state,
        accessTicketsCar: [...state.accessTicketsCar, ...payload.results],
        countAccessTicketsCar: payload.count
      }
    case GET_ACCESS_TICKETS_EMPLOYEE_SUCCESS:
      return {
        ...state,
        accessTicketsEmployee: [...state.accessTicketsEmployee, ...payload.results],
        countAccessTicketsEmployee: payload.count
      }


    case TOGGLE_LOADING_GET_ACCESS_TICKETS_CAR:
      return { ...state, isLoadingTicketsAccessCar: !state.isLoadingTicketsAccessCar }
    case TOGGLE_LOADING_GET_ACCESS_TICKETS_EMPLOYEE:
      return { ...state, isLoadingTicketsAccessEmployee: !state.isLoadingTicketsAccessEmployee }

    case CLEAR_ALL_ACCESS_TICKETS:
      return { ...state, accessTicketsCar: [], accessTicketsEmployee: [] }

    case POST_CREATE_ACCESS_TICKETS_CAR_SUCCESS:
      return { ...state, accessTicketsCar: [payload, ...state.accessTicketsCar] }
    case POST_CREATE_ACCESS_TICKETS_EMPLOYEE_SUCCESS:
      return { ...state, accessTicketsEmployee: [payload, ...state.accessTicketsEmployee] }

    case TOGGLE_LOADING_POST_CREATE_ACCESS_TICKETS_CAR:
      return { ...state, isLoadingCreateAccessTicketCar: !state.isLoadingCreateAccessTicketCar }
    case TOGGLE_LOADING_POST_CREATE_ACCESS_TICKETS_EMPLOYEE:
      return { ...state, isLoadingCreateAccessTicketEmployee: !state.isLoadingCreateAccessTicketEmployee }

    case ADD_NEW_ACCESS_TICKET_CAR:
      const newAccessTicketsCar = state.accessTicketsCar.length % 20 === 0  // 20-кол-во тикетов на странице
        ? [payload, ...state.accessTicketsCar.slice(0, state.accessTicketsCar.length - 1)]
        : [payload, ...state.accessTicketsCar]

      return {
        ...state,
        countAccessTicketsCar: state.countAccessTicketsCar === null ? 1 : state.countAccessTicketsCar + 1,
        accessTicketsCar: newAccessTicketsCar
      }

    case TICKET_UPDATED_SUCCESSFULLY:

      if (!payload.isEmployee) {
        const carTickets = state.accessTicketsCar.length % 20 === 0  // 20-кол-во тикетов на странице
          ? [payload.data, ...state.accessTicketsCar.filter(ticket => ticket.id !== payload.data.id).slice(0, state.accessTicketsCar.length - 1)]
          : [payload.data, ...state.accessTicketsCar.filter(ticket => ticket.id !== payload.data.id)]

        return {
          ...state,
          accessTicketsCar: carTickets
        }
      }

      const employeeTickets = state.accessTicketsEmployee.length % 20 === 0  // 20-кол-во тикетов на странице
        ? [payload.data, ...state.accessTicketsEmployee.filter(ticket => ticket.id !== payload.data.id).slice(0, state.accessTicketsEmployee.length - 1)]
        : [payload.data, ...state.accessTicketsEmployee.filter(ticket => ticket.id !== payload.data.id)]

      return {
        ...state,
        accessTicketsEmployee: employeeTickets
      }

    case ADD_NEW_ACCESS_TICKET_EMPLOYEE:
      const newAccessTicketsEmployee = state.accessTicketsEmployee.length % 20 === 0 // 20 кол-во тикетов на странице
        ? [payload, ...state.accessTicketsEmployee.slice(0, state.accessTicketsEmployee.length - 1)]
        : [payload, ...state.accessTicketsEmployee]

      return {
        ...state,
        countAccessTicketsEmployee: state.countAccessTicketsEmployee === null ? 1 : state.countAccessTicketsEmployee + 1,
        accessTicketsEmployee: newAccessTicketsEmployee
      }

    default:
      return state
  }
}