import React from 'react'
import { useFormik } from 'formik'
import { Button, HoverContainer, Modal } from '../../../../../../../fsd/shared/ui-component'
import { Close } from '../../../../../../../fsd/shared/ui-icons'
import TemplateTextField from '../../../../../pages/DocumentTemplate/TemplateFormFields/TemplateTextField/TemplateTextField'
import * as yup from 'yup'
import './ModalRejectProjectInvitation.scss'

export const ModalRejectProjectInvitation:React.FC<PropsType> = ({
  isOpen,
  onClose,
  onSend
}) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    handleSubmit
  } = useFormik({
    initialValues : {
      rejectComment: ''
    },
    validationSchema: yup.object({
      rejectComment: yup
        .string()
        .required('Заполните комментарий')
        .test({
          message: 'Комментарий не может быть пустым',
          test: (comment: unknown) => Boolean(String(comment || '').trim()) 
        })
    }),
    onSubmit: () => {
      onSend({ decision: 'reject', comment: values.rejectComment })
    }
  })

  const modalOnClose = () => {
    onClose()
    resetForm()
  }

  return (
    <Modal
      open={isOpen}
      onCancel={modalOnClose}
      className='project-invitation-reject-modal'
    >
      <div>
        <div className='project-invitation-reject-modal-header'>
          <h2>Комментарий</h2>
          <HoverContainer onClick={modalOnClose}>
            <Close />
          </HoverContainer>
        </div>

        <TemplateTextField 
          placeholder='Почему вы отказываетесь от приглашения?'
          name='rejectComment'
          title='rejectComment'
          value={values.rejectComment}
          touched={touched.rejectComment}
          error={errors.rejectComment}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
        />

        <Button
          title='Отправить'
          customClassName='btn__send'
          onClick={() => handleSubmit()}
        />
      </div>
    </Modal>
  )
}

type PropsType = {
  isOpen: boolean
  onClose: () => void
  onSend: (args: { decision: 'approve' | 'reject', comment: string | null}) => void
}