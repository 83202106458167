import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IProjectUserModel } from '../../api/projectUserApi/ProjectUserAdapter'

export type AllowedSignerType = {
  allowedToSignUsers: IProjectUserModel[]
  isSignersLimited: boolean
  isAuthor: boolean
}

export type AllowedSignersMap = {
  [k: string]: AllowedSignerType
}

export type AllowedSigners = {
  usersMap: AllowedSignersMap
}

const initialState: AllowedSigners = {
  usersMap: {},
}

export const templateSignerSettingsSlice = createSlice({
  name: 'allowedSigners',
  initialState,
  reducers: {
    deleteSignerSettings(state, { payload: cssSelector }: PayloadAction<string>) {
      delete state.usersMap[cssSelector]
    },
    createAllowedSigner(state, { payload }: PayloadAction<{ cssSelector: string, signer: AllowedSignerType }>) {
      const { cssSelector, signer } = payload
      state.usersMap[cssSelector] = { ...signer }
    },
    toggleIsSignersLimited(state, { payload }: PayloadAction<string>) {
      if (!state.usersMap[payload])
        return

      state.usersMap[payload].isSignersLimited = !state.usersMap[payload].isSignersLimited

      if (state.usersMap[payload].isSignersLimited)
        state.usersMap[payload].isAuthor = false
    },
    toggleIsSignerAuthor(state, { payload }: PayloadAction<string>) {
      if (!state.usersMap[payload]) return
      state.usersMap[payload].isAuthor = !state.usersMap[payload].isAuthor

      if (state.usersMap[payload].isAuthor)
        state.usersMap[payload].isSignersLimited = false
    },
    addAllowedSigner(state, { payload }: PayloadAction<{ cssSelector: string, signer: AllowedSignerType['allowedToSignUsers'][number] }>) {
      const { cssSelector, signer } = payload
      if (!state.usersMap[cssSelector]) return
      state.usersMap[cssSelector].allowedToSignUsers = [signer, ...state.usersMap[cssSelector].allowedToSignUsers]
    },
    removeAllowedSigner(state, { payload }: PayloadAction<{ cssSelector: string, id: number }>) {
      const { cssSelector, id } = payload
      if (!state.usersMap[cssSelector]) return
      const allowedUsersIds = state.usersMap[cssSelector].allowedToSignUsers
      state.usersMap[cssSelector].allowedToSignUsers = allowedUsersIds.filter(signer => signer.id !== id)
    },
    resetAllowedSigners(state) {
      state.usersMap = {}
    },
    resetSigner() {

    }
  }
})

export const {
  deleteSignerSettings,
  toggleIsSignersLimited,
  addAllowedSigner,
  removeAllowedSigner,
  resetAllowedSigners,
  toggleIsSignerAuthor,
  createAllowedSigner
} = templateSignerSettingsSlice.actions

export default templateSignerSettingsSlice.reducer