import React from 'react'
import { SpinnerLoader } from '../../../shared/ui-component/SpinnerLoader/SpinnerLoader'
import { Check } from '../../../shared/ui-icons/Check'
import { WarningCircle } from '../../../shared/ui-icons/WarningCircle'
import { StatusFileUpload } from '../types/types'

export const fileFormats = {
  images: '.png, .jpg, .jpeg, .svg, .webp',
  docs: '.doc, .docx, .pdf',
  excel: '.xlsx, .xls',
}

export const FILE_LOADING_ICON: { [k in StatusFileUpload]: React.ReactNode } = {
  'loading': <SpinnerLoader />,
  'success': <Check color='#1D9F55' />,
  'error': <WarningCircle />,
  'pending': undefined
}