import * as types from './constants'

const initialState = {
  info: {}
}

export const adminReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case types.GET_ADMIN_INFO_SUCCESS:
      return {
        ...state,
        info: payload
      }

    default: 
      return { ...state }
  }
}