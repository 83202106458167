import React from 'react'
import cn from 'classnames'

import './HoverContainer.scss'

export const HoverContainer: React.FC<HoverContainerPropType> = ({
  color = '#F2F2F2',
  className,
  alwaysActive = false,
  disable = false,
  onClick,
  children
}) => (
  <div
    className={cn('hover-container', className, {
      active: alwaysActive,
      disable
    })}
    onClick={(e) => onClick?.(e)}
  >
    <div className='hover-item' style={{ backgroundColor: color }} />
    <div className='hover-child'>{children}</div>
  </div>
)

type HoverContainerPropType = {
  color?: string,
  className?: string,
  alwaysActive?: boolean,
  disable?: boolean,
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  children?: React.ReactNode
}