import { getArrayWithUniqueObj } from '../../utils/getArrayWithUniqueObj'
import * as types from './types'

const initialState = {
  openChannels: [], //смысловая ошибка - это доступные чаты
  isLoadingOpenChannels: false,
  activeChanneInfo: null,
  activeChatId: null,
  messages: null,
  isLoadingMessagesPage: false,
  countMessages: null,
  pinMessage: null,
  lastReadMessageId: null, // !== полю в ласт рид мессадже при кодключении к чату
  projectUsers: [],
  totalPagesProjectUsers: null,
  isLoadingProjectUsers: false,
  netWork: {
    openChannelsConnections: null,
    activeChannelConnections: null,
  }
}

export const chatReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // Connect
    case types.WS_CHAT_CHANNELS_CONNECT_SUCCESS:
      return { ...state, netWork: { ...state.netWork, openChannelsConnections: true } }
    case types.WS_CHAT_CHANNELS_DISCONNECT_SUCCESS:
      return { ...state, netWork: { ...state.netWork, openChannelsConnections: false } }

    // Messages
    // INCOMING
    case types.INCOMING_MESSAGE_WS_CHAT_CHANNELS_CONNECT:
      return {
        ...state,
        openChannels: payload,
        isLoadingOpenChannels: false
      }
    case types.INCOMING_MESSAGE_WS_CHAT_CHANNELS_CREATE_CHAT:
      return { ...state, openChannels: [payload, ...state.openChannels] }
    case types.INCOMING_MESSAGE_WS_CHAT_CHANNELS_SEND_CHAT_MESSAGE:
      return {
        ...state,
        openChannels: state.openChannels.reduce((accum, channel) => (
          channel.id !== payload.id ? [...accum, channel] : [payload, ...accum]
        ), [])
      }
    case types.INCOMING_MESSAGE_WS_CHAT_CHANNELS_UPDATE_CHAT_MESSAGES_COUNT:
      return {
        ...state,
        openChannels: state.openChannels.map(channel => channel.id !== payload.id ? channel : payload)
      }
    case types.INICOMING_MESSAGE_WS_CHAT_CHANNELS_PIN_MESSAGE:
      return {
        ...state,
        openChannels: payload.isPinned
          ? state.openChannels.map(channel => channel.id !== payload.id ? channel : payload)
          : state.openChannels
      }

    // Connect
    case types.WS_CHAT_MESSAGES_CREATE_CONNECT_SUCCESS:
      return {
        ...state,
        netWork: { ...state.netWork, activeChannelConnections: true }
      }
    case types.WS_CHAT_MESSAGES_DISCONNECT_SUCCESS:
      return {
        ...state,
        // activeChatId: null,
        netWork: { ...state.netWork, activeChannelConnections: false }
      }
    // Messages
    case types.INCOMING_MESSAGE_CHAT_MESSAGES_CONNECT:
      return {
        ...state,
        activeChanneInfo: payload,
      }
    case types.INCOMING_MESSAGE_CHAT_MESSAGES_LIST_MESSAGES: {
      const unreadMessagesCount = state.openChannels.find(({ id }) => id == state.activeChatId).unreadMessagesCount
      const lastReadMessageId = unreadMessagesCount && payload.messages.length
        ? payload.messages[payload.messages.length - unreadMessagesCount].id
        : null
      return {
        ...state,
        messages: getArrayWithUniqueObj([...payload.messages, ...state.messages]), //todo null
        countMessages: payload.count,
        isLoadingMessagesPage: false,
        lastReadMessageId
      }
    }
    case types.INCOMING_MESSAGE_CHAT_MESSAGES_SEND_CHAT_MESSAGE:
      return { ...state, messages: getArrayWithUniqueObj([...state.messages, payload]) }
    case types.INCOMING_MESSAGE_CHAT_MESSAGES_SEARCH_MESSAGES_BY_TEXT:
      return { ...state, messages: payload }
    case types.INCOMING_MESSAGE_CHAT_MESSAGES_PIN_MESSAGE:
      return { ...state, pinMessage: payload.isPinned ? payload : null }
    case types.INCOMING_MESSAGE_CHAT_MESSAGES_ADD_TO_CHAT_ROOM:
      return {
        ...state,
        openChannels: state.openChannels.map(dataChannel => (
          dataChannel.id == payload.chatId
            ? { ...dataChannel, members: payload.chatMembers }
            : dataChannel
        ))
      }
    // other
    case types.SET_ACTIVE_CHAT_ID:
      return {
        ...state,
        activeChatId: payload,
        pinMessage: payload && state.openChannels.find(channel => channel.id == payload).isPinned
      }
    case types.CLEAR_MESSAGES:
      return { ...state, messages: [] }
    case types.CLEAR_OPEN_CHANNELS:
      return { ...state, openChannels: [] }
    case types.SET_IS_LOADING_MESSAGES_PAGE:
      return { ...state, isLoadingMessagesPage: true }
    case types.SET_IS_LOADING_OPEN_CHANNELS:
      return { ...state, isLoadingOpenChannels: true }

    case types.FIND_USERS_CHAT_SUCCESS:
      return { ...state, projectUsers: [...state.projectUsers, ...payload.users], totalPagesProjectUsers: payload.totalPages }
    case types.TOGGLE_LOADING_FIND_USERS_CHAT:
      return { ...state, isLoadingProjectUsers: !state.isLoadingProjectUsers }
    case types.CLEAR_FIND_USERS_CHAT:
      return { ...state, projectUsers: [], totalPagesProjectUsers: null }
    default:
      return state
  }
}