import { BuilderRemarksApi } from '../types/types'
import { RemarkStatus } from '../../../../entities/Remarks/types/types'
import {addedNewNotification} from "../../../../../(deprecated)/redux/notifications/actions";

export const updateRemark = (builder: BuilderRemarksApi) => (
  builder.mutation({
    query: ({
      status,
      uuid,
      cb
    } : {
      status: RemarkStatus,
      uuid: string,
      cb?: () => void
    }) => {
      return {
        url: `issue/${uuid}`,
        method: 'PUT',
        body: { status }
      }
    },
    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        await queryFulfilled
        arg?.cb?.()
        dispatch(addedNewNotification({ message: 'Статус успешно изменен' }))
      } catch {
        dispatch(addedNewNotification({ message: 'Не удалось изменить статус' }))
      }
    }
  })
)