import React from 'react'
import { IIcon } from './IIcon'

export const XCircle: React.FC<IIcon> = ({
  width = '2.5',
  height = '2.4',
  color = '#949494',
  ...innerProps
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 25 24" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8574 2.25C10.9291 2.25 9.044 2.82183 7.44062 3.89317C5.83724 4.96451 4.58756 6.48726 3.8496 8.26884C3.11165 10.0504 2.91856 12.0108 3.29477 13.9021C3.67098 15.7934 4.59957 17.5307 5.96314 18.8943C7.3267 20.2579 9.06398 21.1865 10.9553 21.5627C12.8466 21.9389 14.807 21.7458 16.5886 21.0078C18.3702 20.2699 19.8929 19.0202 20.9643 17.4168C22.0356 15.8134 22.6074 13.9284 22.6074 12C22.6047 9.41498 21.5766 6.93661 19.7487 5.10872C17.9208 3.28084 15.4424 2.25273 12.8574 2.25ZM16.3881 14.4694C16.4577 14.5391 16.513 14.6218 16.5507 14.7128C16.5884 14.8039 16.6078 14.9015 16.6078 15C16.6078 15.0985 16.5884 15.1961 16.5507 15.2872C16.513 15.3782 16.4577 15.4609 16.3881 15.5306C16.3184 15.6003 16.2356 15.6556 16.1446 15.6933C16.0536 15.731 15.956 15.7504 15.8574 15.7504C15.7589 15.7504 15.6613 15.731 15.5703 15.6933C15.4792 15.6556 15.3965 15.6003 15.3268 15.5306L12.8574 13.0603L10.3881 15.5306C10.3184 15.6003 10.2356 15.6556 10.1446 15.6933C10.0536 15.731 9.95597 15.7504 9.85743 15.7504C9.75888 15.7504 9.6613 15.731 9.57025 15.6933C9.47921 15.6556 9.39648 15.6003 9.3268 15.5306C9.25712 15.4609 9.20184 15.3782 9.16413 15.2872C9.12642 15.1961 9.10701 15.0985 9.10701 15C9.10701 14.9015 9.12642 14.8039 9.16413 14.7128C9.20184 14.6218 9.25712 14.5391 9.3268 14.4694L11.7971 12L9.3268 9.53063C9.18607 9.38989 9.10701 9.19902 9.10701 9C9.10701 8.80098 9.18607 8.61011 9.3268 8.46937C9.46753 8.32864 9.6584 8.24958 9.85743 8.24958C10.0564 8.24958 10.2473 8.32864 10.3881 8.46937L12.8574 10.9397L15.3268 8.46937C15.3965 8.39969 15.4792 8.34442 15.5703 8.3067C15.6613 8.26899 15.7589 8.24958 15.8574 8.24958C15.956 8.24958 16.0536 8.26899 16.1446 8.3067C16.2356 8.34442 16.3184 8.39969 16.3881 8.46937C16.4577 8.53906 16.513 8.62178 16.5507 8.71283C16.5884 8.80387 16.6078 8.90145 16.6078 9C16.6078 9.09855 16.5884 9.19613 16.5507 9.28717C16.513 9.37822 16.4577 9.46094 16.3881 9.53063L13.9177 12L16.3881 14.4694Z"
      fill={color}/>
  </svg>
)
