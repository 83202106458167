import { addedNewNotification } from '../../../redux/notifications/actions'
import snakecaseKeys from 'snakecase-keys'
import store from '../../../redux/store'

type PostPayload = {
  companyId: string
  profile: Record<string, unknown>
}

export class PostUserWorkerByIdQuery {

  query = ({ companyId, profile }: PostPayload) => {
    const preparedProfile = snakecaseKeys(profile)

    return {
      url: `users/${companyId}/workers`,
      method: 'POST',
      body: { profile: preparedProfile }
    }
  }
}