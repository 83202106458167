import React from 'react'
import { IIcon } from './IIcon'

export const Question: React.FC<IIcon> = ({
  width = '3.2', height = '3.2', color = 'rgba(0, 0, 0, 0.42)', ...innerProps
}) => (
  <svg
    {...innerProps}
    width={`${width}rem`}
    height={`${height}rem`}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.5281 19.312C14.5281 18.5724 14.6917 17.9609 15.0188 17.4773C15.3601 16.9938 15.8508 16.4604 16.4908 15.8773C16.9601 15.4506 17.3014 15.0951 17.5148 14.8106C17.7423 14.512 17.8561 14.1778 17.8561 13.808C17.8561 13.2818 17.6428 12.8693 17.2161 12.5706C16.8037 12.2578 16.249 12.1013 15.5521 12.1013C14.8837 12.1013 14.2863 12.2435 13.7601 12.528C13.2481 12.7982 12.8143 13.1822 12.4588 13.68L9.87744 12.1653C10.4748 11.2551 11.2783 10.5511 12.2881 10.0533C13.3121 9.55553 14.521 9.30664 15.9148 9.30664C17.5503 9.30664 18.8588 9.65508 19.8401 10.352C20.8357 11.0489 21.3334 12.016 21.3334 13.2533C21.3334 13.8364 21.2339 14.3484 21.0348 14.7893C20.8499 15.2302 20.6152 15.6071 20.3308 15.92C20.0606 16.2186 19.705 16.5671 19.2641 16.9653C18.7379 17.4346 18.3539 17.8329 18.1121 18.16C17.8703 18.4729 17.7494 18.8569 17.7494 19.312H14.5281ZM16.1494 24.6666C15.5663 24.6666 15.0757 24.4818 14.6774 24.112C14.2934 23.728 14.1014 23.2658 14.1014 22.7253C14.1014 22.1849 14.2934 21.7369 14.6774 21.3813C15.0614 21.0115 15.5521 20.8266 16.1494 20.8266C16.7468 20.8266 17.2374 21.0115 17.6214 21.3813C18.0054 21.7369 18.1974 22.1849 18.1974 22.7253C18.1974 23.2658 17.9983 23.728 17.6001 24.112C17.2161 24.4818 16.7326 24.6666 16.1494 24.6666Z" fill={color} />
  </svg>
)