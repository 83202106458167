import React from 'react'
import { IIcon } from './IIcon'

export const DownloadOther: React.FC<IIcon> = ({
  width = '2.5',
  height = '2.4',
  color = '#1A65DA',
  opacity = '0.64',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 25 24" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M9.7998 16H15.7998V10H19.7998L12.7998 3L5.7998 10H9.7998V16ZM5.7998 18H19.7998V20H5.7998V18Z"
          fill={color}/>
  </svg>
)