import React, { CSSProperties } from 'react'
import LoderFromLib from 'react-loader-spinner'
import cn from 'classnames'
import './Loader.scss'

export type LoaderProps= {
  style?: CSSProperties
  color?: string
  height?: number
  width?: number
  className?: string,
  ref?: any
}


const Loader: React.FC<LoaderProps> = ({ 
  color = '#1A65DA', 
  height = 100, 
  width = 100,
  className = '',
  style,
}) => (
  <div 
    className={cn('loader-framework', className)}
    style={style}
  >
    <LoderFromLib
      visible={true}
      type='ThreeDots'
      color={color}
      height={height}
      width={width}
    />
  </div>
)

export default Loader