import { useCallback, useEffect, useState, useRef } from 'react'

export const useOutSideClick = (
  onClick: (e: Event) => void,
  targetCss: string[],
) => {
  const [clickEvent, setClickEvent] = useState<Event | undefined>(undefined)

  const isFirstRender = useRef(true)

  /**
    важно не вызывать onClick внутри useCallback, потому что сохранится старая ссылка onClick
    и в этой старой ссылке будет хранится страный внешний стейт. Из-за этого могут возникать 
    каверзные баги.
    Уберать useCallback нельзя, потому что мы не сможем снять обработчик события с документа.
  */
  const handleOutsideClick = useCallback((ev: Event) => {
    const targetClick = ev.target as HTMLElement
    const isClickedOutside = targetCss.some(cssSelector =>
      !Boolean(targetClick.closest(cssSelector))
    )

    if (isClickedOutside)
      setClickEvent(ev)
  }, [])

  useEffect(() => {
    isFirstRender.current = false

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  /**
    Подписываюсь на событие изменения события клика.
    Если объект события изменился, значит произошел клик -> вызываю обработчик onClick
  */
  useEffect(() => {
    if (isFirstRender.current || !clickEvent)
      return

    onClick(clickEvent)
  }, [clickEvent])
}