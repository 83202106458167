import React from 'react'
import { IIcon } from './IIcon'

export const TextT:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 25 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 3H5C4.60218 3 4.22064 3.15804 3.93934 3.43934C3.65804 3.72064 3.5 4.10218 3.5 4.5V19.5C3.5 19.8978 3.65804 20.2794 3.93934 20.5607C4.22064 20.842 4.60218 21 5 21H20C20.3978 21 20.7794 20.842 21.0607 20.5607C21.342 20.2794 21.5 19.8978 21.5 19.5V4.5C21.5 4.10218 21.342 3.72064 21.0607 3.43934C20.7794 3.15804 20.3978 3 20 3ZM17.75 9C17.75 9.19891 17.671 9.38968 17.5303 9.53033C17.3897 9.67098 17.1989 9.75 17 9.75C16.8011 9.75 16.6103 9.67098 16.4697 9.53033C16.329 9.38968 16.25 9.19891 16.25 9V8.25H13.25V16.5H14.75C14.9489 16.5 15.1397 16.579 15.2803 16.7197C15.421 16.8603 15.5 17.0511 15.5 17.25C15.5 17.4489 15.421 17.6397 15.2803 17.7803C15.1397 17.921 14.9489 18 14.75 18H10.25C10.0511 18 9.86032 17.921 9.71967 17.7803C9.57902 17.6397 9.5 17.4489 9.5 17.25C9.5 17.0511 9.57902 16.8603 9.71967 16.7197C9.86032 16.579 10.0511 16.5 10.25 16.5H11.75V8.25H8.75V9C8.75 9.19891 8.67098 9.38968 8.53033 9.53033C8.38968 9.67098 8.19891 9.75 8 9.75C7.80109 9.75 7.61032 9.67098 7.46967 9.53033C7.32902 9.38968 7.25 9.19891 7.25 9V7.5C7.25 7.30109 7.32902 7.11032 7.46967 6.96967C7.61032 6.82902 7.80109 6.75 8 6.75H17C17.1989 6.75 17.3897 6.82902 17.5303 6.96967C17.671 7.11032 17.75 7.30109 17.75 7.5V9Z"
      fill={color}/>
  </svg>
)