import React from 'react'
import { IIcon } from './IIcon'

export const PaperClip:React.FC<IIcon> = ({
  width = '2.3',
  height = '2.2',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 23 22"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.0566 11C22.055 12.367 21.5113 13.6776 20.5447 14.6443C19.578 15.6109 18.2674 16.1547 16.9004 16.1562H4.18164C3.26996 16.1562 2.39562 15.7941 1.75096 15.1494C1.1063 14.5048 0.744141 13.6304 0.744141 12.7188C0.744141 11.8071 1.1063 10.9327 1.75096 10.2881C2.39562 9.64341 3.26996 9.28125 4.18164 9.28125H16.2129C16.4864 9.28125 16.7487 9.3899 16.9421 9.5833C17.1355 9.77669 17.2441 10.039 17.2441 10.3125C17.2441 10.586 17.1355 10.8483 16.9421 11.0417C16.7487 11.2351 16.4864 11.3438 16.2129 11.3438H4.18164C3.81697 11.3438 3.46723 11.4886 3.20937 11.7465C2.95151 12.0043 2.80664 12.3541 2.80664 12.7188C2.80664 13.0834 2.95151 13.4332 3.20937 13.691C3.46723 13.9489 3.81697 14.0938 4.18164 14.0938H16.9004C17.7209 14.0938 18.5078 13.7678 19.088 13.1876C19.6682 12.6074 19.9941 11.8205 19.9941 11C19.9941 10.1795 19.6682 9.39258 19.088 8.81239C18.5078 8.2322 17.7209 7.90625 16.9004 7.90625H7.27539C7.00189 7.90625 6.73958 7.7976 6.54619 7.6042C6.35279 7.41081 6.24414 7.1485 6.24414 6.875C6.24414 6.6015 6.35279 6.33919 6.54619 6.1458C6.73958 5.9524 7.00189 5.84375 7.27539 5.84375H16.9004C18.2674 5.84534 19.578 6.3891 20.5447 7.35574C21.5113 8.32238 22.055 9.63297 22.0566 11Z"
      fill={color}/>
  </svg>
)