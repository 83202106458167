import React from 'react'
import { IIcon } from "./IIcon";

export const PencilSimple: React.FC<IIcon> = ({
  width = '1.8',
  height = '1.8',
  color = '#949494',
  ...props
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} {...props} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9827 5.15881L12.8412 2.01655C12.7367 1.91205 12.6127 1.82916 12.4762 1.77261C12.3397 1.71606 12.1933 1.68695 12.0456 1.68695C11.8978 1.68695 11.7515 1.71606 11.615 1.77261C11.4785 1.82916 11.3545 1.91205 11.25 2.01655L2.57977 10.6875C2.47485 10.7916 2.39167 10.9155 2.33506 11.052C2.27844 11.1885 2.24953 11.3349 2.25001 11.4827V14.625C2.25001 14.9234 2.36853 15.2095 2.57951 15.4205C2.79049 15.6315 3.07664 15.75 3.37501 15.75H6.51727C6.66506 15.7505 6.81147 15.7215 6.94799 15.6649C7.08451 15.6083 7.20842 15.5251 7.31251 15.4202L15.9827 6.74998C16.0872 6.64551 16.1701 6.52148 16.2267 6.38498C16.2832 6.24847 16.3123 6.10216 16.3123 5.9544C16.3123 5.80664 16.2832 5.66033 16.2267 5.52382C16.1701 5.38731 16.0872 5.26328 15.9827 5.15881ZM13.5 7.64155L10.3577 4.49998L12.0452 2.81248L15.1875 5.95405L13.5 7.64155Z" fill={color} />
  </svg>
)