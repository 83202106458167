import { addedNewNotification } from '../../../../../(deprecated)/redux/notifications/actions'
import { BuilderProjectAttachmentsApi } from '../types'

export const createProjectAttachmentQuery = (builder: BuilderProjectAttachmentsApi) => (
  builder.mutation<any, { project: number, file: File}>({
    query: ({  project, file }) => {
      const fileData = new FormData()

      fileData.append('file', file)
      fileData.append('project', String(project))

      return  {
        url: 'project_files',
        method: 'POST',
        body: fileData
      }
    },
    invalidatesTags: ['project-attachments'],
    transformResponse: (response: any) => response,
    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        await queryFulfilled
        dispatch(addedNewNotification({ message: 'Документ успешно добавлен.' }))
      } catch (e) {
        dispatch(addedNewNotification({ message: 'Не удалось добавить документ.' }))
      }
    }
  })
)