import {
  SET_PROJECT_LOADING_START,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAILED,
  PUT_PROJECT_INFO_SUCCESS,
  POST_PROJECT_DOCUMENTS_SUCCESS,
  SET_PROJECT_IMAGE_LOADING,
  GET_PROJECT_IMAGE_SUCCESS,
  GET_PROJECT_IMAGE_FAILED,
  POST_PROJECT_IMAGES_SUCCESS,
  DELETE_PROJECT_IMAGES_SUCCESS,
  GET_PROJECT_DOCUMENTS_SUCCESS,
  GET_PROJECT_DOCUMENTS_FAILED,
  SET_PROJECT_DOCUMENTS_LOADING_START,
  SET_LOADING_PROJECT_COMPANY_START,
  GET_PROJECT_COMPANY_SUCCESS,
  GET_PROJECT_COMPANY_FAILED,
  SET_LOADING_POST_PROJECT_START,
  POST_PROJECT_FAILED,
  POST_PROJECT_SUCCESS,
  SET_LOADING_PROJECT_MEMBERS_START,
  GET_PROJECT_MEMBERS_FAILED,
  GET_PROJECT_MEMBERS_SUCCESS,
  PUT_PROJECT_MEMBERS_SUCCESS,
  PUT_PROJECT_MEMBERS_FAILED,
  DELETE_PROJECT_MEMBER_SUCCESS,
  DELETE_PROJECT_DOCUMENT_SUCCESS,
  SET_LOADING_GET_PROJECT_SIGNERS,
  GET_PROJECT_SIGNERS_SUCCESS,
  GET_PROJECT_SIGNERS_FAILED,
  SET_LOADING_POST_PROJECT_DOCUMENTS,
  POST_PROJECT_DOCUMENTS_FAILED,
  SET_LOADING_GET_PROJECT_WORKER,
  GET_PROJECT_WORKER_SUCCESS,
  GET_PROJECT_WORKER_FAILED,
  SET_LOADING_GET_PROJECT_EXECUTOR,
  GET_PROJECT_EXECUTOR_SUCCESS,
  GET_PROJECT_EXECUTOR_FAILED,
  GET_MOVE_MEMBERS_LOCAL_FROM_PROJECTLIST,
  GET_BUSY_WORKERS_SUCCESS,
  DELETE_BUSY_WORKER,
  CLEAR_BUSY_WORKERS,
  POST_COMPANY_TO_PROJECT_SUCCESS,
  DELETE_COMPANY_FROM_PROJECT_SUCCESS,
  PUT_COMPANY_PROJECT_SUCCESS,
  GET_PROJECT_COMPANY_SIGNERS_SUCCESS,
  PUT_PROJECT_COMPANY_SIGNERS_SUCCESS,
  GET_PROJECT_FIND_USERS_SUCCESS,
  SET_LOADING_PROJECT_FIND_USERS,
  GET_PROJECT_FIND_USERS_LOAD_MORE_SUCCESS,
  SET_METRICS_PROJECT,
  TOGGLE_LOADING_METRIC,
  GET_ORDER_DISTRIBUTOR_USERS_SUCCESS,
  SET_ADDED_COMPANIES,
  UPDATE_ADDED_COMPANIES
} from "./constants"

const initialState = {
  projectList: [],
  // projectUsers: {
  //   count: 0,
  //   results: [],
  // },
  // isLoadingProjectUsers: false,
  addedCompanies: null,
  finishGetProject: false,
  isLoading: false,
  isLoadingImages: false,
  isLoadingDocuments: false,
  isLoadingCompany: false,
  isLoadingProjectUsers: false,
  images: [],
  isLoadingPostDocument: false,
  documents: [],
  isLoadingNewProject: false,
  isLoadingMembers: false,
  members: [],
  isLoadingSigners: false,
  errorMessages: {},
  isLoadingProjectWorker: false,
  workers: [],
  busyWorkers: [],
  isLoadingProjectExecutor: false,
  executor: [],
  orderDistributor: null,
  signers: [],
  metrics: null,
  isLoadingMetric: false,
  orderDistributorUsers: []
}

export const projectReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PROJECT_LOADING_START:
      return { ...state, isLoading: true }

    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        projectList: payload,
        isLoading: false,
        finishGetProject: true
      }

    case GET_PROJECT_FAILED:
      return { ...state, isLoading: false }

    case PUT_PROJECT_INFO_SUCCESS:
      const newProjectList = [...state.projectList]
      const indexProject = newProjectList.findIndex(({ id }) => id === payload.id)
      newProjectList[indexProject] = payload
      return { ...state, projectList: newProjectList }

    case SET_LOADING_POST_PROJECT_DOCUMENTS:
      return { ...state, isLoadingPostDocument: true }

    case POST_PROJECT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: [...state.documents, ...payload],
        isLoadingPostDocument: false
      }

    case POST_PROJECT_DOCUMENTS_FAILED:
      return { ...state, isLoadingPostDocument: false }

    case DELETE_PROJECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: [...state.documents.filter(({ id }) => id !== payload)]
      }
    // case POST_PROJECT_EMLOYEES_SUCCESS:
    //   return { ...state, projectInfo: payload }

    // case  POST_PROJECT_COMPANY_SUCCESS: 
    //   return { ...state, projectInfo: payload }

    case SET_PROJECT_IMAGE_LOADING:
      return { ...state, isLoadingImages: true }

    case GET_PROJECT_IMAGE_SUCCESS:
      return {
        ...state,
        isLoadingImages: false,
        images: payload,
      }

    case GET_PROJECT_IMAGE_FAILED:
      return {
        ...state,
        isLoadingImages: false,
      }

    case POST_PROJECT_IMAGES_SUCCESS:
      return {
        ...state,
        images: [...payload]
      }

    case DELETE_PROJECT_IMAGES_SUCCESS:
      return {
        ...state,
        images: state.images.filter(({ id }) => id !== payload)
      }

    case SET_ADDED_COMPANIES:
      return {
        ...state,
        addedCompanies: payload
      }

    case UPDATE_ADDED_COMPANIES:
      const addedCompaniesIds = state.addedCompanies.map(c => c.company.id)
      return {
        ...state,
        addedCompanies: addedCompaniesIds.includes(payload) ? state.addedCompanies.filter(c => c.company.id !== payload) : [...state.addedCompanies, payload]
      }

    // case GET_PROJECT_CUSTOMER_IMAGE_SUCCESS:
    //   return {
    //     ...state,
    //     customer: {
    //       ...state.customer,
    //       avatar: payload
    //     } 
    //   }

    case SET_PROJECT_DOCUMENTS_LOADING_START:
      return { ...state, isLoadingDocuments: true }

    case GET_PROJECT_DOCUMENTS_SUCCESS:
      return {
        ...state, documents: payload, isLoadingDocuments: false
      }

    case GET_PROJECT_DOCUMENTS_FAILED:
      return {
        ...state, isLoadingDocuments: false
      }

    // case SET_LOADING_PROJECT_COMPANY_START:
    //   return {
    //     ...state, isLoadingCompany: true
    //   }

    // case GET_PROJECT_COMPANY_SUCCESS:
    //   return {
    //     ...state, isLoadingCompany: false, company: payload
    //   }

    // case GET_PROJECT_COMPANY_FAILED:
    //   return {
    //     ...state, isLoadingCompany: false
    //   }

    case SET_LOADING_POST_PROJECT_START:
      return {
        ...state, isLoadingNewProject: true,
        errorMessages: {}
      }

    case POST_PROJECT_SUCCESS:
      return {
        ...state,
        isLoadingNewProject: false,
        projectList: [...state.projectList, payload],
        images: [],
        documents: [],
        company: [],
        errorMessages: {}
      }

    case POST_PROJECT_FAILED:
      return {
        ...state, isLoadingNewProject: false,
        errorMessages: payload
      }
    //---------------------------------------------------------
    case SET_LOADING_PROJECT_MEMBERS_START:
      return {
        ...state, isLoadingMembers: true
      }

    case GET_PROJECT_MEMBERS_SUCCESS:
      return {
        ...state,
        isLoadingMembers: false,
        members: payload
      }

    case GET_MOVE_MEMBERS_LOCAL_FROM_PROJECTLIST:

      const currentMembers = state.projectList.find(project => project.id === payload)
        ?.projectCompanies?.map(({ company }) => company)
      return {
        ...state,
        members: currentMembers || []
      }

    case GET_PROJECT_MEMBERS_FAILED:
      return {
        ...state,
        isLoadingMembers: false,
      }

    case PUT_PROJECT_MEMBERS_SUCCESS:
      return {
        ...state,
        isLoadingMembers: false,
        members: payload.members
      }

    case PUT_PROJECT_MEMBERS_FAILED:
      return {
        ...state,
        isLoadingMembers: false,
      }

    case DELETE_PROJECT_MEMBER_SUCCESS:
      return {
        ...state,
        members: state.members.filter(({ id }) => id !== payload)
      }
    //---------------------------------------------
    case SET_LOADING_GET_PROJECT_SIGNERS:
      return {
        ...state,
        isLoadingSigners: true
      }

    case GET_PROJECT_SIGNERS_SUCCESS:
      return {
        ...state,
        isLoadingSigners: false,
        signers: payload,
      }

    case GET_PROJECT_SIGNERS_FAILED:
      return {
        ...state,
        isLoadingSigners: false
      }

    case SET_LOADING_GET_PROJECT_WORKER:
      return {
        ...state,
        isLoadingProjectWorker: true
      }

    case GET_PROJECT_WORKER_SUCCESS:
      return {
        ...state,
        isLoadingProjectWorker: false,
        workers: payload
      }

    case GET_PROJECT_WORKER_FAILED:
      return {
        ...state,
        isLoadingProjectWorker: false
      }

    case SET_LOADING_GET_PROJECT_EXECUTOR:
      return {
        ...state,
        isLoadingProjectExecutor: true
      }

    case GET_PROJECT_EXECUTOR_SUCCESS:
      return {
        ...state,
        isLoadingProjectExecutor: false,
        executor: payload
      }

    case GET_PROJECT_EXECUTOR_FAILED:
      return {
        ...state,
        isLoadingProjectExecutor: false
      }

    case GET_BUSY_WORKERS_SUCCESS:
      return {
        ...state,
        busyWorkers: [...state.busyWorkers, payload]
      }
    case DELETE_BUSY_WORKER:
      return {
        ...state,
        busyWorkers: state.busyWorkers.filter(({ id }) => id !== payload)
      }
    case CLEAR_BUSY_WORKERS:
      return {
        ...state,
        busyWorkers: []
      }

    case POST_COMPANY_TO_PROJECT_SUCCESS:
      return {
        ...state,
        projectList: state.projectList.map((project) => (
          project.id === payload.project
            ? { ...project, projectCompanies: [...project.projectCompanies, payload] }
            : project
        ))
      }

    case DELETE_COMPANY_FROM_PROJECT_SUCCESS:
      return {
        ...state,
        projectList: state.projectList.map((project) => (
          project.id === payload.projectId
            ? {
              ...project, projectCompanies: project.projectCompanies.filter(({ id }) => (
                id !== payload.projectCompany
              ))
            }
            : project
        ))
      }

    case PUT_COMPANY_PROJECT_SUCCESS:
      return {
        ...state,
        projectList: state.projectList.map((project) => (
          project.id === payload.project
            ? {
              ...project, projectCompanies: project.projectCompanies.map((node) => (
                node.id === payload.id
                  ? payload
                  : node
              ))
            }
            : project
        ))
      }

    case GET_PROJECT_COMPANY_SIGNERS_SUCCESS:
      return {
        ...state,
        orderDistributor: payload.orderDistributor,
        signers: payload.signers,
      }

    case PUT_PROJECT_COMPANY_SIGNERS_SUCCESS:
      return {
        ...state,
        orderDistributor: payload.orderDistributor,
        signers: payload.signers
      }

    case SET_METRICS_PROJECT:
      return {
        ...state,
        metrics: payload
      }

    case TOGGLE_LOADING_METRIC:
      return {
        ...state,
        isLoadingMetric: !state.isLoadingMetric
      }

    case GET_ORDER_DISTRIBUTOR_USERS_SUCCESS:
      return {
        ...state,
        orderDistributorUsers: payload
      }

    default:
      return state
  }
}