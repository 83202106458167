import {
  SET_LOADING_USERS_PROJECT_START,
  GET_USERS_PROJECT_SUCCESS,
  GET_USERS_PROJECT_FAILED,
  GET_ALL_PROJECT_USERS_SUCCESS,
  ADD_PROJECT_USERS
} from './constants'

const initialState = {
  users: [],
  isLoading: false,
  allProjectUsers: [],
  activeUsers: [],
  workers: [],
}

export const usersProjectReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING_USERS_PROJECT_START:
      return { ...state, isLoading: true }

    case GET_USERS_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: payload
      }

    case GET_USERS_PROJECT_FAILED:
      return { ...state, isLoading: false }

    case GET_ALL_PROJECT_USERS_SUCCESS:
      return {
        ...state,
        allProjectUsers: payload,
        isLoading: false,
        activeUsers: payload.filter(user => user.email !== null),
        workers: payload.filter(user => user.email === null)
      }

    case ADD_PROJECT_USERS:
      return { ...state, allProjectUsers: [...state.allProjectUsers, ...payload] }

    default:
      return state
  }
}