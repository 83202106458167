import React from 'react'
import { IIcon } from './IIcon'

export const ChecksWithDashesAndBackground: React.FC<IIcon> = ({
  width = '18.7',
  height = '17.7',
  ...innerProps
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 187 177" fill="none">
    <rect x="0.165039" y="11.6371" width="164.998" height="164.998" rx="82.4989" fill="#F2F2F2" />
    <rect x="20.835" y="1.36511" width="165" height="165" rx="82.5" fill="#F2F2F2" />
    <rect x="20.835" y="1.36511" width="165" height="165" rx="82.5" stroke="#949494" stroke-width="2" stroke-linecap="round" stroke-dasharray="10 15" />
    <path d="M110.109 68.127L78.6094 99.0645C78.3465 99.3221 77.9931 99.4665 77.625 99.4665C77.2569 99.4665 76.9035 99.3221 76.6406 99.0645L63.1406 85.8071C62.8846 85.5438 62.7417 85.1909 62.7425 84.8237C62.7433 84.4565 62.8877 84.1042 63.1448 83.842C63.4019 83.5799 63.7513 83.4287 64.1184 83.4207C64.4856 83.4128 64.8412 83.5488 65.1094 83.7996L77.625 96.1043L108.141 66.1371C108.41 65.8968 108.762 65.7694 109.123 65.7813C109.484 65.7933 109.826 65.9436 110.08 66.2013C110.333 66.4589 110.477 66.8041 110.483 67.1652C110.488 67.5263 110.354 67.8758 110.109 68.141V68.127ZM143.859 66.1407C143.596 65.8777 143.239 65.73 142.866 65.73C142.494 65.73 142.137 65.8777 141.873 66.1407L111.357 96.1078L103.753 88.6407C103.623 88.5059 103.467 88.3986 103.294 88.325C103.122 88.2515 102.936 88.2132 102.749 88.2124C102.561 88.2116 102.375 88.2483 102.202 88.3204C102.029 88.3925 101.872 88.4985 101.74 88.6322C101.609 88.7658 101.505 88.9245 101.436 89.0987C101.367 89.273 101.333 89.4594 101.337 89.6469C101.34 89.8344 101.382 90.0193 101.458 90.1906C101.534 90.362 101.644 90.5163 101.781 90.6446L110.373 99.0821C110.636 99.3397 110.989 99.484 111.357 99.484C111.726 99.484 112.079 99.3397 112.342 99.0821L143.842 68.1446C143.977 68.0161 144.085 67.8619 144.16 67.6912C144.235 67.5204 144.276 67.3365 144.279 67.15C144.282 66.9635 144.248 66.7782 144.179 66.6049C144.11 66.4316 144.008 66.2738 143.877 66.1407H143.859Z" fill="#949494" />
  </svg>
)