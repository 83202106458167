import React from 'react'
import { IIcon } from './IIcon'

export const Notification:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = 'black',
  opacity = '0.64',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 2H4C2.9 2 2.01 2.9 2.01 4L2 22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM13 11H11V5H13V11ZM13 15H11V13H13V15Z"
      fill={color} fillOpacity={opacity}/>
  </svg>
)