import { init, track, setUserId } from '@amplitude/analytics-browser';
import { API_KEY } from './config'
import { AMPLITUDE_EVENTS_TYPE } from './events'

export const initializeAmplitude = () => init(API_KEY)

export const identify = (id: string) => setUserId('user_id_' + id)

export const amplitudeLogEvent = (logData: { id?: string, event: AMPLITUDE_EVENTS_TYPE, options?: any }) => {
  if (process.env.REACT_APP_ENV_SENTRY === 'production') {
    track(logData.event, { options: logData.options })
  } else {
    const logEvent = {
      eventTitle: logData.event,
      options: logData.options
    }
    console.table(logEvent)
  }
}