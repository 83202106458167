import { SystemUserAdapter } from '../SystemUserAdapter'

export class GetSystemUserByIdQuery {
  query = ({ id }: { id: 'me' | number }) => {
    return `users/${id}`
  }

  transformResponse = (userResponse: unknown) => {
    return new SystemUserAdapter(userResponse).getFields()
  }
}