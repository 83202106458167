import getDocument, { getDocumentOnPost } from './getDocument'

const TYPE_XLSX = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const downloadFile = async (path, title = 'New doc') => {
  const data = await getDocument({ path })
  const [_, format] = data.type.split('/')
  const link = document.createElement('a')
  link.href = URL.createObjectURL(data)

  if (format == TYPE_XLSX) {
    link.download = title.replaceAll(/(\\|\.|\/)/g, '')
    link.click()
    return
  }

  link.download = title.includes('.') ? `${title}.${format}` : title
  link.click()
}

export const downloadFilePost = async (path, data, title = 'New doc') => {
  const doc = await getDocumentOnPost(path, data)
  const [_, format] = doc.type.split('/')
  const link = document.createElement('a')
  link.href = URL.createObjectURL(doc)
  link.download = title.includes('.') ? `${title}.${format}` : title
  link.click()
}