import React from 'react'
import { IIcon } from './IIcon'
//§TODO это индентичная CheckInCircleFilled иконка
export const CheckCircle: React.FC<IIcon> = ({
  width = '2.5',
  height = '2.4',
  color = '#1D9F55',
  ...innerProps
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8574 2.25C10.9291 2.25 9.044 2.82183 7.44062 3.89317C5.83724 4.96451 4.58756 6.48726 3.8496 8.26884C3.11165 10.0504 2.91856 12.0108 3.29477 13.9021C3.67098 15.7934 4.59957 17.5307 5.96314 18.8943C7.3267 20.2579 9.06398 21.1865 10.9553 21.5627C12.8466 21.9389 14.807 21.7458 16.5886 21.0078C18.3702 20.2699 19.8929 19.0202 20.9643 17.4168C22.0356 15.8134 22.6074 13.9284 22.6074 12C22.6047 9.41498 21.5766 6.93661 19.7487 5.10872C17.9208 3.28084 15.4424 2.25273 12.8574 2.25ZM17.1381 10.2806L11.8881 15.5306C11.8184 15.6004 11.7357 15.6557 11.6446 15.6934C11.5536 15.7312 11.456 15.7506 11.3574 15.7506C11.2589 15.7506 11.1613 15.7312 11.0702 15.6934C10.9792 15.6557 10.8965 15.6004 10.8268 15.5306L8.5768 13.2806C8.43607 13.1399 8.35701 12.949 8.35701 12.75C8.35701 12.551 8.43607 12.3601 8.5768 12.2194C8.71753 12.0786 8.9084 11.9996 9.10743 11.9996C9.30645 11.9996 9.49732 12.0786 9.63805 12.2194L11.3574 13.9397L16.0768 9.21937C16.1465 9.14969 16.2292 9.09442 16.3203 9.0567C16.4113 9.01899 16.5089 8.99958 16.6074 8.99958C16.706 8.99958 16.8036 9.01899 16.8946 9.0567C16.9856 9.09442 17.0684 9.14969 17.1381 9.21937C17.2077 9.28906 17.263 9.37178 17.3007 9.46283C17.3384 9.55387 17.3578 9.65145 17.3578 9.75C17.3578 9.84855 17.3384 9.94613 17.3007 10.0372C17.263 10.1282 17.2077 10.2109 17.1381 10.2806Z" fill={color} />
  </svg>
)
