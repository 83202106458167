import React from 'react'
import { IIcon } from './IIcon'

export const TailMessage:React.FC<IIcon & { isMy?: boolean }> = ({
  width = '1.6',
  height = '1.2',
  isMy = false,
  className = '',
  ...innerProps
}) => (
  <>
    {isMy ? (
      <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} className={className} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.53674e-07 12H16C16 12 10.9418 9.94699 8 7.84615C4.55901 5.38886 9.53674e-07 0 9.53674e-07 0V12Z" fill="#1A72DA" fill-opacity="0.1" />
      </svg>
    ) : (
      <svg width={`${width}rem`} height={`${height}rem`} className={className} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 12H0C0 12 5.05817 9.94699 8 7.84615C11.441 5.38886 16 0 16 0V12Z" fill="black" fill-opacity="0.05" />
      </svg>
    )}
  </>
)