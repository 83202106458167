import React from 'react'
import { IIcon } from './IIcon'

export const ChevronWithoutBorder:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = 'rgba(0, 0, 0, 0.48)',
  opacity = 1,
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.34317 8L4.92896 9.41421L12 16.4853L19.0711 9.41424L17.6569 8.00003L12 13.6569L6.34317 8Z" fill={color} fillOpacity={opacity} />
  </svg>
)
