export const SET_PROJECT_LOADING_START = 'SET_PROJECT_LOADING_START'
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS'
export const GET_PROJECT_FAILED = 'GET_PROJECT_FAILED'

export const PUT_PROJECT_INFO_SUCCESS = 'PUT_PROJECT_INFO_SUCCESS'

export const SET_LOADING_POST_PROJECT_DOCUMENTS = 'SET_LOADING_POST_PROJECT_DOCUMENTS'
export const POST_PROJECT_DOCUMENTS_SUCCESS = 'POST_PROJECT_DOCUMENTS_SUCCESS'
export const POST_PROJECT_DOCUMENTS_FAILED = 'POST_PROJECT_DOCUMENTS_FAILED'

export const POST_PROJECT_EMLOYEES_SUCCESS = 'POST_PROJECT_EMLOYEES_SUCCESS'
export const POST_PROJECT_EMLOYEES_FAILED = 'POST_PROJECT_EMLOYEES_FAILED'

export const POST_PROJECT_COMPANY_SUCCESS = 'POST_PROJECT_COMPANY_SUCCESS'

export const SET_PROJECT_IMAGE_LOADING = 'SET_PROJECT_IMAGE_LOADING'
export const GET_PROJECT_IMAGE_SUCCESS = 'GET_PROJECT_IMAGE_SUCCESS'
export const GET_PROJECT_IMAGE_FAILED = 'GET_PROJECT_IMAGE_FAILED'

export const POST_PROJECT_IMAGES_SUCCESS = 'POST_PROJECT_IMAGES_SUCCESS'
export const POST_PROJECT_IMAGES_FAILED = 'POST_PROJECT_IMAGES_FAILED'

export const DELETE_PROJECT_IMAGES_SUCCESS = 'DELETE_PROJECT_IMAGES_SUCCESS'
export const DELETE_PROJECT_IMAGES_FAILED = 'DELETE_PROJECT_IMAGES_FAILED'

export const GET_PROJECT_CUSTOMER_IMAGE_SUCCESS = 'GET_PROJECT_CUSTOMER_IMAGE_SUCCESS'
export const GET_PROJECT_CUSTOMER_IMAGE_FAILED = 'GET_PROJECT_CUSTOMER_IMAGE_FAILED'

export const SET_PROJECT_DOCUMENTS_LOADING_START = 'SET_PROJECT_DOCUMENTS_LOADING_START'
export const GET_PROJECT_DOCUMENTS_SUCCESS = 'GET_PROJECT_DOCUMENTS_SUCCESS'
export const GET_PROJECT_DOCUMENTS_FAILED = 'GET_PROJECT_DOCUMENTS_FAILED'
export const DELETE_PROJECT_DOCUMENT_SUCCESS = 'DELETE_PROJECT_DOCUMENT_SUCCESS'

export const SET_LOADING_PROJECT_MEMBERS_START = 'SET_LOADING_PROJECT_MEMBERS_START'
export const GET_PROJECT_MEMBERS_SUCCESS = 'GET_PROJECT_MEMBERS_SUCCESS'
export const GET_MOVE_MEMBERS_LOCAL_FROM_PROJECTLIST = 'GET_MOVE_MEMBERS_LOCAL_FROM_PROJECTLIST'
export const GET_PROJECT_MEMBERS_FAILED = 'GET_PROJECT_MEMBERS_FAILED'
export const PUT_PROJECT_MEMBERS_SUCCESS = 'PUT_PROJECT_MEMBERS_SUCCESS'
export const PUT_PROJECT_MEMBERS_FAILED = 'PUT_PROJECT_MEMBERS_FAILED'
export const DELETE_PROJECT_MEMBER_SUCCESS = 'DELETE_PROJECT_MEMBER_SUCCESS'
export const DELETE_PROJECT_MEMBER_FAILED = 'DELETE_PROJECT_MEMBER_FAILED'

export const SET_LOADING_POST_PROJECT_START = 'SET_LOADING_POST_PROJECT_START'
export const POST_PROJECT_SUCCESS = 'POST_PROJECT_SUCCESS'
export const POST_PROJECT_FAILED = 'POST_PROJECT_FAILED'

export const SET_LOADING_GET_PROJECT_SIGNERS = 'SET_LOADING_GET_PROJECT_SIGNERS'
export const GET_PROJECT_SIGNERS_SUCCESS = 'GET_PROJECT_SIGNERS_SUCCESS'
export const GET_PROJECT_SIGNERS_FAILED = 'GET_PROJECT_SIGNERS_FAILED'

export const SET_LOADING_GET_PROJECT_WORKER = 'SET_LOADING_GET_PROJECT_WORKER'
export const GET_PROJECT_WORKER_SUCCESS = 'GET_PROJECT_WORKER_SUCCESS'
export const GET_PROJECT_WORKER_FAILED = 'GET_PROJECT_WORKER_FAILED'

export const SET_LOADING_GET_PROJECT_EXECUTOR = 'SET_LOADING_GET_PROJECT_EXECUTOR'
export const GET_PROJECT_EXECUTOR_SUCCESS = 'GET_PROJECT_EXECUTOR_SUCCESS'
export const GET_PROJECT_EXECUTOR_FAILED = 'GET_PROJECT_EXECUTOR_FAILED'

export const GET_BUSY_WORKERS_SUCCESS = 'GET_BUSY_WORKERS_SUCCESS'
export const DELETE_BUSY_WORKER = 'DELETE_BUSY_WORKER'
export const CLEAR_BUSY_WORKERS = 'CLEAR_BUSY_WORKERS'
export const GET_BUSY_WORKERS_FAILED = 'GET_BUSY_WORKERS_FAILED'

export const POST_COMPANY_TO_PROJECT_SUCCESS = 'POST_COMPANY_TO_PROJECT_SUCCESS'

export const DELETE_COMPANY_FROM_PROJECT_SUCCESS = 'DELETE_COMPANY_FROM_PROJECT_SUCCESS'

export const PUT_COMPANY_PROJECT_SUCCESS = 'PUT_COMPANY_PROJECT_SUCCESS'

export const GET_PROJECT_COMPANY_SIGNERS_SUCCESS = 'GET_PROJECT_COMPANY_SIGNERS_SUCCESS'

export const PUT_PROJECT_COMPANY_SIGNERS_SUCCESS = 'PUT_PROJECT_COMPANY_SIGNERS_SUCCESS'

export const SET_LOADING_PROJECT_FIND_USERS = 'SET_LOADING_PROJECT_FIND_USERS'
export const GET_PROJECT_FIND_USERS_SUCCESS = 'GET_PROJECT_FIND_USERS_SUCCESS'
export const GET_PROJECT_FIND_USERS_LOAD_MORE_SUCCESS = 'GET_PROJECT_FIND_USERS_LOAD_MORE_SUCCESS'

export const SET_METRICS_PROJECT = 'SET_METRICS_PROJECT'
export const TOGGLE_LOADING_METRIC = 'TOGGLE_LOADING_METRIC'

export const GET_ORDER_DISTRIBUTOR_USERS_SUCCESS = 'GET_ORDER_DISTRIBUTOR_USERS_SUCCESS'

export const SET_ADDED_COMPANIES = 'SET_ADDED_COMPANIES'
export const UPDATE_ADDED_COMPANIES = 'UPDATE_ADDED_COMPANIES'