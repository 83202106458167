type PostPayload = {
  companyId: string
  excelFile: File
}

export class PostExcelWorkersQuery {
  query = ({ companyId, excelFile }: PostPayload) => {
    const fileData = new FormData()
    fileData.append('file', excelFile)

    return {
      url: `users/${companyId}/upload_workers`,
      method: 'POST',
      body: fileData
    }
  }

  prepareHeaders = (headers: Headers) => {
    headers.set("Content-Type", "multipart/form-data");
    return headers
  }

  transformResponse = (data: any) => {
    if (data?.workers instanceof Array)
      return data?.workers
    return []
  }
}