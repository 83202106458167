import * as actionTypes from './constants'

const initialState = {
  isLoadingGetLocations: false,
  isLoadingPostLocation: false,
  isLoadingPutLocation: false,
  isLoadingDeleteLocation: false,
  list: []
}

export const locationReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case actionTypes.SET_LOADING_GET_LOCATIONS:
      return {
        ...state,
        isLoadingGetLocations: true
      }

    case actionTypes.GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        isLoadingGetLocations: false,
        [payload.keyListLocations]: payload.data
      }

    case actionTypes.GET_LOCATIONS_FAILED:
      return {
        ...state,
        isLoadingGetLocations: false,
      }

    case actionTypes.SET_LOADING_POST_NEW_LOCATION:
      return {
        ...state,
        isLoadingPostLocation: true
      }

    case actionTypes.POST_NEW_LOCATION_SUCCESS:
      return {
        ...state,
        isLoadingPostLocation: false,
        [payload.keyListLocations]: [...state[payload.keyListLocations], payload.data]
      }

    case actionTypes.POST_NEW_LOCATION_FAILED:
      return {
        ...state,
        isLoadingPostLocation: false,
      }

    case actionTypes.SET_LOADING_PUT_LOCATION:
      return {
        ...state,
        isLoadingPutLocation: true
      }

    case actionTypes.PUT_LOCATION_SUCCESS:
      const currentList = [...state[payload.keyListLocations]]
      const index = currentList.findIndex(({ id }) => id === payload.data.id)
      currentList[index] = payload.data
      return {
        ...state,
        isLoadingPutLocation: false,
        [payload.keyListLocations]: currentList
      }

    case actionTypes.PUT_LOCATION_FAILED:
      return {
        ...state,
        isLoadingPutLocation: false
      }

    case actionTypes.SET_LOADING_DELETE_LOCATION:
      return {
        ...state,
        isLoadingDeleteLocation: true
      }

    case actionTypes.DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        isLoadingDeleteLocation: false,
        [payload.keyListLocations]: state[payload.keyListLocations].filter(({ id }) => id !== payload.id)
      }

    case actionTypes.DELETE_LOCATION_FAILED:
      return {
        ...state,
        isLoadingDeleteLocation: false
      }

    case actionTypes.REMOVE_UNIQUE_STATE_PROPERTY:
      const stateForUpdate = { ...state }
      delete (stateForUpdate[payload.uniqueId])
      return stateForUpdate
    default:
      return state
  }
}