import { WS_SEND_MESSAGE } from "../../utils/websockets/actionTypes/actionTypes";
import * as types from './types'

export const getNotifications = (page) => ({
  type: WS_SEND_MESSAGE,
  nameConnection: 'notifications',
  payload: {
    type: 'all_notifications',
    page,
    perPage: 10
  }
})
export const markAsReadNotifications = () => ({
  type: WS_SEND_MESSAGE,
  nameConnection: 'notifications',
  payload: {
    type: 'mark_as_read_notifications',
  }
})

// common

export const setToggleLoadingNotification = () => ({
  type: types.SET_TOGGLE_LOADING_NOTIFICATIONS
})
export const clearAppNotifications = () => ({
  type: types.CLEAR_APP_NOTIFICATIONS
})
export const readAllAppNotifications = () => ({
  type: types.READ_ALL_APP_NOTIFICATIONS
})
export const clearAppNotificationsForShow = () => ({
  type: types.CLEAR_ALL_APP_NOTIFICATIONS_FOR_SHOW
})