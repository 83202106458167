import { IDoc, IDocAction, IDocTableAction } from '../../../../models/IDocs'

const comparator = (a: IDocTableAction, b: IDocTableAction) => {
  const first = Number(a.cssSelector.replace(/\d+-signer-/, '').replace('-', '.'))
  const second = Number(b.cssSelector.replace(/\d+-signer-/, '').replace('-', '.'))
  return first - second
}

// Получение массива actions в соотвествии с порядком в actionOrder(при наличии)
const getOrderedActions = (doc: IDoc): IDocAction[] => {
  if (!!doc?.actionsOrder?.length && doc.actionsOrder !== null) {
    return doc.actionsOrder.reduce((accum, cur) => {
      const curExistingAction = doc?.actions?.find(action => cur.cssSelector === action.cssSelector)

      return curExistingAction ? [...accum, curExistingAction] : accum
    }, [] as IDocAction[])
  }

  return doc.actions
}

// Создание массива actions из обычных и табличных экшенов
const transformDocActions = (actions: IDocAction[]) => (
  actions.reduce((accum, action) => (
    action.allTableActions
      ? [...accum, ...[...action.allTableActions].map((tAction) => ({
        ...tAction,
        title: action.title,
        baseAction: action.activeTableActions![0]?.baseAction
      })).sort(comparator)]
      : [...accum, action]
  ), [] as (IDocAction | IDocTableAction)[])
)


export const getAllAction = (doc: IDoc): (IDocTableAction | IDocAction)[] => {
  if (!doc?.actions?.length)
    return []

  const orderedActions = getOrderedActions(doc)

  return transformDocActions(orderedActions)
}
