import React, { useEffect, useState } from 'react'
import { IProject } from '../../../../models/IProject'
import { useHistory } from 'react-router-dom'
import { PortalWrapper } from '../../../../../fsd/shared/ui-component'
import { useTSelector } from '../../../../utils/hooks/reduxHooks'
import { projectApi } from '../../../../api/projectApi/projectApi'
import { INotification } from '../../../../models/INotification'
import { ProjectButton } from "./ProjectButton/ProjectButton";
import { ProjectsListPopUp } from './ProjectsListPopUp/ProjectsListPopUp'
import { getProjects } from '../../../../redux/project/actions'
import { useOutSideClick } from '../../../../utils/hooks/useOutSideClick/useOutSideClick'
import { useDispatch } from 'react-redux'
import * as Icon from '../../../../../fsd/shared/ui-icons'
import cn from 'classnames'
import './LeftSidebarProject.scss'

export const LeftSidebarProject = React.memo(() => {
  const history = useHistory()
  const dispatch = useDispatch()

  const projectList = useTSelector((state: any) => state.project.projectList) as IProject[]
  const isOpen = useTSelector(state => state.sidebar.isOpenedLeftSidebar)
  const allNotifications: INotification[] = useTSelector((state: any) => state.appNotification.allNotifications)
  const companyRole = useTSelector(state => state.user.userInfo.companyRole)
  const finishGetProject = useTSelector((state: any) => state.project.finishGetProject)
  const disabledLeftSideBar = !projectList.length && finishGetProject

  const [isOpenProjectPopUp, setIsOpenProjectsPopUp] = useState(false)

  const [loadList, listQuery] = projectApi.useLazyGetProjectsInvitationsQuery()
  const { data: listData } = listQuery
  const { invitingProjects, invitingProjectsCount } = listData || {}

  const handleNotification = () => {
    const lastNotification = allNotifications[0]

    if (!lastNotification) return

    //Уведомления о приглашении в проект приходят с типом 'projects'
    //Но когда пользователь только зарегистрировался у него одного уведомление ->
    //"Добро пожаловать" с типом docflow(не понятно почему)
    //Поэтому ориентируюсь на header

    const isProjectNotification = lastNotification?.notificationType === 'projects'
    const isWelcomeNotification = lastNotification?.header === 'Добро пожаловать'
    const shouldHandleNotification = !isProjectNotification || !isWelcomeNotification
    const isUserAdmin = companyRole === 'admin'

    if (!shouldHandleNotification || !isUserAdmin) return

    dispatch(getProjects())
    loadList({})
  }

  useOutSideClick(() => {
    setIsOpenProjectsPopUp(false)
  }, ['.project-button'])

  useEffect(() => {
    if (companyRole === 'admin')
      handleNotification()
  }, [allNotifications])

  if (companyRole !== 'admin' && projectList.length === 0)
    return <></>

  return (
    <>
      <ProjectButton
        isOpen={isOpen}
        disabledSidebar={disabledLeftSideBar}
        invitingProjectsCount={invitingProjectsCount}
        isOpenProjectPopUp={isOpenProjectPopUp}
        setIsOpenProjectsPopUp={setIsOpenProjectsPopUp}
      />

      {!disabledLeftSideBar && (
        <div
          className={cn('settings-btn', { close: !isOpen })}
          onClick={() => history.push('/')}
        >
          {
            companyRole === 'admin'
            ? <Icon.Gear color='#949494'/>
            : <Icon.Eye />
          }
        </div>
      )}

      <PortalWrapper parentNodeSelector='.main-layout'>
        <ProjectsListPopUp
          isOpenPopUp={isOpenProjectPopUp}
          isOpenLeftSidebar={isOpen}
          getInvitationsProject={loadList}
          invitingProjects={invitingProjects}
          invitingProjectsCount={invitingProjectsCount}
          onChooseProject={() => setIsOpenProjectsPopUp(false)}
        />
      </PortalWrapper>
    </>
  )
})