import { IModelProperty } from '../../../models/IModel'

export const idFieldConfig = {
  name: "id",
  text: "id",
  type: "id",
  isVisible: true,
  id: "id"
} as IModelProperty

export const createPointProperty = (property: IModelProperty, withTransformDtEmptyTime = false) => {
  if (['date', 'time', 'datetime'].includes(property.type) && property.data?.length) {
    // Добавляем время в dt фильтр withTransformDtEmptyTime равен true только в момент гета на сущности
    if (property.data[0].includes(' ') && property.data[0].slice(-1) == ' ' && withTransformDtEmptyTime) {
      return {
        [property.name + '_after']: [property.data[0] + '00:00'],
        [property.name + '_before']: [property.data[1] + '23:59']
      }
    }
    return {
      [property.name + '_after']: [property.data[0]],
      [property.name + '_before']: [property.data[1]]
    }
  }
  if (property.data?.length) {
    return {
      [property.name]: property.data
    }
  }

  return {}
}

export const transformPointPropertyFromTemplate = (data: any[]) => {
  const resPointData: string[] = []
  data.forEach((value) => {
    if (value && typeof value == 'object')
      return Object.keys(value).forEach(key => resPointData.push(value[key]))
    resPointData.push(value)
  })
  return { data: resPointData.length ? resPointData : [] }
}

export const sortModelPropertyForPosition = (data: IModelProperty[], properyPosition: string[]) => {
  const fastTakeProperty: { [k: string]: IModelProperty } = data.reduce((accum, property) => ({
    ...accum,
    [property.name]: property
  }), {})

  return properyPosition.map((name) => fastTakeProperty[name])
}

export const getModelPointRequestProperties = (
  data: {
    [k: string]: string[] | Omit<IModelProperty, 'id'>[],
    fields: Omit<IModelProperty, 'id'>[]
  }
) => (
  Object
    .keys(data)
    .filter((key) => key !== 'fields')
    .reduce((accum, key) => ({
      ...accum,
      [key]: data[key]
    }), {})
)

export const sortModelPropertiesForExel = (data: IModelProperty[], propertyPositionList: string[]): IModelProperty[] => {
  const sortedModelProperties = propertyPositionList.reduce((acc, cur) => {
    const property = data.find(property => property.name === cur)
    if (property) acc.push(property)

    return acc
  }, [] as IModelProperty[])

  return sortedModelProperties
}

export const linkUpdate = (link: string) => (
  link.replace('/send/all-admin', '/sent')
)