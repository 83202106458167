import React from 'react'
import { IIcon } from './IIcon'

export const Select: React.FC<IIcon> = ({
  width = '1.2',
  height = '0.8',
  color = 'rgba(26, 114, 218, 0.3)',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 12 8"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 5.65685L10.5858 7.07107L6.34315 2.82843L2.10051 7.07107L0.686293 5.65685L6.34315 0L12 5.65685Z"
          fill={color}/>
  </svg>
)