import { useEffect, useRef, useState } from 'react'
import { useTDispatch, useTSelector } from '../../../../../(deprecated)/utils/hooks/reduxHooks'
import { setMobileInfo } from '../../../../../(deprecated)/redux/app/actionCreators'
import { getUserOnboarding } from '../../../../../(deprecated)/redux/user/actions'
import { useHistory } from 'react-router-dom'

const iosPlatforms = ['iphone', 'ipad', 'ipod', 'ipod touch']

export const useMobileMode = () => {
  const dispatch = useTDispatch()
  const history = useHistory()
  const { current: params } = useRef(history.location.search)

  const allParams = new URLSearchParams(params)
  const accessToken = allParams.get('access')
  const refreshToken = allParams.get('refresh')
  const [isViewBanner, setIsViewBanner] = useState(true)

  const typeOS = useTSelector(state => state.app.mobileInfo.typeOS)
  const isMobile = useTSelector(state => state.app.mobileInfo.isMobile)


  // ---handlers---
  const setMobileInfoCallback = () => {
    let type = /android/.test(navigator.userAgent.toLowerCase()) ? 1 : null
    type = iosPlatforms.includes(navigator.platform.toLocaleLowerCase()) ? 2 : type
    dispatch(setMobileInfo({
      isMobile: !!type,
      typeOS: type,
      width: window.screen?.orientation?.type == 'portrait-primary' ? window.innerWidth : window.innerHeight,
      height: window.screen?.orientation?.type == 'portrait-primary' ? window.innerHeight : window.innerWidth
    }))
  }

  //---useEffects---
  useEffect(() => {
    accessToken && refreshToken && history.push('/')
    setMobileInfoCallback()
    dispatch(getUserOnboarding())
    window.addEventListener('resize', setMobileInfoCallback)

    return () => {
      window.removeEventListener('resize', setMobileInfoCallback)
    }
  }, [])

  return {
    isViewBanner,
    setIsViewBanner,
    typeOS,
    isMobile
  }
}