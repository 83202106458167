import React, { useMemo } from 'react'
import Dropzone, { DropzoneProps } from 'react-dropzone'
import { fileFormats } from '../../../entities/File'
import cn from 'classnames'
import * as Icons from '../../ui-icons'

import './DndInput.scss'

export const DndInput: React.FC<PropsType> = ({
  onDrop,
  multiple = true,
  acceptingFormats,
  className,
  maxFilesCount = 20,
  maxFileSize = 100,
  icon = <Icons.UploadSimple />,
  withDescription = true,
  validator,
}) => {
  const accept = useMemo(() => (
    acceptingFormats.map((format: string) => fileFormats[format as keyof typeof fileFormats]).join(', ')
  ), [acceptingFormats])

  return (
    <Dropzone
      onDrop={onDrop}
      validator={validator}
    >
      {({ getRootProps, getInputProps }) => (
        <section className='drag-and-drop'>
          <div
            className={cn('dnd-wrapper', className)}
            {...getRootProps()}
          >

            <input
              {...getInputProps()}
              multiple={multiple}
              accept={accept}
            />

            <div className='img-wrapper'>
              {icon}
            </div>

            <p className='title'>
              Перетащите файлы сюда <br />
              или&nbsp;
              <span className='bold-text'>
                загрузите с устройства
              </span>
            </p>
            {
              withDescription && (
                <p className='description'>
                  Размер одного файла до {maxFileSize} Мб, <br />
                  количество - не более {maxFilesCount}
                </p>
              )
            }
          </div>
        </section>
      )}
    </Dropzone>
  )
}

type PropsType = {
  onDrop: DropzoneProps['onDrop']
  acceptingFormats: Array<keyof typeof fileFormats>

  maxFilesCount?: number
  maxFileSize?: number
  multiple?: boolean
  validator?: DropzoneProps['validator']

  title?: string
  icon?: JSX.Element
  className?: string
  withDescription?: boolean
}