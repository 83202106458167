import { useEffect } from 'react'

const useHandleClickOutside = (targetSelector, onClickOutside, isModal = false) => {
  const handleClick = (e) => {
    if ((targetSelector.every(selector => !!e.target.querySelector(selector)) && isModal)
      || !isModal) {
      if ([...targetSelector, '.info-tooltip'].find(selector => e.target.closest(selector))) return
      onClickOutside(e)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => document.removeEventListener('mousedown', handleClick)
  })
}

export default useHandleClickOutside