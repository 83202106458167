import { IProjectRole } from "../../../../(deprecated)/models/IProjectRole"
import { ACCESS_SUBJECT_KEY } from "../constant/accessSubject"
import { LOCKED_ROLE_FOR_CHANGE } from "../constant/role"

export const checkLockRoleAccessSubject = (
  data: {
    name: string,
    accessSubjects: IProjectRole['accessSubjects']
  },
  accessSubjectKey: ACCESS_SUBJECT_KEY
) => {
  if (LOCKED_ROLE_FOR_CHANGE.includes(data.name))
    return true

  if (['restrictedAccessToSystem', 'createOrUpdateProject'].includes(accessSubjectKey))
    return true

  return false
}