import { CompanyAdapter } from './CompanyAdapter'
import { addedNewNotification } from '../../redux/notifications/actions'
import store from '../../redux/store'

type UpdateCompanyQuery = {
  data: Record<string, any>,
  id: string
}

export class PutCompanyByIdQuery {
  query = ({ data, id }: UpdateCompanyQuery) => {
    return {
      url: `/companies/${id}`,
      method: 'PUT',
      body: data
    }
  }

  transformResponse = (response: any) =>  new CompanyAdapter(response).getFields()
}