export const WS_INIT = 'WS_INIT'
export const WS_SET_CONNECT = 'WS_SET_CONNECT'
export const WS_SEND_MESSAGE = 'WS_SEND_MESSAGE'
export const WS_SET_DISCONNECT = 'WS_SET_DISCONNECT'

export const WS_CONNECT_SUCCESS = 'WS_CONNECT_SUCCESS'
export const WS_DISCONNECT_SUCCESS = 'WS_DISCONNECT_SUCCESS'
export const WS_HAS_BEEN_ERROR = 'WS_HAS_BEEN_ERROR'
export const WS_ERROR_SEND_MESSAGE = 'WS_ERROR_SEND_MESSAGE'

export const WS_INCOMING_MESSAGE = 'WS_INCOMING_MESSAGE'