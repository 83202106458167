import axios, { AxiosRequestHeaders, ResponseType } from 'axios'
import { getHeaders } from './getHeaders.js'
import { errorProccessing } from './errorProccessing.js'
import { abortController } from './abortController'

import camelcaseFields from '../formatFields'
import HOST from './host'

const post = async <R, P = any>(path: string, data: P, responseType: ResponseType = 'json', withCancellation = false) => (
  new Promise<R>(async (res, rej) => {

    if (withCancellation) {
      abortController.abort(`post/${path}`)
    }

    const headers = await getHeaders() as AxiosRequestHeaders
    const REQUEST_URL = HOST.getInstance().getRequestUrl()

    try {
      const response = await axios.post<R>(
        `${REQUEST_URL}${path}`,
        data,
        { headers, signal: abortController.getSignal(`post/${path}`) },
      )
      res(camelcaseFields(response.data))
    } catch (error) {
      rej(camelcaseFields(errorProccessing(error)))
    }
  })
)

export default post