import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import useHandleClickOutside from '../../../utils/hooks/useHandleClickOutside'

import './popUp.scss'

const PopUp = ({ onClose, children, className, otherClass = [], ...containerProps }) => {
  useHandleClickOutside(['.pop-up__wrapper', ...otherClass], onClose)
  return <div className={cn('pop-up__wrapper', className)} {...containerProps}>{children}</div>
}

PopUp.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  containerProps: PropTypes.object,
}

export default PopUp
