import { useEffect } from "react"
import { identify, initializeAmplitude } from "../../amplitude"

export const useAmplitudeInitialize = (userId: string | null | undefined) => {
  useEffect(() => {
    initializeAmplitude()
  }, [])
  
  useEffect(() => {
    userId && identify(userId)
  }, [userId])
}