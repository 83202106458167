import React from 'react'
import { IIcon } from './IIcon'

export const PaperPlane:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.75 11.9895C21.7507 12.2567 21.68 12.5193 21.5451 12.7501C21.4103 12.9808 21.2163 13.1714 20.9831 13.302L5.24156 22.3029C5.01539 22.4312 4.75999 22.499 4.5 22.4998C4.26023 22.4993 4.02407 22.4413 3.81132 22.3308C3.59857 22.2202 3.41542 22.0602 3.27723 21.8643C3.13905 21.6684 3.04984 21.4421 3.0171 21.2046C2.98436 20.9671 3.00904 20.7252 3.08906 20.4992L5.65312 13.0039C5.67839 12.9297 5.72624 12.8653 5.78997 12.8198C5.8537 12.7742 5.93009 12.7497 6.00844 12.7498H12.75C12.8528 12.75 12.9546 12.7291 13.049 12.6883C13.1434 12.6476 13.2284 12.5879 13.2987 12.5129C13.369 12.4379 13.4232 12.3492 13.4578 12.2524C13.4925 12.1556 13.5068 12.0527 13.5 11.9501C13.483 11.7572 13.3938 11.5779 13.2502 11.4481C13.1066 11.3182 12.9192 11.2474 12.7256 11.2498H6.015C5.93677 11.2499 5.86046 11.2256 5.79675 11.1802C5.73303 11.1348 5.68511 11.0706 5.65969 10.9967L3.08719 3.49667C2.98701 3.20928 2.97662 2.89821 3.05741 2.60478C3.13819 2.31135 3.30632 2.04943 3.53948 1.85381C3.77264 1.65819 4.05978 1.53812 4.36279 1.50955C4.6658 1.48098 4.97032 1.54526 5.23594 1.69385L20.9859 10.6835C21.2176 10.814 21.4104 11.0036 21.5447 11.2331C21.6789 11.4626 21.7498 11.7236 21.75 11.9895Z" fill={color}/>
  </svg>
)