import {
  setLoadingAddedImages,
  addedImagesSuccess,
  addedImagesFailed,
  clearImagesSuccess
} from './actionCreator'
import { addedNewNotification } from '../notifications/actions'
import postImage from '../../utils/axios/postImage'

export const addedImage = (images, type) => (
  dispatch => {
    dispatch(setLoadingAddedImages)
    Promise.allSettled(Object.keys(images).map(key => postImage(images[key])))
      .then((data) => {
        if (data[0].status === 'rejected') {
          dispatch(addedNewNotification({ message: 'Не удалось загрузить изображение' }))
          return
        }
        dispatch(addedImagesSuccess({
          images: data.map(({ value }) => value),
          type
        }))
        setTimeout(() => {
          dispatch(clearImagesSuccess())
        }, 500)
      })
      .catch(() => dispatch(addedImagesFailed()))
  }
)