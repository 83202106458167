import React from 'react'
import { IIcon } from './IIcon'

export const ChatTeardropText: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 19 19"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.375 0.25C6.88943 0.25273 4.50645 1.24133 2.74889 2.99889C0.991327 4.75645 0.00272962 7.13943 0 9.625V17.5309C0.000496137 17.9204 0.155431 18.2938 0.430826 18.5692C0.706222 18.8446 1.0796 18.9995 1.46906 19H9.375C11.8614 19 14.246 18.0123 16.0041 16.2541C17.7623 14.496 18.75 12.1114 18.75 9.625C18.75 7.1386 17.7623 4.75403 16.0041 2.99587C14.246 1.23772 11.8614 0.25 9.375 0.25ZM12 12.25H6C5.80109 12.25 5.61032 12.171 5.46967 12.0303C5.32902 11.8897 5.25 11.6989 5.25 11.5C5.25 11.3011 5.32902 11.1103 5.46967 10.9697C5.61032 10.829 5.80109 10.75 6 10.75H12C12.1989 10.75 12.3897 10.829 12.5303 10.9697C12.671 11.1103 12.75 11.3011 12.75 11.5C12.75 11.6989 12.671 11.8897 12.5303 12.0303C12.3897 12.171 12.1989 12.25 12 12.25ZM12 9.25H6C5.80109 9.25 5.61032 9.17098 5.46967 9.03033C5.32902 8.88968 5.25 8.69891 5.25 8.5C5.25 8.30109 5.32902 8.11032 5.46967 7.96967C5.61032 7.82902 5.80109 7.75 6 7.75H12C12.1989 7.75 12.3897 7.82902 12.5303 7.96967C12.671 8.11032 12.75 8.30109 12.75 8.5C12.75 8.69891 12.671 8.88968 12.5303 9.03033C12.3897 9.17098 12.1989 9.25 12 9.25Z"
      fill={color}/>
  </svg>
)