import React from 'react'
import './CardWrapper.scss'

export const CardWrapper = React.memo<PropsType>(({
  onClick,
  className= '',
  children
}) => (
  <div
    className={`card__wrapper ${className}`}
    onClick={onClick}
  >
    {children}
  </div>
))

type PropsType = {
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  className?: string
  children: React.ReactNode | React.ReactNode[]
}

