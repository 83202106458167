import { getArrayWithUniqueObj } from "../../utils/getArrayWithUniqueObj"
import * as types from "./types"

const initialState = {
  allNotifications: [],
  allCount: null,
  totalPages: null,
  unreadNotificationsCount: null,
  isLoadingNotifications: false,
  notificationsConnections: false,
  notificationsForShow: [] // нотификации для отображения сплывашки справа
}

export const appNotificationReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    // CONNECTIONS
    case types.WS_NOTIFICATIONS_CONNECT_SUCCESS:
      return { ...state, notificationsConnections: true }
    case types.WS_NOTIFICATIONS_DISCONNECT_SUCCESS:
      return { ...state, notificationsConnections: false }
    // INCOMING_MESSAGES
    case types.WS_INCOMING_MESSAGE_ALL_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: [...state.allNotifications, ...payload.results],
        allCount: payload.count,
        totalPages: payload.meta.totalPages,
        unreadNotificationsCount: payload.meta.unreadCount,
        isLoadingNotifications: false
      }
    case types.WS_INCOMING_MESSAGE_RECEIVE_INCOMING_NOTIFICATION:
      return {
        ...state,
        unreadNotificationsCount: state.unreadNotificationsCount !== null
          ? state.unreadNotificationsCount + 1
          : 1,
        notificationsForShow: [...state.notificationsForShow, payload.results],
        allNotifications: getArrayWithUniqueObj([payload.results, ...state.allNotifications])
      }

    // common
    case types.SET_TOGGLE_LOADING_NOTIFICATIONS:
      return {
        ...state,
        isLoadingNotifications: true
      }
    case types.CLEAR_APP_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: []
      }
    case types.READ_ALL_APP_NOTIFICATIONS:
      return {
        ...state,
        unreadNotificationsCount: 0,
        allNotifications: state.allNotifications.map(notification => ({ ...notification, isRead: true }))
      }
    case types.CLEAR_ALL_APP_NOTIFICATIONS_FOR_SHOW:
      return {
        ...state,
        notificationsForShow: []
      }
    default:
      return state
  }
}