import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DocsFilter } from './model'

export const initialState: Readonly<DocsFilter> = {
  search: {
    searchValue: '',
    searchType: 'title'
  },
  templates: [],
  owners: [],
  companies: [],
  statuses: [],
  dateTime: ''
}

export const docsFilterSlice = createSlice({
  name: 'docsFilter',
  initialState,
  reducers: {
    setSearchFilter(state, { payload }: PayloadAction<DocsFilter['search']>) {
      state.search = payload
    },

    setFilters(state, { payload }: PayloadAction<Partial<DocsFilter>>) {
      (Object.keys(payload) as (keyof DocsFilter)[]).map((key) => (
        (state as Record<string, unknown>)[key] = payload[key]
      ))
    },

    resetDocFilters() {
      return initialState
    },
  }
})

export const {
  setSearchFilter,
  setFilters,
  resetDocFilters,
} = docsFilterSlice.actions

export default docsFilterSlice.reducer