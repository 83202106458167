import { propInit } from '../type'

export const chatMessagesInitSettings: propInit = {
  initApp: false,
  uniqueName: 'chat_messages',
  path: '/$PROJECT$/chats/$CHAT_ID$?access_token=$TOKEN$',
  pathData: 'data',
  dependencies: [
    {
      state: {
        actionTypeDependence: 'SET_ACTIVE_PROJECT',
        pathActionType: 'payload',
        pathStore: 'app.activeProjectId'
      },
      action: 'close',
      uniqueKey: '$PROJECT$'
    },
    {
      state: {
        actionTypeDependence: 'SET_ACTIVE_CHAT_ID',
        pathActionType: 'payload',
        pathStore: 'chat.activeChatId'
      },
      uniqueKey: '$CHAT_ID$'
    }
  ],
}