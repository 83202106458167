import camelcaseFields from '../../utils/formatFields'

export interface ICompanyModel {
  id: number
  title: string
  type: string
  imageUrl: string
  imageId: number
  phone: string
  email: string
  description: string
  address: string
  inn: string
}

export class CompanyAdapter {
  id: ICompanyModel['id'] = NaN
  title: ICompanyModel['title'] = ''
  type: ICompanyModel['type'] = ''
  imageUrl: ICompanyModel['imageUrl'] = ''
  imageId: ICompanyModel['imageId'] = 0
  phone: ICompanyModel['phone'] = ''
  email: ICompanyModel['email'] = ''
  description: ICompanyModel['description'] = ''
  address: ICompanyModel['address'] = ''
  inn: ICompanyModel['inn'] = ''

  constructor(company: unknown) {
    try {
      const camelCaseCompany = camelcaseFields(company)

      this.id = camelCaseCompany.id
      this.title = camelCaseCompany.title
      this.type = camelCaseCompany.type
      this.imageUrl = camelCaseCompany.image?.url
      this.imageId = camelCaseCompany.image?.id
      this.phone = camelCaseCompany.phone
      this.email = camelCaseCompany.email
      this.description = camelCaseCompany.description
      this.address = camelCaseCompany.address
      this.inn = camelCaseCompany.inn

    } catch (e) {
      console.error(e)
    }
  }

  getDeserializedFields() {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
      image: {
        id: this.imageId,
        url: this.imageUrl
      },
      phone: this.phone,
      email: this.email,
      description: this.description,
      address: this.address,
      inn: this.inn
    }
  }

  getFields(): ICompanyModel {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
      imageUrl: this.imageUrl,
      imageId: this.imageId,
      phone: this.phone,
      email: this.email,
      description: this.description,
      address: this.address,
      inn: this.inn
    }
  }
}