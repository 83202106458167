import React, { useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { SnackbarContent, useSnackbar } from 'notistack'
import { unparseTags } from '../../../utils/pasrseTags'
import { Button } from '../../../../fsd/shared/ui-component'
import { settingActiveProject } from '../../../redux/project/actions'
import { setBacklightForDocShort } from '../../../../fsd/shared/reducers/docsShort/backlightForDocsShort/slice'
import { useDispatch } from 'react-redux'

import ReactHtmlParser from 'html-react-parser'
import cn from 'classnames'
import queryString from 'query-string'
import sanitize from 'sanitize-html'

import './Notification.scss'

const ACTION_BUTTON_LIST = {
  close: ({ key, closeSnackbar }) => closeSnackbar(key),
  redirect: ({ history, path, cb, key }) => {
    history.push(path)
    cb?.(key)
  },
  nothing: ({cb}) => {
    cb?.()
  }
}

const CustomNotification = React.forwardRef((props, ref) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const buttonGroup = useRef(null)
  const mainInfo = useRef(null)

  const { closeSnackbar } = useSnackbar()
  const { notification, id } = props
  const { header, message } = notification

  const getPath = () => {
    const url = new URL(notification.url)
    return `${url.pathname}${url.search}`
  }

  const getButtonList = (data) => {
    const defaultButton = { title: 'Понятно', action: 'close' }
    const buttonList = [defaultButton]

    //  TODO переделать! 
    //  Обработка нотификации, когда изменили доступ к нескольким документам
    if (data.notificationType == 'docflow' && data.title == 'Изменение в доступе к документу') {

      const buttonForSeenDoc = {
        title: 'Смотреть',
        action: 'nothing',
        cb: () => {
          const parsedUrl = queryString.parseUrl(data.url)

          dispatch(settingActiveProject(Number(parsedUrl.query.project_id)))
          dispatch(setBacklightForDocShort(data.additionalInfo))
          dispatchEvent(new Event('update-docsShort'))

          !history.location.pathname.includes('/documents/incoming') &&
            history.push('/documents/incoming')
        }
      }

      buttonList.push(buttonForSeenDoc)
      return buttonList
    }


    if (data.url) {
      const buttonForSeenDoc = {
        title: 'Смотреть',
        action: 'redirect',
        // todo убрать replace полсе изменений на беке
        path: getPath().replace('all/', ''),
        cb: notification.notificationType === 'document_discussion'
          ? (key) => {
            dispatchEvent(new Event('openDocDiscussion'))
            closeSnackbar(key)
          }
          : undefined
      }
      buttonList.push(buttonForSeenDoc)
    }

    return buttonList
  }

  const buttonList = getButtonList(notification)

  const handleControlButton = (button) => (
    ACTION_BUTTON_LIST[button.action]({
      key: id,
      closeSnackbar,
      history,
      path: button.path,
      cb: button?.cb
    })
  )

  const renderButtons = () => (
    <div className='notification__btn-group' ref={buttonGroup}>
      {buttonList.map(button => (
        <Button
          key={button.title}
          buttonType='small'
          title={button.title}
          onClick={() => ACTION_BUTTON_LIST[button.action] ? handleControlButton(button) : button.action()}
        />
      ))}
    </div>
  )

  //---useEffect---
  useEffect(() => {
    const { current: button } = buttonGroup
    const { current: info } = mainInfo

    if (button && info && button.offsetLeft === info.offsetLeft) {
      button.classList.add('notification__btn-group_top')
      info.classList.add('notification__main-info_no-margin')
    }
  }, [notification])

  return (
    <SnackbarContent
      ref={ref}
      className={cn('notification')}
    >
      <div className='notification__main-info' ref={mainInfo}>
        {notification.notificationType === 'document_discussion'
          ? (
            <>
              <h2 className='notification__title'>{notification.header}</h2>
              <h2 className='notification__title'>{notification.title}</h2>
            </>
          )
          : <h2 className="notification__title">{notification.title || header}</h2>
        }

        {message && typeof message == 'string' && (
          <div className='notification__message'>
            {ReactHtmlParser(unparseTags(sanitize(message)))}
          </div>
        )}
      </div>

      {buttonList.length && renderButtons()}
    </SnackbarContent>
  )
})

export default CustomNotification