import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { DeletedUserInfo } from './types'
import { LoadingType } from '../../../../(deprecated)/redux/utilityObject'

type InitialState = {
  deletedUserEmail: string,
  userCode: string,
  deletedUserInfo: null | DeletedUserInfo,
  loadingDelete: LoadingType
}

const initialState: InitialState = {
  deletedUserEmail: '',
  userCode: '',
  deletedUserInfo: null,
  loadingDelete: 'idle'
}

const accountDeleteSlice = createSlice({
  name: 'accountDelete-slice',
  initialState,
  reducers: {
    setDeletedUserEmail(state, { payload }: PayloadAction<string>) {
      state.deletedUserEmail = payload
    },
    setUserCode(state, { payload }: PayloadAction<string>) {
      state.userCode = payload
    },
    setDeletedUserInfo(state, { payload }: PayloadAction<DeletedUserInfo>) {
      state.deletedUserInfo = payload
    },
    toggleLoadingDelete(state, { payload }: PayloadAction<LoadingType>) {
      state.loadingDelete = payload
    }
  }
})

export const {
  setDeletedUserEmail,
  setUserCode,
  setDeletedUserInfo,
  toggleLoadingDelete
} = accountDeleteSlice.actions

export default accountDeleteSlice.reducer