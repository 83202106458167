import {
  SET_LOADING_GET_COMPANY,
  GET_COMPANY_SUCCESS,
  POST_COMPANY_DOCUMENT_SUCCESS,
  PUT_COMPANY_SUCCESS,
  SET_LOADING_POST_WORKER,
  POST_WORKER_SUCCESS,
  POST_WORKER_FAILED,
  SET_LOADING_PUT_COMPANY,
  PUT_COMPANY_FAILED,
  SET_LOADING_GET_ACTIVE_USER,
  GET_ACTIVE_USER_SUCCESS,
  GET_ACTIVE_USER_FAILED,
  SET_LOADING_GET_WORKER,
  GET_WORKER_SUCCESS,
  GET_WORKER_FAILED,
  SET_LOADING_POST_WORKER_FROM_EXCEL,
  POST_WORKER_FROM_EXCEL_SUCCESS,
  POST_WORKER_FROM_EXCEL_FAILED,
  GET_LINK_FOR_QR_CODE,
  DELETE_USER,
  DELETE_COMPANY,
  DELETE_WORKER,
  GET_COMPANY_FAILED,
  SET_IS_ALL_USERS_LOADING,
  GET_IS_ALL_USERS_SUCCESS,
  CLEAR_ALL_USERS,
  SET_LOADING_ADD_USER,
  SET_LOADING_COMPANY_QRS
} from './constants'

const initialState = {
  isLoading: false,
  isAllUsersLoading: false,
  allUsersCount: 0,
  allUsers: [],
  allUsersMeta: null, // todo не хранить отдельно мету, исправить зависимые компоненты
  company: {},
  isLoadingPostWorker: false,
  isLoadingPutCompany: false,
  isLoadingActiveUser: false,
  isLoadingAddUser: false,
  activeUser: [],
  isLoadingWorker: false,
  worker: [],
  isLoadingPostWorkerFromExcel: false,
  linkForQrCode: null,
  isNotFoundCompany: false,
  loadingCompanyQrsInfo: {
    isLoadingCompanyQrs: false,
    companyId: null
  }
  
}

export const companyReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case SET_LOADING_GET_COMPANY:
      return { ...state, isLoading: true, isNotFoundCompany: false }

    case GET_COMPANY_SUCCESS:
      return { ...state, company: payload, isLoading: false }

    case GET_COMPANY_FAILED:
      return { ...state, isLoading: false, isNotFoundCompany: true }

    case POST_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          document: [
            payload,
            ...state.company.document
          ]
        }
      }

    case SET_LOADING_PUT_COMPANY:
      return {
        ...state,
        isLoadingPutCompany: true
      }

    case PUT_COMPANY_SUCCESS:
      return {
        ...state,
        company: { ...state.company, ...payload },
        isLoadingPutCompany: false
      }

    case PUT_COMPANY_FAILED:
      return {
        ...state,
        isLoadingPutCompany: false
      }

    case SET_LOADING_POST_WORKER:
      return {
        ...state,
        isLoadingPostWorker: true
      }

    case POST_WORKER_SUCCESS:
      return {
        ...state,
        isLoadingPostWorker: false,
        worker: [...state.worker, payload]
      }

    case POST_WORKER_FAILED:
      return {
        ...state,
        isLoadingPostWorker: false
      }

    case SET_LOADING_GET_ACTIVE_USER:
      return {
        ...state,
        isLoadingActiveUser: true
      }

    case GET_ACTIVE_USER_SUCCESS:
      return {
        ...state,
        isLoadingActiveUser: false,
        activeUser: payload
      }

    case GET_ACTIVE_USER_FAILED:
      return {
        ...state,
        isLoadingActiveUser: false,
      }

    case SET_LOADING_GET_WORKER:
      return {
        ...state,
        isLoadingWorker: true,
      }

    case GET_WORKER_SUCCESS:
      return {
        ...state,
        isLoadingWorker: false,
        worker: payload
      }

    case GET_WORKER_FAILED:
      return {
        ...state,
        isLoadingWorker: false,
      }

    case SET_LOADING_POST_WORKER_FROM_EXCEL:
      return {
        ...state,
        isLoadingPostWorkerFromExcel: true
      }

    case POST_WORKER_FROM_EXCEL_SUCCESS:
      return {
        ...state,
        isLoadingPostWorkerFromExcel: false
      }

    case POST_WORKER_FROM_EXCEL_FAILED:
      return {
        ...state,
        isLoadingPostWorkerFromExcel: false
      }

    case GET_LINK_FOR_QR_CODE:
      return {
        ...state,
        linkForQrCode: payload
      }
    case DELETE_WORKER:
      return {
        ...state,
        worker: state.worker.filter(({ id }) => (id !== payload))
      }
    case DELETE_USER:
      return {
        ...state,
        activeUser: state.activeUser.filter(({ id }) => (id !== payload))
      }
    case DELETE_COMPANY:
      return initialState

    case GET_IS_ALL_USERS_SUCCESS: {
      const users = payload.hasOwnProperty('results')
        ? payload.results
        : payload

      return {
        ...state,
        isAllUsersLoading: false,
        allUsers: [...state.allUsers, ...users],
        allUsersMeta: payload.meta,
        allUsersCount: payload.count
      }
    }
    case SET_IS_ALL_USERS_LOADING: {
      return {
        ...state,
        isAllUsersLoading: payload
      }
    }
    case CLEAR_ALL_USERS: {
      return {
        ...state,
        allUsers: [],
        allUsersMeta: null,
        allUsersCount: 0
      }
    }

    case SET_LOADING_ADD_USER:
      return {
        ...state,
        isLoadingAddUser: payload
      }

    case SET_LOADING_COMPANY_QRS:
      return {
        ...state,
        loadingCompanyQrsInfo: payload
      } 

    default:
      return state
  }
}