import React from 'react'
import { IIcon } from './IIcon'

export const ArrowsCounterClockwise: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#1A65DA',
  ...innerProps
}) => (
  < svg xmlns="http://www.w3.org/2000/svg"  {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none">
    <path d="M21 15V19.5C21.0001 19.6484 20.9562 19.7935 20.8738 19.917C20.7914 20.0404 20.6742 20.1367 20.5371 20.1935C20.4 20.2503 20.2491 20.2651 20.1035 20.2361C19.958 20.2072 19.8243 20.1356 19.7194 20.0306L18.2137 18.5241C16.3247 20.1469 14.19 21 12 21C7.98563 21 5.57063 18.6309 5.46938 18.5306C5.32852 18.3901 5.24924 18.1995 5.24898 18.0005C5.24871 17.8016 5.32749 17.6107 5.46797 17.4698C5.60845 17.329 5.79913 17.2497 5.99807 17.2494C6.197 17.2492 6.3879 17.328 6.52875 17.4684C6.5625 17.5013 8.64 19.5 12 19.5C14.1722 19.5 15.93 18.4828 17.1488 17.46L15.2194 15.5306C15.1144 15.4257 15.0428 15.292 15.0139 15.1465C14.9849 15.0009 14.9997 14.85 15.0565 14.7129C15.1133 14.5758 15.2096 14.4586 15.333 14.3762C15.4565 14.2938 15.6016 14.2499 15.75 14.25H20.25C20.4489 14.25 20.6397 14.329 20.7803 14.4697C20.921 14.6103 21 14.8011 21 15ZM8.94281 9.28687C8.99966 9.14985 9.0146 8.99904 8.98573 8.85352C8.95686 8.70801 8.88548 8.57432 8.78063 8.46937L6.85125 6.54C8.07 5.51719 9.82781 4.5 12 4.5C15.3703 4.5 17.4497 6.51 17.4713 6.5325C17.6122 6.67298 17.8032 6.75171 18.0023 6.75135C18.2013 6.751 18.392 6.6716 18.5325 6.53062C18.673 6.38965 18.7517 6.19863 18.7514 5.99961C18.751 5.80059 18.6716 5.60986 18.5306 5.46938C18.4294 5.36906 16.0144 3 12 3C9.81 3 7.67531 3.85313 5.78625 5.47594L4.28063 3.96938C4.17573 3.86437 4.04204 3.79284 3.89648 3.76385C3.75092 3.73487 3.60002 3.74972 3.46291 3.80653C3.32579 3.86335 3.20861 3.95957 3.12621 4.08301C3.04381 4.20646 2.99988 4.35158 3 4.5V9C3 9.19891 3.07902 9.38968 3.21967 9.53033C3.36032 9.67098 3.55109 9.75 3.75 9.75H8.25C8.39834 9.74997 8.54333 9.70595 8.66665 9.62352C8.78997 9.54108 8.88607 9.42393 8.94281 9.28687Z" fill={color} />
  </svg >
)
