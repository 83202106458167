import React from 'react'
import { IIcon } from './IIcon'

export const Link:React.FC<IIcon> = ({
  width = '2',
  height = '1',
  color = '#1A65DA',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 20 10"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.9 5C1.9 3.29 3.29 1.9 5 1.9H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8.1H5C3.29 8.1 1.9 6.71 1.9 5ZM6 6H14V4H6V6ZM15 0H11V1.9H15C16.71 1.9 18.1 3.29 18.1 5C18.1 6.71 16.71 8.1 15 8.1H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0Z"
      fill={color}/>
  </svg>
)


