import Host from "./host"

// Если есть версия (v), то вырезаем все что после 4 слеша
// Если нет версии, то вырезаем все что после 3 слеша
export const withoutHost = (url: string) => {
    const splited = url.split('/')

    if (Host.getInstance().apiVersion)
        return '/' + splited.slice(4, splited.length).join('/')

    return '/' + splited.slice(3, splited.length).join('/')
}