import {
  SET_PROJECT_LOADING_START,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAILED,
  PUT_PROJECT_INFO_SUCCESS,
  POST_PROJECT_DOCUMENTS_SUCCESS,
  POST_PROJECT_DOCUMENTS_FAILED,
  POST_PROJECT_EMLOYEES_SUCCESS,
  SET_PROJECT_IMAGE_LOADING,
  GET_PROJECT_IMAGE_SUCCESS,
  GET_PROJECT_IMAGE_FAILED,
  POST_PROJECT_IMAGES_SUCCESS,
  POST_PROJECT_IMAGES_FAILED,
  DELETE_PROJECT_IMAGES_SUCCESS,
  DELETE_PROJECT_IMAGES_FAILED,
  GET_PROJECT_CUSTOMER_IMAGE_SUCCESS,
  SET_PROJECT_DOCUMENTS_LOADING_START,
  GET_PROJECT_DOCUMENTS_SUCCESS,
  GET_PROJECT_DOCUMENTS_FAILED,
  SET_LOADING_PROJECT_MEMBERS_START,
  GET_PROJECT_MEMBERS_SUCCESS,
  GET_PROJECT_MEMBERS_FAILED,
  SET_LOADING_POST_PROJECT_START,
  POST_PROJECT_SUCCESS,
  POST_PROJECT_FAILED,
  PUT_PROJECT_MEMBERS_SUCCESS,
  DELETE_PROJECT_MEMBER_SUCCESS,
  DELETE_PROJECT_MEMBER_FAILED,
  DELETE_PROJECT_DOCUMENT_SUCCESS,
  SET_LOADING_GET_PROJECT_SIGNERS,
  GET_PROJECT_SIGNERS_SUCCESS,
  GET_PROJECT_SIGNERS_FAILED,
  SET_LOADING_POST_PROJECT_DOCUMENTS,
  SET_LOADING_GET_PROJECT_WORKER,
  GET_PROJECT_WORKER_SUCCESS,
  GET_PROJECT_WORKER_FAILED,
  SET_LOADING_GET_PROJECT_EXECUTOR,
  GET_PROJECT_EXECUTOR_SUCCESS,
  GET_PROJECT_EXECUTOR_FAILED,
  GET_MOVE_MEMBERS_LOCAL_FROM_PROJECTLIST,
  GET_BUSY_WORKERS_FAILED,
  GET_BUSY_WORKERS_SUCCESS,
  DELETE_BUSY_WORKER,
  CLEAR_BUSY_WORKERS,
  POST_COMPANY_TO_PROJECT_SUCCESS,
  DELETE_COMPANY_FROM_PROJECT_SUCCESS,
  PUT_COMPANY_PROJECT_SUCCESS,
  GET_PROJECT_COMPANY_SIGNERS_SUCCESS,
  PUT_PROJECT_COMPANY_SIGNERS_SUCCESS,
  PUT_PROJECT_MEMBERS_FAILED,
  GET_PROJECT_FIND_USERS_SUCCESS,
  SET_LOADING_PROJECT_FIND_USERS,
  GET_PROJECT_FIND_USERS_LOAD_MORE_SUCCESS,
  SET_METRICS_PROJECT,
  TOGGLE_LOADING_METRIC,
  GET_ORDER_DISTRIBUTOR_USERS_SUCCESS,
  SET_ADDED_COMPANIES,
  UPDATE_ADDED_COMPANIES
} from './constants';

export const setLoadingProjectStart = () => ({
  type: SET_PROJECT_LOADING_START
})

export const getProjectSuccess = project => ({
  type: GET_PROJECT_SUCCESS,
  payload: project,
})

export const getProjectFailed = () => ({
  type: GET_PROJECT_FAILED
})

export const putProjectInfoSuccess = (data) => ({
  type: PUT_PROJECT_INFO_SUCCESS,
  payload: data
})

export const setLoadingPostProjectDocuments = () => ({
  type: SET_LOADING_POST_PROJECT_DOCUMENTS
})

export const postProjectEmloyeesSuccess = (project) => ({
  type: POST_PROJECT_EMLOYEES_SUCCESS,
  payload: project
})


export const setProjectImagesLoading = () => ({
  type: SET_PROJECT_IMAGE_LOADING
})

export const getProjectImagesSuccess = (images) => ({
  type: GET_PROJECT_IMAGE_SUCCESS,
  payload: images
})

export const getProjectImagesFailed = () => ({
  type: GET_PROJECT_IMAGE_FAILED
})

export const postProjectImagesSuccess = (images) => ({
  type: POST_PROJECT_IMAGES_SUCCESS,
  payload: images
})

export const postProjectImagesFailed = () => ({
  type: POST_PROJECT_IMAGES_FAILED
})

export const deleteProjectImageSuccess = (imageId) => ({
  type: DELETE_PROJECT_IMAGES_SUCCESS,
  payload: imageId
})

export const deleteProjectImageFailed = () => ({
  type: DELETE_PROJECT_IMAGES_FAILED
})

export const getProjectCustomerImage = (data) => ({
  type: GET_PROJECT_CUSTOMER_IMAGE_SUCCESS,
  payload: data
})

export const setProjectDocumentsLoading = () => ({
  type: SET_PROJECT_DOCUMENTS_LOADING_START
})

export const getProjectDocumentsSuccess = (data) => ({
  type: GET_PROJECT_DOCUMENTS_SUCCESS,
  payload: data
})

export const getProjectDocumentsFailed = () => ({
  type: GET_PROJECT_DOCUMENTS_FAILED
})

export const setLoadingProjectMembers = () => ({
  type: SET_LOADING_PROJECT_MEMBERS_START
})

export const putProjectMembersSuccess = (members) => ({
  type: PUT_PROJECT_MEMBERS_SUCCESS,
  payload: members
})

export const putProjectMembersFailed = () => ({
  type: PUT_PROJECT_MEMBERS_FAILED
})
export const getMoveMembersFromProjectList = (id) => ({
  type: GET_MOVE_MEMBERS_LOCAL_FROM_PROJECTLIST,
  payload: id
})

export const getProjectMembersSuccess = (data) => ({
  type: GET_PROJECT_MEMBERS_SUCCESS,
  payload: data
})

export const getProjectMembersFailed = () => ({
  type: GET_PROJECT_MEMBERS_FAILED
})

export const deleteProjectMemberSuccess = (id) => ({
  type: DELETE_PROJECT_MEMBER_SUCCESS,
  payload: id
})


export const deleteProjectMemberFailed = () => ({
  type: DELETE_PROJECT_MEMBER_FAILED
})

export const setLoadingPostProject = () => ({
  type: SET_LOADING_POST_PROJECT_START
})

export const postProjectSuccess = (project) => ({
  type: POST_PROJECT_SUCCESS,
  payload: project
})

export const postProjectFailed = (errorMessages) => ({
  type: POST_PROJECT_FAILED,
  payload: errorMessages
})

export const deleteProjectDocumentSuccess = (id) => ({
  type: DELETE_PROJECT_DOCUMENT_SUCCESS,
  payload: id
})

export const setLoadingGetProjectSigners = () => ({
  type: SET_LOADING_GET_PROJECT_SIGNERS
})

export const getProjectSignersSuccess = (signers) => ({
  type: GET_PROJECT_SIGNERS_SUCCESS,
  payload: signers
})

export const getProjectSignersFailed = () => ({
  type: GET_PROJECT_SIGNERS_FAILED
})

export const setLoadingGetProjectWorker = () => ({
  type: SET_LOADING_GET_PROJECT_WORKER
})
export const getProjectWorkerSuccess = (data) => ({
  type: GET_PROJECT_WORKER_SUCCESS,
  payload: data
})
export const getProjectWorkerFailed = () => ({
  type: GET_PROJECT_WORKER_FAILED
})

export const setLoadingGetProjectExecutor = () => ({
  type: SET_LOADING_GET_PROJECT_EXECUTOR
})
export const getProjectExecutorSuccess = (data) => ({
  type: GET_PROJECT_EXECUTOR_SUCCESS,
  payload: data
})
export const getProjectExecutorFailed = () => ({
  type: GET_PROJECT_EXECUTOR_FAILED
})

export const getBusyWorkersSuccess = (busyWorker) => ({
  type: GET_BUSY_WORKERS_SUCCESS,
  payload: busyWorker
})
export const getBysuWorkersFailed = () => ({
  type: GET_BUSY_WORKERS_FAILED
})
export const removeBusyWorker = (id) => ({
  type: DELETE_BUSY_WORKER,
  payload: id
})
export const clearBusyWorkers = () => ({
  type: CLEAR_BUSY_WORKERS
})

export const postCompanyToProjectSuccess = (data) => ({
  type: POST_COMPANY_TO_PROJECT_SUCCESS,
  payload: data
})

export const deleteCompanyFromProjectSuccess = (data) => ({
  type: DELETE_COMPANY_FROM_PROJECT_SUCCESS,
  payload: data
})

export const putCompanyProjectSuccess = (data) => ({
  type: PUT_COMPANY_PROJECT_SUCCESS,
  payload: data
})

export const getProjectCompanySignersSuccess = (data) => ({
  type: GET_PROJECT_COMPANY_SIGNERS_SUCCESS,
  payload: data
})

export const putProjectCompanySignersSuccess = (data) => ({
  type: PUT_PROJECT_COMPANY_SIGNERS_SUCCESS,
  payload: data
})

export const getProjectFindUsersSuccess = (data) => ({
  type: GET_PROJECT_FIND_USERS_SUCCESS,
  payload: data
})

export const getProjectFindUsersLoadMoreSuccess = (data) => ({
  type: GET_PROJECT_FIND_USERS_LOAD_MORE_SUCCESS,
  payload: data
})

export const setLoadingProjectFindUsers = (data) => ({
  payload: data,
  type: SET_LOADING_PROJECT_FIND_USERS,
})

export const setMetricsProject = (data) => ({
  type: SET_METRICS_PROJECT,
  payload: data
})
export const toggleLoadingMetric = () => ({
  type: TOGGLE_LOADING_METRIC
})

export const getOrderDistributorUsersSuccess = (data) => ({
  type: GET_ORDER_DISTRIBUTOR_USERS_SUCCESS,
  payload: data
})

export const setAddedCompanies = (data) => ({
  type: SET_ADDED_COMPANIES,
  payload: data
})

export const updateAddedCompanies = (id) => ({
  type: UPDATE_ADDED_COMPANIES,
  payload: id
})