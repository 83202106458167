import { snakecaseFields } from '../../utils/formatFields'
import { createThunk } from '../../utils/hooks/reduxHooks'
import { downloadFilePost } from '../../utils/axios/downloadFile'
import { addedNewNotification } from '../notifications/actions'
import { getModelPointRequestProperties } from '../../components/pages/Reports/utils'
import { IDynamicPageNumberPagination } from '../../models/IPagination'
import {
  IModelEntity,
  IModel,
  IModelProperty,
  IModelAdditionalPropertyInfo,
  IModelAdditionalProperty,
  IModelTemplatePropertiesInfo,
  IModelTemplateProperties,
  IModelPropertyListValue
} from '../../models/IModel'
import {
  toggleLoadingAdditionalProperties,
  toggleLoadingAdditionalPropertiesInfo,
  toggleLoadingEntityData,
  toggleLoadingModeDownloadReportXls,
  toggleLoadingModelProperties,
  toggleLoadingModelPropertiesColValues,
  toggleLoadingModelPropertiesListValues,
  toggleLoadingModelTemplatesPropertiesInfo
} from './modelSlice'

import deleteRequest from '../../utils/axios/delete'
import get from '../../utils/axios/get'
import post from '../../utils/axios/post'
import put from '../../utils/axios/put'


export const getModels = createThunk<IModel[]>(
  'model/get-model',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const res = await get<IModel[]>({ path: '/reportings' })
      return res
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)


type GetModelsPropertiesPayload = {
  projectId: number,
  modelName: string,
}
export const getModelsProperties = createThunk<IModelProperty[], GetModelsPropertiesPayload>(
  'model/get-model-property',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingModelProperties('pending'))
      const res = await get<IModelProperty[]>({
        path: `/reportings/${payload.modelName}/project/${payload.projectId}/filter-fields`
      })
      dispatch(toggleLoadingModelProperties('succeeded'))
      return res
    } catch (error) {
      dispatch(toggleLoadingModelProperties('failed'))
      dispatch(addedNewNotification({
        message: 'Не удалось загрузить данные отчета. Обновите страницу или попробуйте позднее.'
      }))
      return rejectWithValue(error)
    }
  }
)

type GetEntityDataPayload = {
  modelName: string,
  projectId: number,
  page: number,
  data: {
    [k: string]: string[] | Omit<IModelProperty, 'id'>[],
    fields: IModelProperty[]
  },
  startShowSkeleton?: () => void
  stopShowSkeleton?: () => void
}
export const getEntityData = createThunk<IDynamicPageNumberPagination<IModelEntity>, GetEntityDataPayload>(
  'model/get-entity-data',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const pointModelProperties = getModelPointRequestProperties(payload.data)
      dispatch(toggleLoadingEntityData('pending'))
      payload.startShowSkeleton?.()

      const res = await post<IDynamicPageNumberPagination<IModelEntity>>(
        `/reportings/${payload.modelName}/project/${payload.projectId}?page=${payload.page}&per_page=9`, {
        ...pointModelProperties,
        fields: snakecaseFields(payload.data.fields)
      })
      dispatch(toggleLoadingEntityData('succeeded'))
      return res
    } catch (error) {
      dispatch(toggleLoadingEntityData('failed'))
      dispatch(addedNewNotification({
        message: 'Не удалось загрузить данные отчета. Обновите страницу или попробуйте позднее.'
      }))
      return rejectWithValue(error)
    } finally {
      payload.stopShowSkeleton?.()
    }
  }
)

type GetAdditionalModelPropertiesInfoPayload = {
  modelName: string,
  projectId: number,
  search?: string
}
export const getModelAdditionalPropertiesInfo = createThunk<IModelAdditionalPropertyInfo[], GetAdditionalModelPropertiesInfoPayload>(
  'model/get-model-additional-properties-info',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingAdditionalPropertiesInfo('pending'))
      const res = await get<IModelAdditionalPropertyInfo[]>({
        path: `/reportings/${payload.modelName}/project/${payload.projectId}/add-filter-fields-types`,
        params: { search: payload.search }
      })
      dispatch(toggleLoadingAdditionalPropertiesInfo('succeeded'))
      return res
    } catch (error) {
      dispatch(toggleLoadingAdditionalPropertiesInfo('failed'))
      return rejectWithValue(error)
    }
  }
)

type GetModelAdditionalPropertiesPayload = {
  modelName: string,
  projectId: number,
  additionalModelPropetiesInfoName: string,
  search?: string
}
type GetModelAdditionalPropertiesResponse = {
  additionalModelPropetiesInfoName: string,
  data: IModelAdditionalProperty[]
}
export const getModelAdditionalProperties = createThunk<GetModelAdditionalPropertiesResponse, GetModelAdditionalPropertiesPayload>(
  'model/get-model-additional-properties',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingAdditionalProperties({ loading: 'pending', name: payload.additionalModelPropetiesInfoName }))
      const res = await get<IModelAdditionalProperty[]>({
        path: `/reportings/${payload.modelName}/project/${payload.projectId}/add-filter-fields`,
        params: {
          filter_field_type: payload.additionalModelPropetiesInfoName,
          search: payload.search
        }
      })
      dispatch(toggleLoadingAdditionalProperties({ loading: 'succeeded', name: payload.additionalModelPropetiesInfoName }))
      return {
        additionalModelPropetiesInfoName: payload.additionalModelPropetiesInfoName,
        data: res
      }
    } catch (error) {
      dispatch(toggleLoadingAdditionalProperties({ loading: 'failed', name: payload.additionalModelPropetiesInfoName }))
      return rejectWithValue(error)
    }
  }
)

type GetModelTemplatesPropertyInfo = {
  projectId: number,
  page: number,
  search?: string,
}
export const getModelTemplatesPropertyInfo = createThunk<IDynamicPageNumberPagination<IModelTemplatePropertiesInfo>, GetModelTemplatesPropertyInfo>(
  'model/get-model-templates-properties-info',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingModelTemplatesPropertiesInfo('pending'))
      const res = await get<IDynamicPageNumberPagination<IModelTemplatePropertiesInfo>>({
        path: `/reportings/project/${payload.projectId}/filter-templates?page=${payload.page}&per_page=10`,
        params: { search: payload.search },
        withCancellation: true
      })
      dispatch(toggleLoadingModelTemplatesPropertiesInfo('succeeded'))
      return res
    } catch (error) {
      dispatch(toggleLoadingModelTemplatesPropertiesInfo('failed'))
      return rejectWithValue(error)
    }
  }
)

type GetModelTemplateProperties = {
  projectId: number,
  templateId: number
}
export const getModelTemplateProperties = createThunk<IModelTemplateProperties, GetModelTemplateProperties>(
  'model/get-model-template-property',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const res = await get<IModelTemplateProperties>({ path: `/reportings/project/${payload.projectId}/filter-templates/${payload.templateId}` })
      return res
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type PostModelTemplateProperties = {
  projectId: number,
  reportObjType: string,
  templateTitle: string,
  data: {
    [k: string]: string[] | Omit<IModelProperty, 'id'>[],
    fields: Omit<IModelProperty, 'id'>[]
  }
  callback: () => void
}
export const postModelTemplateProperties = createThunk<any, PostModelTemplateProperties>(
  'model/post-model-template-property',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const pointModelProperties = getModelPointRequestProperties(payload.data)

      await post(`/reportings/project/${payload.projectId}/filter-templates`, {
        ...snakecaseFields({
          reportingType: payload.reportObjType,
          templateTitle: payload.templateTitle,
          fields: payload.data.fields,
        }),
        ...pointModelProperties
      })
      payload.callback()
      dispatch(getModelTemplatesPropertyInfo({ projectId: payload.projectId, page: 1, search: '' }))
      dispatch(addedNewNotification({ message: 'Шаблон отчета успешно создан' }))
    } catch (error) {
      dispatch(addedNewNotification({ message: 'Не удалось создать шаблон отчета' }))
      return rejectWithValue(error)
    }
  }
)

type PutTemplateModelPayload = {
  projectId: number
  templateId: number
  reportObjType: string
  templateTitle: string
  search: string
  data: {
    [k: string]: string[] | Omit<IModelProperty, 'id'>[],
    fields: Omit<IModelProperty, 'id'>[]
  }
}
export const putModelTemplateProperties = createThunk<void, PutTemplateModelPayload>(
  'model/put-model-template-property',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const pointModelProperties = getModelPointRequestProperties(payload.data)

      await put(
        `/reportings/project/${payload.projectId}/filter-templates/${payload.templateId}`,
        {
          ...snakecaseFields({
            reportingType: payload.reportObjType,
            templateTitle: payload.templateTitle,
            fields: payload.data.fields,
          }),
          ...pointModelProperties
        },
        false
      )
      dispatch(addedNewNotification({ message: 'Шаблон отчета успешно обновлен' }))
      dispatch(getModelTemplatesPropertyInfo({ projectId: payload.projectId,
        page: 1,
        search: payload.search
      }))
    } catch (error) {
      dispatch(addedNewNotification({ message: 'Не удалось обновить отчет шаблона' }))
    }
  }
)

type DeleteTemplateModelPayload = {
  projectId: number
  templateId: number
}
export const deleteModelTemplateProperties = createThunk<void, DeleteTemplateModelPayload>(
  'model/delete-model-template-property',
  async (payload, { dispatch }) => {
    try {
      await deleteRequest(`/reportings/project/${payload.projectId}/filter-templates/${payload.templateId}`)
      dispatch(addedNewNotification({ message: 'Шаблон отчета успешно удален' }))

      dispatch(getModelTemplatesPropertyInfo({
        projectId: payload.projectId,
        page: 1,
        search: ''
      }))
    } catch (error) {
      dispatch(addedNewNotification({ message: 'Не удалось удалить шаблон отчета' }))
    }
  }
)
type GetModelPropertyColValuesPayload = {
  modelName: string,
  projectId: number,
  propertyName: string,
  search?: string
}
export const getModelPropertyColValues = createThunk<string[], GetModelPropertyColValuesPayload>(
  'model/get-model-property-col-values',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoadingModelPropertiesColValues('pending'))
      const res = await get<string[]>({
        path: `/reportings/${payload.modelName}/project/${payload.projectId}/filters`,
        params: {
          field: payload.propertyName,
          search: payload.search
        }
      })
      dispatch(toggleLoadingModelPropertiesColValues('succeeded'))
      return res
    } catch (error) {
      dispatch(toggleLoadingModelPropertiesColValues('failed'))

      return rejectWithValue(error)
    }
  }
)

type GetXlsxModelPayload = {
  modelName: string,
  projectId: number,
  title: string,
  data: {
    [k: string]: string[] | Omit<IModelProperty, 'id'>[],
    fields: Omit<IModelProperty, 'id'>[]
  },
  failedDownloadingCb: () => void
}
export const getModelXlsxReport = createThunk<void, GetXlsxModelPayload>(
  'model/get-model-xlsx-report',
  async (payload, { dispatch }) => {
    try {
      const pointModelProperties = getModelPointRequestProperties(payload.data)

      dispatch(toggleLoadingModeDownloadReportXls('pending'))

      await downloadFilePost(
        `/reportings/${payload.modelName}/project/${payload.projectId}/download-xlsx?`,
        {
          ...pointModelProperties,
          fields: snakecaseFields(payload.data.fields)
        },
        payload.title
      )

      dispatch(toggleLoadingModeDownloadReportXls('succeeded'))
    } catch (error) {
      payload.failedDownloadingCb()
      dispatch(toggleLoadingModeDownloadReportXls('failed'))
    }
  }
)

type GetPropertyLisOfValuesType = {
  projectId: number
  entityId: number
  data: any
  modelName: string
  page: number
  perPage: number
  search: string
}

export const getModelPropertiesList = createThunk<IDynamicPageNumberPagination<IModelPropertyListValue>, GetPropertyLisOfValuesType>(
  'model/get-model-property-list',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { projectId, entityId, data, modelName, page, perPage, search } = payload

      dispatch(toggleLoadingModelPropertiesListValues('pending'))
      const res = await post<IDynamicPageNumberPagination<IModelPropertyListValue>>(
        `/reportings/${modelName}/project/${projectId}/entity/${entityId}/get_list_of_values?page=${page}&per_page=${perPage}&search=${search}`,
        data
      )
      dispatch(toggleLoadingModelPropertiesListValues('succeeded'))
      return res
    } catch (error) {
      dispatch(addedNewNotification({ message: 'Не удалось загрузить список' }))
      dispatch(toggleLoadingModelPropertiesListValues('failed'))
      return rejectWithValue(error)
    }
  }
)