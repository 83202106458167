import axios, { AxiosRequestHeaders } from 'axios'
import camelcaseFields, { snakecaseFields } from '../formatFields'
import HOST from './host'

import { errorProccessing } from './errorProccessing.js'
import { getHeaders } from './getHeaders.js'


const put = async <R, P = any>(path: string, info: P, transformCamelCase = true) => (
  new Promise<R>(async (res, rej) => {

    const headers = await getHeaders() as AxiosRequestHeaders
    const REQUEST_URL = HOST.getInstance().getRequestUrl()
    const data = info && transformCamelCase ? snakecaseFields(info) : info

    try {
      const response = await axios.put<R>(
        `${REQUEST_URL}${path}`,
        data,
        { headers }
      )
      res(camelcaseFields(response.data))
    } catch (error) {
      rej(camelcaseFields(errorProccessing(error)))
    }
  })
)

export default put