import { BuilderProjectAttachmentsApi } from '../types'
import { addedNewNotification } from '../../../../../(deprecated)/redux/notifications/actions'

type QueryArgsType = {
  fileId: number
  cbSuccess: () => void
  cbFinally: () => void
}

export const deleteProjectAttachmentQuery = (builder: BuilderProjectAttachmentsApi) => (
  builder.mutation<any, QueryArgsType>({
    query: ({ fileId }) => {
      return {
        url: `project_files/${fileId}`,
        method: 'DELETE'
      }
    },
    transformResponse: (response: any) => response,
    async onQueryStarted({ cbSuccess, cbFinally }, { dispatch, queryFulfilled }) {
      try {
        await queryFulfilled
        dispatch(addedNewNotification({ message: 'Документ успешно удален.' }))
        cbSuccess()
      } catch (e) {
        dispatch(addedNewNotification({ message: 'Не удалось удалить документ.' }))
      } finally {
        cbFinally()
      }
    }
  })
)