import { 
  SET_LOADING_ADDED_IMAGES,
  ADDED_IMAGES_FAILED,
  ADDED_IMAGES_SUCCESS,
  CLEAR_IMAGES
} from "./constant";

export const setLoadingAddedImages = () => ({
  type: SET_LOADING_ADDED_IMAGES
})

export const addedImagesSuccess = (data) => ({
  type: ADDED_IMAGES_SUCCESS,
  payload: data
})

export const addedImagesFailed = () => ({
  type: ADDED_IMAGES_FAILED
})

export const clearImagesSuccess = () => ({
  type: CLEAR_IMAGES
})