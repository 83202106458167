import React from 'react'
import { IIcon } from './IIcon'

export const Pencil: React.FC<IIcon> = React.memo(({
 width = '2.4',
 height = '2.4',
 color = 'black',
 opacity = '0.64',
 ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 22 22" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5345 6.30525L15.6948 2.4647C15.5671 2.33698 15.4155 2.23567 15.2487 2.16655C15.0818 2.09743 14.903 2.06186 14.7224 2.06186C14.5418 2.06186 14.363 2.09743 14.1961 2.16655C14.0293 2.23567 13.8777 2.33698 13.75 2.4647L3.15305 13.0625C3.02482 13.1897 2.92315 13.3412 2.85396 13.508C2.78476 13.6749 2.74943 13.8538 2.75001 14.0345V17.875C2.75001 18.2397 2.89487 18.5894 3.15274 18.8473C3.4106 19.1051 3.76033 19.25 4.12501 19.25H7.96555C8.14619 19.2506 8.32513 19.2153 8.49199 19.1461C8.65885 19.0769 8.81029 18.9752 8.93751 18.847L19.5345 8.25001C19.6622 8.12233 19.7635 7.97073 19.8326 7.80389C19.9017 7.63705 19.9373 7.45822 19.9373 7.27763C19.9373 7.09703 19.9017 6.91821 19.8326 6.75137C19.7635 6.58452 19.6622 6.43293 19.5345 6.30525ZM16.5 9.3397L12.6595 5.50001L14.722 3.43751L18.5625 7.2772L16.5 9.3397Z"
      fill={color} fillOpacity={opacity}/>
  </svg>
))