import {
  setLoadingUsersProject,
  getUsersProjectSuccess,
  getUsersProjectFailed,
  getAllProjectUsersSuccess
} from './actionCreator'
import { get } from '../../utils/axios'
import { addedNewNotification } from '../notifications/actions'

export const getAllUsers = (projectId) => (
  dispatch => {
    const ALL_USERS_PARAMS = {
      'filter{email.isnull}': false,
      'filter{profile|first_name.isnull}': false,
    }
    dispatch(setLoadingUsersProject())
    get({ path: `/projects/${projectId}/companies` })
      .then(data => {
        if (data.length) ALL_USERS_PARAMS['filter{company.id.in}'] = data.map(({ id }) => id)
        return get({ path: '/users', params: ALL_USERS_PARAMS })
      })
      .then(data => dispatch(getUsersProjectSuccess(data.users)))
      .catch(() => dispatch(getUsersProjectFailed()))
  }
)

export const getAllProjectUsers = (projectId) => (
  dispatch => {
    dispatch(setLoadingUsersProject())
    get({ path: `/projects/${projectId}/find_users` })
      .then(data => {
        dispatch(getAllProjectUsersSuccess(data))
      })
      .catch(() => dispatch(addedNewNotification({ message: 'Не удалось загрузить пользователей' })))
  }
)