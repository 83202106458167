import React from 'react'
import { IIcon } from './IIcon'

export const CopySimple: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg width={`${width}rem`} className={className} height={`${height}rem`} {...innerProps} viewBox="0 0 24 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 6.75V20.25C18 20.4489 17.921 20.6397 17.7803 20.7803C17.6397 20.921 17.4489 21 17.25 21H3.75C3.55109 21 3.36032 20.921 3.21967 20.7803C3.07902 20.6397 3 20.4489 3 20.25V6.75C3 6.55109 3.07902 6.36032 3.21967 6.21967C3.36032 6.07902 3.55109 6 3.75 6H17.25C17.4489 6 17.6397 6.07902 17.7803 6.21967C17.921 6.36032 18 6.55109 18 6.75ZM20.25 3H6.75C6.55109 3 6.36032 3.07902 6.21967 3.21967C6.07902 3.36032 6 3.55109 6 3.75C6 3.94891 6.07902 4.13968 6.21967 4.28033C6.36032 4.42098 6.55109 4.5 6.75 4.5H19.5V17.25C19.5 17.4489 19.579 17.6397 19.7197 17.7803C19.8603 17.921 20.0511 18 20.25 18C20.4489 18 20.6397 17.921 20.7803 17.7803C20.921 17.6397 21 17.4489 21 17.25V3.75C21 3.55109 20.921 3.36032 20.7803 3.21967C20.6397 3.07902 20.4489 3 20.25 3Z"
      fill={color}/>
  </svg>
)