import React, { useState } from 'react'
import { Button } from '../../../shared/ui-component/Button/Button'
import { Modal, ModalHeader } from '../../../shared/ui-component/Modal'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'

//TODO перенести
import { SimpleInput } from '../../../../(deprecated)/components/UI/SimpleInput/SimpleInput'
import { postNewProject } from '../../../../(deprecated)/redux/project/actions'

import * as Icons from '../../../shared/ui-icons'
import * as yup from 'yup'

import './CreateProjectButton.scss'

export const CreateProjectButton = () => {
  const [showModal, setShowModal] = useState(false)
  const [isCreateLoading, setIsCreateLoading] = useState(false)

  const dispatch = useDispatch()

  const handleCreateProject = ({ title }: NewProjectFields) => {
    setIsCreateLoading(true)
    dispatch(postNewProject({ name: title }, () => {
      setIsCreateLoading(false)
      setShowModal(false)
    }))
  }

  return (
    <>
      <Button
        title='Новый проект'
        customClassName='new-project_btn'
        buttonType='secondary'
        icon={<Icons.Plus/>}
        onClick={() => setShowModal(true)}
      />
      <Modal 
        open={showModal} 
        onCancel={() => setShowModal(false)}
        className='create-project-modal'
      >
        <ModalHeader title='Создать проект' onClose={() => setShowModal(false)} />
        Придумайте название для проекта
        <Formik<NewProjectFields>
          validationSchema={newProjectShema}
          initialValues={{ title: '' }}
          validateOnMount
          onSubmit={handleCreateProject}
        >
          {({ handleSubmit, values, setFieldValue, setFieldTouched, errors, touched }) => (
            <>
              <SimpleInput
                name='title'
                stylePattern={['border', 'blue-border-focus', 'top-placeholder']}
                placeholder='Название проекта'
                value={values.title}
                error={errors.title}
                touched={touched.title}
                setValue={(name: string, value: string) => setFieldValue(name, value)}
                onBlur={() => setFieldTouched('title', true)}
              />
              <Button 
                isLoading={isCreateLoading}
                title='Создать'
                onClick={() => handleSubmit()} 
              />
            </>
          )}
        </Formik>
      </Modal>
    </>
  )
}

const newProjectShema = yup.object().shape({
  title: yup.string().required('Поле не может быть пустым').max(256, 'Максимум 256 символов')
})

type NewProjectFields = {
  title: string
}