import React from 'react'
import { IIcon } from './IIcon'

export const BigMenu: React.FC<IIcon> = ({
  width = '10.4',
  height = '10.4',
  color = 'rgba(0,0,0,0.24)',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 104 104"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 52.333C13 54.5421 14.7909 56.333 17 56.333H17.6667C19.8758 56.333 21.6667 54.5421 21.6667 52.333V51.6663C21.6667 49.4572 19.8758 47.6663 17.6667 47.6663H17C14.7909 47.6663 13 49.4572 13 51.6663V52.333ZM13 69.6663C13 71.8755 14.7909 73.6663 17 73.6663H17.6667C19.8758 73.6663 21.6667 71.8755 21.6667 69.6663V68.9997C21.6667 66.7905 19.8758 64.9997 17.6667 64.9997H17C14.7909 64.9997 13 66.7905 13 68.9997V69.6663ZM13 34.9997C13 37.2088 14.7909 38.9997 17 38.9997H17.6667C19.8758 38.9997 21.6667 37.2088 21.6667 34.9997V34.333C21.6667 32.1239 19.8758 30.333 17.6667 30.333H17C14.7909 30.333 13 32.1239 13 34.333V34.9997ZM30.3333 52.333C30.3333 54.5421 32.1242 56.333 34.3333 56.333H87C89.2091 56.333 91 54.5421 91 52.333V51.6663C91 49.4572 89.2091 47.6663 87 47.6663H34.3333C32.1242 47.6663 30.3333 49.4572 30.3333 51.6663V52.333ZM30.3333 69.6663C30.3333 71.8755 32.1242 73.6663 34.3333 73.6663H87C89.2091 73.6663 91 71.8755 91 69.6663V68.9997C91 66.7905 89.2091 64.9997 87 64.9997H34.3333C32.1242 64.9997 30.3333 66.7905 30.3333 68.9997V69.6663ZM34.3333 30.333C32.1242 30.333 30.3333 32.1239 30.3333 34.333V34.9997C30.3333 37.2088 32.1242 38.9997 34.3333 38.9997H87C89.2091 38.9997 91 37.2088 91 34.9997V34.333C91 32.1239 89.2091 30.333 87 30.333H34.3333Z"
      stroke={color} strokeWidth="2"/>
  </svg>
)