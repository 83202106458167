export const PUT_PROFILE_INFO = 'PUT_PROFILE_INFO'
export const PUT_PROFILE_INFO_SUCCEED = 'PUT_PROFILE_INFO_SUCCEED'

export const POST_PROFILE_DOCUMENT_SUCCEED = 'POST_PROFILE_DOCUMENT_SUCCEED'
export const POST_PROFILE_DOCUMENT_FAILED = 'POST_PROFILE_DOCUMENT_FAILED'

export const LOG_IN = 'LOG_IN'

// подключение АПИ

export const SET_CONFIRM_INVITE_LOADING = 'SET_CONFIRM_INVITE_LOADING'
export const GET_CONFIRM_INVITE_SUCCESS = 'GET_USER_EMAIL_SUCCESS'
export const GET_CONFIRM_INVITE_FAILED = 'GET_CONFIRM_INVITE_FAILED'

export const SET_LOADING_REGISTER_USER_START = 'SET_LOADING_REGISTER_USER_START'
export const POST_REGISTER_USER_SUCCESS = 'POST_REGISTER_USER_SUCCES'
export const POST_REGISTER_USER_FAILED = 'POST_REGISTER_USER_FAILED'

export const SET_LOADING_AUTH_START = 'SET_LOADING_AUTH_START'
export const POST_AUTH_SUCCESS = 'POST_AUTH_SUCCESS'
export const POST_AUTH_FAILED = 'POST_AUTH_FAILED'
export const SET_TOKEN = 'SET_TOKEN'

export const SET_LOADING_AUTH_REFRESH_START = 'SET_LOADING_AUTH_REFRESH_START'
export const POST_AUTH_REFRESH_SUCCESS = 'POST_AUTH_REFRESH_SUCCESS'
export const POST_AUTH_REFRESH_FAILED = 'POST_AUTH_REFRESH_FAILED '

export const SET_LOADING_GET_USER = 'SET_LOADING_GET_USER'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILED = 'GET_USER_FAILED'

export const SET_LOADING_PUT_USER_START = 'SET_LOADING_PUT_USER_START'
export const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS'
export const PUT_USER_FAILED = 'PUT_USER_FAILED'

export const SET_LOADING_POST_SIGNATURE = 'SET_LOADING_POST_SIGNATURE'
export const POST_SIGNATURE_SUCCESS = 'POST_SIGNATURE_SUCCESS'
export const POST_SIGNATURE_FAILED = 'POST_SIGNATURE_FAILED'
export const DELETE_SIGNATURE_SUCCESS = 'DELETE_SIGNATURE_SUCCESS'

export const TOGGLE_LOADING_POST_NEW_PASSWORD = 'TOGGLE_LOADING_POST_NEW_PASSWORD'
export const POST_NEW_PASSWORD_FAILED = 'POST_NEW_PASSWORD_FAILED'
export const POST_NEW_PASSWORD_SUCCESS = 'POST_NEW_PASSWORD_SUCCESS'
export const CLEAR_ERROR_POST_NEW_PASSWROD = 'CLEAR_ERROR_POST_NEW_PASSWROD'

export const SET_LOADING_GET_CONFIRM_PASSWORD_START = 'SET_LOADING_GET_CONFIRM_PASSWORD_START'
export const SET_LOADING_GET_CONFIRM_PASSWORD_FINISH = 'SET_LOADING_GET_CONFIRM_PASSWORD_FINISH'

export const GET_USER_ONBOARDIN_SUCCESS = 'GET_USER_ONBOARDIN_SUCCESS'

export const PUT_USER_ONBOARDING_SUCCESS = 'PUT_USER_ONBOARDING_SUCCESS'
export const TOGGLE_PUT_ONBOARDING_LOADING = 'TOGGLE_PUT_ONBOARDING_LOADING'

export const SET_LOADING_RESET_PASSWORD = 'SET_LOADING_RESET_PASSWORD'
export const POST_RESET_PASSWORD_FAILED = 'POST_RESET_PASSWORD_FAILED'
export const PUT_RESET_PASSWORD_FAILED = 'PUT_RESET_PASSWORD_FAILED'
export const POST_RESET_PASSWORD_SUCCESS = 'POST_RESET_PASSWORD_SUCCESS'

//---TENANTS---
export const SET_TENANTS_LIST = 'SET_TENANTS_LIST'
export const CLEAR_TENANTS_LIST = 'CLEAR_TENANTS_LIST'
export const SET_IS_LOADING_TENANTS = 'SET_IS_LOADING_TENANTS'

export const GET_USER_ROLE_IS_PROJECT_SUCCESS = 'GET_USER_ROLE_IS_PROJECT_SUCCESS'
export const SET_USER_ROLE_LODAING = 'SET_USER_ROLE_LODAING'

export const PUT_USER_COMPANY_INFO = 'PUT_USER_COMPANY_INFO'