import { MetaAdapter } from '../../MetaAdapter'
import { IProjectCompany } from '../../../models/ICompany'
import { ProjectCompanyAdapter } from './ProjectCompanyAdapter'
import QueryString from 'query-string'
import snakecaseKeys from 'snakecase-keys'
import camelcaseFields from '../../../utils/formatFields'

export type QueryParams = {
  page: number
  perPage: number
  projectId: number
  searchText: string
  orderBy: string
}

export class GetProjectCompanyListQuery {
  query = ({
    page,
    perPage,
    projectId,
    searchText,
    orderBy
  }: Partial<QueryParams>): string => {
    const queryString = QueryString.stringify(snakecaseKeys({
      search: searchText,
      page,
      perPage,
      orderBy
    }), { skipNull: true })

    return `projects/${projectId}/companies?${queryString}`
  }

  transformResponse = (response: {
    results: IProjectCompany[],
    meta: Record<string, unknown>,
    next: string,
    previous: string
  }) => {
    if (response instanceof Array) {
      const listOptions = response.map((projectCompany) =>
        new ProjectCompanyAdapter(projectCompany).getFields()
      )

      return { meta: new MetaAdapter({}).getFields(), listOptions }
    }

    const { meta, results, next, previous } = response

    const listOptions = results.map((projectCompany: IProjectCompany) =>
      new ProjectCompanyAdapter(projectCompany).getFields()
    )

    return { meta: camelcaseFields(meta), listOptions, next, previous }
  }
}