import React from 'react'
import { IIcon } from './IIcon'

export const Eye:React.FC<IIcon & { isOpenEye?: boolean }> = ({
  onClick = () => {},
  width = '2.4',
  height = '2.4',
  className = '',
  opacity = '1',
  isOpenEye = true,
  color = '#949494',
  ...props
}) => (
  <svg {...props} onClick={onClick} className={className} width={`${width}rem`} height={`${height}rem`}
       viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    {
      isOpenEye
        ? <path
          d="M23.1853 11.6962C23.1525 11.6222 22.3584 9.86062 20.5931 8.09531C18.2409 5.74312 15.27 4.5 12 4.5C8.72999 4.5 5.75905 5.74312 3.40687 8.09531C1.64155 9.86062 0.843741 11.625 0.814679 11.6962C0.772035 11.7922 0.75 11.896 0.75 12.0009C0.75 12.1059 0.772035 12.2097 0.814679 12.3056C0.847491 12.3797 1.64155 14.1403 3.40687 15.9056C5.75905 18.2569 8.72999 19.5 12 19.5C15.27 19.5 18.2409 18.2569 20.5931 15.9056C22.3584 14.1403 23.1525 12.3797 23.1853 12.3056C23.2279 12.2097 23.25 12.1059 23.25 12.0009C23.25 11.896 23.2279 11.7922 23.1853 11.6962ZM12 15.75C11.2583 15.75 10.5333 15.5301 9.9166 15.118C9.29992 14.706 8.81927 14.1203 8.53544 13.4351C8.25161 12.7498 8.17735 11.9958 8.32205 11.2684C8.46674 10.541 8.82389 9.8728 9.34834 9.34835C9.87279 8.8239 10.541 8.46675 11.2684 8.32205C11.9958 8.17736 12.7498 8.25162 13.4351 8.53545C14.1203 8.81928 14.7059 9.29993 15.118 9.91661C15.5301 10.5333 15.75 11.2583 15.75 12C15.75 12.9946 15.3549 13.9484 14.6516 14.6517C13.9484 15.3549 12.9946 15.75 12 15.75Z"
          fill={color}/>
        : <path
          d="M5.05499 3.24563C4.98913 3.17138 4.90918 3.11095 4.81979 3.06783C4.7304 3.02471 4.63334 2.99976 4.53423 2.99443C4.43513 2.9891 4.33595 3.00349 4.24245 3.03677C4.14895 3.07005 4.06298 3.12156 3.98953 3.18831C3.91608 3.25506 3.85661 3.33572 3.81457 3.42562C3.77252 3.51552 3.74874 3.61288 3.7446 3.71204C3.74045 3.8112 3.75603 3.9102 3.79043 4.00329C3.82483 4.09639 3.87737 4.18173 3.94499 4.25438L5.74874 6.23906C2.34374 8.32875 0.879366 11.55 0.814679 11.6963C0.772035 11.7922 0.75 11.896 0.75 12.0009C0.75 12.1059 0.772035 12.2097 0.814679 12.3056C0.847491 12.3797 1.64155 14.1403 3.40687 15.9056C5.75905 18.2569 8.72999 19.5 12 19.5C13.6806 19.5096 15.3442 19.1636 16.8816 18.4847L18.9441 20.7544C19.0099 20.8286 19.0899 20.8891 19.1793 20.9322C19.2686 20.9753 19.3657 21.0002 19.4648 21.0056C19.5639 21.0109 19.6631 20.9965 19.7566 20.9632C19.8501 20.93 19.9361 20.8784 20.0095 20.8117C20.083 20.7449 20.1424 20.6643 20.1845 20.5744C20.2265 20.4845 20.2503 20.3871 20.2544 20.288C20.2586 20.1888 20.243 20.0898 20.2086 19.9967C20.1742 19.9036 20.1217 19.8183 20.0541 19.7456L5.05499 3.24563ZM13.3987 14.6541C12.8105 14.9636 12.136 15.0689 11.4814 14.9535C10.8268 14.8382 10.229 14.5087 9.78188 14.0168C9.33481 13.5248 9.06377 12.8984 9.01134 12.2357C8.9589 11.573 9.12804 10.9117 9.49218 10.3556L13.3987 14.6541ZM23.1853 12.3056C23.1459 12.3938 22.1962 14.4966 20.0578 16.4119C19.9104 16.5441 19.7167 16.6127 19.519 16.6027C19.3212 16.5927 19.1354 16.5049 19.0022 16.3584L9.50624 5.91281C9.4154 5.81313 9.35312 5.69079 9.32596 5.55869C9.29879 5.42659 9.30776 5.2896 9.3519 5.16216C9.39604 5.03473 9.47373 4.92155 9.57679 4.83455C9.67985 4.74755 9.80445 4.68995 9.93749 4.66781C10.6192 4.55525 11.309 4.49912 12 4.5C15.27 4.5 18.2409 5.74313 20.5931 8.09531C22.3584 9.86063 23.1525 11.6222 23.1853 11.6963C23.2279 11.7922 23.25 11.896 23.25 12.0009C23.25 12.1059 23.2279 12.2097 23.1853 12.3056Z"
          fill={color}/>
    }
  </svg>
)