import * as TYPES from './types'
import { WS_SEND_MESSAGE } from '../../utils/websockets/actionTypes/actionTypes'
import { sanitizeHtmlChat } from '../../components/pages/Chat/utils/settingSanitizeHtml'
import { DOCS_COMMENTS_SETTINGS_UNIQUE_NAME } from '../../utils/websockets/instance/documentsCommentsSettings'

// SHORT TEMPLATE DOCUMENT
export const getShortTemplatedDocumentSuccess = (payload) => ({
  type: TYPES.GET_SHORT_TEMPLATED_DOCUMENTS_SUCCESS,
  payload
})
export const toggleLoadingShortTemplatedDocument = () => ({
  type: TYPES.TOGGLE_LOADING_SHORT_TEMPLATED_DOCUMENTS
})
export const clearShortTemplatedDocument = () => ({
  type: TYPES.CLEAR_SHORT_TEMPLATED_DOCUMENT
})
export const setInShortTemplateDocumentAfterAction = (data) => ({
  type: TYPES.SET_DATA_IN_SHORT_TEMPLATE_DOCUMENT_AFTER_ACTION,
  payload: data
})
export const clearShortTemplateAfterChangeStatus = (id) => ({
  type: TYPES.CLEAR_SHORT_TEMPLATED_DOCUMENT_AFTER_CHANGE_STATUS,
  payload: { id }
})

// TEMPLATE TYPES
export const getTemplateTypesSuccess = (payload) => ({
  type: TYPES.GET_TEMPLATE_TYPE_SUCCESS,
  payload
})
export const toggleLoadingTemplateTypes = () => ({
  type: TYPES.TOGGLE_LOADING_TEMPLATE_TYPE
})

// TEMPLATE
export const getTemplateSuccess = (payload) => ({
  type: TYPES.GET_TEMPLATE_SUCCESS,
  payload
})
export const toggleLoadingGetTemplate = () => ({
  type: TYPES.TOGGLE_LOADING_GET_TEMPLATE
})
export const clearTemplate = () => ({
  type: TYPES.CLEAR_TEMPLATE
})

// HISTORY
export const getDocumentHistroySuccess = (payload) => ({
  type: TYPES.GET_DOCUMENT_HISTORY_SUCCESS,
  payload
})
export const toggleLoadingDocumentHistory = () => ({
  type: TYPES.TOGGLE_LOADING_DOCUMENT_HISTORY
})
export const clearDocumentHistory = () => ({
  type: TYPES.CLEAR_DOCUMENT_HISTORY
})

// TEMPLATED DOCUMENT 
export const getTemplateDocumentSuccess = (payload) => ({
  type: TYPES.GET_TEMPLATE_DOCUMENT_SUCCESS,
  payload
})
export const updatedDocumentsAttachmentsSuccess = (payload) => ({
  type: TYPES.UPDATE_ATTACHMENTS_USER_DOCUMENTS,
  payload
})
export const toggleLoadingTemplateDocument = () => ({
  type: TYPES.TOGGLE_LOADING_TEMPLATE_DOCUMENT
})
export const clearTemplateDocument = () => ({
  type: TYPES.CLEAR_TEMPLATE_DOCUMENT
})
export const toggleLoadingCreationDocument = (payload) => ({
  type: TYPES.TOGGLE_LOADING_CREATION_DOCUMENT,
  payload
})

// TOGGLE LOADING ACTIONS 
export const toggleLoadingTemplatedDocumentActionApprove = () => ({
  type: TYPES.TOGGLE_LOADING_TEMPLATE_DOCUMENT_ACTION_APPROVE,
})
export const toggleLoadingTemplatedDocumentActionReject = () => ({
  type: TYPES.TOGGLE_LOADING_TEMPLATE_DOCUMENT_ACTION_REJECT
})
export const toggleLoadingTemplatedDocumentActionNotNegotiate = () => ({
  type: TYPES.TOGGLE_LOADING_TEMPLATE_DOCUMENT_ACTION_NOT_NEGOTIATE
})

// EXCEL TABLE
export const toggleLoadingTableExcelFile = () => ({
  type: TYPES.TOGGLE_LOADING_TABLE_EXCEL_FILE
})
export const updateTableExcelInfo = (payload) => ({
  type: TYPES.UPDATE_TABLE_EXCEL_INFO,
  payload
})

// CONSTRUCTOR
export const getTemplatesSuccess = (payload) => ({
  type: TYPES.GET_TEMPLATES_SUCCESS,
  payload
})
export const toggleLoadingTemplates = () => ({
  type: TYPES.TOGGLE_LOADING_TEMPLATES
})

export const postTemplateSuccess = (payload) => ({
  type: TYPES.POST_TEMPLATES_SUCCESS,
  payload
})
export const toggleLoadingPostTemplate = () => ({
  type: TYPES.TOGGLE_LOADING_POST_TEMPLATES
})

export const putUpdateTemplateSuccess = (payload) => ({
  type: TYPES.PUT_UPDATE_TEMPLATES_SUCCESS,
  payload
})
export const toggleLoadingPutUpdateTemplate = () => ({
  type: TYPES.TOGGLE_LOADING_PUT_UPDATE_TEMPLATES
})

export const deleteTemplateSuccess = (payload) => ({
  type: TYPES.DELETE_TEMPLATE_SUCCESS,
  payload
})

export const setDocumentPreview = (payload) => ({
  type: TYPES.SET_DOCUMENT_PREVIEW,
  payload
})

export const clearDocumentPreview = () => ({
  type: TYPES.CLEAR_DOCUMENT_PREVIEW
})

export const setPreviewDocLoading = (payload) => ({
  type: TYPES.SET_PREVIEW_DOC_LOADING,
  payload
})

export const toggleLoadingDownloadPdf = () => ({
  type: TYPES.TOGGLE_LOADING_DOWNLOAD_PDF
})


//---Documents comments (Websocket)---
export const docCommentsListMessages = (data) => ({
  type: WS_SEND_MESSAGE,
  nameConnection: DOCS_COMMENTS_SETTINGS_UNIQUE_NAME,
  payload: {
    command: 'list_messages',
    payload: data
  }
})
export const deleteDocComment = (id) => ({
  type: WS_SEND_MESSAGE,
  nameConnection: DOCS_COMMENTS_SETTINGS_UNIQUE_NAME,
  payload: {
    command: 'delete_message',
    payload: { id }
  }
})

export const docCommentsSendMessage = ({ message, isTagged }) => ({
  type: WS_SEND_MESSAGE,
  nameConnection: DOCS_COMMENTS_SETTINGS_UNIQUE_NAME,
  payload: {
    command: 'send_discussion_message',
    payload: {
      message: (() => {
        if (!message?.length) return ''
        const sanitizeMessage = sanitizeHtmlChat(message) ?? ''
        return sanitizeMessage.length ? sanitizeMessage : `С этого аккаунта отправляются подозрительные сообщения`
      })(),
      isTagged
    }
  }
})

export const docCommentsUpdateMessage = ({ message, id }) => ({
  type: WS_SEND_MESSAGE,
  nameConnection: DOCS_COMMENTS_SETTINGS_UNIQUE_NAME,
  payload: {
    command: 'update_message',
    payload: {
      id,
      newText: (() => {
        if (!message?.length) return ''
        const sanitizeMessage = sanitizeHtmlChat(message) ?? ''
        return sanitizeMessage.length ? sanitizeMessage : `С этого аккаунта отправляются подозрительные сообщения`
      })(),

    }
  }
})

export const setDocCommentsLoading = (payload) => ({
  type: TYPES.SET_DOC_COMMENTS_LOADING,
  payload
})

export const clearDocComments = () => ({
  type: TYPES.CLEAR_DOCUMENT_COMMENT_LIST
})

export const setCategories = (payload) => ({
  type: TYPES.SET_CATEGORIES,
  payload
})

export const setCategoriesLoading = (payload) => ({
  type: TYPES.SET_CATEGORIES_LOADING,
  payload
})

export const clearCategories = () => ({
  type: TYPES.CLEAR_CATEGORIES
})

export const setLoadingCheckDocumentTitle = (payload) => ({
  type: TYPES.TOGGLE_LOADING_CHECK_DOCUMENT_TITLE,
  payload
})