import { createThunk } from '../../utils/hooks/reduxHooks'
import { addedNewNotification } from '../notifications/actions'
import { setDocTemplateIsLoading, setTemplatesCategoriesLoading } from './docsTemplatesSlice'
import { ITemplate, ITemplateCreate } from '../../models/ITemplate'
import { deleteRequest } from '../../utils/axios'
import camelcaseFields, { snakecaseFields } from '../../utils/formatFields'
import { ICategoriesStructure, ICategory } from '../../models/ICategory'
import { getTemplateSuccess } from '../templates/actionCreator'

import get from '../../utils/axios/get'
import post from '../../utils/axios/post'
import put from '../../utils/axios/put'


export const getTemplatesCategories = createThunk<ICategoriesStructure, { project: number, search?: string }>(
  'docs-templates/docs-templates-categories',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setTemplatesCategoriesLoading('pending'))
      const res = await get<ICategoriesStructure>({ path: '/docflow/templates/categories', params: payload, isCamelCase: false })
      dispatch(setTemplatesCategoriesLoading('succeeded'))
      return Object.keys(res).reduce((acc, key) => ({ ...acc, [key]: camelcaseFields(res[key]) }), {})

    } catch (error) {
      dispatch(setTemplatesCategoriesLoading('failed'))
      dispatch(addedNewNotification({ message: 'Не удалось загрузить категории шаблонов' }))
      return rejectWithValue(error)
    }
  }
)

export const createTemplatesCategory = createThunk<ICategory, { project: number, title: string, cb: () => void }>(
  'docs-templates/doc-templates-category/create',
  async (payload, { dispatch, rejectWithValue }) => {
    try {

      const res = await post<ICategory>(
        '/docflow/category',
        {
          project: payload.project,
          title: payload.title
        }
      )
      dispatch(addedNewNotification({ message: 'Категория успешно создана' }))
      payload.cb()
      return res
    } catch (error) {
      dispatch(addedNewNotification({ message: 'Не удалось создать категорию' }))
      return rejectWithValue(error)
    }
  }
)

export const getCategoriesList = createThunk<ICategory[], { project: number }>(
  'docs-templates/doc-templates-categories-list',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const res = await get<ICategory[]>({ path: '/docflow/category', params: payload })
      return res
    } catch (error) {
      dispatch(addedNewNotification({ message: 'Не удалось загрузить список категорий' }))
      return rejectWithValue(error)
    }
  }
)

export const getTemplate = createThunk<ITemplate, { temolateId: number, projectId: number }>(
  'docs-templates/get-doc-template',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setDocTemplateIsLoading('pending'))
      const res = await get<ITemplate>({
        path: `/docflow/templates/${payload.temolateId}`,
        params: { project: payload.projectId }
      })
      // для старого редюсера
      dispatch(getTemplateSuccess(res))

      dispatch(setDocTemplateIsLoading('succeeded'))
      return res
    } catch (error) {
      dispatch(setDocTemplateIsLoading('failed'))
      dispatch(addedNewNotification({ message: 'Не удалось загрузить шаблон' }))
      return rejectWithValue(error)
    }
  }
)

export const getValidTetmplateTitle = createThunk<string, { templateId: number, callback: (value: string) => void }>(
  'docs-templates/get-valid-template-title',
  async ({ templateId, callback }, { dispatch, rejectWithValue }) => {
    try {
      const res = await get<{ title: string }>({
        path: `/docflow/documents/get-document-title?template=${templateId}`
      })
      callback(res.title)
      return res.title
    }
    catch (e) {
      dispatch(addedNewNotification({ message: 'Не удалось загрузить название документа' }))
      return rejectWithValue(e)
    }
  }
)

type DeleteCategoryRequestType = {
  deleteWithTemplates: boolean
  project: number
  categoryId: number
}

export const deleteTemplateCategory = createThunk<any, DeleteCategoryRequestType>(
  'docs-templates/delete-category',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { categoryId: id, deleteWithTemplates, project } = payload
      await deleteRequest(`/docflow/category/${id}?project=${project}`, snakecaseFields({ deleteWithTemplates }))
      dispatch(getTemplatesCategories({ project }))
      dispatch(getCategoriesList({ project }))
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const copyCategoryWithTemplates = createThunk<any, { categoryId: number, project: number }>(
  'docs-templates/duplicate-category-with-templates',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const res = await post(
        '/docflow/category/duplicate_categories_with_templates',
        { category: payload.categoryId }
      )
      dispatch(getTemplatesCategories({ project: payload.project }))
      dispatch(getCategoriesList({ project: payload.project }))
      dispatch(addedNewNotification({ message: 'Категория с шаблонами успешно скопирована' }))
      return res
    } catch (e) {
      dispatch(addedNewNotification({ message: 'Не удалось скопировать категорию' }))
      return rejectWithValue(e)
    }
  }
)

export const updateCategoryTemplates = createThunk<any, { categoryTitle: string, project: number, categoryId: number }>(
  'docs-templates/update-category-templates',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      await put(`/docflow/category/${payload.categoryId}?project=${payload.project}`, { title: payload.categoryTitle })
      dispatch(getTemplatesCategories({ project: payload.project }))
      dispatch(getCategoriesList({ project: payload.project }))
      dispatch(addedNewNotification({ message: 'Заголовок категории успешно редактирован' }))

    } catch (e) {
      dispatch(addedNewNotification({ message: 'Не удалось обновить заголовок категории' }))
      return rejectWithValue(e)
    }
  }
)

interface IDeleteTemplatePayload {
  templateId: number,
  withNotification?: boolean
  projectId: number
}
interface IDeleteTemplateResponse {
  templateId: number,
}


export const deleteTemplate = createThunk<IDeleteTemplateResponse, IDeleteTemplatePayload>(
  'docs-templates/delete-template',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      await deleteRequest(`/docflow/templates/${payload.templateId}?project=${payload.projectId}`)
      payload?.withNotification && dispatch(addedNewNotification({ message: 'Шаблон успешно удален' }))
      return payload
    } catch (error) {
      dispatch(addedNewNotification({ message: 'Не удалось удалить шаблон ' + error }))
      return rejectWithValue(error)
    }
  }
)


export const postCopyTemplate = createThunk<ITemplate, ITemplateCreate>(
  'docs-template/post-copy-template',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const res = await post<ITemplate>('/docflow/templates', snakecaseFields(payload))
      dispatch(addedNewNotification({ message: 'Шаблон успешно скопирован' }))
      return res
    } catch (error: any) {
      dispatch(addedNewNotification({ message: 'Не удалось скопировать шаблон. ' + error?.admittedUsers?.nonFieldErrors[0] ?? '' }))
      return rejectWithValue(error)
    }
  }
)
