import React from 'react'
import { IIcon } from './IIcon'

export const UploadSimple: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.5',
  color = '#1A65DA',
  ...innerProps
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 24 25" fill="none">
    <path d="M21 14.75V20C21 20.3978 20.842 20.7794 20.5607 21.0607C20.2794 21.342 19.8978 21.5 19.5 21.5H4.5C4.10218 21.5 3.72064 21.342 3.43934 21.0607C3.15804 20.7794 3 20.3978 3 20V14.75C3 14.5511 3.07902 14.3603 3.21967 14.2197C3.36032 14.079 3.55109 14 3.75 14C3.94891 14 4.13968 14.079 4.28033 14.2197C4.42098 14.3603 4.5 14.5511 4.5 14.75V20H19.5V14.75C19.5 14.5511 19.579 14.3603 19.7197 14.2197C19.8603 14.079 20.0511 14 20.25 14C20.4489 14 20.6397 14.079 20.7803 14.2197C20.921 14.3603 21 14.5511 21 14.75ZM8.25 8.75001H11.25V14.75C11.25 14.9489 11.329 15.1397 11.4697 15.2803C11.6103 15.421 11.8011 15.5 12 15.5C12.1989 15.5 12.3897 15.421 12.5303 15.2803C12.671 15.1397 12.75 14.9489 12.75 14.75V8.75001H15.75C15.8984 8.75013 16.0435 8.7062 16.167 8.6238C16.2904 8.5414 16.3867 8.42422 16.4435 8.2871C16.5003 8.14999 16.5151 7.99909 16.4861 7.85353C16.4572 7.70797 16.3856 7.57428 16.2806 7.46938L12.5306 3.71939C12.461 3.64965 12.3783 3.59433 12.2872 3.55659C12.1962 3.51885 12.0986 3.49942 12 3.49942C11.9014 3.49942 11.8038 3.51885 11.7128 3.55659C11.6217 3.59433 11.539 3.64965 11.4694 3.71939L7.71937 7.46938C7.61437 7.57428 7.54284 7.70797 7.51385 7.85353C7.48487 7.99909 7.49972 8.14999 7.55653 8.2871C7.61335 8.42422 7.70957 8.5414 7.83301 8.6238C7.95646 8.7062 8.10158 8.75013 8.25 8.75001Z" fill={color} />
  </svg>
)