import {
  setLoadingFlowStageTitle,
  getFlowStageTitleSuccess,
  getFlowStageTitleFailed,
  setLoadingFlowActions,
  getFlowActionSuccess,
  getFlowActionFailed,
  setIsLoadingFlowTemplate,
  getDocumentFlowTemplateSuccess,
  getDocumentFlowTemplateFailed,
  setLoadingGetDocumentForm,
  getDocumentFormSucces,
  getDocumentFormFailed,
  setLoadingGetMembersRole,
  getMembersRoleSuccess,
  getMembersRoleFailed,
  setLoadingGetTemplateExcel,
  getTemplateExcelSuccess,
  getTemplateExcelFailed,
} from './actionCreators'
import { get } from '../../utils/axios'
import getDocument from '../../utils/axios/getDocument'

export const getFlowTemplates = () => (
  dispatch => {
    dispatch(setIsLoadingFlowTemplate())
    get({ path: '/flow_templates' })
      .then(templates => dispatch(getDocumentFlowTemplateSuccess(templates)))
      .catch(() => dispatch(getDocumentFlowTemplateFailed()))
  }
)

export const getFlowStageTitles = () => (
  dispatch => {
    dispatch(setLoadingFlowStageTitle)
    get({ path: '/flow_stage_titles' })
      .then(titles => dispatch(getFlowStageTitleSuccess(titles)))
      .catch(() => dispatch(getFlowStageTitleFailed))
  }
)

export const getFlowActions = () => (
  dispatch => {
    dispatch(setLoadingFlowActions())
    get({ path: '/flow_actions' })
      .then(data => dispatch(getFlowActionSuccess(data)))
      .catch(() => dispatch(getFlowActionFailed()))
  }
)

export const getDocumentForm = () => (
  dispatch => {
    dispatch(setLoadingGetDocumentForm())
    get({ path: '/document_forms' })
      .then(data => dispatch(getDocumentFormSucces(data)))
      .catch(() => dispatch(getDocumentFormFailed()))
  }
)

export const getMembersRole = () => (
  dispatch => {
    dispatch(setLoadingGetMembersRole())
    get({ path: '/projects/members_roles', isCamelCase: false })
      .then(data => dispatch(getMembersRoleSuccess(data)))
      .catch(() => dispatch(getMembersRoleFailed()))
  }
)

export const getTemplateExcel = () => (
  async dispatch => {
    dispatch(setLoadingGetTemplateExcel())
    getDocument({ path: '/users/upload_workers' })
      .then(data => {
        const res = URL.createObjectURL(data)
        dispatch(getTemplateExcelSuccess(res))
      })
      .catch(() => dispatch(getTemplateExcelFailed()))
  }
)
