import React from 'react'
import { useTSelector } from '../../../../utils/hooks/reduxHooks'
import * as Icon from '../../../../../fsd/shared/ui-icons'
import cn from 'classnames'

export const Notifications:React.FC<PropsType> = ({
  isOpenAppNotification
}) => {

  const unreadNotificationsCount = useTSelector((state: any) => state.appNotification.unreadNotificationsCount) as null | number

  return (
    <div style={{ position: 'relative' }}>
      <Icon.Bell
        opacity='1'
        color='#5C5C5C'
        className={cn({'btn_active': isOpenAppNotification})}
      />

      {!!unreadNotificationsCount && (
        <div className='sidebar_top__notification-count'>
          {unreadNotificationsCount}
        </div>
      )}
    </div>
  )
}

type PropsType = {
  isOpenAppNotification: boolean
}

