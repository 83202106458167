import { getHeaders } from '../utils/axios/getHeaders'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import Host from '../utils/axios/host'

export const baseQuery = {
  baseUrl: Host.getInstance().getRequestUrl(),
  prepareHeaders: async (Headers: Headers) => {
    Object.entries(await getHeaders())
      .forEach((header) => Headers.set(...header))
    return Headers
  }
}
// export const baseQuery = () => fetchBaseQuery({
//   baseUrl: Host.getInstance().getRequestUrl(),
//   prepareHeaders: async (Headers: Headers) => {
//     Object.entries(await getHeaders())
//       .forEach((header) => Headers.set(...header))
//     return Headers
//   }
// })