import React from 'react'
import { IIcon } from './IIcon'

export const CommentPlaceholder: React.FC<IIcon> = ({
  width = '10.4',
  height = '10ю4',
  color = '#C2C2C2',
  ...innerProps
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M87.75 34.125H73.125V19.5C73.125 18.2071 72.6114 16.9671 71.6971 16.0529C70.7829 15.1386 69.5429 14.625 68.25 14.625H16.25C14.9571 14.625 13.7171 15.1386 12.8029 16.0529C11.8886 16.9671 11.375 18.2071 11.375 19.5V71.5C11.375 71.931 11.5462 72.3443 11.851 72.649C12.1557 72.9538 12.569 73.125 13 73.125C13.3712 73.1245 13.7311 72.9969 14.0197 72.7634L29.6562 60.125H30.875V74.75C30.875 76.0429 31.3886 77.2829 32.3029 78.1971C33.2171 79.1114 34.4571 79.625 35.75 79.625H74.3438L89.9803 92.2634C90.2689 92.4969 90.6288 92.6245 91 92.625C91.431 92.625 91.8443 92.4538 92.149 92.149C92.4538 91.8443 92.625 91.431 92.625 91V39C92.625 37.7071 92.1114 36.4671 91.1971 35.5529C90.2829 34.6386 89.0429 34.125 87.75 34.125ZM29.0794 56.875C28.7082 56.8755 28.3483 57.0031 28.0597 57.2366L14.625 68.0956V19.5C14.625 19.069 14.7962 18.6557 15.101 18.351C15.4057 18.0462 15.819 17.875 16.25 17.875H68.25C68.681 17.875 69.0943 18.0462 69.399 18.351C69.7038 18.6557 69.875 19.069 69.875 19.5V55.25C69.875 55.681 69.7038 56.0943 69.399 56.399C69.0943 56.7038 68.681 56.875 68.25 56.875H29.0794ZM89.375 87.5956L75.9403 76.7366C75.6517 76.5031 75.2918 76.3755 74.9206 76.375H35.75C35.319 76.375 34.9057 76.2038 34.601 75.899C34.2962 75.5943 34.125 75.181 34.125 74.75V60.125H68.25C69.5429 60.125 70.7829 59.6114 71.6971 58.6971C72.6114 57.7829 73.125 56.5429 73.125 55.25V37.375H87.75C88.181 37.375 88.5943 37.5462 88.899 37.851C89.2038 38.1557 89.375 38.569 89.375 39V87.5956Z" fill={color}/>
  </svg>
)