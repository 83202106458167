import React from 'react'
import { IIcon } from './IIcon'

export const Users:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4191 18.3403C15.4929 18.4535 15.5347 18.5845 15.5402 18.7194C15.5457 18.8544 15.5147 18.9884 15.4503 19.1072C15.386 19.226 15.2908 19.3252 15.1747 19.3943C15.0586 19.4635 14.926 19.5 14.791 19.5H0.959076C0.823978 19.5 0.691393 19.4635 0.575329 19.3943C0.459265 19.3252 0.364031 19.226 0.299689 19.1072C0.235348 18.9884 0.204287 18.8544 0.20979 18.7194C0.215293 18.5845 0.257154 18.4535 0.330951 18.3403C1.38668 16.7153 2.93954 15.4752 4.75783 14.805C3.75264 14.1358 2.98949 13.1609 2.58123 12.0244C2.17297 10.8879 2.14128 9.65027 2.49085 8.4944C2.84041 7.33853 3.55267 6.32584 4.5223 5.60608C5.49193 4.88632 6.66744 4.49771 7.87501 4.49771C9.08258 4.49771 10.2581 4.88632 11.2277 5.60608C12.1974 6.32584 12.9096 7.33853 13.2592 8.4944C13.6087 9.65027 13.5771 10.8879 13.1688 12.0244C12.7605 13.1609 11.9974 14.1358 10.9922 14.805C12.8105 15.4752 14.3634 16.7153 15.4191 18.3403ZM23.6606 18.3262C22.6046 16.7081 21.0552 15.4733 19.2422 14.805C20.4289 14.0056 21.2663 12.7841 21.5841 11.3891C21.9019 9.99401 21.6762 8.53029 20.9528 7.29583C20.2294 6.06137 19.0628 5.14897 17.6904 4.74433C16.318 4.3397 14.843 4.47325 13.5656 5.11781C13.5168 5.14303 13.4741 5.17868 13.4406 5.22223C13.407 5.26579 13.3835 5.31619 13.3716 5.36986C13.3597 5.42353 13.3597 5.47915 13.3717 5.5328C13.3837 5.58645 13.4073 5.63681 13.441 5.68031C14.3908 6.86513 14.9363 8.32297 14.9975 9.84029C15.0588 11.3576 14.6325 12.8547 13.7813 14.1122C13.7262 14.1944 13.7059 14.2949 13.7247 14.392C13.7435 14.4891 13.7999 14.5749 13.8816 14.6306C14.9915 15.4053 15.9416 16.3866 16.68 17.5209C16.9778 17.977 17.0994 18.5258 17.0222 19.065C17.0135 19.1186 17.0166 19.1735 17.0312 19.2258C17.0458 19.2782 17.0716 19.3267 17.1068 19.3681C17.142 19.4094 17.1858 19.4427 17.2352 19.4654C17.2845 19.4882 17.3382 19.5 17.3925 19.5H23.0456C23.2108 19.5 23.3713 19.4456 23.5024 19.3451C23.6334 19.2445 23.7276 19.1036 23.7703 18.9441C23.7964 18.8391 23.8001 18.7299 23.7812 18.6235C23.7623 18.517 23.7212 18.4158 23.6606 18.3262Z"
      fill={color}/>
  </svg>
)