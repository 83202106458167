import React from 'react'
import { IIcon } from './IIcon'

export const Gate: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = 'rgba(0, 0, 0, 0.64)',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M3 19V20H11.3721V19C11.3721 18.4477 10.9556 18 10.4419 18V13H19.7442C20.1699 13 20.5787 12.9117 20.9539 12.7502C22.1514 12.2348 23 10.9751 23 9.5C23 7.567 21.5423 6 19.7442 6H9.46464C8.87742 5.38141 8.07316 5 7.18605 5C5.38791 5 3.93023 6.567 3.93023 8.5V18C3.41648 18 3 18.4477 3 19ZM10.4419 11H11.8586L10.7423 8H10.4419V11ZM8.11633 9.3662C8.11633 9.91849 7.69985 10.3662 7.18609 10.3662C6.67234 10.3662 6.25586 9.91849 6.25586 9.3662C6.25586 8.81392 6.67234 8.3662 7.18609 8.3662C7.69985 8.3662 8.11633 8.81392 8.11633 9.3662ZM13.8624 11L12.7461 8H14.4632L15.5795 11H13.8624ZM17.5833 11L16.467 8H18.1842L19.3004 11H17.5833ZM20.9943 10.1671L20.2214 8.09003C20.757 8.29959 21.1395 8.85178 21.1395 9.5C21.1395 9.73937 21.0873 9.96597 20.9943 10.1671Z"
          fill={color}/>
  </svg>
)
