import React, { useCallback, useEffect, useRef } from 'react'

import * as Icons from '../../ui-icons/index'
import cn from 'classnames'

//TODO перенести
import useHandleClickOutside from '../../../../(deprecated)/utils/hooks/useHandleClickOutside'

import './CollapseWithTitle.scss'

export const CollapseWithTitle: React.FC<PropType> = ({
  title,
  className,
  isOpen,
  toggleOpen,
  targetClassNamesForNotCloseCollapse,
  children
}) => {
  const refContainerForSetSize = useRef<HTMLDivElement>(null)
  const refObserveItems = useRef<HTMLDivElement>(null)


  const onToggleOpenWithAnimation = (isOpen: boolean) => {
    refContainerForSetSize.current?.classList.add('collapse-with-title__items-transition')
    toggleOpen(isOpen)
    setTimeout(() => {
      refContainerForSetSize.current?.classList.remove('collapse-with-title__items-transition')
    }, 300)
  }

  const proccessingSizeContainer = useCallback(() => {
    if (refContainerForSetSize.current) {
      const parent = refContainerForSetSize.current.parentElement!
      const isOpen = parent.classList.contains('open')
      refContainerForSetSize.current.style.height = !isOpen ? '0px' : `${refObserveItems.current?.offsetHeight}px`
    }
  }, [])

  useEffect(() => {
    if (refContainerForSetSize.current) {

      refContainerForSetSize.current.style.height = !isOpen ? '0px' : `${refObserveItems.current?.offsetHeight}px`
      const resize = () => {
        refContainerForSetSize.current!.style.height = !isOpen ? '0px' : `${refObserveItems.current?.offsetHeight}px`
      }

      window.addEventListener('resize', resize)
      return () => window.removeEventListener('resize', resize)
    }
  }, [isOpen])

  useEffect(() => {
    if (refObserveItems.current) {
      const observe = new ResizeObserver(proccessingSizeContainer)
      observe.observe(refObserveItems.current)

      return () => {
        observe.disconnect()
      }
    }
  }, [refObserveItems.current])
  
  useHandleClickOutside(['.collapse-with-title', ...targetClassNamesForNotCloseCollapse ?? []], () => (
    onToggleOpenWithAnimation(false)
  ))

  return (
    <div
      className={cn('collapse-with-title', className, { 'open': isOpen })}
      onClick={() => onToggleOpenWithAnimation(true)}
      data-testid={`${title}-testid`}
    >
      <div className='collapse-with-title__header'>
        <p>{title}</p>
        <Icons.ChevronWithoutBorder
          onClick={(e) => {
            e.stopPropagation()
            onToggleOpenWithAnimation(!isOpen)
          }}
        />
      </div>
      <div
        ref={refContainerForSetSize}
        className='collapse-with-title__items'
        data-testid='collapse-with-title__items-testid'
      >
        <div ref={refObserveItems}>
          {children}
        </div>
      </div>
    </div>
  )
}

type PropType = {
  title: string,
  className?: string,
  isOpen: boolean,
  toggleOpen: (isOpen: boolean) => void,
  targetClassNamesForNotCloseCollapse?: string[]
  children: React.ReactNode[] | React.ReactNode
}
