import React from 'react'
import { IIcon } from './IIcon'

export const LocationIcon: React.FC<IIcon> = ({
  width = '2',
  height = '2',
  color = 'rgba(0, 0, 0, 0.64)',
   className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 20 20"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0001 1.66663C6.77508 1.66663 4.16675 4.27496 4.16675 7.49996C4.16675 8.94996 4.58341 10.3083 5.34175 11.5333C6.13341 12.8166 7.17508 13.9166 7.97508 15.2C8.36675 15.825 8.65008 16.4083 8.95008 17.0833C9.16675 17.5416 9.34175 18.3333 10.0001 18.3333C10.6584 18.3333 10.8334 17.5416 11.0417 17.0833C11.3501 16.4083 11.6251 15.825 12.0167 15.2C12.8167 13.925 13.8584 12.825 14.6501 11.5333C15.4167 10.3083 15.8334 8.94996 15.8334 7.49996C15.8334 4.27496 13.2251 1.66663 10.0001 1.66663ZM10.0001 9.79163C8.85008 9.79163 7.91675 8.85829 7.91675 7.70829C7.91675 6.55829 8.85008 5.62496 10.0001 5.62496C11.1501 5.62496 12.0834 6.55829 12.0834 7.70829C12.0834 8.85829 11.1501 9.79163 10.0001 9.79163Z"
      fill={color}/>
  </svg>
)