import React from 'react'
import { IIcon } from './IIcon'

export const MapPin:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 25 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 1.5C10.3127 1.50248 8.21575 2.37247 6.66911 3.91911C5.12247 5.46575 4.25248 7.56273 4.25 9.75C4.25 16.8094 11.75 22.1409 12.0697 22.3641C12.1958 22.4524 12.346 22.4998 12.5 22.4998C12.654 22.4998 12.8042 22.4524 12.9303 22.3641C13.25 22.1409 20.75 16.8094 20.75 9.75C20.7475 7.56273 19.8775 5.46575 18.3309 3.91911C16.7843 2.37247 14.6873 1.50248 12.5 1.5ZM12.5 6.75C13.0933 6.75 13.6734 6.92595 14.1667 7.25559C14.6601 7.58524 15.0446 8.05377 15.2716 8.60195C15.4987 9.15013 15.5581 9.75333 15.4424 10.3353C15.3266 10.9172 15.0409 11.4518 14.6213 11.8713C14.2018 12.2909 13.6672 12.5766 13.0853 12.6924C12.5033 12.8081 11.9001 12.7487 11.3519 12.5216C10.8038 12.2946 10.3352 11.9101 10.0056 11.4167C9.67595 10.9234 9.5 10.3433 9.5 9.75C9.5 8.95435 9.81607 8.19129 10.3787 7.62868C10.9413 7.06607 11.7044 6.75 12.5 6.75Z"
      fill={color}/>
  </svg>
)