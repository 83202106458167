import React, { useMemo } from 'react'
import { LOCKED_ROLE_FOR_CHANGE } from '../..'
import { Tooltip } from 'antd'

import * as Icons from '../../../../shared/ui-icons'
import cn from 'classnames'

import './RoleInfoMatrix.scss'

export const RoleInfoMatrix: React.FC<PropType> = React.memo(({
  roleName,
  className,
  renderChildrenHead,
  childrenBottom
}) => {

  const withLock = useMemo(() => (
    LOCKED_ROLE_FOR_CHANGE.includes(roleName)
  ), [roleName])

  return (
    <div className={cn('role-info-matrix', className)}>
      <div className='role-info-matrix_header'>
        {withLock && <Icons.Lock width='1.8' height='1.8' color='rgba(148, 148, 148, 1)' />}
        <Tooltip
          title={roleName}
          placement='top'
          autoAdjustOverflow={true}
          arrowPointAtCenter={true}
          overlayClassName='role-name-tooltip'
        >
          <p>{roleName}</p>
        </Tooltip>
        {renderChildrenHead?.(withLock)}
      </div>
      {childrenBottom}
    </div>
  )
})


type PropType = {
  roleName: string
  className?: string,
  renderChildrenHead?: (withLock: boolean) => React.ReactNode | React.ReactNode[] | boolean
  childrenBottom?: React.ReactNode | React.ReactNode[] | boolean
}
