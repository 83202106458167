import React from 'react'
import { IIcon } from './IIcon'

export const BackgroundBanner: React.FC<IIcon> = ({ className }) => (
  <svg width="34.3rem" className={className} height="56.4rem" preserveAspectRatio="none" viewBox="0 0 343 564" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 512.893C0 512.893 0 564 171.5 564C343 564 343 512.893 343 512.893V0H0V512.893Z" fill="#1A65DA" />
    <path d="M0 512.893C0 512.893 0 564 171.5 564C343 564 343 512.893 343 512.893V0H0V512.893Z" fill="url(#paint0_linear)" fillOpacity="0.2" />
    <defs>
      <linearGradient id="paint0_linear" x1="171.5" y1="564" x2="171.5" y2="0" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
)