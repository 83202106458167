import * as types from './types'

const initialState = {
  checkpointsList: [],
  isLoadingCheckpoints: false,
  isLoadingPostCheckpoint: false,
  checkpointsVehiclesLog: [],
  countCheckpointLog: null,
  isLoadingVehiclesLog: false,
  checkpointsEmployeeLog: [],
  countCheckpointEmployeeLog: null,
  isLoadingEmploeeLog: false,
  checkpointsSecurity: [],
}

export const checkpointReducer = (state = initialState, { payload, type }) => {
  switch (type) {

    // CHECKPOINTS
    case types.SET_LOADING_GET_CHECKPOINTS:
      return {
        ...state,
        isLoadingCheckpoints: !state.isLoadingCheckpoints
      }

    case types.GET_CHECKPOINTS_SUCCESS:
      return {
        ...state,
        isLoadingCheckpoints: false,
        checkpointsList: payload
      }

    case types.SET_LOADING_POST_CHECKPOINT:
      return {
        ...state,
        isLoadingPostCheckpoint: !state.isLoadingPostCheckpoint
      }

    // LOGS
    case types.GET_CHECKPOINTS_VEHICLES_LOG:
      const checkpointsVehiclesLogList = payload.results
        ? payload.offset === 0 ? payload.results : [...state.checkpointsVehiclesLog, ...payload.results]
        : []

      return {
        ...state,
        checkpointsVehiclesLog: checkpointsVehiclesLogList,
        countCheckpointLog: payload.count
      }

    case types.GET_CHECKPOINTS_EMPLOYEE_LOG:
      const checkpointsEmployeeLogList = payload.results
        ? payload.offset === 0 ? payload.results : [...state.checkpointsEmployeeLog, ...payload.results]
        : []

      return {
        ...state,
        checkpointsEmployeeLog: checkpointsEmployeeLogList,
        countCheckpointEmployeeLog: payload.count
      }

    case types.TOGGLE_LOADING_GET_CHECKPOINTS_VEHICLES_LOG:
      return { ...state, isLoadingVehiclesLog: !state.isLoadingVehiclesLog }

    case types.TOGGLE_LOADING_GET_CHECKPOINTS_EMPLOYEE_LOG:
      return { ...state, isLoadingEmploeeLog: !state.isLoadingEmploeeLog }

    case types.CLEAR_ALL_CHECKPOINTS_LOGS:
      return { ...state, checkpointsVehiclesLog: [], checkpointsEmployeeLog: [] }

    //  CHECKPOINT_POSITION
    case types.PUT_CHECKPOINT_POSITION_SUCCESS:
      return {
        ...state,
        checkpointsList: payload
      }

    // CHECKPOINT_SECURITY
    case types.GET_CHECKPOINT_SECURITY_SUCCESS:
      return {
        ...state,
        checkpointsSecurity: payload
      }

    case types.POST_CHECKPOINT_SECURITY_SUCCESS:
      return {
        ...state,
        checkpointsSecurity: [...state.checkpointsSecurity, payload]
      }

    case types.DELETE_CHECKPOINT_SECURITY_SUCCESS:
      return {
        ...state,
        checkpointsSecurity: state.checkpointsSecurity.filter(
          ({ securityId }) => securityId !== payload
        )
      }

    default:
      return { ...state }
  }
}