import React from 'react'
import { Modal as BaseModal, ModalProps } from 'antd'
import * as Icons from '../../../../fsd/shared/ui-icons'
import cn from 'classnames'
import './Modal.scss'
import 'antd/lib/modal/style/css'

export const Modal: React.FC<IPropType> = ({
  children,
  className,
  ...props
}) => (
  <BaseModal
    centered
    destroyOnClose
    closeIcon={<Icons.Close className='btn btn_hover' />}
    className={cn('modal-with-antd', className)}
    footer={null}
    {...props}
  >
    {children}
  </BaseModal>
)

interface IPropType extends ModalProps {
  children: React.ReactNode | React.ReactNode[]
}