import React from 'react'
import cn from 'classnames'
import * as Icon from '../../../../fsd/shared/ui-icons'

import './DynamicPagination.scss'

export const DynamicPagination: React.FC<DynamicPaginationTypeComponent> = React.memo(({
  className,
  currentPage,
  totalCount,
  countOnePage,
  changePage,
  mode = 'itemsAmount',
  children
}) => {

  const countsControl = {
    itemsAmount:
      `${(currentPage - 1) * countOnePage + 1} - ${currentPage * countOnePage <= totalCount!
        ? currentPage * countOnePage
        : totalCount
      } из ${totalCount}`,
    pagesAmount: `${currentPage} из ${Math.ceil(totalCount! / countOnePage)}`
  }

  return (
  <div className={cn('dynamic-pagination', className)}>
    {children}
    {!!totalCount && (
      <div className='dynamic-pagination__btn-control'>
        <p>{countsControl[mode]}</p>

        <Icon.ChevronPagination
          className={cn({ 'disable': currentPage == 1 })}
          onClick={() => (currentPage !== 1) && changePage(currentPage - 1)}
        />
        <Icon.ChevronPagination
          className={cn({ 'disable': currentPage >= totalCount / countOnePage })}
          onClick={() => currentPage * countOnePage < totalCount && changePage(currentPage + 1)}
        />
      </div>
    )}
  </div>
)})


type DynamicPaginationTypeComponent = {
  className?: string,
  currentPage: number,
  totalCount: number | null,
  countOnePage: number,
  mode?: 'pagesAmount' | 'itemsAmount',
  changePage: (page: number) => void,
  children?: React.ReactNode
}
