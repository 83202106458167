import React from 'react'
import { IIcon } from './IIcon'

export const VolumeOff:React.FC<IIcon> = ({
  width = '2.0',
  height = '2.0',
  color = 'rgba(0, 0, 0, 0.24)',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 20 20"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.75 10C13.75 8.525 12.9 7.25833 11.6667 6.64167V8.48333L13.7083 10.525C13.7333 10.3583 13.75 10.1833 13.75 10ZM15.8333 10C15.8333 10.7833 15.6667 11.5167 15.3833 12.2L16.6417 13.4583C17.1917 12.425 17.5 11.25 17.5 10C17.5 6.43333 15.0083 3.45 11.6667 2.69167V4.40833C14.075 5.125 15.8333 7.35833 15.8333 10ZM3.55833 2.5L2.5 3.55833L6.44167 7.5H2.5V12.5H5.83333L10 16.6667V11.0583L13.5417 14.6C12.9833 15.0333 12.3583 15.375 11.6667 15.5833V17.3C12.8167 17.0417 13.8583 16.5083 14.7417 15.7917L16.4417 17.5L17.5 16.4417L10 8.94167L3.55833 2.5ZM10 3.33333L8.25833 5.075L10 6.81667V3.33333Z"
      fill={color}/>
  </svg>
)