import { addedNewNotification } from '../../redux/notifications/actions'
import { ProjectAdapter } from './ProjectAdapter'
import store from '../../redux/store'

type UpdateProjectQuery = {
  data: Record<string, unknown>
  id: number
}

export class PutProjectByIdQuey {

  query = ({ data, id }: UpdateProjectQuery) => {
    return {
      url: `/projects/${id}`,
      method: 'PUT',
      body: data
    }
  }

  transformResponse = (response: any) => {
    const message = 'Изменения сохранены.'

    store.dispatch(
      addedNewNotification({ message })
    )

    return new ProjectAdapter(response).getFields()
  }

  transformErrorResponse = () => {
    const message = 'Не удалось сохранить изменения.'

    store.dispatch(
      addedNewNotification({ message })
    )

    return message
  }
}