import { createEntityAdapter, createSlice, current, PayloadAction } from "@reduxjs/toolkit"
import { IFullMeet, IMeetList, IMeetType, IShortMeet, ISubscriber } from "../../models/IMeet"
import { IDynamicPageNumberPaginationMeta } from "../../models/IPagination"
import { deleteMeet, deleteMeetTypes, deleteSubscriber, getMeet, getMeetList, getMeetMonth, getMeetTypes, getSubscribers, postMeet, postMeetTypes, postSubscriber, postUsersBusyness, PostUsersBusynessResponse, putEngageStatusActiveMeet, putMeet, putMeetTypes, putSubscriber } from "./thunk"

// Вынести для переиспользования
type LoadingType = 'idle' | 'pending' | 'succeeded' | 'failed'
const loadingObj: { loading: LoadingType } = {
    loading: 'idle'
}
// 

export const subscriberAdapter = createEntityAdapter<ISubscriber>()
export const meetListAdapter = createEntityAdapter<IMeetList>()
export const meetMonthAdapter = createEntityAdapter<IShortMeet>()
export const meetTypesAdapter = createEntityAdapter<IMeetType>()

const meetSlice = createSlice({
    name: 'meet',
    initialState: {
        subscribers: subscriberAdapter.getInitialState({ ...loadingObj }),
        meetTypes: meetTypesAdapter.getInitialState({ ...loadingObj }),
        meetList: meetListAdapter.getInitialState({
            ...loadingObj,
            meta: null as IDynamicPageNumberPaginationMeta | null,
            newMeetCount: null as number | null
        }),
        meetMonthData: meetMonthAdapter.getInitialState({
            ...loadingObj,
        }),
        activeMeet: {
            data: null as IFullMeet | null,
            ...loadingObj,
            loadingEngageStatus: 'idle' as LoadingType
        },
        usersBusyness: null as PostUsersBusynessResponse | null,
        loadingFormMeet: 'idle' as LoadingType // Форма создания/обновления
    },
    reducers: {
        // SUBSCRIBERS
        toggleLoadingSubscribers(state, action: PayloadAction<LoadingType>) {
            state.subscribers.loading = action.payload
        },
        // MEET TYPE
        toggleLoadingMeetTypes(state, action: PayloadAction<LoadingType>) {
            state.meetTypes.loading = action.payload
        },
        // MEET LIST
        toggleLoadingMeetList(state, action: PayloadAction<LoadingType>) {
            state.meetList.loading = action.payload
        },
        clearMeetList(state) {
            meetListAdapter.removeAll(state.meetList)
            state.meetList.meta = null
            state.meetList.newMeetCount = null
            state.meetList.loading = 'idle'
        },
        // MEET MONTH
        toggleLoadingMeetMonthData(state, action: PayloadAction<LoadingType>) {
            state.meetMonthData.loading = action.payload
        },
        // ACTIVE MEET
        toggleLoadingActiveMeet(state, action: PayloadAction<LoadingType>) {
            state.activeMeet.loading = action.payload
        },
        clearActiveMeet(state) {
            state.activeMeet.data = null
        },
        toggleLoadingEngageStatus(state, action: PayloadAction<LoadingType>) {
            state.activeMeet.loadingEngageStatus = action.payload
        },

        toggleLoadingFormMeet(state, action: PayloadAction<LoadingType>) {
            state.loadingFormMeet = action.payload
        },

        // USERS BUSYNESS
        clearUsersBusyness(state) {
            state.usersBusyness = null
        },
    },
    extraReducers: (builder) => {

        // SUBSCRIBERS
        builder.addCase(getSubscribers.fulfilled, (state, { payload }) => {
            subscriberAdapter.setAll(state.subscribers, payload)
        })
        builder.addCase(putSubscriber.fulfilled, (state, { payload }) => {
            subscriberAdapter.upsertOne(state.subscribers, payload)
        })
        builder.addCase(postSubscriber.fulfilled, (state, { payload }) => {
            subscriberAdapter.addOne(state.subscribers, payload)
        })
        builder.addCase(deleteSubscriber.fulfilled, (state, { payload }) => {
            subscriberAdapter.removeOne(state.subscribers, payload)
        })

        // MEET TYPES
        builder.addCase(getMeetTypes.fulfilled, (state, { payload }) => {
            meetTypesAdapter.setAll(state.meetTypes, payload)
        })
        builder.addCase(postMeetTypes.fulfilled, (state, { payload }) => {
            meetTypesAdapter.addOne(state.meetTypes, payload)
        })
        builder.addCase(putMeetTypes.fulfilled, (state, { payload }) => {
            // Обновление dndPosition у meetTypes
            const meetTypes = meetTypesAdapter.getSelectors().selectAll(state.meetTypes)
            const hasDndPosChanged = meetTypes.find(mt => mt.id === payload.id)?.dndPosition !== payload.dndPosition

            if (hasDndPosChanged) {
                const prevDndPos = meetTypes.find(mt => mt.id === payload.id)!.dndPosition
                const isDraggedUp = payload.dndPosition < prevDndPos

                let updatedMeetTypes = meetTypes
                    .filter(mt => mt.id !== payload.id)
                    .map(mt => (
                        isDraggedUp
                            ? mt.dndPosition >= payload.dndPosition && mt.dndPosition <= prevDndPos
                                ? { ...mt, dndPosition: mt.dndPosition < meetTypes.length ? mt.dndPosition + 1 : meetTypes.length }
                                : mt
                            : mt.dndPosition <= payload.dndPosition && mt.dndPosition > prevDndPos
                                ? { ...mt, dndPosition: mt.dndPosition > 1 ? mt.dndPosition - 1 : mt.dndPosition }
                                : mt
                    ))
                updatedMeetTypes.push(payload)

                meetTypesAdapter.setAll(
                    state.meetTypes,
                    updatedMeetTypes.sort((prev, next) => prev.dndPosition > next.dndPosition ? 1 : -1)
                )
            }

            // Обновление названия или цвета в календаре 
            meetTypesAdapter.updateOne(state.meetTypes, { id: payload.id, changes: payload })
            const meets = meetMonthAdapter.getSelectors().selectAll(state.meetMonthData)
            const meetsForUpdate = meets
                .filter(({ eventType }) => eventType?.id == payload.id)
                .map(({ id }) => ({ id, changes: { eventType: payload } }))
            meetMonthAdapter.updateMany(state.meetMonthData, meetsForUpdate)
        })
        builder.addCase(deleteMeetTypes.fulfilled, (state, { payload }) => {
            meetTypesAdapter.removeOne(state.meetTypes, payload)
            // Обновление dndPosition после удаления
            const updatedMeetTypes = meetTypesAdapter.getSelectors().selectAll(state.meetTypes).map((mt, index) => ({ ...mt, dndPosition: index + 1 }))
            meetTypesAdapter.setAll(state.meetTypes, updatedMeetTypes)

            // Удаление события из календаря
            const meets = meetMonthAdapter.getSelectors().selectAll(state.meetMonthData)
            const meetsForUpdate = meets
                .filter(({ eventType }) => eventType?.id == payload)
                .map(({ id }) => ({ id, changes: { eventType: null } }))

            meetMonthAdapter.updateMany(state.meetMonthData, meetsForUpdate)
        })

        // MEET LIST
        builder.addCase(getMeetList.fulfilled, (state, { payload }) => {
            meetListAdapter.addMany(state.meetList, payload.results)
            state.meetList.meta = payload.meta
            state.meetList.newMeetCount = payload.newEventsCount
        })

        // MEET MONTH DATA
        builder.addCase(getMeetMonth.fulfilled, (state, { payload }) => {
            let shortMeetsSet = new Set<IShortMeet>()
            Object.keys(payload.events).forEach((key: keyof typeof payload.events) => {
                payload.events[key].forEach(event => shortMeetsSet.add(event))
            })

            let shortMeets: IShortMeet[] = []
            shortMeetsSet.forEach(meet => { shortMeets.push(meet) })

            meetMonthAdapter.setAll(state.meetMonthData, shortMeets)
        })

        // ACTIVE MEET
        builder.addCase(getMeet.fulfilled, (state, { payload }) => {
            state.activeMeet.data = payload
        })
        builder.addCase(deleteMeet.fulfilled, (state) => {
            meetMonthAdapter.removeOne(state.meetMonthData, state.activeMeet.data!.id)
            state.activeMeet.data = null
        })
        builder.addCase(putEngageStatusActiveMeet.fulfilled, (state, { payload }) => {
            state.activeMeet.data = payload.data
            const myParticipant = payload.data.participants.find(({ user }) => user.id == payload.meId)
            meetListAdapter.updateOne(state.meetList, { id: payload.data.id, changes: { userStatus: myParticipant?.status } })
            meetMonthAdapter.upsertOne(state.meetMonthData, payload.data)
        })


        builder.addCase(postMeet.fulfilled, (state, { payload }) => {
            meetMonthAdapter.addOne(state.meetMonthData, payload)
        })
        builder.addCase(putMeet.fulfilled, (state, { payload }) => {
            meetMonthAdapter.updateOne(state.meetMonthData, { id: payload.id, changes: payload })
        })
        // USERS BUSYNESS
        builder.addCase(postUsersBusyness.fulfilled, (state, { payload }) => {
            state.usersBusyness = payload
        })

    }
})
export const {
    toggleLoadingSubscribers,
    toggleLoadingMeetTypes,
    toggleLoadingMeetList,
    clearMeetList,
    toggleLoadingMeetMonthData,
    toggleLoadingActiveMeet,
    clearActiveMeet,
    toggleLoadingEngageStatus,
    clearUsersBusyness,
    toggleLoadingFormMeet
} = meetSlice.actions
export default meetSlice.reducer