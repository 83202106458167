import { BuilderRemarksApi } from '../types/types'
import { addedNewNotification } from '../../../../../(deprecated)/redux/notifications/actions'

export const getDrawingRemark = (builder: BuilderRemarksApi) => (
  builder.query({
    query: ({ uuid } : { uuid: string, cbSuccess: (data: any) => void }) => `drawing-issue/uuid/${uuid}`,

    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled
        arg?.cbSuccess?.(data)
      } catch (e) {
        dispatch(addedNewNotification({ message: 'Не удалось загрузить ссылку на чертеж замечания' }))
      }
    }
  })
)