import React, { useMemo } from 'react'
import { SimpleInput } from '../../../../(deprecated)/components/UI/SimpleInput/SimpleInput'
import { HoverContainer } from '../index'
import { debounce } from 'lodash'

import * as Icon from '../../ui-icons'
import cn from 'classnames'

import './InputWithDebouncedRequest.scss'


export const InputWithDebouncedRequest = React.memo<PropsType>(({
  searchValue,
  placeholder,
  customClassName = '',
  setSearchValue,
  getSearchValues,
}) => {

  const debouncedRequest = useMemo(() => debounce(getSearchValues, 500), [getSearchValues])

  const rightInputIcon = searchValue
    ? (
      <HoverContainer onClick={() => {
        setSearchValue('')
        debouncedRequest({ search: '' })
      }}>
        <Icon.Close />
      </HoverContainer>
    )
    : <Icon.MagnifyingGlass />

  return (
    <SimpleInput
      className={cn('simple-input-debounced', customClassName)}
      value={searchValue}
      placeholder={placeholder}
      stylePattern={['gray-background', 'blue-border-focus']}
      setValue={(value: string) => {
        setSearchValue(value)
        debouncedRequest({ search: value })
      }}
      icons={{ right: rightInputIcon }}
    />
  )
})

type PropsType = {
  searchValue: string
  placeholder: string
  customClassName?: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  getSearchValues: ({ page, search }: { page?: number, search?: string }) => void
}