import { addedNewNotification } from '../../redux/notifications/actions'
import store from '../../redux/store'

export class DeleteUserByIdQuery {
  query = ({ id }: { id: number }) => {
    return {
      url: `users/${id}`,
      method: 'DELETE',
    }
  }

  transformResponse = () => {
    const message = 'Сотрудник успешно удален.'

    store.dispatch(
      addedNewNotification({ message })
    )

    return message
  }

  transformErrorResponse = () => {
    const message = 'Не удалось удалить сотрудника.'

    store.dispatch(
      addedNewNotification({ message })
    )

    return message
  }

}