import * as TYPES from './types'
import { WS_DOCUMENT_DOC_COMMENT_UPDATE } from './types'
import { uniqBy } from 'lodash'

const initialState = {
  shortTemplatedDocument: null, //IDynamicPageNumber урезанные созданные документы
  isLoadingShortTemplatedDocuments: false,

  templateTypes: null, //ITamplateType урезаные типы шаьлонов
  isLoadingTemplateTypes: false,
  activeTemplate: null, // ITemplate  шаблон документа
  isLoadingActiveTemplate: false,
  categoryList: [],
  isLoadingCategoryList: false,
  isLoadingTemplates: false,
  isLoadingCreateTemplate: false,
  isLoadingUpdateTemplate: false,
  isLoadingCheckDocumentTitle: false,

  isLoadingCreationDocument: false,

  activeTemplateDocument: null, // ITemplateDocument созданный документ
  isLoadingActiveTemplateDocument: false,
  isLoadingAction: {
    aprrove: false,
    reject: false,
    notNegotiate: false,
    excel: false
  },
  listTemplates: null, // ITemplate[] шаблоны документов



  isLoadingExcelFile: false,
  excelTable: null,

  previewDocHtmlBody: null,
  isDocPreviewLoading: true,
  isLoadingDownloadPdf: false,
  historyData: {
    items: [],
    meta: {},
  },
  isLoadingHistory: false,

  //---COMMENTS---
  isOpenDiscussionConnection: false,
  isDocCommentsLoading: true,
  commentsList: null, // []
  activeDocInfo: null,
  countComments: null,
  meta: null
}

export const templatesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // Existing Document(s)
    case TYPES.GET_SHORT_TEMPLATED_DOCUMENTS_SUCCESS:
      return { ...state, shortTemplatedDocument: payload }
    case TYPES.TOGGLE_LOADING_SHORT_TEMPLATED_DOCUMENTS:
      return { ...state, isLoadingShortTemplatedDocuments: !state.isLoadingShortTemplatedDocuments }
    case TYPES.CLEAR_SHORT_TEMPLATED_DOCUMENT:
      return { ...state, shortTemplatedDocument: null }
    case TYPES.SET_DATA_IN_SHORT_TEMPLATE_DOCUMENT_AFTER_ACTION:
      return {
        ...state,
        shortTemplatedDocument: {
          ...state.shortTemplatedDocument,
          results: state.shortTemplatedDocument.results.map(template => (
            template.id == payload.id
              ? { ...template, ...payload }
              : template
          ))
        }
      }
    case TYPES.CLEAR_SHORT_TEMPLATED_DOCUMENT_AFTER_CHANGE_STATUS:
      return {
        ...state,
        shortTemplatedDocument: {
          ...state.shortTemplatedDocument,
          // todo подумать, что сделать с метой
          // count: state.shortTemplatedDocument.count - 1,
          results: state.shortTemplatedDocument.results.filter(template => template.id !== payload.id)
        }
      }

    case TYPES.TOGGLE_LOADING_CREATION_DOCUMENT:
      return {
        ...state,
        isLoadingCreationDocument: payload
      }
    // History
    case TYPES.GET_DOCUMENT_HISTORY_SUCCESS: {
      return {
        ...state,
        historyData: {
          items: [...state.historyData.items, ...payload.results],
          meta: payload.meta
        }
      }
    }
    case TYPES.TOGGLE_LOADING_DOCUMENT_HISTORY: {
      return {
        ...state,
        isLoadingHistory: !state.isLoadingHistory
      }
    }
    case TYPES.CLEAR_DOCUMENT_HISTORY: {
      return {
        ...state,
        historyData: {
          items: [],
          meta: {}
        }
      }
    }

    // Types Template
    case TYPES.GET_TEMPLATE_TYPE_SUCCESS:
      return { ...state, templateTypes: payload }
    case TYPES.TOGGLE_LOADING_TEMPLATE_TYPE:
      return { ...state, isLoadingTemplateTypes: !state.isLoadingTemplateTypes }

    // Active Template
    case TYPES.GET_TEMPLATE_SUCCESS:
      return { ...state, activeTemplate: payload }
    case TYPES.TOGGLE_LOADING_GET_TEMPLATE:
      return { ...state, isLoadingActiveTemplate: !state.isLoadingActiveTemplate }
    case TYPES.CLEAR_TEMPLATE:
      return { ...state, activeTemplate: null }

    // ActiveTemplateDocument
    case TYPES.GET_TEMPLATE_DOCUMENT_SUCCESS:
      // Если не работает разворот
      // return { ...state, activeTemplateDocument: { ...payload, htmlBody: DOC_TEMPLATE.replace('{{document}}',getMainFromTemplate(payload.htmlBody)) } }
      return { ...state, activeTemplateDocument: payload }
    case TYPES.TOGGLE_LOADING_TEMPLATE_DOCUMENT:
      return { ...state, isLoadingActiveTemplateDocument: !state.isLoadingActiveTemplateDocument }
    case TYPES.CLEAR_TEMPLATE_DOCUMENT:
      return { ...state, activeTemplateDocument: null }

    //ПЕРНЕСЕНО В docsSlice

    // case TYPES.UPDATE_ATTACHMENTS_USER_DOCUMENTS:
    //   return {
    //     ...state,
    //     activeTemplateDocument: {
    //       ...state.activeTemplateDocument,
    //       attachments: state.activeTemplateDocument.attachments.map(attachment => (
    //         attachment.id === payload.id ? payload : attachment
    //       ))
    //     }
    //   }

    // TOGGLE LOADING ACTIONS
    case TYPES.TOGGLE_LOADING_TEMPLATE_DOCUMENT_ACTION_APPROVE:
      return { ...state, isLoadingAction: { ...state.isLoadingAction, approve: !state.isLoadingAction.aprrove } }
    case TYPES.TOGGLE_LOADING_TEMPLATE_DOCUMENT_ACTION_REJECT:
      return { ...state, isLoadingAction: { ...state.isLoadingAction, reject: !state.isLoadingAction.reject } }
    case TYPES.TOGGLE_LOADING_TEMPLATE_DOCUMENT_ACTION_NOT_NEGOTIATE:
      return { ...state, isLoadingAction: { ...state.isLoadingAction, reject: !state.isLoadingAction.notNegotiate } }


    // EXCEL TABLE
    case TYPES.TOGGLE_LOADING_TABLE_EXCEL_FILE:
      return { ...state, isLoadingExcelFile: !state.isLoadingExcelFile }
    case TYPES.UPDATE_TABLE_EXCEL_INFO:
      return { ...state, excelTable: { ...state.excelTable, ...payload } }

    // CONSTRUCTOR
    case TYPES.GET_TEMPLATES_SUCCESS:
      return { ...state, listTemplates: payload }
    case TYPES.POST_TEMPLATES_SUCCESS:
      return {
        ...state,
        listTemplates: state.listTemplates?.length
          ? [...state.listTemplates, payload]
          : [payload],
        templateTypes: state.templateTypes?.length
          ? [...state.templateTypes, { id: payload.id, templateName: payload.templateName }]
          : [{ id: payload.id, templateName: payload.templateName }]
      }
    case TYPES.PUT_UPDATE_TEMPLATES_SUCCESS:
      return {
        ...state,
        listTemplates: state.listTemplates?.map(template => template.id == payload.id ? payload : template),
        activeTemplate: state.activeTemplate?.id == payload.id ? payload : state.activeTemplate
      }

    case TYPES.DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        listTemplates: state.listTemplates?.filter(template => template.id != payload) ?? null,
        templateTypes: state.templateTypes?.filter(template => template.id != payload) ?? null
      }

    // TOGGLE LOADING CONSTRUCTOR
    case TYPES.TOGGLE_LOADING_TEMPLATES:
      return { ...state, isLoadingTemplates: !state.isLoadingTemplates }
    case TYPES.TOGGLE_LOADING_POST_TEMPLATES:
      return { ...state, isLoadingCreateTemplate: !state.isLoadingCreateTemplate }
    case TYPES.TOGGLE_LOADING_PUT_UPDATE_TEMPLATES:
      return { ...state, isLoadingUpdateTemplate: !state.isLoadingUpdateTemplate }


    // CATEGORIES
    case TYPES.CLEAR_CATEGORIES:
      return { ...state, categoryList: [] }
    case TYPES.SET_CATEGORIES:
      return { ...state, categoryList: payload }
    case TYPES.SET_CATEGORIES_LOADING:
      return { ...state, isLoadingCategoryList: payload }

    case TYPES.SET_DOCUMENT_PREVIEW:
      return { ...state, previewDocHtmlBody: payload.htmlBody }
    case TYPES.CLEAR_DOCUMENT_PREVIEW:
      return { ...state, previewDocHtmlBody: null }
    case TYPES.SET_PREVIEW_DOC_LOADING:
      return { ...state, isDocPreviewLoading: payload }

    case TYPES.TOGGLE_LOADING_DOWNLOAD_PDF:
      return { ...state, isLoadingDownloadPdf: !state.isLoadingDownloadPdf }

    //---COMMENTS---
    case TYPES.WS_DOCUMENT_DISCUSSION_CONNECT_SUCCESS: {
      return {
        ...state,
        isOpenDiscussionConnection: true
      }
    }
    case TYPES.WS_DOCUMENT_DISCUSSION_DISCONNECT_SUCCESS: {
      return {
        ...state,
        isOpenDiscussionConnection: false,
        isDocCommentsLoading: true,
      }
    }

    case TYPES.WS_DOCUMENT_DISCUSSION_CONNECT: {
      return {
        ...state,
        activeDocInfo: payload.data
      }
    }

    case TYPES.INCOMING_DOC_DISCUSSION_COMMENTS: {
      const { results, count, meta } = payload.data;

      return {
        ...state,
        isDocCommentsLoading: false,
        commentsList: state.commentsList ? uniqBy([...state.commentsList, ...results], 'id') : results,
        countComments: count,
        meta: meta
      }
    }

    case TYPES.INCOMING_DISCUSSION_COMMENTS_SEND_COMMENT: {
      return {
        ...state,
        commentsList: state.commentsList ? [payload.data, ...state.commentsList] : [payload.data]
      }
    }

    case WS_DOCUMENT_DOC_COMMENT_UPDATE: {
      return {
        ...state,
        commentsList: state.commentsList.map(comment => comment.id === payload.data.id ? payload.data : comment)
      }
    }

    case TYPES.DELETE_DISCUSSION_COMMENT: {
      return {
        ...state,
        commentsList: state.commentsList.map(comment => (
          comment.id === payload.data ? { ...comment, isDeleted: true } : comment
        ))
      }
    }


    case TYPES.CLEAR_DOCUMENT_COMMENT_LIST:
      return {
        ...state,
        commentsList: null
      }

    case TYPES.SET_DOC_COMMENTS_LOADING:
      return {
        ...state,
        isDocCommentsLoading: payload
      }

    case TYPES.TOGGLE_LOADING_CHECK_DOCUMENT_TITLE:
      return {
        ...state,
        isLoadingCheckDocumentTitle: payload
      }

    default:
      return state
  }
}