import React from 'react'
import { IIcon } from './IIcon'

export const Rows:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#1A65DA',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 12.75H4.5C4.10218 12.75 3.72064 12.908 3.43934 13.1893C3.15804 13.4706 3 13.8522 3 14.25V18C3 18.3978 3.15804 18.7794 3.43934 19.0607C3.72064 19.342 4.10218 19.5 4.5 19.5H19.5C19.8978 19.5 20.2794 19.342 20.5607 19.0607C20.842 18.7794 21 18.3978 21 18V14.25C21 13.8522 20.842 13.4706 20.5607 13.1893C20.2794 12.908 19.8978 12.75 19.5 12.75ZM19.5 18H4.5V14.25H19.5V18ZM19.5 4.5H4.5C4.10218 4.5 3.72064 4.65804 3.43934 4.93934C3.15804 5.22064 3 5.60218 3 6V9.75C3 10.1478 3.15804 10.5294 3.43934 10.8107C3.72064 11.092 4.10218 11.25 4.5 11.25H19.5C19.8978 11.25 20.2794 11.092 20.5607 10.8107C20.842 10.5294 21 10.1478 21 9.75V6C21 5.60218 20.842 5.22064 20.5607 4.93934C20.2794 4.65804 19.8978 4.5 19.5 4.5ZM19.5 9.75H4.5V6H19.5V9.75Z"
      fill={color}/>
  </svg>
)