import {
  SET_LOADING_USERS_PROJECT_START,
  GET_USERS_PROJECT_SUCCESS,
  GET_USERS_PROJECT_FAILED,
  GET_ALL_PROJECT_USERS_SUCCESS,
  ADD_PROJECT_USERS
} from './constants'

export const setLoadingUsersProject = () => ({
  type: SET_LOADING_USERS_PROJECT_START
})

export const getUsersProjectSuccess = data => ({
  type: GET_USERS_PROJECT_SUCCESS,
  payload: data
})

export const getUsersProjectFailed = () => ({
  type: GET_USERS_PROJECT_FAILED
})

export const getAllProjectUsersSuccess = (data) => ({
  type: GET_ALL_PROJECT_USERS_SUCCESS,
  payload: data
})

export const addProjectUsersAction = (projectUsers) => ({
  type: ADD_PROJECT_USERS,
  payload: projectUsers
})