//----Шаблоны удостоверений----
export const GET_LICENSES_TEMPLATES_LIST = 'GET_LICENSES_TEMPLATES_LIST'
export const SET_IS_LOADING_LICENSES = 'SET_IS_LOADING_LICENSES'
export const CLEAR_LICENSES_TEMPLATES_LIST = 'CLEAR_LICENSES_TEMPLATES_LIST'
//----Типы шаблонов удостоверений----
export const GET_LICENSES_TYPES = 'GET_LICENSES_TYPES'
export const SET_IS_LOADING_LICENSES_TYPES = 'SET_IS_LOADING_LICENSES_TYPES'
export const CLEAR_LICENSES_TYPES = 'CLEAR_LICENSES_TYPES'
//----CRUD на шаблон удостоверения----
export const GET_LICENSE_TEMPLATE = 'GET_LICENSE_TEMPLATE'
export const CREATE_LICENSE_TEMPLATE = 'CREATE_LICENSE_TEMPLATE'
export const DELETE_LICENSE_TEMPLATE = 'DELETE_LICENSE_TEMPLATE'
export const DELETE_LICENSE_TEMPLATE_LOADING = 'DELETE_LICENSE_TEMPLATE_LOADING,'
export const UPDATE_LICENSE_TEMPLATE = 'DELETE_LICENSE_TEMPLATE'

export const SET_IS_LOADING_LICENSE_TEMPLATE = 'SET_IS_LOADING_LICENSE_TEMPLATE'
export const SET_LOADING_CREATE_LICENSE_TEMPLATE = 'SET_LOADING_CREATE_LICENSE_TEMPLATE'
export const SET_IS_LOADING_CREATE_LICENSE = 'SET_IS_LOADING_CREATE_LICENSE'



