import React from 'react'
import cn from 'classnames'

export const BlockWrapper: React.FC<{ title: string, className?: string, children: React.ReactNode, id?: any }> = React.memo(({
  title,
  children,
  className,
  id,
}) => (
  <div className={cn('create-admission-order__block', className)} id={id}>
    <div className='block__header'>
      <h3>{title}</h3>
    </div>
    <div className='block__body'>
      {children}
    </div>
  </div>
))