import { IProjectRole } from "../../../../(deprecated)/models/IProjectRole";
import { snakecaseFields } from "../../../../(deprecated)/utils/formatFields";

export class PutAssignUsersProjectRole {

  query = (params: {
    roleId: number
    data: {
      addingUsers?: number[],
      allUsers?: boolean
    },
  }) => ({
    url: `/role_access/${params.roleId}/add_users_to_role`,
    method: 'PUT',
    body: snakecaseFields(params.data),
  })
}