export const abortController = (() => {
    const _instances: { [path: string]: AbortController } = {}

    const abort = (path: string) => {
        _instances?.[path]?.abort()
    }

    const getSignal = (path: string) => {
        const instance = new AbortController()
        _instances[path] = instance
        return instance.signal
    }

    return {
        abort,
        getSignal
    }
})()
