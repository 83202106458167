import { addedNewNotification } from '../../../redux/notifications/actions'
import { ISystemUserModel } from '../SystemUserAdapter'
import store from '../../../redux/store'

type UpdateUserQuery = {
  id: string
  role: ISystemUserModel['companyRole']
}

export class PutUserRoleByIdQuery {
  query = ({ role, id }: UpdateUserQuery) => {
    return {
      url: `users/${id}/update_company_role`,
      method: 'PUT',
      body: { company_role: role }
    }
  }

  transformResponse = () => {
    const message = 'Роль сотрудника в компании успешно изменена.'

    store.dispatch(
      addedNewNotification({ message })
    )

    return message
  }

  transformErrorResponse = () => {
    const message = 'Не удалось изменить роль сотрудника.'

    store.dispatch(
      addedNewNotification({ message })
    )

    return message
  }
}