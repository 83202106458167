import React from 'react'
import { IIcon } from './IIcon'

export const NumberCircleOne:React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  className = '',
  ...innerProps
}) => (
  <svg {...innerProps} className={className} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM13.125 16.5C13.125 16.6989 13.046 16.8897 12.9053 17.0303C12.7647 17.171 12.5739 17.25 12.375 17.25C12.1761 17.25 11.9853 17.171 11.8447 17.0303C11.704 16.8897 11.625 16.6989 11.625 16.5V8.90625L10.5413 9.62906C10.4593 9.68373 10.3673 9.7217 10.2706 9.74083C10.174 9.75995 10.0745 9.75985 9.97784 9.74052C9.88121 9.72119 9.78933 9.68302 9.70745 9.62819C9.62557 9.57336 9.55529 9.50293 9.50063 9.42094C9.44597 9.33894 9.40799 9.24699 9.38887 9.15031C9.36974 9.05364 9.36985 8.95415 9.38917 8.85752C9.4085 8.76089 9.44667 8.66901 9.5015 8.58713C9.55634 8.50525 9.62676 8.43498 9.70875 8.38031L11.9588 6.88031C12.0714 6.80515 12.2023 6.7619 12.3375 6.75513C12.4728 6.74836 12.6073 6.77834 12.7269 6.84188C12.8465 6.90542 12.9467 7.00016 13.0168 7.11603C13.0868 7.2319 13.1242 7.36458 13.125 7.5V16.5Z"
      fill={color}/>
  </svg>
)