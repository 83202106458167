import {
  getToken,
  getRefresh,
  setToken,
  getExpirationTime
} from '../localStorageManagement'
import { errorProccessing } from './errorProccessing'
import { setAccessToken } from '../../redux/user/actionCreators'

import axios from 'axios'
import HOST from './host'
import store from '../../redux/store'

export const getHeaders = async () => {
  const token = getToken()
  let newToken = { data: {} }
  if (token) {
    const expirationTime = getExpirationTime()
    const currentTime = Date.now()
    if ((Number(expirationTime * 1000) - currentTime) < 1000) {
      const refresh = getRefresh()
      const REQUEST_URL = HOST.getInstance().getRequestUrl()

      try {
        newToken = await axios.post(`${REQUEST_URL}/users/auth/refresh`, { refresh })
      } catch (e) {
        errorProccessing(e)
      }
      setToken({ ...newToken.data, refresh })
      store.dispatch(setAccessToken(newToken.data.access))
    }
  }

  return token
    ? ({
      Accept: '*/*',
      Authorization: `Bearer ${newToken.data.access || token}`,
    })
    : ({
      Accept: '*/*',
    })
}