import { useEffect } from 'react'
import { getProjectIdExtended, setProjectExtended } from '../../../(deprecated)/utils/localStorageManagement'
import { settingActiveProject } from '../../../(deprecated)/redux/project/actions'
import { useTDispatch, useTSelector } from '../../../(deprecated)/utils/hooks/reduxHooks'
import { IProject} from '../../../(deprecated)/models/IProject'

export const useSetUserProjectToLS = () => {

  const dispatch = useTDispatch()

  const userInfo = useTSelector(state => state.user.userInfo)
  const projectList = useTSelector((state:any) => state.project.projectList) as IProject[]
  const finishGetProject = useTSelector((state: any) => state.project.finishGetProject)
  const activeProjectId = useTSelector(state => state.app.activeProjectId)

  const isEmptyProject = !projectList.length && finishGetProject

  useEffect(() => {
    if (isEmptyProject || !userInfo.id)
      return

    const storagedProjectId = getProjectIdExtended(userInfo?.id)
    const projectListIds = projectList.map(proj => proj.id)

    if (projectListIds.includes(storagedProjectId)) {
      dispatch(settingActiveProject(storagedProjectId))
      return
    }

    setProjectExtended({
      userId: userInfo.id,
      projectId: activeProjectId,
    })
    dispatch(settingActiveProject(projectList[0]?.id))
  }, [projectList])
}